<template>
    <app-layout title="Dashboard - My Bikes">
        <template #header>
        </template>
        <Card
            v-if="!saveUpdate"
            title="Bikes -- Add / Remove or Edit your bikes here.">
            <Teleport to="body"><FlashMessage /></Teleport>
            <jet-form-section>
                <template #form @submit.prevent="updateMemberBike" >
                    <div v-if="showAdd === true" class="col-span-6 sm:col-span-4">
                        <h3 class="mt-4 font-medium text-navy-300">Motorcycle{{ motorcycles > 1 ? 's' : '' }}</h3>
                        <Motorcycle
                            v-for="n in motorcycles"
                            :key="'member-motorcycle-' + n"
                            :index="n-1"
                            :states="states"
                            v-model:motorcycle-year="form.member_motorcycles[n-1].motorcycle_year"
                            v-model:motorcycle-make="form.member_motorcycles[n-1].motorcycle_make"
                            v-model:motorcycle-model="form.member_motorcycles[n-1].motorcycle_model"
                            v-model:motorcycle-color="form.member_motorcycles[n-1].motorcycle_color"
                            v-model:motorcycle-plate="form.member_motorcycles[n-1].motorcycle_plate"
                            v-model:motorcycle-state="form.member_motorcycles[n-1].motorcycle_state"
                            v-model:motorcycle-country="form.member_motorcycles[n-1].motorcycle_country"
                            v-model:motorcycle-vin="form.member_motorcycles[n-1].motorcycle_vin"
                            v-model:trike-autocycle="form.member_motorcycles[n-1].trike_autocycle"
                            :errors="form.errors"
                        />
                        <jet-button type="button" @click.prevent="addAnotherMotorcycle();" class="ml-4 mt-4">
                            Add Another Bike
                        </jet-button>
                        <jet-button v-if="motorcycles > 1" type="button" @click.prevent="removeMotorcycle();" class="ml-4 mt-4">
                            Remove Bike
                        </jet-button>
                        <jet-button v-if="showAdd" @click.prevent="updateMemberBike()" class="ml-5" :disabled="form.processing">
                            Save
                        </jet-button>
                    </div>
                    <BikesTable
                        :data="bikes"
                        :states="states"
                        @bikes-updated="fetchMemberBikes()"
                        @row-deleted="rowDeleted($event, 'bike')"
                        ref="bikeTable"
                    />
                    <jet-button class="m-5" @click.prevent="showAddMotorcycle()" >
                        Add Another Bike
                    </jet-button>
                </template>
            </jet-form-section>
        </Card>
        <Card
            v-if="saveUpdate"
            title="Bikes -- Payment">
            <jet-form-section>
                <template #form @submit="submit" >
                     <h3 class="mt-4 font-medium text-navy-300">Payment breakdown:</h3>
                     <p>You are paying to insure {{ this.ccForm.difference }} more motorcycle{{ form.member_motorcycles.length > 1 ? "s" : ""}} at a cost of ${{ this.ccForm.amount}}.</p>
                     <h3 class="mt-10">Credit Card Information</h3>
                    <CreditCard
                        v-model:card-holder="ccForm.card_holder"
                        v-model:card-number="ccForm.card_number"
                        v-model:exp-month="ccForm.exp_month"
                        v-model:exp-year="ccForm.exp_year"
                        v-model:ccv="ccForm.ccv"
                        :errors="ccForm.errors"
                    />
                    <jet-button class="mt-5" @click.prevent="saveAndUpdate()" >
                        SAVE AND UPDATE
                    </jet-button>
                </template>
            </jet-form-section>
        </Card>
    </app-layout>
</template>
<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../../DashboardPartials/MemberHeader.vue'
    import Motorcycle from '../Memberships/Forms/Partials/Motorcycles.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import BikesTable from './Tables/BikesTable.vue'
    import Card from '@/Components/Card.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import CreditCard from './Forms/Partials/CreditCard.vue';

    export default defineComponent({
        mixins: [formattingMixin],

        components: {
            AppLayout,
            JetApplicationLogo,
            MemberHeader,
            FlashMessage,
            BikesTable,
            Card,
            Motorcycle,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            CreditCard,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    member_motorcycles: [{
                        motorcycle_year: '',
                        motorcycle_make: '',
                        motorcycle_model: '',
                        motorcycle_color: '',
                        motorcycle_plate: '',
                        motorcycle_state: '',
                        motorcycle_country: '',
                        motorcycle_vin: '',
                        trike_autocycle: 0,
                    }],
                }),
                ccForm: this.$inertia.form({
                    _method: 'POST',
                    member_id: '',
                    amount: '',
                    card_holder: '',
                    card_number: '',
                    exp_month: '',
                    exp_year: '',
                    ccv: '',
                    difference: '',
                    member_motorcycles: [],
                }),
                bikes: [],
                states: [],
                motorcycles: 1,
                showAdd: false,
                saveUpdate: false,
                extraPer: '',
            }
        },
        methods: {
            fetchMemberBikes() {
                var memberId = this.$page.props.member.member.member_id;

                axios({
                    method: 'post',
                    url: route('dashboard.motorists.membership.fetch_member_bikes'),
                    data: {
                        member_id: memberId
                    }
                })
                .then(response => {
                    this.bikes   = response.data.data;
                    this.form.id = memberId;
                    this.showAdd = false;
                })
            },
            fetchPaymentInformation() {
                var memberId = this.$page.props.member.member.member_id;
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.membership.new_bikes_payment_data'),
                    data: {
                        member_id: memberId,
                        motorcycles: this.form.member_motorcycles,
                    }
                })
                .then(response => {
                    this.ccForm.amount     = parseFloat(response.data.amount);
                    this.ccForm.member_id  = memberId;
                    this.ccForm.difference = response.data.difference;
                    this.extraPer          = response.data.plan_extra_pr_mc;
                    this.ccForm.member_motorcycles = this.form.member_motorcycles;
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                    }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            rowDeleted(id, table) {
                this.$inertia.post('/dashboard/motorists/memberships/delete_bike/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchMemberBikes();
                        this.$refs.bikeTable.closeModal();
                    }
                })
            },
            showAddMotorcycle () {
                this.showAdd = true;
            },
            addAnotherMotorcycle () {
                this.motorcycles++;
                this.form.member_motorcycles.push({
                    motorcycle_year: '',
                    motorcycle_make: '',
                    motorcycle_model: '',
                    motorcycle_color: '',
                    motorcycle_plate: '',
                    motorcycle_state: '',
                    motorcycle_country: '',
                    motorcycle_vin: '',
                    trike_autocycle: 0,
                })
            },
            removeMotorcycle () {
                this.motorcycles--;
                this.form.member_motorcycles.pop();
            },
            updateMemberBike() {
                this.form.post(route('dashboard.motorists.membership.bikes_update'), {
                    errorBag: 'addModifyMemberBikes',
                    preserveScroll: true,
                    onSuccess: () => {
                        if(this.$page.props.flash.success == 'Payment required.'){
                            this.saveUpdate = true;
                            this.fetchPaymentInformation();
                        } else {
                            this.form.reset();
                            this.fetchMemberBikes();
                        }
                    }
                });
            },
            saveAndUpdate() {
                this.ccForm.post(route('dashboard.motorists.membership.new_bikes_payment'), {
                    errorBag: 'bikePayment',
                    onSuccess: () => {
                        if(this.$page.props.flash.success == 'Your payment has been accepted and your new motorcycles have been added to your policy.'){
                            this.form.reset();
                            this.ccForm.reset();
                            this.fetchMemberBikes();
                            this.saveUpdate = false;
                        }
                    }
                });
            },
        },
        mounted() {
            this.fetchMemberBikes();
            this.getStates();
        }
    });
</script>
