<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
            <Card
            title="Towing Company"
            >
                <div>
                    <Teleport to="body"><FlashMessage /></Teleport>
                    <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                        BACK
                    </JetButton>
                </div>
            <form @submit.prevent="submit" id="TowingCompanyForm">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="company_name" value="Company Name" />
                        <jet-input id="company_name" type="text" class="mt-1 block w-full" v-model="form.company_name" name="company_name" required  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.company_name" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="join_status" value="Status" />
                        <select id="join_status" class="mt-1 block w-full" name="join_status" v-model="form.join_status" required >
                            <option value=""> -- </option>
                            <option v-for='data in status' :value='data.value' :key="data.value">{{ data.value }}</option>

                        </select>
                        <jet-input-error :message="form.errors.join_status" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="first_name" value="First Name" />
                        <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" name="first_name" :readonly="readonly"/>
                        <jet-input-error :message="form.errors.first_name" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="last_name" value="Last Name" />
                        <jet-input id="last_name" type="text" class="mt-1 block w-full" name="last_name" v-model="form.last_name" :readonly="readonly"/>
                        <jet-input-error :message="form.errors.last_name" class="mt-2" />
                    </div>
                </div>
                <div>
                    <jet-label for="address" value="Address" />
                    <jet-input id="address" type="text" class="mt-1 block w-full" name="address" v-model="form.address" required  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.address" class="mt-2" />
                </div>

                <div>
                    <jet-label for="address2" value="Address 2" />
                    <jet-input id="address2" type="text" class="mt-1 block w-full" name="address2" v-model="form.address2"  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.address2" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="city" value="City" />
                        <jet-input id="city" type="text" class="mt-1 block w-full" name="city" v-model="form.city" required  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.city" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="state" value="State/Province" />
                        <select id="state" class="mt-1 block w-full" name="state" v-model="form.state" required  :disabled="readonly">
                            <option value=""> -- </option>
                            <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>

                        </select>
                        <jet-input-error :message="form.errors.state" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="zip" value="Zip/Postal Code" />
                        <jet-input id="zip" type="text" class="mt-1 block w-full" name="zip" v-model="form.zip" required  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.zip" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="country" value="Country" />
                        <jet-input id="country" type="text" class="mt-1 block w-full" name="country" v-model="form.country" required :readonly="readonly"/>
                        <jet-input-error :message="form.errors.country" class="mt-2" />
                    </div>
                    <div>
                        <vue-tel-input id="office_phone" label="Office Phone" v-model="form.office_phone" :error-message="form.errors.office_phone" />
                    </div>
                    <div>
                        <vue-tel-input id="fax" label="Office Fax" v-model="form.fax" :error-message="form.errors.fax" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <vue-tel-input id="cell" label="Cell Phone" v-model="form.cell" :error-message="form.errors.cell" />
                    </div>

                    <div>
                        <jet-label for="email" value="Email" />
                        <jet-input id="email" type="email" class="mt-1 block w-full" name="email" v-model="form.email" :readonly="readonly"/>
                        <jet-input-error :message="form.errors.email" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="website" value="Website" />
                        <jet-input id="website" type="text" class="mt-1 block w-full" name="website" v-model="form.website"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.website" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="social_url1" value="Social URL" />
                        <jet-input id="social_url1" type="text" class="mt-1 block w-full" name="social_url1" v-model="form.social_url1"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.social_url1" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="social_url2" value="Social URL" />
                        <jet-input id="social_url2" type="text" class="mt-1 block w-full" name="social_url2" v-model="form.social_url2"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.social_url2" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="social_url3" value="Social URL" />
                        <jet-input id="social_url3" type="text" class="mt-1 block w-full" name="social_url3" v-model="form.social_url3"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.social_url3" class="mt-2" />
                    </div>
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Mailing Address</h3>
                <div class="grid md:grid-cols-12 gap-1">
                    <jet-label class="md:col-span-3 h4 text-lg" for="same_as_company_address" value="Same as Company Address" />
                    <jet-input id="same_as_company_address" type="checkbox" class="mt-1 md:col-span-9" v-model="form.same_as_company_address" name="same_as_company_address" @change="copyToMailingAddress()"  :disabled="readonly"/>
                    <jet-input-error :message="form.errors.same_as_company_address" class="mt-2" />
                </div>
                <div>
                    <jet-label for="mailing_address" value="Address" />
                    <jet-input id="mailing_address" type="text" class="mt-1 block w-full" v-model="form.mailing_address" name="mailing_address" required  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.mailing_address" class="mt-2" />
                </div>

                <div>
                    <jet-label for="mailing_address2" value="Address 2" />
                    <jet-input id="mailing_address2" type="text" class="mt-1 block w-full" name="mailing_address2" v-model="form.mailing_address2"  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.mailing_address2" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="mailing_city" value="City" />
                        <jet-input id="mailing_city" type="text" class="mt-1 block w-full" name="mailing_city" v-model="form.mailing_city" required  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.mailing_city" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="mailing_state" value="State/Province" />
                        <select id="mailing_state" class="mt-1 block w-full" name="mailing_state" v-model="form.mailing_state" :disabled="readonly" required >
                            <option value=""> -- </option>
                            <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>

                        </select>
                        <jet-input-error :message="form.errors.mailing_state" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="mailing_zip" value="Zip/Postal Code" />
                        <jet-input id="mailing_zip" type="text" class="mt-1 block w-full" name="mailing_zip" v-model="form.mailing_zip" required  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.mailing_zip" class="mt-2" />
                    </div>
                </div>

                <div>
                    <jet-label for="mailing_country" value="Country" />
                    <jet-input id="mailing_country" type="text" class="mt-1 block w-full" name="mailing_country" v-model="form.mailing_country" required  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.mailing_country" class="mt-2" />
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Insurance Information</h3>
                <div>
                    <jet-label for="insurance_company" value="Insurance Company" />
                    <jet-input id="insurance_company" type="text" class="mt-1 block w-full" name="insurance_company" v-model="form.insurance_company"  :readonly="readonly"/>
                    <jet-input-error :message="form.errors.insurance_company" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="insurance_agent" value="Agent/Contact Name" />
                        <jet-input id="insurance_agent" type="text" class="mt-1 block w-full" name="insurance_agent" v-model="form.insurance_agent"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.insurance_agent" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="insurance_agent_number" value="Agent/Contact Number" />
                        <jet-input id="insurance_agent_number" type="text" class="mt-1 block w-full" name="insurance_agent_number" v-model="form.insurance_agent_number"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.insurance_agent_number" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="insurance_expire_date" value="Insurance Expires" />
                        <jet-input id="insurance_expire_date" type="date" class="mt-1 block w-full" name="insurance_expire_date" v-model="form.insurance_expire_date"  :readonly="readonly"/>
                        <jet-input-error :message="form.errors.insurance_expire_date" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-1 gap-4" v-if="form.insurance">
                    <div>
                        <jet-label for="insurance" value="Imported Insurance Info" />
                        <textarea id="insurance" class="mt-1 block w-full" name="insurance" v-model="form.insurance"></textarea>
                        <jet-input-error :message="form.errors.insurance" class="mt-2" />
                    </div>
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Service Charges</h3>
                <table class="w-100">
                    <tr class="mt-2">
                        <th class="text-blue-500 h4">Type/Service</th>
                        <th>Base Rate</th>
                        <th>On Route Mileage</th>
                        <th>Charge/Loaded Mile</th>
                    </tr>
                    <tr class="mt-2">
                        <td class="font-medium">Hook-up/Dispatch</td>
                        <td>
                            <jet-input id="service_coverage_hookup_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_base_rate" v-model="form.service_coverage_hookup_base_rate" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_hookup_base_rate" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_hookup_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_mileage_allowance" v-model="form.service_coverage_hookup_mileage_allowance" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_hookup_mileage_allowance" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_hookup_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_charge_mile" v-model="form.service_coverage_hookup_charge_mile" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_hookup_charge_mile" class="mt-2" />
                        </td>
                    </tr>
                    <tr class="mt-2">
                        <td class="font-medium">Jump Start</td>
                        <td>
                            <jet-input id="service_coverage_jump_start_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_base_rate" v-model="form.service_coverage_jump_start_base_rate" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_jump_start_base_rate" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_jump_start_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_mileage_allowance" v-model="form.service_coverage_jump_start_mileage_allowance" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_jump_start_mileage_allowance" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_jump_start_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_charge_mile" v-model="form.service_coverage_jump_start_charge_mile" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_jump_start_charge_mile" class="mt-2" />
                        </td>
                    </tr>
                    <tr class="mt-2">
                        <td class="font-medium">Fuel Delivery</td>
                        <td>
                            <jet-input id="service_coverage_fuel_delivery_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_base_rate" v-model="form.service_coverage_fuel_delivery_base_rate" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_fuel_delivery_base_rate" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_fuel_delivery_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_mileage_allowance" v-model="form.service_coverage_fuel_delivery_mileage_allowance" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_fuel_delivery_mileage_allowance" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_fuel_delivery_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_charge_mile" v-model="form.service_coverage_fuel_delivery_charge_mile" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_fuel_delivery_charge_mile" class="mt-2" />
                        </td>
                    </tr>
                    <tr class="mt-2">
                        <td class="font-medium">GOA</td>
                        <td>
                            <jet-input id="service_coverage_goa_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_base_rate" v-model="form.service_coverage_goa_base_rate" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_goa_base_rate" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_goa_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_mileage_allowance" v-model="form.service_coverage_goa_mileage_allowance" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_goa_mileage_allowance" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_goa_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_charge_mile" v-model="form.service_coverage_goa_charge_mile" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_goa_charge_mile" class="mt-2" />
                        </td>
                    </tr>
                    <tr class="mt-2">
                        <td class="font-medium">Storage/Day</td>
                        <td>
                            <jet-input id="service_coverage_storage_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_base_rate" v-model="form.service_coverage_storage_base_rate" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_storage_base_rate" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_storage_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_mileage_allowance" v-model="form.service_coverage_storage_mileage_allowance" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_storage_mileage_allowance" class="mt-2" />
                        </td>
                        <td>
                            <jet-input id="service_coverage_storage_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_charge_mile" v-model="form.service_coverage_storage_charge_mile" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.service_coverage_storage_charge_mile" class="mt-2" />
                        </td>
                    </tr>
                </table>
                <div class="grid grid-cols-1 md:grid-cols-1 gap-4" v-if="form.charges">
                    <div>
                        <jet-label for="charges" value="Imported Service Charge" />
                        <textarea id="charges" class="mt-1 block w-full" name="charges" v-model="form.charges" :disabled="readonly"></textarea>
                        <jet-input-error :message="form.errors.charges" class="mt-2" />
                    </div>
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Additional Charges/Exceptions</h3>
                <div>
                    <jet-label for="additional_charges" value="Additional Charges/Exceptions" />
                    <textarea id="additional_charges" class="mt-1 block w-full" name="additional_charges" v-model="form.additional_charges" :disabled="readonly"></textarea>
                    <jet-input-error :message="form.errors.additional_charges" class="mt-2" />
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Company Recommendations</h3>
                <div>
                    <jet-label for="company_recommendation" value="Is there another company you recommend in the event you are not available?" />
                    <textarea id="company_recommendation" class="mt-1 block w-full" name="company_recommendation" v-model="form.company_recommendation" :disabled="readonly"></textarea>
                    <jet-input-error :message="form.errors.company_recommendation" class="mt-2" />
                </div>

                <h3 class="font-medium text-navy-300 mt-4">Equipment Information</h3>
                <div class="grid grid-cols-2 lg:grid-cols-5">
                    <div v-for="data in towing_equipment" v-bind:key="data.id" class="grid grid-cols-4 items-end" >
                        <jet-label class="mt-4 text-right mr-2 col-span-3" :for="`equipment${data.id}`" :value="data.name"  />
                        <input
                            :id="`equipment${data.id}`"
                            :name="`Equipment-${data.id}`"
                            type="checkbox"
                            class="mt-1 col-span-1 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            :checked="form.equipment.map(v=>Number(v)).includes(data.id)"
                            :value="data.id"
                            @input="updateEquipment()"
                        />
                    </div>
                </div>
                <!-- </template> -->
                <h3 class="font-medium text-navy-300 mt-4">Notes / Alerts</h3>
                <div class="grid grid-cols-2 gap-4">
                    <div>
                        <jet-label for="notes" value="Notes" />
                        <textarea id="notes" class="mt-1 block w-full" name="notes" v-model="form.notes" rows="5" :disabled="readonly"></textarea>
                        <jet-input-error :message="form.errors.notes" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="alert_notes" value="Alert" />
                        <textarea id="alert_notes" class="mt-1 block w-full" name="alert_notes" v-model="form.alert_notes" rows="5" :disabled="readonly"></textarea>
                        <jet-input-error :message="form.errors.alert_notes" class="mt-2" />
                    </div>
                </div>
                <div class="flex items-center justify-end mt-10">
                    <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing" v-if="!readonly">
                        Save
                    </JetButton>
                </div>
            </form>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import FlashMessage from '@/Pages/DashboardPartials/FlashMessages'
    import Card from '@/Components/Card.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            AppLayout,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetInputError,
            Link,
            FlashMessage,
            Card,
            VueTelInput,
        },
        data(){
            return{
                form: this.$inertia.form({
                    id: 0,
                    join_status: 'Pending',
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    address_id: 0,
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    office_phone: '',
                    fax: '',
                    cell: '',
                    email: '',
                    website: '',
                    social_url1: '',
                    social_url2: '',
                    social_url3: '',
                    same_as_company_address: false,
                    mailing_address_id: 0,
                    mailing_address: '',
                    mailing_address2: '',
                    mailing_city: '',
                    mailing_state: '',
                    mailing_zip: '',
                    mailing_country: '',
                    insurance_company: '',
                    insurance_agent: '',
                    insurance_agent_number: '',
                    insurance_expire_date: null,
                    service_coverage_id: 0,
                    service_coverage_hookup_base_rate: '',
                    service_coverage_hookup_mileage_allowance: '',
                    service_coverage_hookup_charge_mile: '',
                    service_coverage_jump_start_base_rate: '',
                    service_coverage_jump_start_mileage_allowance: '',
                    service_coverage_jump_start_charge_mile: '',
                    service_coverage_fuel_delivery_base_rate: '',
                    service_coverage_fuel_delivery_mileage_allowance: '',
                    service_coverage_fuel_delivery_charge_mile: '',
                    service_coverage_goa_base_rate: '',
                    service_coverage_goa_mileage_allowance: '',
                    service_coverage_goa_charge_mile: '',
                    service_coverage_storage_base_rate: '',
                    service_coverage_storage_mileage_allowance: '',
                    service_coverage_storage_charge_mile: '',
                    additional_charges: '',
                    company_recommendation: '',
                    equipment: [],
                    terms: false,
                    notes: '',
                    alert_notes: '',
                    insurance: '',
                    charges: ''
                }),
                states: [],
                towing_equipment: [],
                status: [
                    {value: 'Pending'},
                    {value: 'Active'},
                    {value: 'Inactive'},
                    {value: 'Denied/Do Not Use'}
                ],
                isViewOnly: false,
            }
        },
        computed: {
            towEquipment: {
                get() {
                    return this.form.equipment
                },
                set(value) {
                    let combined = [...new Set([...this.form.equipment, value])];
                    if (this.form.equipment.includes(value)) combined.splice(combined.indexOf(value), 1);
                    this.form.equipment = combined;
                }
            },
            readonly() {
                return this.isViewOnly === true;
            }
        },
        methods: {
            submit() {
                this.form.post('/dashboard/towing-companies/update_towing_company', {
                    errorBag: 'TowingCompanyForm',
                    preserveScroll: true,
                    onSuccess: () => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                        this.resetForm();
                    },
                });
            },
            copyToMailingAddress: function() {
                if (this.form.same_as_company_address){
                    this.form.mailing_address = this.form.address;
                    this.form.mailing_address2 = this.form.address2;
                    this.form.mailing_city = this.form.city;
                    this.form.mailing_state = this.form.state;
                    this.form.mailing_zip = this.form.zip;
                    this.form.mailing_country = this.form.country;
                } else {
                    this.form.mailing_address = "";
                    this.form.mailing_address2 = "";
                    this.form.mailing_city = "";
                    this.form.mailing_state = "";
                    this.form.mailing_zip = "";
                    this.form.mailing_country = "";
                }
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            updateEquipment() {
                let val   = event.target.value,
                    equip = this.form.equipment;

                if (equip.includes(val)) {
                    equip.splice(equip.indexOf(val), 1);
                } else {
                    equip.push(val);
                }
            },
            getEquipment: function() {
                axios.get(route('.towing-companies.fetch_equipment'),{

                }).then(function(response){
                    this.towing_equipment = response.data;
                }.bind(this));
            },
            getTowingCompany() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var id = parseInt(last_segment);

                // If the company ID > 0, lets fetch the company data
                if (Number.isInteger(id) && id > 0)
                {
                    this.form.id = id;
                    if(segment_array.includes('view_towing_company')){
                        this.isViewOnly = true;
                    }
                    axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.fetch_towing_company'),
                    data: {
                        towingCompany: id
                    }

                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                            this.form[key] = value;
                    });
                })
                } else {
                    this.form.id = 0;
                }
            },
            resetForm() {
                if (!this.form.id)
                {
                    this.form.reset();

                    // Find all check boxes and reset them to their default state
                    // It finds the checkboxes with the class attribute "checkbox"
                    var allInputs = document.getElementsByTagName("input");
                    for (var i = 0, max = allInputs.length; i < max; i++){
                        if (allInputs[i].type === 'checkbox')
                            allInputs[i].checked = allInputs[i].defaultChecked;
                    }
                }
            },
            back() {
              window.history.back();
            },
        },
        created: function(){
            this.getStates();
            this.getEquipment();
            this.getTowingCompany();
        },
        mounted: function(){
        }
    })
</script>
