<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div class="memberships-table">
        <MembershipsTable />
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MembershipsTable from './Tables/MembershipsTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'

    export default ({
        mixins: [formattingMixin],
        components: {
            JetApplicationLogo,
            MembershipsTable,
            FlashMessage,
        },
    })
</script>
