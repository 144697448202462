<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <form class="search-form" @submit.prevent="applyFilters()">
        <div class="search-fields md:align-center search flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center justify-between">
                <label for="date" class="m-1">Date Range</label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                <div class="m-1 w-100 md:w-auto">
                <label for="login_type" class="m-1">Member Type </label>
                <select v-model="login_type" class="border py-2 pl-3 pr-8 text-grey-800 m-1 w-100 md:w-auto">
                    <option value=""> - </option>
                    <option
                        v-for="option in user_type_list"
                        :key="option.value"
                        :value="option.name"
                    >{{option.name}}</option>
                </select>
                </div>
            </div>
            <div>
        <jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetButton></div>
        </div>
    </form>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!users.isLoading">
        <div class="st-hidden-sorts">
            <p :class="users.sortClass('username')" @click="users.sortBy('username')">Username</p>
            <p :class="users.sortClass('name')" @click="users.sortBy('name')" >Name</p>
            <p :class="users.sortClass('email')" @click="users.sortBy('email')">Email</p>
            <p :class="users.sortClass('team:name')" @click="users.sortBy('team:name')">Type</p>
            <p :class="users.sortClass('last_logged_in')" @click="users.sortBy('last_logged_in','date')">Expiration</p>

        </div>
        <div class="simple-table" style="--cols:6">
            <p class="st-header first-col" :class="users.sortClass('username')" @click="users.sortBy('username')">Username</p>
            <p class="st-header" :class="users.sortClass('name')" @click="users.sortBy('name')" >Name</p>
            <p class="st-header" :class="users.sortClass('email')" @click="users.sortBy('email')">Email</p>
            <p class="st-header" :class="users.sortClass('team:name')" @click="users.sortBy('team:name')">Type</p>
            <p class="st-header" :class="users.sortClass('last_logged_in')" @click="users.sortBy('last_logged_in','date')">Last Login</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="row in users.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Username</span>{{ row.username }}</p>
                <p><span class="st-hidden">Name</span>{{ row.name }}</p>
                <p><span class="st-hidden">Email</span>{{ row.email }}</p>
                <p><span class="st-hidden">Type</span>{{ row.login_type }}</p>
                <p><span class="st-hidden">Last Login</span>{{ row.last_logged_in }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/users/edit_user/${row.id}`"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-secondary-button v-if="row.role_type != 'AD'">Modify/View Logs</jet-secondary-button>
                        </Link>
                        <Link>
                            <jet-button class="w-max" @click="resetPassword(row.username)">
                                Reset Password
                            </jet-button>
                        </Link>
                        <jet-danger-button v-if="$page.props.user.current_team.name == 'Admin'" @click="confirmUserDeletion(index, row.id)">
                            DELETE
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ users.rangeText }}</p>
            <Pagination
                v-if="users.itemCount || users.itemCount <= users.itemsPerPage"
                :active-page="users.activePage"
                :item-count="users.itemCount"
                :items-per-page="users.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete User Confirmation Modal -->
    <jet-dialog-modal :show="confirmingDeletion" @close="closeModal">
        <template #title>
            Delete User
        </template>

        <template #content>
            Are you sure you want to delete this User? (You will still be able to restore this User if needed.)

        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteUser(this.userId)" >
                Delete User
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>
<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }

    .selected {
        background: var(--gray-200);
        font-weight: bold;
    }

    *.first-col {
        padding-left: 1rem !important;
    }
    label {
        @include fluid-size(12px, 16px, font-size);
        font-size: 14px;
        font-weight: 500;
    }

    .search-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: initial;

         @include bp-up ($bp-md + 1) {
        align-items: center;
         }
    }
</style>
<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import Pagination from '@/Components/Pagination.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import axios from 'axios'
    import VueLoader from '@/Components/VueLoader'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Status,
            JetSecondaryButton,
            JetButton,
            JetDangerButton,
            Link,
            JetDialogModal,
            VueLoader,
            Pagination,
            FlashMessage,
            moment
        },

        data() {
            return {
                form: this.$inertia.form({
                    username: ''
                }),
                users: reactive(new EloquentSSFP()),
                searchModel: '',
                memberId: null,
                userId: null,
                login_type: '',
                confirmingDeletion: false,
                user_type_list: [],
                start_date: null,
                end_date: null,


            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            resetPassword: function (username){
                this.form.username = username;
                this.form.post(this.route('dashboard.users.reset_user_password'));
            },
            confirmUserDeletion(index, id) {
                this.confirmingDeletion = true;
                this.userId = id;
                this.index = index;
            },
            closeModal: function () {
                this.confirmingDeletion = false;
            },
            resetOne: function () {
                this.$refs.customTable.updateActivePage(1);
            },
            deleteUser(id) {
                let options = {...this.users.options};
                this.$inertia.post('/dashboard/users/delete/' + id, id, {
                    onSuccess: (id) => {
                        this.closeModal();
                        this.users.refreshData(options);
                    }
                })
            },
            resetFilters() {
                this.start_date = null;
                this.end_date = null;
                this.$refs.userTable.resetOne();
                this.selected_team = null;
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            applyFilters() {
                let options = {...this.users.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('last_logged_in');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                   if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.login_type) {
                    options['filterProps'].push('team:name');
                    options['filterOperators'].push([null])
                    options['filterValues'].push([this.login_type])
                    options['filterExclusivities'].push(1);
                }

                if (this.status) {
                    options['filterProps'].push('status');
                    options['filterValues'].push([this.status]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }

                this.users.refreshData(options);
            },
            updateActivePage(page) {
                this.users.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            fetchUserTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.users.fetch_user_types'),
                })
                .then(response => {
                    this.user_type_list.push({'value' : '0', 'name' : ''})
                    response.data.data.forEach(element => {
                        this.user_type_list.push(element);
                    });
                })
            },
        },
        mounted() {
            this.users.init('/dashboard/users/fetch_users' , {
                searchableData: ['name', 'username','last_logged_in','email','team:name'],
                itemsPerPage: 50,
            })

        },
        created() {
            this.fetchUserTypes();
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
