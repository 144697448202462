<template>
    <FrontEndLayout title="MTS Towing - Submit Towing Invoice">
        <template #head>
            <meta name="description" content="Submit Towing Invoice">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>SUBMIT AN INVOICE</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container">
                <div class="text-center">
                    <h2 class="font-bold text-navy-500">Complete Our Simple Invoice Form</h2>
                    <p>Please complete the MTS invoice submission form to start the process of receiving payment for a completed tow.</p>
                </div>
            </div>
            <form @submit.prevent="submit" id="submitAnInvoice">
        <div class="gray-section">
            <div class="container md:px-0">
                <h3 class="text-navy-500">Company Information</h3>
                <div>
                    <jet-label for="company_name" value="Company Name" />
                    <jet-input id="company_name" type="text" class="mt-1 block w-full" name="company_name" v-model="form.company_name" required  />
                </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="company_first_name" value="First Name" />
                    <jet-input id="company_first_name" type="text" class="mt-1 block w-full" name="company_first_name" v-model="form.company_first_name" required  />
                    <jet-input-error :message="form.errors.company_first_name" class="mt-2" />
                </div>

                <div>
                    <jet-label for="company_last_name" value="Last Name" />
                    <jet-input id="company_last_name" type="text" class="mt-1 block w-full" name="company_last_name" v-model="form.company_last_name" required  />
                    <jet-input-error :message="form.errors.company_last_name" class="mt-2" />
                </div>
            </div>
                <div>
                    <jet-label for="company_address" value="Address" />
                    <jet-input id="company_address" type="text" class="mt-1 block w-full" name="company_address" v-model="form.company_address" required  />
                    <jet-input-error :message="form.errors.company_address" class="mt-2" />
                </div>

                <div>
                    <jet-label for="company_address2" value="Address 2" />
                    <jet-input id="company_address2" type="text" class="mt-1 block w-full" name="company_address2" v-model="form.company_address2"  />
                    <jet-input-error :message="form.errors.company_address2" class="mt-2" />
                </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="company_city" value="City" />
                    <jet-input id="company_city" type="text" class="mt-1 block w-full" name="company_city" v-model="form.company_city" required  />
                    <jet-input-error :message="form.errors.company_city" class="mt-2" />
                </div>

                <div>
                    <jet-label for="company_state" value="State/Province" />
                    <select id="company_state" class="mt-1 block w-full" name="company_state" v-model="form.company_state" required >
                        <option value="" > -- </option>
                        <option v-for='(data,index) in states' :value="data.abbreviation" :key="index">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.company_state" class="mt-2" />
                </div>

                <div>
                    <jet-label for="company_zip" value="Zip/Postal Code" />
                    <jet-input id="company_zip" type="text" class="mt-1 block w-full" name="company_zip" required v-model="form.company_zip" />
                    <jet-input-error :message="form.errors.company_zip" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label class="mt-4" for="company_country" value="Country" />
                <select id="company_country" class="mt-1 block w-full" name="company_country" v-model="form.company_country" required >
                    <option value=""> -- </option>
                    <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                </select>
                <jet-input-error :message="form.errors.company_country" class="mt-2" />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <vue-tel-input id="company_phone" label="Phone" v-model="form.company_phone" :error-message="form.errors.company_phone" />
                </div>

                <div>
                    <jet-label for="email" value="Company Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" name="email" v-model="form.email" required  />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
            </div>
        </div>
    </div>
            <div class="container md:px-0 py-8">
                <h3>Customer Information</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="member_name" value="Member Name" />
                        <jet-input id="member_name" type="text" class="mt-1 block w-full" name="member_name" v-model="form.member_name" required  />
                        <jet-input-error :message="form.errors.member_name" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="membership_number" value="Membership #/ID" />
                        <jet-input id="membership_number" type="text" class="mt-1 block w-full" name="membership_number" v-model="form.membership_number" required  />
                        <jet-input-error :message="form.errors.membership_number" class="mt-2" />
                    </div>
                </div>
            </div>
    <div class="gray-section">
        <div class="container md:px-0">
            <h3>Tow Information</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="tow_date" value="Date of Tow" />
                    <jet-input id="tow_date" type="date" class="mt-1 block w-full" name="tow_date" v-model="form.tow_date" required  />
                    <jet-input-error :message="form.errors.tow_date" class="mt-2" />
                </div>
                <div>
                    <jet-label for="mts_po_number" value="MTS/PO #" />
                    <jet-input id="mts_po_number" type="text" class="mt-1 block w-full" name="mts_po_number" v-model="form.mts_po_number" required  />
                    <jet-input-error :message="form.errors.mts_po_number" class="mt-2" />
                </div>
                <div>
                    <jet-label for="rental_agreement" value="RA #" />
                    <jet-input id="rental_agreement" type="text" class="mt-1 block w-full" name="rental_agreement" v-model="form.rental_agreement"  />
                    <jet-input-error :message="form.errors.rental_agreement" class="mt-2" />
                </div>
                </div>
                <div>
                    <jet-label for="break_down_location" value="Breakdown Location" />
                    <jet-input id="break_down_location" type="text" class="mt-1 block w-full" name="break_down_location" v-model="form.break_down_location" required  />
                    <jet-input-error :message="form.errors.break_down_location" class="mt-2" />
                </div>
            </div>
        </div>
        <div class="container md:px-0 py-8">
                <h3>Motorcycle Information</h3>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="bike_make" value="Make" />
                        <jet-input id="bike_make" type="text" class="mt-1 block w-full" name="bike_make" v-model="form.bike_make"  required />
                        <jet-input-error :message="form.errors.bike_make" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="bike_year" value="Year" />
                        <jet-input id="bike_year" type="text" class="mt-1 block w-full" name="bike_year" v-model="form.bike_year" />
                        <jet-input-error :message="form.errors.bike_year" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="bike_plate" value="Plate Number" />
                        <jet-input id="bike_plate" type="text" class="mt-1 block w-full" name="bike_plate" v-model="form.bike_plate" />
                        <jet-input-error :message="form.errors.bike_plate" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="bike_state" value="State/Province" />
                        <select id="bike_state" class="mt-1 block w-full" name="bike_state" required v-model="form.bike_state" >
                            <option value="" > -- </option>
                            <option v-for='(data,index) in states' :value="data.abbreviation" :key="index">{{ data.name }}</option>
                        </select>
                        <jet-input-error :message="form.errors.bike_state" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="bike_vin" value="VIN Number" />
                        <jet-input id="bike_vin" type="text" class="mt-1 block w-full" name="bike_vin" v-model="form.bike_vin"/>
                        <jet-input-error :message="form.errors.bike_vin" class="mt-2" />
                    </div>
                </div>
        </div>
        <div class="gray-section">
            <div class="container md:px-0">
                <h3>Charges</h3>
                <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div>
                        <jet-label for="dispatch_fee_charge" value="Base/Hook Up Rate" />
                        <jet-input id="dispatch_fee_charge" type="number" step="0.01" min="0" class="mt-1 block w-full" name="dispatch_fee_charge" v-model="form.dispatch_fee_charge" required />
                        <jet-input-error :message="form.errors.dispatch_fee_charge" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="mileage_charge" value="On Route Miles" />
                        <jet-input id="mileage_charge" type="number" step="1" min="0" class="mt-1 block w-full" name="mileage_charge" v-model="form.mileage_charge" required />
                        <jet-input-error :message="form.errors.mileage_charge" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="miles_charge" value="Rate/Mile" />
                        <jet-input id="miles_charge" type="number" step="0.01" min="0" class="mt-1 block w-full" name="miles_charge" v-model="form.miles_charge" required />
                        <jet-input-error :message="form.errors.miles_charge" class="mt-2" />
                    </div>
                    <div>
                        <h6 class="text-left md:text-right mt-2 md:mt-8">Base Total: {{ moneyMaker(initial_total) }} </h6>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="loaded_rate" value="Loaded Rate/Mile" />
                        <jet-input id="loaded_rate" type="number" step="0.01" min="0" class="mt-1 block w-full" name="loaded_rate" v-model="form.loaded_rate" required />
                        <jet-input-error :message="form.errors.loaded_rate" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="loaded_miles" value="Loaded Miles" />
                        <jet-input id="loaded_miles" type="number" step="1" min="0" class="mt-1 block w-full" name="loaded_miles" v-model="form.loaded_miles" required />
                        <jet-input-error :message="form.errors.loaded_miles" class="mt-2" />
                    </div>
                    <div>
                        <h6 class="text-left md:text-right mt-2 md:mt-8">Loaded Total: {{ moneyMaker(loaded_total) }} </h6>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                    <div class="col-span-2">
                        <jet-label for="storage_rate" value="Storage Rate" />
                        <jet-input id="storage_rate" type="number" step="0.01" min="0" class="mt-1 block w-full" name="storage_rate" v-model="form.storage_rate" />
                        <jet-input-error :message="form.errors.storage_rate" class="mt-2" />
                    </div>
                    <div class="col-span-2">
                        <jet-label for="storage_days" value="# of Days" />
                        <jet-input id="storage_days" type="number" class="mt-1 block w-full" name="storage_days" v-model="form.storage_days" />
                        <jet-input-error :message="form.errors.storage_days" class="mt-2" />
                    </div>
                    <div>
                        <h6 class="text-left md:text-right mt-2 md:mt-8">Storage Total: {{ moneyMaker(storage_total) }} </h6>
                    </div>
                </div>
                    <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div class="col-span-2">
                            <jet-label for="additional_charge" value="Goa/Other Charges" />
                            <jet-input id="additional_charge" type="number" step="0.01" min="0" class="mt-1 block w-full" name="additional_charge" v-model="form.additional_charge" />
                            <jet-input-error :message="form.errors.additional_charge" class="mt-2" />
                        </div>
                        <div class="col-span-2">
                            <jet-label for="additional_charge_notes" value="Goa/Other Charges Notes" />
                            <textarea id="additional_charge_notes" class="mt-1 block w-full" name="additional_charge_notes" v-model="form.additional_charge_notes" />
                            <jet-input-error :message="form.errors.additional_charge_notes" class="mt-2" />
                        </div>
                        <div>
                        <h6 class="text-left md:text-right mt-2 md:mt-8">GOA/Other Charges: {{ moneyMaker(goa_total) }} </h6>
                    </div>
                </div>
            <div>
                <h4 class="text-left md:text-right mt-2 md:mt-6 font-bold text-red-500">Grand Total: {{ moneyMaker(grand_total) }} </h4>
            </div>
                <div>
                    <jet-label for="invoice_id" value="Invoice #" />
                    <jet-input id="invoice_id" type="text" class="mt-1 block w-full" name="invoice_id" v-model="form.invoice_id" required />
                    <jet-input-error :message="form.errors.invoice_id" class="mt-2" />
                </div>
            </div>
        </div>
        <div class="container md:px-0 py-8">
                <h3>Validation of Submission</h3>
                <div>
                    <jet-label for="date" value="Date" />
                    <jet-input id="date" type="date" class="mt-1 block w-full" name="date" v-model="form.date" required  />
                    <jet-input-error :message="form.errors.date" class="mt-2" />
                </div>
                <div>
                    <jet-label for="full_name" value="Your Full Name" />
                    <jet-input id="full_name" type="text" class="mt-1 block w-full" name="full_name" v-model="form.full_name" required  />
                    <jet-input-error :message="form.errors.full_name" class="mt-2" />
                </div>
                <div>
                    <jet-label for="title" value="Your Title" />
                    <jet-input id="title" type="text" class="mt-1 block w-full" name="title" v-model="form.title" required  />
                    <jet-input-error :message="form.errors.title" class="mt-2" />
                </div>
                <div class="disclaimers">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                        <div>
                            <h3 class="mt-3 text-red-500">Payment Terms:</h3>
                            <p>No invoices will be paid after 90 days from date of tow</p>
                        </div>
                    <div class="text-right">
                        <jet-button class="ml-4" >
                            Submit
                        </jet-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<template #footer-gap>
    <FooterGap />
    </template>
    </FrontEndLayout>
</template>

<style lang="scss">
@import "../../../../scss/_mixins.scss";

main {
  position: relative;
  top: calc(var(--header-size) + 30px);
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }
</style>

<script>
    import { defineComponent } from 'vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            Head,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            Link,
            FrontEndLayout,
            FooterGap,
            FlashMessage,
            VueTelInput,
        },

        data() {
            return {
                form: this.$inertia.form({
                    company_name: '',
                    company_first_name: '',
                    company_last_name: '',
                    company_phone: '',
                    company_address: '',
                    company_address2: '',
                    company_city: '',
                    company_state: '',
                    company_country: '',
                    company_zip: '',
                    membership_number: '',
                    break_down_location: '',
                    tow_date: '',
                    invoice_id: '',
                    member_name: '',
                    email: '',
                    rental_agreement: '',
                    bike_make: '',
                    bike_year: '',
                    bike_plate: '',
                    bike_state: '',
                    bike_vin: '',
                    dispatch_fee: '',
                    mts_po_number: '',
                    dispatch_fee_charge: '',
                    mileage_charge: '',
                    miles_charge: '',
                    loaded_rate: '',
                    loaded_miles: '',
                    storage_days: '',
                    storage_rate: '',
                    additional_charge: '',
                    additional_charge_notes: '',
                    full_name: '',
                    title: '',
                    date: '',
                }),
                states: [],
                countries: ['USA', 'Canada'],
            }
        },

        methods: {
            submit() {
                this.form.post(route('.towing-companies.store'), {
                    errorBag: 'submitAnInvoice',
                    onSuccess: () => this.form.reset(),
                });
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            moneyMaker(value) {
                return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value);
            },
            setRequired() {
                const membershipForm = document.getElementById("submitAnInvoice");
                const inputs         = membershipForm.querySelectorAll("[required]");
                inputs.forEach(input => {
                    let id = input.id;
                    let label = input.closest('label');
                    if (id) label = membershipForm.querySelector(`label[for="${input.id}"]`)
                    let span = document.createElement('span');
                    span.classList.add('text-red-700');
                    span.innerHTML = " *";
                    if (label) label.appendChild(span)
                })
            }
        },
        mounted() {
            this.setRequired()
        },
        computed: {
            loaded_total() {
                let [lrate, lmiles] = [this.form.loaded_rate,this.form.loaded_miles];
                return (parseFloat(lrate * lmiles)||0).toFixed(2);
            },
            initial_total() {
                var ibase = parseFloat(this.form.dispatch_fee_charge);
                var imiles = parseFloat(this.form.mileage_charge);
                var imilesRate = parseFloat(this.form.miles_charge);
                var unloadTot = imiles * imilesRate;
                var initTotal = ibase + unloadTot;
                return (parseFloat(initTotal) || 0).toFixed(2);
            },
            storage_total() {
                let [sbase, sdays] = [this.form.storage_rate,this.form.storage_days];
                return (parseFloat(sbase * sdays) || 0).toFixed(2);
            },
            goa_total() {
                let goa = this.form.additional_charge;
                return (parseFloat(goa) || 0).toFixed(2);
            },
            grand_total() {
                return (parseFloat(this.initial_total) + parseFloat(this.loaded_total) + (parseFloat(this.storage_total)) + parseFloat(this.form.additional_charge ? this.form.additional_charge : '0') || 0).toFixed(2);
            },
        },
        created: function(){
            this.getStates()
        }
    })
</script>
