<template>
    <div class="credit-card">
        <div class="form-group flex flex-col w-1/2">
            <jet-label :for="`card_holder`">Full name (on the card)</jet-label>
            <jet-input :id="`card_holder`" type="text" name="cardholder" placeholder="Full Name" :value="cardHolder" @input="$emit('update:cardHolder', $event.target.value)" required class="mt-1 block" />
            <jet-input-error :message="errors.card_holder" class="mt-2" />
        </div>
        <div class="form-group">
            <jet-label :for="`card_number`">Card number</jet-label>
            <div class="input-group">
                <jet-input :id="`card_number`" type="number" name="cardNumber" :value="cardNumber" @input="$emit('update:cardNumber', $event.target.value)" placeholder="Your card number" class="form-control w-1/2" required />
                <jet-input-error :message="errors.card_number" class="mt-2" />
                <div class="input-group-append">
                    <div class="ml-1 text-navy-200">
                        <FontAwesomeIcon fixed-width :icon="visaIcon"/>
                        <FontAwesomeIcon fixed-width :icon="mastercardIcon"/>
                        <FontAwesomeIcon fixed-width :icon="amexIcon"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div class="col-span-2">
                            <jet-label><span class="hidden-xs">Expiration</span><span class="text-red-700"> *</span></jet-label>
                            <div class="grid grid-cols-2 gap-4">
                                <select id="`exp_month`" class="mt-1 block w-full" :value="expMonth" name="exp_month" @change="$emit('update:expMonth', $event.target.value)" required >
                                    <option value=""> -- </option>
                                    <option v-for='data in months' :value='data.value'>{{ data.value }}</option>
                                </select>
                                <jet-input-error :message="errors.exp_month" class="mt-2" />

                                <select id="`exp_year`" class="mt-1 block w-full" :value="expYear" name="exp_year" @change="$emit('update:expYear', $event.target.value)" required >
                                    <option value=""> -- </option>
                                    <option v-for='data in years' :value='data.value'>{{ data.value }}</option>
                                </select>
                                <jet-input-error :message="errors.exp_year" class="mt-2" />
                            </div>
                        </div>
                        <div class="grid grid-col-2">
                            <jet-label for="ccv" title="Three-digits code on the back of your card">CVV</jet-label>
                            <input :id="`ccv`" type="text" :value="ccv" @input="$emit('update:ccv', $event.target.value)" required class="form-control w-4/5 mb-5" maxlength="4">
                            <jet-input-error :message="errors.ccv" class="mt-2" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'


    export default {
        components: {
            JetInput,
            JetLabel,
            JetInputError,
            FontAwesomeIcon,
            faCcVisa,
            faCcMastercard,
            faCcAmex,
            library,
        },
        props: {
            cardHolder:String,
            cardNumber:String,
            expMonth:String,
            expYear:String,
            ccv:String,
            errors:Object,
        },
        data() {
            return {
                questionCircle: faQuestionCircle,
                visaIcon : faCcVisa,
                mastercardIcon: faCcMastercard,
                amexIcon: faCcAmex,
            }
        },
        computed: {
            months() {
                let count = 0;
                let myMonths = [];
                while (count < 12)
                {
                    let month = count + 1;
                    if (month < 10)
                    {
                        month = '0' + month;
                    }
                    myMonths.push({'value': month})
                    count ++;
                }
                return myMonths;
            },
            years() {
                let year =  new Date().getFullYear();
                let count = 0;
                let myYears = [];
                while (count < 20)
                {
                    myYears.push({'value': year + count})
                    count ++;
                }
                return myYears;
            },
        },
        emits: ['update:cardHolder', 'update:cardNumber', 'update:expMonth', 'update:expYear', 'update:ccv'],
    }
</script>
