<template>
<div class="cards">
    <div class="cta-card" :class="cardPosition" :style="cardColor">
        <a :href="target" :title="'Link to ' + title + ' page'">
        <img :src="image" :alt="alt">
        <h5>
            {{ title }}
        </h5></a>
    </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

.cards {
    z-index: 2;
    margin: auto;

    .cta-card {
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        width: 346px;
        height: auto;
        box-shadow: 0px 3px 6px #00000029;
        margin: 1rem auto;
        position: relative;

        &:hover {
            transform: translate(-3px, -10px);
            transition: ease-in .15s;

            img {
                filter: grayscale(90%);
                transition: ease-in .15s;
            }
        }

        @include bp-up ($bp-sm) {
            margin: 1rem;
        }

        h5 {
            color: white;
            background-color: var(--card-color);
            padding: 1rem;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}    

    </style>

<script>
import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "CTACard",
        props: {
            color: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            image: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                required: true,
            },
            target: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            }
        },
        components: {
            Link,
        },
        computed: {
            cardColor() {
                return `--card-color: var(--${this.color})`;
            },
            cardPosition() {
                return `${this.position}`;
            }
        }
    }

</script>
