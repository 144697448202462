<template>
    <div class="card-grid">
        <Card
            title="Find Member"
            cta-route="dashboard.motorists.memberships.memberships"
            cta-cover
            icon
        >
            <FontAwesomeIcon class="card-icon" fixed-width :icon="memberIcon"/><p class="font-normal">Find a MTS member member's information.</p>
        </Card>
        <Card
            title="Towing Companies"
            cta-route="dashboard.towing_companies.towing_companies"
            cta-cover
            icon
        >
            <FontAwesomeIcon class="card-icon" fixed-width :icon="towingIcon"/><p class="font-normal">Find a towing company in the MTS network.</p>
        </Card>
        <Card
            title="Find A Dealer"
            cta-route="dashboard.dealerships.dealerships"
            cta-cover
            icon
        >
            <FontAwesomeIcon class="card-icon" fixed-width :icon="dealershipIcon"/><p class="font-normal">Find a dealership in the MTS network.</p>
        </Card>
        <Card
            title="Add Tow For Dispatch"
            cta-route="dashboard.tow_requests.add_tow_requests_step1"
            cta-cover
            icon
        >
            <FontAwesomeIcon class="card-icon" id="phone" fixed-width :icon="dispatchIcon"/><p class="font-normal">Provide the details needed to initiate a tow for an MTS member.</p>
        </Card>
        <Card
            title="Locate Towing Company"
            cta-route="dashboard.tow_requests.tow_company_locator.towing_company_locator"
            cta-cover
            icon
        >
            <FontAwesomeIcon class="card-icon" fixed-width :icon="locateTowIcon"/><p class="font-normal">Enter a zip code to find the towing company closest to where the MTS member needs a tow.</p>
        </Card>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .card-grid {
        margin: -1rem;
        display: grid;

        @include desktop {
            grid-template:
                "a b c" max-content
                "d e f" max-content
                / 1fr 1fr 1fr;
        }

        @include not-desktop {
            grid-template:
                "a" max-content
                "b" max-content
                "c" max-content
                "d" max-content
                "e" max-content
                "f" max-content
                / 100%;
        }

        .card:nth-of-type(1) { grid-area: a; }
        .card:nth-of-type(2) { grid-area: b; }
        .card:nth-of-type(3) { grid-area: c; }
        .card:nth-of-type(4) { grid-area: d; }
        .card:nth-of-type(5) { grid-area: e; }
        .card:nth-of-type(6) { grid-area: f; }

        .card {
            margin: 1rem;
        }

    }

    .card-icon {
        position: absolute;
        color: var(--blue-500);
        top: 15px;
        width: 40px;
        height: auto;

        &#phone {
            width: 30px;
            height: auto;
        }

    }
</style>
<script>
    import { faMotorcycle, faTruckPickup, faPhoneVolume, faMapMarkedAlt, faUsersCog, faChevronRight, faWarehouse } from '@fortawesome/free-solid-svg-icons';
    import { defineComponent } from 'vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Card from '@/Components/Card.vue';

    export default defineComponent({
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            Card,
        },
        data() {
            return {
                memberIcon: faMotorcycle,
                towingIcon: faTruckPickup,
                dealershipIcon: faWarehouse,
                dispatchIcon: faPhoneVolume,
                locateTowIcon: faMapMarkedAlt,
                usersIcon: faUsersCog,
                chevronRight: faChevronRight,
            }
        }
    })
</script>
