<template>
    <div>
        <em>NOTE: Motorcycles manufactured prior to 1985 are eligible for specific coverage plans.</em>
        <div class="plans grid grid-cols-1 md:grid-cols-2 my-4" v-for="(plan, index) in plans" :key="plan.id">
            <div class="pr-6">
                <h4 class="text-blue-500 font-bold">{{ index }} </h4>
                <p class="md:pr-10 pr-0">{{ plan.description }} </p>
            </div>
            <div class="grid grid-cols-1 md:ml-6 mt-4">
                <div class="plan grid grid-cols-2 items-center" v-for="iplan in plan.plans" :key="iplan.id">
                    <jet-label v-if="promoCodeData == null || promoPrice(iplan.id) == null" :for="`plan-${iplan.id}`" :value="`${iplan.name}, $${iplan.price_1_year}`" />
                    <jet-label v-else :for="`plan-${iplan.id}`" :value="`${iplan.name}, $${promoPrice(iplan.id)}`" />
                    <jet-input :id="`plan-${iplan.id}`" type="radio" v-on:click="familyPlan(iplan.name)" class="mt-1 block" :checked="iplan.id == planId" v-model="planModel" :value="`${iplan.id}`" name="Plan" required />
                    <jet-input-error :message="errors.plan_id" class="mt-2" />
                </div>
            </div>
        </div>
        <div class="plan-options">
            <div class="plan-option grid md:grid-cols-2 items-center" v-for="planOption in planOptions" :key="planOption.id">
                <div class="pr-6">
                    <h4 class="text-blue-500 font-bold">{{ planOption.name }}</h4>
                    <p>{{ planOption.description }}</p>
                </div>
                <div class="md:ml-6 mt-4 grid grid-cols-2 items-center">
                    <jet-label  v-if="promoCodeData == null || promoOptionsPrice(planOption.id) == null" :for="`plan-${planOption.id}`" :value="`${planOption.name}, $${planOption.price}`" />
                    <jet-label v-else :for="`plan-${planOption.id}`" :value="`${planOption.name}, $${promoOptionsPrice(planOption.id)}`" />
                    <jet-input :id="`plan-${planOption.id}`" type="checkbox" class="mt-1 block rounded-none" :checked="filterOptions(planOption.id)" v-model="planOptionsModel" :value="`${planOption.id}`" :name="`Plan_Option_${planOption.name}`" />
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInputError from '@/Jetstream/InputError.vue'

export default {
    components: {
        JetInput,
        JetLabel,
        JetInputError,
    },
    data() {
        return {
            plans : [],
            planOptions : [],
        }
    },
    emits: ['update:memberPlanOptions', 'update:memberPlan', 'family-plan-select'],
    computed: {
        planModel: {
            get() {
                return this.memberPlan
            },
            set(value) {
                this.$emit('update:memberPlan', parseInt(value));
            }
        },
        planOptionsModel: {
            get() {
                return this.memberPlanOptions
            },
            set(value) {
                this.setArrayModel(this.memberPlanOptions, parseInt(value), 'update:memberPlanOptions');
            }
        },
    },
    props: {
        memberPlan:Number,
        memberPlanOptions:Array,
        errors:Object,
        planId:Number,
        promoCodeData:Object,
        familyPlanSelected:Function,
    },
    methods: {
        setArrayModel(model, value, emitter){
            let combined = [...new Set([...model, value])];
            if (model.includes(value)) combined.splice(combined.indexOf(value), 1);
            this.$emit(emitter, combined);
        },
        filterOptions(option) {
            return this.memberPlanOptions.includes(option);
        },
        promoPrice(planId) {
            function getPrice(myArray) {
                return myArray.plan_pricing_id == planId;
            }
            let coupon = this.promoCodeData.coupon_code_to_plan_pricing.find(getPrice);
            if (!coupon || !coupon.coupon_price)
            {
                return null;
            }
            return coupon.coupon_price;
        },
        promoOptionsPrice(planOptionsId) {
            function getPrice(myArray) {
                return myArray.plan_options_id == planOptionsId;
            }
            let coupon = this.promoCodeData.coupon_code_to_plan_options.find(getPrice);
            if (!coupon || !coupon.coupon_price)
            {
                return null;
            }
            return coupon.coupon_price;
        },
        familyPlan(name) {
            if(name.includes('Family')){
                this.familyPlanSelected(true);
            } else {
                this.familyPlanSelected(false);
            }

        }
    },
    mounted() {
        let vm = this
        axios.get('/motorists-and-rentals/member-registration/getPlans')
            .then(function (response) {
                vm.plans = response.data
            })
            .catch(function (response) {
                console.log(response);
            })
        axios.get('/motorists-and-rentals/member-registration/getPlanOptions')
            .then(function (response) {
                vm.planOptions = response.data
            })
            .catch(function (response) {
                console.log(response);
            })
    }
}

</script>

<style>

</style>