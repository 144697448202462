<template>
    <jet-form-section @submit.prevent="addModifyPlan()">
        <template #description>
            <span v-if="isModify" class="font-medium">Update Plan Type. </span>
            <span v-else class="font-medium">Add Plan Type</span>
        </template>

        <template #form @submit.prevent="form.post('/add-membership-type')" >
            <jet-input v-if="isModify" id="plan_id" type="hidden" name="plan_id" v-model="form.id" />
            <!-- Member Type Info -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div>
                    <jet-label for="title" value="Title" />
                    <jet-input id="title" type="text" class="mt-1 block w-full" v-model="form.title" />
                    <jet-input-error :message="form.errors.title" class="mt-2" />
                </div>
                <div>
                    <jet-label for="code" value="Plan Type (Code)" />
                    <jet-input id="code" type="text" class="mt-1 block w-full" v-model="form.code" maxlength="5" />
                    <jet-input-error :message="form.errors.code" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label for="description" value="Description" />
                <textarea id="description" type="textarea" class="mt-1 block w-full" v-model="form.description" rows="5" />
                <jet-input-error :message="form.errors.description" class="mt-2" />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div>
                    <jet-label for="terms" value="Terms" />
                    <jet-input id="terms" type="text" class="mt-1 block w-full" v-model="form.terms" />
                    <jet-input-error :message="form.errors.terms" class="mt-2" />
                </div>
                <div>
                    <jet-label for="plan_type_id" value="Member Type" />
                    <select id="plan_type_id" v-model="form.plan_type_id" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in plan_type_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.plan_type_id" class="mt-2" />
                </div>
            </div>
        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Plan Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Plan Added.
            </jet-action-message>

            <jet-button  v-if="isModify" @click="updatePlan()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Update
            </jet-button>

            <jet-button v-else @click="addPlan()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetCheckbox,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    code: '',
                    terms: '',
                    title: '',
                    description: '',
                    plan_type_id: '',
                }),
                isModify: false,
                typeId: '',
                plan_type_list: [],
            }
        },
        methods: {
            addPlan(event)
            {
                this.form.post(route('dashboard.motorists.plan_types.store'),
                {
                    errorBag: 'addModifyPlan',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset('id', 'code', 'terms', 'title', 'description', 'plan_type_id'),
                })
            },
            updatePlan(event)
            {
                this.form.post(route('dashboard.motorists.plan_types.update'),
                {
                    errorBag: 'addModifyPlan',
                    preserveScroll: true,
                })
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.typeId = typeId;
                    this.loadPlanData();
                }
            },
            loadPlanData()
            {
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.plan_types.fetch_plan_data'),
                    data: {
                        plan: this.typeId
                    }
                })
                .then(response => {
                    Object.entries(response.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            getPlanTypes()
            {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.plan_types.fetch_plan_types'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                })
            }
        },
        mounted() {
            this.checkModify();
            this.getPlanTypes();
        }
    }
</script>
