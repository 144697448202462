<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Towing Company Locator"
        >
            <Map />
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Map from './Map/Map.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'

    export default defineComponent({
        components: {
            AppLayout,
            Map,
            Link,
            Card,
            JetButton,
        },
    })
</script>
