<template>
    <app-layout title="Dashboard - Membership Types">
        <template #header>
        </template>
        <Card
            title="Membership Type" >
            <Link
                    :href="`/dashboard/motorists/membership-types/add_membership_type`"
                    as="button"
                    type="button"
                >
                <JetButton  class="button button-primary absolute top-4 right-4">
                    Add Membership Type
                </JetButton>
            </Link>
            <div v-if="plans.length == 0">
                <VueLoader  />
            </div>
            <div v-else>
                <MembershipTypesTable
                    :data="plans"
                    :searchable-data="['name', 'upload_name']"
                    @row-deleted="rowDeleted($event)"
                    ref="typesTable"
                />
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import MembershipTypesTable from './Tables/MembershipTypesTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            MembershipTypesTable,
            Link,
            JetButton,
            Card,
            VueLoader,
        },
        data() {
            return {
                plans:[],
            }
        },
        methods: {
            fetchPlans() {
                axios
                    .get(route('dashboard.motorists.membership_types.fetch_membership_types'))
                    .then(response => {
                        this.plans = response.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/motorists/membership-types/delete_membership_type/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchPlans();
                        this.$refs.typesTable.closeModal();
                    }
                })
            },
        },
        mounted() {
            this.fetchPlans();
        }
    })
</script>
