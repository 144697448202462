<template>
    <CustomTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
        ref="customTable"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('coupon_code')" @click="sortBy('coupon_code')">Coupon Code</p>
            <p :class="sortClass('usage_date')" @click="sortBy('usage_date','date')">Usage Date</p>
            <p :class="sortClass('coupon_price')" @click="sortBy('coupon_price','number')">Coupon Plan Price</p>
            <p :class="sortClass('original_plan_price')" @click="sortBy('original_plan_price','number')">Original Plan Price</p>
            <p :class="sortClass('plan_option_coupon_price')" @click="sortBy('plan_option_coupon_price','number')">Coupon Plan Option Price</p>
            <p :class="sortClass('original_plan_option_price')" @click="sortBy('original_plan_option_price','number')">Original Plan Option Price</p>
            <p :class="sortClass('discount_total')" @click="sortBy('discount_total')" >Discount Total</p>
        </div>
        <div class="simple-table" style="--cols:7">
            <p class="st-header first-col" :class="sortClass('coupon_code')" @click="sortBy('coupon_code')">Coupon Code</p>
            <p class="st-header" :class="sortClass('usage_date')" @click="sortBy('usage_date','date')">Usage Date</p>
            <p class="st-header" :class="sortClass('coupon_price')" @click="sortBy('coupon_price','number')">Coupon Plan Price</p>
            <p class="st-header" :class="sortClass('original_plan_price')" @click="sortBy('original_plan_price','number')">Original Plan Price</p>
            <p class="st-header" :class="sortClass('plan_option_coupon_price')" @click="sortBy('plan_option_coupon_price','number')">Coupon Plan Option Price</p>
            <p class="st-header" :class="sortClass('original_plan_option_price')" @click="sortBy('original_plan_option_price','number')">Original Plan Option Price</p>
            <p class="st-header" :class="sortClass('discount_total')" @click="sortBy('discount_total')" >Discount Total</p>


            <template v-for="row in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Coupon Code</span>{{ row.coupon_code }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Joined</span>{{ row.usage_date }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Coupon Plan Price</span>${{ parseFloat(row.coupon_price).toFixed(2) }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Original Plan Price</span>${{ parseFloat(row.original_plan_price).toFixed(2) }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Coupon Plan Option Price</span>${{ parseFloat(row.plan_option_coupon_price).toFixed(2) }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Original Plan Option Price</span>${{ parseFloat(row.original_plan_option_price).toFixed(2) }}</p>
                <p class="cursor-pointer"><span class="st-hidden">Discount Total</span>${{ parseFloat(row.discount_total).toFixed(2) }}</p>
            </template>
        </div>
    </CustomTableWrapper>
</template>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }

    .selected {
        background: var(--gray-200);
        font-weight: bold;
    }

    *.first-col {
        padding-left: 1rem !important;
    }
</style>

<script>
    import CustomTableWrapper from '@/Components/VueTable/CustomTableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            CustomTableWrapper,
            Status,
            JetSecondaryButton,
            Link
        },
        data() {
            return {
                memberId: null,
            }
        },
        props: [
            'data',
        ],
        methods: {
            resetOne: function () {
                this.$refs.customTable.updateActivePage(1);
            },
        },
        watch: {
            displayedList: {
                deep: true,
                handler(n) {
                    this.$emit('update-selection', n);
                }
            }
        }
    })
</script>
