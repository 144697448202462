<template>
    <app-layout title="Dashboard - Settings">
        <template #header>
        </template>
        <Card
            title="Site Settings"
        >
        <div>
            <Teleport to="body"><FlashMessage /></Teleport>
        </div>
            <SettingsFormField
                v-for="n in form.site_settings.length"
                :key="'setting-' + n"
                :index="n"
                v-model:setting-name="form.site_settings[n-1].name"
                v-model:setting-value="form.site_settings[n-1].value"
                v-model:setting-description="form.site_settings[n-1].description"
            />
            
            <a
                href="/dashboard/logs"
                class="absolute top-4 right-4 items-center px-4 py-2 bg-blue-500 border border-transparent font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-200 active:bg-blue-600 focus:outline-none focus:border-blue-900 focus:ring focus:ring-blue-300 disabled:opacity-25 transition"
            >
                Error Logs
            </a>

            <jet-button @click="saveSettings()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>

        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import SettingsFormField from './Partials/SettingsFormField.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages'

    export default defineComponent({
        components: {
            AppLayout,
            Link,
            Card,
            JetButton,
            JetFormSection,
            SettingsFormField,
            FlashMessage,
        },
        data() {
            return {

                form: this.$inertia.form({
                    _method: 'PUT',
                    site_settings: [{
                        name: '',
                        value: '',
                        description: '',
                    }]
                }),
            }
        },
        methods: {
            fetchSettings() {
                axios
                    .get(route('dashboard.settings.fetch_settings'))
                    .then(response => {
                        this.form.site_settings = response.data;
                    })
            },
            saveSettings() {
                this.form.put(route('dashboard.settings.store'), {
                    errorBag: 'addModifySettings',
                });
            },
        },
        mounted() {
            this.fetchSettings();
        }
    })
</script>
