<template>
    <FrontEndLayout title="Submit a Testimonial">
        <template #head>
            <meta name="description" content="Submit a Testimonial">
            <meta name="keywords" content="submit testimonial">
        </template>
        <template #header>
            <h1>Submit a Testimonial</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container">
            <h2 class="text-center font-bold text-navy-500">Let us know how we did!</h2>
                <p class="text-center">::CONTENT HERE::</p>
                <jet-validation-errors class="mb-4" />

                <form @submit.prevent="submit" id="testimonialForm">
                    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
                        <div>
                            <jet-label for="submitted_by" value="Name" />
                            <jet-input id="submitted_by" type="text" class="mt-1 block w-full" name="submitted_by" required v-model="form.submitted_by" />
                            <jet-input-error :message="form.errors.submitted_by" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="email" value="Email" />
                            <jet-input id="email" type="email" class="mt-1 block w-full" name="email" required v-model="form.email" />
                            <jet-input-error :message="form.errors.email" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="phone" value="Phone" />
                            <jet-input id="phone" type="text" class="mt-1 block w-full" name="phone" required  v-model="form.phone" />
                            <jet-input-error :message="form.errors.phone" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="city" value="City" />
                            <jet-input id="city" type="text" class="mt-1 block w-full" name="city" required v-model="form.city" />
                            <jet-input-error :message="form.errors.city" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="state" value="State/Province" />
                                <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                                    <option value=""> -- </option>
                                    <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                                </select>
                            <jet-input-error :message="form.errors.state" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="type" value="Type" />
                                <select id="type" class="mt-1 block w-full" v-model="form.testimonial_type" name="type" required >
                                    <option value=""> -- </option>
                                    <option v-for='data in types' :value='data' :key="data">{{ data }}</option>
                                </select>
                            <jet-input-error :message="form.errors.testimonial_type" class="mt-2" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <jet-label for="terms">
                            <div class="flex items-center">
                                <jet-checkbox name="terms" id="terms" v-model:checked="form.terms" required />

                                <div class="ml-2">
                                    I agree to the <a target="_blank" :href="route('.policies.terms')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a> and <a target="_blank" :href="route('.policies.privacy')" class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
                                </div>
                                <jet-input-error :message="form.errors.terms" class="mt-2" />
                            </div>
                        </jet-label>
                    </div>
                    <div class="mt-4">
                        <jet-label for="content" value="Message" />
                        <textarea name="content" id="content" type="textarea" class="mt-1 block w-full" v-model="form.content" required />
                        <jet-input-error :message="form.errors.message" class="mt-2" />
                    </div>
                    <div class="mt-4">
                        <div class="d-flex flex-column">
                            <jet-label for="image" value="File:" />
                            <input type="file" name="testimonial_image" @input="form.testimonial_image = $event.target.files[0]" class="form-control" id="image" />
                            <jet-input-error :message="form.errors.testimonial_image" class="mt-2" />
                        </div>
                    </div>
                    <div class="flex items-center justify-end mt-4">
                        <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Submit
                        </jet-button>
                    </div>
                </form>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FlashMessage from '../DashboardPartials/FlashMessages.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import TextArea from '@/Components/FormElements/TextArea.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FooterGap from '@/Components/FooterGap.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            TextArea,
            JetLabel,
            JetValidationErrors,
            FrontEndLayout,
            FlashMessage,
            FooterGap,
        },
        data() {
            return {
                form: this.$inertia.form({
                    submitted_by: '',
                    city: '',
                    state: '',
                    phone: '',
                    email: '',
                    content: '',
                    testimonial_type: '',
                    terms: false,
                    testimonial_image: null,
                }),
                states: [],
                types: ['Motorist', 'Dealer', 'Vendor'],
            }
        },
        methods: {
            submit() {
                this.form.post(route('.testimonials.submit_testimonial'), {
                    forceFormData: true,
                    errorBag: 'testimonialForm',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset(),
                });
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
        },
        mounted() {
            this.getStates();
        }
    });
</script>