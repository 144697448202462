<template>
  <FrontEndLayout title="About Us">
    <template #head>
      <meta name="description" content="About MTS Towing" />
      <meta name="keywords" content="services" />
    </template>
    <template #header>
      <h1>TOWING COMPANY SERVICES</h1>
    </template>
    <template #fepage-content>
      <div class="container py-6">
        <div class="text-center">
          <h2 class="text-navy-500 font-bold">
            Join MTS to Increase Your Business?
          </h2>
          <p>
            Motorcycle Towing Service (MTS) is a service focused on providing
            roadside assistance and towing service exclusively to motorcycle
            riders. We’ve been in business since 1988, and are always looking to
            expand our network to find approved professional towing providers
            who can meet the needs of motorcycle riders. If you have the right
            knowledge, equipment and personnel,
            <strong>MTS WANTS YOU!!!!</strong>
          </p>
        </div>
      </div>
      <div class="gray-section">
        <div class="container grid gap-2 grid-row-2 md:grid-cols-3 items-center">
          <h3 class="text-navy-500 md:col-span-2">
            Who Is Eligible To Join the MTS Vendor Network?
          </h3>
          <ul class="red-check list-outside mx-auto">
            <li>Dealerships</li>
            <li>Rentals Companies</li>
            <li>Tow Providers (with the right equipment and knowledge)</li>
          </ul>
        </div>
      </div>
      <div class="container grid grid-row-2 md:grid-cols-3 items-center py-10">
        <h3 class="text-navy-500 md:col-span-2">Why You Should Join the MTS Network?</h3>
        <ul class="red-check list-outside mx-auto">
          <li>MTS does not require you to sign a contract</li>
          <li>MTS never sets the rates – We pay your rates</li>
          <li>There is never a fee to be part of the MTS Network</li>
          <li>MTS never sets a zone or area that you have to cover</li>
          <li>You’ll receive immediate payment via credit card at the time of the tow
          </li>
        </ul>
      </div>
      <div class="gray-section">
        <div class="container px-4">
            <div class="grid gap-2 grid-row-2 md:grid-cols-3 items-center">
                <div class="md:col-span-2">
          <h3 class="text-navy-500">How MTS Works With You?</h3>
          <p class="w-2/3">
            We have our own dispatch center. All of our dispatchers are
            knowledgeable and experienced with motorcycles. When you receive a
            call from MTS, the dispatch center will give you:
          </p>
          </div>
          <div>
          <ul class="red-check list-outside mx-auto">
            <li>The member’s exact location</li>
            <li>What is covered by their membership</li>
            <li>Make and model of the bike</li>
            <li>Any other details that will help you on the call</li>
          </ul>
</div>
</div>
          <p class="text-left mt-3 font-bold text-navy-500">
            If the member has chosen a plan that requires them to make a partial
            payment to you, you will be notified of the amount before your truck
            responds to the call.
          </p>
        </div>
      </div>
      <div class="container py-10">
          <div class="grid gap-2 grid-row-2 md:grid-cols-3 items-center">
              <div class="col-span-2">
        <h3 class="text-navy-500 col-span-2">How Are You Paid By MTS?</h3>
        <p>
          All invoices are paid weekly. MTS can pay you in two different ways:
        </p>
              </div>
              <div>
        <ol class="list-decimal list-outside">
          <li>
            You can be paid via credit card on the phone at the time of the
            completion of the towing.
          </li>
          <li>
            You can also submit an invoice via fax (319)-984-6132, email
            driley@mts-towing.com, or through our online invoice submission
            tool.
          </li>
        </ol>
              </div>
              </div>
              <div class="flex justify-center">
        <a
          class="btn btn-arrow-tertiary text-left md:text-center"
          href="/invoice"
          title="internal link in same tab to submit an invoice page"
          >Submit an Invoice</a
        >
        </div>
      </div>
      <div class="gray-section">
        <div class="container text-center">
          <h3 class="text-navy-500">Contact MTS to join Our Network</h3>
          <p>
            If you’re interested in joining the MTS network to gain an advantage
            over your competitors, we’d love to connect with you. You can call
            us a 800-999-7064 if you have any questions, or complete our vendor
            application to get the process started. We look forward to working
            with you.
          </p>
          <a
            class="btn btn-arrow-tertiary text-center"
            href="/towing-companies/vendor-application"
            title="internal link in same tab to application page"
            >Submit Vendor Application</a
          >
        </div>
      </div>
    </template>
    <template #footer-gap>
        <FooterGap />
    </template>
  </FrontEndLayout>
</template>
<style lang="scss">
@import "../../../scss/_mixins.scss";

main {
  position: relative;
  top: calc(var(--header-size) + 30px);
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }
</style>

<script>
import { defineComponent } from "vue";
import FrontEndLayout from "@/Layouts/FrontEndLayout.vue";
import FooterGap from '@/Components/FooterGap.vue'
import "../../../scss/_mixins.scss";

export default defineComponent({
  components: {
    FrontEndLayout,
    FooterGap,
  },
});
</script>
