<template>
    <FrontEndLayout title="About Us">
        <template #head>
            <meta name="description" content="About MTS Towing">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>DEALERSHIP SERVICES</h1>
        </template>
        <template #fepage-content>
            <div class="text-center container py-5">
            <h2 class="font-bold text-navy-500">How An MTS Membership Helps Your Dealership</h2>
            <p>Most manufacturers do not provide roadside assistance for new motorcycle purchases. Very few dealers, if any, provide coverage for used motorcycles. MTS can provide that coverage and give your dealership an advantage over your competitors. MTS covers the US and Canada with roadside assistance that is specialized for motorcycles only. All providers are vetted to insure they have the correct equipment and are fully insured.</p>
                <a
                class="btn btn-arrow-tertiary text-left md:text-center"
                href="/dealerships/dealership-application"
                title="internal link in same tab to application page"
                >
               Apply Today</a>
            </div>
            <div class="gray-section">
                <div class="container">
                <h3>Benefits For Your Motorcycle Dealership</h3>
                <p>Some of the benefits to your dealership include:</p>
                <ul class="red-check list-outside md:w-3/4 pt-4">
                <li>If you choose to include a membership, it adds value to every sale. MTS has plans to cover new and used bikes, scooters, trikes and other motorcycle equipment.</li>
                <li>Add revenue to your dealership’s operation when you choose to sell an MTS plan. MTS plans are offered at a low cost to your dealership, allowing you to offer your customer whatever you feel is the right price for you and your dealership.</li>
                <li>Generate revenue for your repair department. An MTS membership will encourage your customer to bring their vehicle back to your location for any needed repairs.</li>
                <li>MTS can customize a plan to fit any dealership’s budget or needs.</li>
                <li>An MTS membership shows your customers you are there for them even after the sale. It gives them peace of mind that you’re there for them anywhere they ride.</li>
                </ul>
            </div>
            </div>
            <div class="container pt-8">
                <h3>Benefits For Your Dealership’s Customers</h3>
                <p>Some of the benefits for your customer include:</p>
                <ul class="red-check list-outside md:w-3/4 pt-4">
                <li>Customer receive a welcome packet to introduce MTS and our services. Your dealership can also add a welcome letter on your letterhead that can be added to our mailing.</li>
                <li>Customers will receive our emergency contact information.</li>
                <li>MTS offers translation services for those customers who need it.</li>
                <li>MTS has the latest GPS location service to locate your customer in any area.</li>
                </ul>
                </div>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }
}

</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import "../../../scss/_mixins.scss";

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
        },
    })
</script>
