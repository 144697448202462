<template>
    <div class="testi-card">
            <p :class="`testimonial ${type}`">
                {{ testimonial }}
            </p>
            <p class="testi-author">
                {{ author }}  {{ company }}
            </p>
        </div>
        <p :class="`h1 testi-type -${type}`"> {{ type }}</p>
</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

.testi-cards {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;

    @include bp-up ($bp-lg){
        flex-direction: row;
    }

    .testi-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3rem auto;
    position: relative;
    flex-wrap: wrap;
    min-height: 300px;
    max-height: fit-content;
    background-color: transparent;
    border: 10px solid white;
    border-top: none;

        &:before {
            content: '';
            position: absolute;
            border: 5px solid white;
            top:-10px;
            right:-10px;
            z-index: 3;
            border-left: none;
            width: 65%;
            background-color: white;
        }

        &:after {
            content: url(/images/quotes.png);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 30px;
            top: -50px;

        }


        p {
            color: white;
            padding: 1rem;
            text-shadow: 0px 2px 4px #0E234759;
        }

        .testimonial {
            color: white;
            padding: 3rem 2rem 2rem;

        }

        .testi-author {
            text-transform: uppercase;
            padding: 0 2rem 1rem;
        }
    }
}

.testi-type {
    color: white;
    position: absolute;
    right: 35px;
    top: 115px;
    font-weight: 100;
}
 </style>

<script>
import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "TestimonialCard",
        props: {
            testimonial: {
                type: String,
                required: true,
            },
            author: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            company: {
                type: String,
                required: false,
            }
        },
        components: {
            Link,
        },
        computed: {
            cardColor() {
                return `--card-color: var(--${this.color})`;
            }
        }
    }

</script>
