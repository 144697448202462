<template>
    <FrontEndLayout title="Home">
        <template #head>
            <title>Your page title</title>
            <meta name="description" content="Home Page MTS Towing">
            <meta name="keywords" content="introduction">
        </template>

        <template #fepage-content>
            <div class="video-bg-controls">
                <button v-show="paused" @click="play"><FontAwesomeIcon fixed-width :icon="playIcon"/></button>
                <button v-show="playing" @click="pause"><FontAwesomeIcon fixed-width :icon="pauseIcon"/></button>
            </div>
            <div class="video-sec">
                <video autoplay loop controls muted id="homepageVideo"  @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused">
                <source src="https://storage.googleapis.com/mts_towing/videos/hero-video.mp4" type="video/mp4">
                <source src="https://storage.googleapis.com/mts_towing/videos/hero-video.webm" type="video/webm">
                <source src="https://storage.googleapis.com/mts_towing/videos/hero-video.ogv" type="video/ogv">
                </video>
            <p class="video-title animate" data-delay=".3s" data-animation="fadeIn">No matter where you are, <br> We're there for you!</p>
                </div>
            <div class="container">
                <div class="intro grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3" style="top: var(--header-size);">
                        <CTACard
                            color="blue-500"
                            title="Membership Options"
                            target="/motorists-and-rentals/membership-options"
                            image="/images/cta-test.jpg"
                            alt="image of motorcycle"
                            position= null
                        ></CTACard>
                        <CTACard
                            color="navy-500"
                            title="Apply Now"
                            target="/motorists-and-rentals/member-registration"
                            image="/images/img-ApplyNow.png"
                            alt="place holder image"
                            position="move"
                        ></CTACard>
             <div class="intro-text m-8">
                <h2 class="font-bold leading-10 pb-2 mt-10 md:mt-0 text-navy-500 text-center sm:text-left">At Motorcycle Towing Services...</h2>
                <p> We are dedicated to making sure our members always have someone to call when they have a breakdown. What makes us different, unlike other towing services, we only serve customers who ride. So, whether you ride a motorcycle, trike or scooter, When You Ride with MTS, You Never Ride Alone. Members can call us 24/7 and our dispatch center will connect you with a towing company that specializes in motorcycle towing. Learn more about our membership options and how you can become an MTS member by clicking the buttons below.</p>
                </div>
                </div>
            </div>
             <div class="testimonial-wrapper">
            <h2 class="testi-title">What Our Members Say About Us...</h2>
                <div class="testi-cards">
                    <div class="testimonials" v-for="(testimonial) in testimonials.slice(0,3)" :key="testimonial.id">
                            <TestimonialCards
                            :author=testimonial.submitted_by
                            :testimonial=testimonial.content
                            :type=testimonial.testimonial_type
                                />
                    </div>
                </div>
            </div>
            <div class="partners">
                <div class="partner-title">
                <h2>We Are Proud To Partner&nbsp;With... </h2></div>
                <div class="partner-logos grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center place-items-center">
                    <a href="https://ridemsta.com" title="external link in new tab to Ride MSTA Website" target="_blank"><img src="/images/MSTA-Logo.png" alt="MSTA logo" /></a>
                    <a href="https://amerideckproducts.com/" title="external link in new tab to AmeriDeck Website" target="_blank"><img src="/images/AmeriDeck.png" alt="AmeriDeck logo" /></a>
                    <a href="http://bikerdown.org/" title="external link in new tab to Biker Down website" target="_blank"><img src="/images/biker-down.png" alt="BikerDown.org logo" /></a>
                    <a href="http://condor-lift.com" title="external link in new tab to Condor Lift website" target="_blank"><img src="/images/VynalBanner-Condor.png" alt="Condor logo" /></a>
                    <a href="https://www.eaglerider.com" title="external link in new tab to Eagle Riger website" target="_blank"><img src="/images/ER_LOGO_2019_color.png" alt="Eagle Rider logo" /></a>
                    <a href="https://motorcyclesafetylawyers.com/" title="external link in new tab to Motorcycle Safety Lawyers website" target="_blank"><img src="/images/MSL Logo.png" alt="Motorcycle Safety Lawyers logo" /></a>
                
                </div>
            </div>
            <div class="gray-section">
                <div class="container intro grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                        <div class="intro-text text-center sm:text-left m-8">
                                <h2 class="font-bold leading-10 pb-2 mt-10 md:mt-0 text-navy-500">Dealership & Vendor Services</h2>
                                <p> Whether you’re interested in offering an MTS membership to your customers or you’re looking to join our nationwide network of towing companies, we’re interested in working with you. Click the buttons below to learn more about the benefits of becoming one of our partners or offering MTS memberships at your dealership.</p>
                        </div>
                            <CTACard
                                color="red-500"
                                title="Vendor Benefits"
                                target="/towing-companies/towing-company-services"
                                image="/images/img-Vendor.png"
                                alt="image of motorcycle"
                                position="move"
                            ></CTACard>
                            <CTACard
                                color="navy-500"
                                title="Dealer Benefits"
                                target="/dealerships/dealership-services"
                                image="/images/img-Dealership.png"
                                alt="place holder image"
                                position="bottom"
                            ></CTACard>
                        </div>
                    </div>
             <MarketingMessage />
        </template>
    </FrontEndlayout>
</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';
    @import '../../css/app.css';

    .gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;


        &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }

        }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }

    .intro {
        position: relative;

       .intro-text {
           max-width: 500px;
           margin: auto;
           padding: 1rem;

       }
    }

    .move {
        position: relative;
        margin-top: 100px;
    }

    .bottom {
        position: relative;
        margin-top: 100px;

        @include bp-up($bp-sm + 1) { margin-top: 0; }
    }

.testimonial-wrapper {
        position: relative;
        margin: 0;
        padding: 0;
        top: var(--header-size);

        &:before {
            content: '';
            background-image: url(/images/testi-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 98px;
            width: 100%;
            position: relative;
            top: 45px;
            left: 0;
            display: block;
            background-position: bottom center;
            z-index: 1;
            filter: drop-shadow(4px 11px 5px #0c26549a);

            @include bp-up($bp-lg) {
                top: 95px;
            }
        }

        &:after {
            content: '';
            background-image: url(/images/footer-texture.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 95px;
            width: 100%;
            position: relative;
            bottom: 95px;
            left: 0;
            display: block;
            background-position: bottom center;
            z-index: 3;
            color: white;
            filter: drop-shadow(4px -11px 6px #0c26549a)
        }

    .testimonials {
        background-image: url(/images/img-testimonial.jpg);
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        padding: 120px 32px 64px 32px;
        background-attachment: fixed;
        background-position: center;

        @include bp-up ($bp-md){ }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(42, 82, 152, 0.6);
            z-index: -1;
        }

        &:after {
            content: '';
            background-image: url(/images/americanflag.png);
            position: absolute;
            top: 0;
            left: 0;
            width: 93%;
            height: 97%;
            z-index: -2;
            opacity: .43;
            background-attachment: fixed;
            background-position: left;
        }

    }
        .testi-title {
            color: white;
            font-weight: bold;
            text-align: center;
            position: relative;
            z-index: 1;
            top: 100px;
        }
    }

    .testi-cards {
        display: flex;
        flex-direction: column;

        @include bp-up ($bp-lg){
            flex-direction: row;
        }
    }

    .video-sec {
    position: relative;
    overflow: hidden;
    background-image: url(/images/default-hompage-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: var(--header-size);
    z-index: -2;
    height: 70vh;

        @include bp-between($bp-sm + 1, $bp-lg) {
            height: 80vh;
        }

        @include bp-up($bp-lg + 1) {
            height: 100vh;
        }


    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--blue-overlay);
        z-index: 1;
    }

    &:after {
        content: '';
        background: url('/images/mm-bottom-texture.svg') no-repeat;
        background-size: 100% auto;
        height: 5.5vw;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        display: block;
        background-position: bottom center;
        z-index: 3;
        filter: drop-shadow(4px -11px 6px #0c26549a);

    }

    video {
        display: block;
        position: absolute;
        bottom: 0;
        right: -12rem;
        height: 70vh;
        max-width: none !important;

        @include bp-up ( $bp-sm ) {
            right: 0;
            height: auto;
        }
}


    .video-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: $fw-sbold;
        color: white;
        text-transform: uppercase;
        text-shadow: 5px 5px 10px rgba(0,0,0,.35);
        width: 80%;
        text-align: center;
        z-index: 2;
        text-transform: uppercase;
        font-size: 25px;

        @include bp-between($bp-sm + 1, $bp-xl) {
            font-size: 40px;
        }

         @include bp-up($bp-xl + 1) {
            font-size: 80px;
         }
    }
}

.video-bg-controls {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

     @include bp-up($bp-lg + 1) { bottom:8vw; }

    .inactive svg {
        color: rgba(white, .4);

        &:hover { cursor: auto; }
    }

    svg {
        color: rgba(white, .85);
        margin: .25rem;

        &:hover { cursor: pointer; }
    }
}

.partners {
    position: relative;
    top: calc(var(--header-size) + 30px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    .partner-title {
        display: flex;
        margin: auto;
        padding: 0 1rem;
        text-align: center;
    }

    .partner-logos {
        margin: 1rem;

        img {
        height: auto;
        width: auto;
        max-height: 200px;
        padding: 1rem;
        }
    }
}

main {
    position: relative;
    top: 0 !important;
}
</style>

<script>
    import { defineComponent } from 'vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FooterNav from '@/Layouts/FooterNav.vue';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue';
    import CTACard from '@/Components/CTACard.vue';
    import MarketingMessage from '@/Components/MarketingMessage.vue';
    import TestimonialCards from '@/Components/TestimonialCards.vue';
    import '../../scss/_mixins.scss';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

    export default defineComponent({
        components: {
            Head,
            Link,
            FooterNav,
            CTACard,
            FontAwesomeIcon,
            TestimonialCards,
            MarketingMessage,
            FrontEndLayout,
        },

        data () {
        return  {
            pauseIcon: faPause,
            playIcon: faPlay,
            videoElement: null,
            paused: null,
            testimonials: []
            }
        },

        methods: {
            updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
            },
            play() {
            this.videoElement.play();
            },
            pause() {
            this.videoElement.pause();
            },
            fetchTestimonials() {
                axios({
                    method: 'get',
                    url: route('.testimonials.fetch_testimonials'),
                })
                .then(response => {
                    this.testimonials = response.data.data;
                })
            },
        },

        computed: {
            playing() { return !this.paused; }
        },

        props: {
            canLogin: Boolean,
            canRegister: Boolean,
            laravelVersion: String,
            phpVersion: String,
        },

        mounted() {
            this.fetchTestimonials();
        }
    })


</script>
