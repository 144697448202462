<template>
    <app-layout title="Dashboard - Edit Form">
        <template #header>
        </template>
            <div>
                <Teleport to="body"><FlashMessage /></Teleport>
            </div>
            <edit-form-form />
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import EditFormForm from './Forms/EditFormForm.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages'

    export default defineComponent({
        mixins: [formattingMixin],

        components: {
            AppLayout,
            JetButton,
            JetApplicationLogo,
            MemberHeader,
            FlashMessage,
            EditFormForm,
        },
        methods: {
            back() {
              window.history.back();
            },
        }
    })
</script>
