<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!audit_logs.isLoading">
        <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center">
                <label for="date" class="m-1">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
            </div>
            <div>
                <jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">APPLY FILTERS</span></jet-button>
                <JetSecondaryButton class="button button-primary m-1" @click="resetFilters()">RESET FILTERS</JetSecondaryButton>
            </div>
            </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="audit_logs.sortClass('user')" @click="audit_logs.sortBy('user')">User</p>
            <p :class="audit_logs.sortClass('event')" @click="audit_logs.sortBy('event')">Event</p>
            <p :class="audit_logs.sortClass('ip')" @click="audit_logs.sortBy('ip')">IP</p>
            <p :class="audit_logs.sortClass('created_at')" @click="audit_logs.sortBy('created_at',null, 'date')">Date</p>
            <p :class="audit_logs.sortClass('details')" @click="audit_logs.sortBy('details')" >Details</p>
        </div>
        <div class="simple-table" style="--cols:5">
            <p class="st-header first-col" :class="audit_logs.sortClass('user')" @click="audit_logs.sortBy('user')">User</p>
            <p class="st-header" :class="audit_logs.sortClass('event')" @click="audit_logs.sortBy('event')" >Event</p>
            <p class="st-header" :class="audit_logs.sortClass('ip')" @click="audit_logs.sortBy('ip')">IP</p>
            <p class="st-header" :class="audit_logs.sortClass('created_at')" @click="audit_logs.sortBy('created_at',null, 'date')">Date</p>
            <p class="st-header" :class="audit_logs.sortClass('details')" @click="audit_logs.sortBy('details')" >Details</p>

            <template v-for="row in audit_logs.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">User</span>{{ row.user }}</p>
                <p ><span class="st-hidden">Event</span>{{ row.event }}</p>
                <p ><span class="st-hidden">IP</span>{{ row.ip }}</p>
                <p ><span class="st-hidden">Date</span>{{ row.created_at }}</p>
                <p ><span class="st-hidden last-col">Details</span>{{ row.details }}</p>
            </template>
        </div>
    </div>
</template>

<script>
    import MemberHeader from '../../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import { reactive, toRefs } from 'vue'
    import EloquentSSFP from '@/EloquentSSFP'
    import Pagination from '@/Components/Pagination.vue'
    import VueLoader from '@/Components/VueLoader'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import moment from 'moment';

    export default{
        mixins: [formattingMixin],
        components: {
            Pagination,
            VueLoader,
            MemberHeader,
            FlashMessage,
            Status,
            JetButton,
            JetSecondaryButton,
            Link,
            Card,
            moment
        },

        data() {
            return {
                confirmingMemberDeletion: false,
                planId: null,
                audit_logs: reactive(new EloquentSSFP()),
                searchModel: '',
                start_date: null,
                end_date: null,
            }
        },
        methods: {
            applyFilters() {
                let options = {...this.audit_logs.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('created_at');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                this.audit_logs.refreshData(options);
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            resetFilters() {
                this.start_date = null;
                this.end_date = null;
                this.audit_logs.resetAll();
            },
        },
        mounted() {
            this.audit_logs.init('/dashboard/back-office/audit-logs/fetch_audit_logs_ssfp', {
                searchableData: ['user:name', 'event', 'details', 'ip', 'created_at'],
                itemsPerPage: 50,
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
