<template>
    <div class="plan-options">
        <div class="plan-option grid md:grid-cols-2 items-center" v-for="planOption in planOptions" :key="planOption.id">
            <div class="pr-6">
                <h4 class="text-blue-500 font-bold">{{ planOption.name }}</h4>
                <p>{{ planOption.description }}</p>
            </div>
            <div class="md:ml-6 mt-4 grid grid-cols-2 items-center">
                <jet-label :for="`plan-${planOption.id}`" :value="`${planOption.name}, $${planOption.price}`" />
                <jet-input :id="`plan-${planOption.id}`" type="checkbox" class="mt-1 block rounded-none" :checked="filterOptions(planOption.id)" v-model="planOptionsModel" :value="`${planOption.id}`" :name="`Plan_Option_${planOption.name}`" />
            </div>
        </div>
    </div>
</template>
<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInputError from '@/Jetstream/InputError.vue'

export default {
    components: {
        JetInput,
        JetLabel,
        JetInputError,
    },
    data() {
        return {
            plans : [],
            planOptions : [],
        }
    },
    emits: ['update:memberPlanOptions'],
    computed: {
        planOptionsModel: {
            get() {
                return this.memberPlanOptions
            },
            set(value) {
                this.setArrayModel(this.memberPlanOptions, parseInt(value), 'update:memberPlanOptions');
            }
        },
    },
    props: {
        memberPlanOptions:Array,
        errors:Object,
    },
    methods: {
        setArrayModel(model, value, emitter){
            let combined = [...new Set([...model, value])];
            if (model.includes(value)) combined.splice(combined.indexOf(value), 1);
            this.$emit(emitter, combined);
        },
        filterOptions(option) {
            return this.memberPlanOptions.includes(option);
        }
    },
    mounted() {
        let vm = this
        axios.get('/motorists-and-rentals/member-registration/getPlanOptions')
            .then(function (response) {
                vm.planOptions = response.data
            })
            .catch(function (response) {
                console.log(response);
            })
    }
}

</script>

<style>

</style>