<template>
    <jet-form-section @submitted="updatePlanPricing">
        <template #title>
            <span v-if="isModify" class="font-medium">Update plan pricing information. </span>
            <span v-else class="font-medium">Add plan pricing.</span>
        </template>

        <template #description>
        </template>

        <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="plan_pricing_id" type="hidden" name="plan_pricing_id" v-model="form.id" />
            <!-- Plan Info -->
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="name" value="Plan Pricing Name" />
                <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" />
                <jet-input-error :message="form.errors.name" class="mt-2" />
            </div>
            <div>
                <jet-label for="member_type" value="Member Type" />
                <select v-model="form.member_type" class="border py-2 px-3 text-grey-800 w-full">
                    <option
                        v-for="option in member_type_list"
                        :key="option.value"
                        :value="option.value"
                    >{{option.name}}</option>
                </select>
                <jet-input-error :message="form.errors.member_type" class="mt-2" />
            </div>
                <div>
                <jet-label for="plan_type" value="Plan Type" />
                <select id="plan_type" v-model="form.plan_type" class="border py-2 px-3 text-grey-800 w-full">
                    <option
                        v-for="option in planTypeList"
                        :key="option.value"
                        :value="option.value"
                    >{{option.name}}</option>
                </select>
                <jet-input-error :message="form.errors.plan_type" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="price_1_year" value="Price 1 Year" />
                <jet-input id="price_1_year" type="text" class="mt-1 block w-full" v-model="form.price_1_year" />
                <jet-input-error :message="form.errors.price_1_year" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="price_2_year" value="Price 2 Year" />
                <jet-input id="price_2_year" type="text" class="mt-1 block w-full" v-model="form.price_2_year" />
                <jet-input-error :message="form.errors.price_2_year" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="extra_pr_mc" value="Extra Per Motorcycle" />
                <jet-input id="extra_pr_mc" type="text" class="mt-1 block w-full" v-model="form.extra_pr_mc" />
                <jet-input-error :message="form.errors.extra_pr_mc" class="mt-2" />
            </div>
        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Plan Pricing Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Plan Pricing Added.
            </jet-action-message>

            <jet-button v-if="isModify" @click="updatePlanPricing()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
            <jet-button v-else @click="addPlanPricing()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    name: '',
                    plan_type: '',
                    member_type: '',
                    price_1_year: '',
                    price_2_year: '',
                    extra_pr_mc: '',
                }),
                member_type_list: [],
                plan_type_list: [],
                planPricingId: 0,
                isModify: false,
            }
        },
        computed: {
            planTypeList() {
                // Reduce the plan type to the items associated to the selected member type
                return this.plan_type_list.filter(obj => {
                    return obj.plan_type == this.form.member_type
                })
            }
        },
        methods: {
            updatePlanPricing() {
                this.form.post(route('dashboard.motorists.plan_pricing.update'), {
                    errorBag: 'addModifyPlanPricing',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.resetForm();
                        this.fetchPlanPricingData();
                    }
                });
            },
            addPlanPricing() {
                this.form.post(route('dashboard.motorists.plan_pricing.store'), {
                    errorBag: 'addModifyPlanPricing',
                    preserveScroll: true,
                });
            },
            checkModify() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var planPricingId = parseInt(last_segment);
                var planPricingId = parseInt(last_segment);

                if(Number.isNaN(planPricingId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.planPricingId = planPricingId;
                    this.fetchPlanPricingData();

                }
                //need something here to check if it is a number before we do the call, or do check within membercontroller

            },
            fetchPlanPricingData() {
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.plan_pricing.fetch_plan_pricing_data'),
                    data: {
                        plan: this.planPricingId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchPlanTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_plan_type_list'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                })
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
        },
        mounted() {
            this.checkModify();
            this.fetchMemberTypes();
            this.fetchPlanTypes();
        },
    }
</script>
