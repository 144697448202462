<template>
    <AppLayout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Memberships"
        >
            <MembershipTable />
        </Card>
    </AppLayout>
</template>

<style lang="scss" scoped>
@import '../../../../scss/mixins';

</style>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import MembershipTable from './Tables/MembershipTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'
    import formattingMixin from '@/Mixins/formattingMixin.js'

    export default defineComponent({
        mixins: [formattingMixin],
        components: {
            AppLayout,
            MembershipTable,
            Link,
            Card,
            JetButton,
            VueLoader,
        },
    })
</script>
