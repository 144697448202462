<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('form_name')" @click="sortBy('form_name')">Name</p>
            <p :class="sortClass('recipients')" @click="sortBy('recipients',)">Recipients</p>
            <p :class="sortClass('created_at')" @click="sortBy('created_at')">Created</p>
        </div>
        <div class="simple-table" style="--cols:4">
            <p class="st-header first-col" :class="sortClass('form_name')" @click="sortBy('form_name')">Name</p>
            <p class="st-header" :class="sortClass('recipients')" @click="sortBy('recipients')" >Recipients</p>
            <p class="st-header" :class="sortClass('created_at')" @click="sortBy('created_at')">Created</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="row in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.form_name }}</p>
                <p ><span class="st-hidden">Recipients</span>{{ row.recipients }}</p>
                <p ><span class="st-hidden">Created</span>{{ row.created_at }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/forms/edit/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>
                        <Link
                            :href="`/dashboard/forms/form_submissions/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-secondary-button>
                                View Submissions
                            </jet-secondary-button>
                        </Link>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingFormDeletion" @close="closeModal">
        <template #title>
            Delete Form Submission
        </template>

        <template #content>
            Are you sure you want to delete this Form Submission? (You will still be able to restore this form submission if needed.)


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteForm(this.formId)" >
                Delete Form Submission
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            MemberHeader,
            FlashMessage,
            TableWrapper,
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link
        },

        data() {
            return {
                confirmingFormDeletion: false,
                formId: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            confirmFormDeletion(index, id) {
                this.confirmingFormDeletion = true;
                this.formId = id;
                this.index = index;
            },

            deleteForm(id) {
                this.$emit('row-deleted', id)
            },

            closeModal: function () {
                this.confirmingFormDeletion = false
            },
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>