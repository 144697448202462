<template>
    <app-layout title="Dashboard - Add Tow Request Step 3">
        <template #header>
        </template>
        <Card
            title="Add Tow Step Three - Submit Tow Info">
            <add-tow-request-form />
        </Card>
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import AddTowRequestForm from './Forms/AddTowRequestsStep3Form.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'

    import Card from '@/Components/Card.vue'

    export default ({
        mixins: [formattingMixin],
        components: {
            AppLayout,
            formattingMixin,
            Link,
            AddTowRequestForm,
            MemberHeader,
            Card,
        },
        data() {
            return {
            }
        },
        methods: {
        },

        mounted() {
        }
    })
</script>
