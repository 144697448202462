<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('company_name')" @click="sortBy('company_name')">Name</p>
            <p :class="sortClass('company_phone')" @click="sortBy('company_phone', 'number')">Phone</p>
            <p :class="sortClass('notes')" @click="sortBy('notes')">Note</p>
            <p :class="sortClass('created_at')" @click="sortBy('created_at', 'number')">Time</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('company_name')" @click="sortBy('company_name')">Name</p>
            <p class="st-header" :class="sortClass('company_phone')" @click="sortBy('company_phone', 'number')" >Phone</p>
            <p class="st-header" :class="sortClass('notes')" @click="sortBy('notes')" >Note</p>
            <p class="st-header last-col" :class="sortClass('created_at')" @click="sortBy('created_at', 'number')" >Time</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.company_name }}</p>
                <p><span class="st-hidden">Phone</span>{{ row.company_phone }}</p>
                <p><span class="st-hidden">Note</span>{{ row.notes }}</p>
                <p class="last-col"><span class="st-hidden">Time</span>{{ row.created_at }}</p>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>
