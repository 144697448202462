/**
  I'm tired of writing and rewriting Search, Sort, Filter, and Paginate logic,
  so I'm moving it all here to the EloquentSSFP class.

  Usage:
  let productList = new EloquentSSFP(this.products);

  productList.sortBy('name');

 */

  import axios from 'axios';
  import _ from 'lodash';

  export default class EloquentSSFP {
    _isLoading        = true;
    _endpoint         = "";
    _data             = [];
    _maxPage          = 1;
    _rangeText        = '0 entries'
    _hasActiveFilters = false;
    _defaultOptions   = {};
    _itemCount        = 0;
    _selectedRows     = [];
    _options          = {
      sortDirs:            [],
      sortTypes:           [],
      activeSorts:         [],
      searchModel:         "",
      filterProps:         [],
      itemsPerPage:        10,
      filterValues:        [],
      searchableData:      [],
      filterOperators:     [],
      filterExclusivities: [],
      activePage:          1,
    };

    init(endpoint, options = {}) {
      this.endpoint = endpoint;
      this.options  = {
        sortDirs: options.sortDirs || [],
        sortTypes: options.sortTypes || [],
        activeSorts: options.activeSorts || [],
        searchModel: options.searchModel || "",
        filterProps: options.filterProps || [],
        itemsPerPage: options.itemsPerPage || 10,
        filterValues: options.filterValues || [],
        searchableData: options.searchableData || [],
        filterOperators: options.filterOperators || [],
        filterExclusivities: options.filterExclusivities || [],
        activePage: options.activePage || 1,
      };

      this._defaultOptions = options;

      // Make initial request with no parameters
      this.refreshData(this._options);
    }

    refreshData(options) {
      this.isLoading = true;
      axios.post(this._endpoint, options)
        .then(res => {
          this.data             = res.data.data;
          this.maxPage          = res.data.maxPage;
          this.rangeText        = res.data.rangeText;
          this.options          = res.data.options;
          this.itemCount        = res.data.itemCount;
          this.isLoading        = false;
        })
        .catch(err => {
          console.error(err);
        });
    }

    // Pre-implemented setter change interceptor and handler
    _onPropertyChange(prop, oldVal, newVal) {
      this.refreshData(this._options);
    }

    // Setter built to have changes passed through it so they can be intercepted if needed
    _setter(prop, val) {
      let prev = this[`_${prop}`];
      this[`_${prop}`] = val;
      this._onPropertyChange(prop, prev, val);
    }

    _setOptions(val) {
      let prev = this._options;
      this._options = val;
      this._onPropertyChange("options", prev, val);
    }

    _setOption(prop, val) {
      let prev = this._options[prop];
      this._options[prop] = val;
      this._onPropertyChange(prop, prev, val);
    }

    // Getters
      get options() { return this._options; }
      get isLoading() { return this._isLoading; }

      // Settings
      get searchableData() { return this._options.searchableData; }
      get itemsPerPage() { return this._options.itemsPerPage; }
      get data() { return this._data; }
      get itemCount() { return this._itemCount; }

      // Search
      get searchModel() { return this._options.searchModel; }

      // Sort
      get activeSorts() { return this._options.activeSorts; }
      get sortTypes() { return this._options.sortTypes; }
      get sortDirs() { return this._options.sortDirs; }

      // Filter
      get filterExclusivities() { return this._options.filterExclusivities; }
      get filterOperators() { return this._options.filterOperators; }
      get filterCallback() { return this._options.filterCallback; }
      get filterValues() { return this._options.filterValues; }
      get filterProps() { return this._options.filterProps; }

      // Paginate
      get activePage() { return this._options.activePage; }

      // Select Rows
      get selectedRows() { return this._selectedRows; }


    // Setters
      set endpoint(val) { this._endpoint = val; }
      set itemCount(val) { this._itemCount = val; }
      set options(val) { this._options = val; }
      set isLoading(val) { this._isLoading = val; }

      // Settings
      set searchableData(val) { this._setOption("searchableData", val); }
      set itemsPerPage(val) { this._setOption("itemsPerPage", val); }
      set data(val) { this._data = val; }
      set maxPage(val) { this._maxPage = val; }
      set rangeText(val) { this._rangeText = val; }

      // Search
      set searchModel(val) { this._setOption("searchModel", val); }

      // Sort
      set activeSorts(val) { this._setOption("activeSorts", val); }
      set sortTypes(val) { this._setOption("sortTypes", val); }
      set sortDirs(val) { this._setOption("sortDirs", val); }

      // Filter
      set filterExclusivities(val) { this._setOption("filterExclusivities", val); }
      set filterOperators(val) { this._setOption("filterOperators", val); }
      set filterCallback(val) { this._setOption("filterCallback", val); }
      set filterValues(val) { this._setOption("filterValues", val); }
      set filterProps(val) { this._setOption("filterProps", val); }

      // Paginate
      set activePage(val) { this._setOption("activePage", val); }

      // Select Rows
      set selectedRows(val) { this._selectedRows = val }


    // Methods
    resetAll() {
      this.refreshData(this._defaultOptions);
    }

    updateSearch(value) {
      let options = {...this.options};
      options.searchModel = value;
      this.refreshData(options);
    }

    sortBy(value, dir = null, type = "string") {
      if (this.activeSorts) {
        let options = {...this.options};
        let sortIndex = this.activeSorts.indexOf(value);

        if (sortIndex === -1) { // If prop has not yet been set, add it as next sort
          let newIndex = options.activeSorts.length;
          options.activeSorts.push(value);
          options.sortTypes.push(type);
          options.sortDirs.push(dir || "asc");
        } else { // Prop HAS BEEN SET
          if (!dir) { // If no dir has been explicitly passed in
            if (options.sortDirs[sortIndex] == 'desc') { // If prop has been set and is currently descending, remove it
              options.activeSorts.splice(sortIndex, 1);
              options.sortTypes.splice(sortIndex, 1);
              options.sortDirs.splice(sortIndex, 1);
            } else { // If prop has been set and is asc, switch to desc
              options.sortDirs[sortIndex] = 'desc';
            }
          } else {
            options.activeSorts[sortIndex] = value;
            options.sortTypes[sortIndex] = type;
            options.sortDirs[sortIndex] = dir;
          }
        }

        this.refreshData(options);
      }
    }

    sortClass(value) {
      if (this._options.activeSorts) {
        let sortIndex = this._options.activeSorts.indexOf(value);
        return `sort${sortIndex !== -1 && this._options.sortDirs[sortIndex] ? " " + this._options.sortDirs[sortIndex] : ""}`;
      }
    }

    filterBy(prop, preFormattedValue, operator = null, exclusive) {
      let options         = {...this._options},
          value           = !preFormattedValue && parseFloat(preFormattedValue) != 0 ? null : preFormattedValue,
          props           = options.filterProps,
          values          = options.filterValues,
          operators       = options.filterOperators,
          exclusivities   = options.filterExclusivities,
          exclusiveSet    = typeof exclusive !== "undefined",
          exclusiveValue  = exclusiveSet ? exclusive : 0,
          propIndex       = props.indexOf(prop),
          propExists      = propIndex !== -1,
          valueNotNull    = value !== null,
          valueIndex      = propExists ? values[propIndex].indexOf(value) : -1,
          valueSet        = valueIndex !== -1,
          operatorNotNull = operator !== null,
          operatorIndex   = propExists ? operators[propIndex].indexOf(operator) : -1,
          operatorSet     = operatorIndex !== -1;

      function removeProp() {
        props.splice(propIndex, 1);
        values.splice(propIndex, 1);
        operators.splice(propIndex, 1);
        exclusivities.splice(propIndex, 1);
      }

      function removeValue() {
        if (valueIndex !== -1) {
          values[propIndex].splice(valueIndex, 1);
          operators[propIndex].splice(valueIndex, 1);
        } else if (operatorIndex !== -1) {
          values[propIndex].splice(operatorIndex, 1);
          operators[propIndex].splice(operatorIndex, 1);
        } else {
          removeProp()
        }

        if (!values[propIndex].length) removeProp();
      }

      function addNewValueAndOperator() {
        values[propIndex].push(value);
        operators[propIndex].push(operator);
      }

      function updateValue() {
        values[propIndex][operatorIndex] = value;
      }

      function updateExclusive() {
        exclusivities[propIndex] = exclusiveValue;
      }

      if (!propExists) {
        props.push(prop);
        values.push([value]);
        operators.push([operator]);
        exclusivities.push(exclusiveValue);
      } else if (!valueNotNull) { // If value is set to null, remove it
        removeValue();
      } else if (operatorNotNull) {
        let caseId = valueSet + (operatorSet * 2) + (exclusiveSet * 4);
        // caseId                                          | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 |
        // ---------------------------------------------------------------------------------
        // valueSet (value already exists in values)       | 0 | 1 | 0 | 1 | 0 | 1 | 0 | 1 |
        // operatorSet (value already exists in operators) | 0 | 0 | 1 | 1 | 0 | 0 | 1 | 1 |
        // exclusiveSet (something was passed to function) | 0 | 0 | 0 | 0 | 1 | 1 | 1 | 1 |

        switch (caseId) {
          case 0: // value is unique, operator is unique, exclude is unset
          case 1: // value is not unique, operator is unique, exclude is unset
            addNewValueAndOperator(); break;
          case 2: // value is unique, operator is not unique, exclude is unset
          case 7: // value is not unique, operator is not unique, exclude is set; To prevent the function from unsetting when running multiple times
          case 3: // value is not unique, operator is not unique, exclude is unset
            updateValue(); break;
          case 4: // value is unique, operator is unique, exclude is set
          case 5: // value is not unique, operator is unique, exclude is set
            addNewValueAndOperator(); updateExclusive(); break;
          case 6: // value is unique, operator is not unique, exclude is set
            updateValue(); updateExclusive(); break;
        }
      } else if (operators[propIndex][valueIndex]) { // If operator at index exists but is now being set to null, remove it
        removeValue();
      } else { // Operator is not set, new operator is not being set, value is being passed
        let caseId = valueSet + (exclusiveSet * 2);
        // caseId                                          | 0 | 1 | 2 | 3 |
        // -----------------------------------------------------------------
        // valueSet (value already exists in values)       | 0 | 1 | 0 | 1 |
        // exclusiveSet (something was passed to function) | 0 | 0 | 1 | 1 |

        switch (caseId) {
          case 0: // value is unique, exclude is unset
            addNewValueAndOperator(); break;
          case 1: // value is not unique, exclude is unset
          case 3: // value is not unique, exclude is set
            removeValue(); break;
          case 2: // value is unique, exclude is set
            addNewValueAndOperator(); updateExclusive(); break;
        }
      }

      this.refreshData(options);
    }

    isActiveFilter(prop, value) {
      if (this._options.filterProps) {
        let propIndex = this._options.filterProps.indexOf(prop);
        return propIndex !== -1 && this._options.filterValues[propIndex].includes(value);
      }
    }

    isRowSelected(uid) {
      return this.selectedRows.includes(uid);
    }

    _removeRow(uid) {
      this._selectedRows.splice(this._selectedRows.indexOf(uid),1);
    }

    _addRow(uid) {
      this._selectedRows.push(uid);
    }

    selectRow(uid) {
      if (this.isRowSelected(uid)) this._removeRow(uid);
      else this._addRow(uid);
    }

    updateActivePage(page) {
      let options = {...this._options};
      options.activePage = page;
      this.refreshData(options);
    }

    get displayedList() {
      return this._data;
    }

    get maxPage() {
      return this._maxPage;
    }

    get rangeText() {
      return this._rangeText;
    }

    get hasActiveFilters() {
      return !_.isEqual(this._options, this._defaultOptions);
    }
  }
