<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
            <Card
                title="Vendor Invoices">
                <div>
                    <VendorInvoiceTable
                        @printPDF="printPDF($event)"
                    />
                </div>
            </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import VendorInvoiceTable from './Tables/VendorInvoiceTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'

    export default defineComponent({
        components: {
            AppLayout,
            VendorInvoiceTable,
            Link,
            Card,
            JetButton,
        },
        methods: {
            exportCSV() {
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.vendor_invoices.exportCSV'),
                    responseType: "blob",
                    data: {
                        invoices: this.searchList
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `vendor-invoices.xlsx`;
                        link.click();
                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            printPDF: function (id) {
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.vendor_invoices.printPDF'),
                    responseType: "blob",
                    data: {
                        invoice: id
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-breakdown-${id}.pdf`;
                        link.click();

                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
        },
    })
</script>
