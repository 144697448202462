<template>
<footer class="footer">
     <div class="footer-nav w-full md:w-10/12 mx-auto pr-2 md:pr-0">  <!--grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5  -->
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Motorist & Rentals</h6>
            <p><Link href="/motorists-and-rentals/motorist-services" title="Internal link in same page to Motorist Services page">Motorist Services</Link></p>
            <p><Link class="hn-item" href="/motorists-and-rentals/motorist-rentals" title="Internal link in same tab to Motorist Rentals page">Motorist Rentals</Link></p>
            <p><Link href="/motorists-and-rentals/membership-options" title="Internal link in same page to Membership Options page">Membership Options</Link></p>
            <p><Link href="/motorists-and-rentals/member-registration" title="Internal link in same page to Membership Application page">Membership Application</Link></p>
            <p><Link href="/login" title="Internal link in same page to Member Login page">Member Login</Link></p>
            <p><Link href="/optin" title="Internal link in same tab to Portal Opt-In/Opt-Out page">Text Opt-In/Opt-Out</Link></p>
        </div>
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Towing Companies</h6>
            <p><Link href="/towing-companies/towing-company-services" title="Internal link in same page to Towing Company Services page">Towing Company Services</Link></p>
            <p><Link href="/invoice" title="Internal link in same page to Submit Invoice page">Submit Invoice</Link></p>
            <p><Link href="/towing-companies/vendor-application" title="Internal link in same page to Vendor Application page">Vendor Application</Link></p>
        </div>
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Dealerships</h6>
            <p><Link href="/dealerships/dealership-services" title="Internal link in same page to Dealership Services page">Dealership Services</Link></p>
            <p><Link href="/dealerships/dealership-application" title="Internal link in same page to Membership Application page">Dealership Application</Link></p>
            <p><Link href="/login" title="Internal link in same page to Dealer Login page">Dealer Login</Link></p>
        </div>
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Testimonials</h6>
            <p><Link href="/testimonials#Motorists" title="Internal link in same page to Motorist Testimonials page">Motorist Testimonials</Link></p>
            <p><Link href="/testimonials#Vendor" title="Internal link in same page to Towing Company Testimonials page">Towing Company Testimonials</Link></p>
            <p><Link href="/testimonials#Dealer" title="Internal link in same page to Dealership Testimonials page">Dealership Testimonials</Link></p>
            <p><Link href="/testimonials#testimonialForm" title="Internal link in same page to Dealership Testimonials page">Submit A Testimonial</Link></p>
        </div>
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Apply Now</h6>
            <p><Link href="/motorists-and-rentals/member-registration" title="Internal link in same page to Motorist Membership Application page">Motorist Membership Application</Link></p>
            <p><Link href="/towing-companies/vendor-application" title="Internal link in same page to Towing Company Application page">Towing Company Application</Link></p>
            <p><Link href="/dealerships/dealership-application" title="Internal link in same page to  Dealership Application page">Dealership Application</Link></p>
        </div>
        <div class="footer-groups">
            <h6 class="font-bold mt-2 text-left">Policies</h6>
            <p><Link href="/policies/privacy-policy" title="Internal link in same page to Privacy Policy page">Privacy Policy</Link></p>
            <p><a href="/policies/terms-of-service" title="internal link in same tab to terms of use page">Terms of Use</a></p>
            <p><Link href="/policies/web-content-accessibility" title="Internal link in same page to Web Content Accessibility page">Web Content Accessibility</Link></p>
            <p><Link href="/policies/refund-policy" title="Internal link in same page to Refund Policy page">Refund Policy</Link></p>
            <span id="sp-tb-embed-widget" class="btn btn-security" onclick="window.sp.showPrivacyBanner();">Privacy</span>

        </div>
    </div>

    <hr class="primary w-11/12!important" />

    <div class="footer-copy font-bold">
        <div class="copy-left">
        <p>©2021 Motorcycle Towing Services, LLC. All rights reserved.</p>
        </div>
        <div class="copy-right relative">
            <a href="https://www.forbin.com" class="flex" title="external link in new page to Forbin website" target="_blank" style="align-items: center">
                <p class="pr-4">Web Solutions Powered By VGM&nbsp;Forbin</p>
        <div><img src="/images/50x50-icon.webp" alt="VGM Forbin logo"></div></a>
       </div>
    </div>
</footer>
</template>


<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .footer {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;
        color: #3B74D7;
        bottom: 0;
        padding-bottom: 2rem;

        &:before {
            content: '';
            background: url('/images/footer-texture.svg') no-repeat;
            background-size: 100% auto;
            height: 106px;
            width: 100vw;
            position: absolute;
            top: -106px;
            left: 0;
            display: block;
            background-position: bottom center;
            filter: drop-shadow(4px -11px 6px #0c26549a);

        }

        .footer-nav {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            justify-content: space-around;
            padding-top: 3rem;
            flex-wrap: wrap;
            margin: 0 .5rem;

            @include bp-up($bp-md){
                grid-template-columns: repeat(3, minmax(0, 1fr));
                margin: auto;
            }

            @include bp-up($bp-lg +1){
                grid-template-columns: repeat(6, minmax(0, 1fr));
                margin: auto;
            }

            a {
                text-decoration: none;
                display: flex;
                flex-wrap: wrap;

                &:hover {
                    text-decoration: underline;
                }
            }

            .footer-groups {
                padding: 0 .5rem;

                p { padding: .25rem 0 ;}
            }

        }

        .footer-copy {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 2.5rem;
            align-items: center;

            @include bp-up($bp-md +1){ flex-direction: row;}


            .copy-right {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: .5rem;

                @include bp-up($bp-sm +1){ margin-top: 0;}
            }
        }


    }


</style>

<script>
    import { defineComponent } from 'vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';

    export default defineComponent ({
        components: {
            Link,
        },
        props: {
            laravelVersion: String,
            phpVersion: String,
        },
        methods: {
            checkVisible(elm) {
                var rect = elm.getBoundingClientRect();
                var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
            }
        },
        mounted() {
            let footer = document.querySelector('footer.footer')

            let footerIntersectionObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    let floatingBadge = document.querySelector('[sp-widget-holder="trust-badge"]')
                    if (floatingBadge) floatingBadge.style.display = entry.intersectionRatio ? 'none' : 'block'
                })
            })

            footerIntersectionObserver.observe(footer)
        }
    })
</script>
