<template>
    <Export 
        :members="memberships"
        :searchModel="searchModel"
        :start_date="start_date"
        :end_date="end_date"
        :member_type="member_type"
        :status="status"
    />
    <form class="search-form" @submit.prevent="applyFilters()">
        <div class="search-fields flex justify-between flex-col md:flex-row md:items-end">
        <input
            class="st-search"
            type="search"
            v-model="searchModel"
            placeholder="Search..."
        />
            <div class="flex flex-col md:flex-row md:items-center flex-wrap">
            <div> <label for="date" class="m-1">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/></div>
            </div>

                <div><label for="member_type" class="m-1">Member Type </label>
                <select v-model="member_type" class="border py-2 pl-3 pr-8 text-grey-800 m-1">
                    <option value=""> - </option>
                    <option
                        v-for="option in member_type_list"
                        :key="option.value"
                        :value="option.name"
                    >{{option.name}}</option>
                </select></div>

                <div><label for="status" class="m-1">Status </label>
                <select v-model="status" class="border py-2 pl-3 pr-8 text-grey-800 m-1">
                    <option value=""> - </option>
                    <option
                        v-for="option in member_status_list"
                        :key="option"
                        :value="option"
                    >{{option}}</option>
                </select></div>
                <div><jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">Apply Filters</span></jet-button><JetButton class="button button-primary m-1" @click="resetFilters()">Reset Filters</JetButton></div>
        </div>
    </form>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!memberships.isLoading">
        <div class="st-hidden-sorts">
            <p :class="memberships.sortClass('membership_number')" @click="memberships.sortBy('membership_number')">Membership #</p>
            <p :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
            <p :class="memberships.sortClass('member_type')" @click="memberships.sortBy('member_type')">Member Type</p>
            <p :class="memberships.sortClass('plan_type')" @click="memberships.sortBy('plan_type')">Plan Type</p>
            <p :class="memberships.sortClass('city_state')" @click="memberships.sortBy('city_state')">City State</p>
            <p :class="memberships.sortClass('plan_expiration')" @click="memberships.sortBy('plan_expiration',null,'date')">Expiration</p>
            <p :class="memberships.sortClass('status')" @click="memberships.sortBy('status')">Status</p>
        </div>
        <div class="simple-table" style="--cols:8">
            <p class="st-header first-col" :class="memberships.sortClass('membership_number')" @click="memberships.sortBy('membership_number')">Membership #</p>
            <p class="st-header" :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
            <p class="st-header" :class="memberships.sortClass('member_type')" @click="memberships.sortBy('member_type')">Member Type</p>
            <p class="st-header" :class="memberships.sortClass('plan_type')" @click="memberships.sortBy('plan_type')">Plan Type</p>
            <p class="st-header" :class="memberships.sortClass('city_state')" @click="memberships.sortBy('city_state')">City / State</p>
            <p class="st-header" :class="memberships.sortClass('plan_expiration')" @click="memberships.sortBy('plan_expiration',null,'date')">Expiration</p>
            <p class="st-header" :class="memberships.sortClass('status')" @click="memberships.sortBy('status')">Status</p>
            <p class="st-header last-col">Actions</p>
            <template v-for="(row, index) in memberships.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Membership #</span>{{ row.membership_number }}</p>
                <p><span class="st-hidden">Motorist</span>{{ row.full_name }}</p>
                <p><span class="st-hidden">Member Type</span>{{ row.member_type }}</p>
                <p><span class="st-hidden">Plan Type</span>{{ row.plan_type }} </p>
                <p><span class="st-hidden">City / State</span>{{ row.city_state }}</p>
                <p><span class="st-hidden">Expiration</span>{{ row.plan_expiration }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.status" /></p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <jet-secondary-button @click="printPdf(row.id)">
                            Print PDF
                        </jet-secondary-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ memberships.rangeText }}</p>
            <Pagination
                v-if="memberships.itemCount || memberships.itemCount <= memberships.itemsPerPage"
                :active-page="memberships.activePage"
                :item-count="memberships.itemCount"
                :items-per-page="memberships.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
</template>

<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import Pagination from '@/Components/Pagination.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import VueLoader from '@/Components/VueLoader'
    import Export from '../Exports/ExportButton.vue'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Status,
            JetButton,
            JetSecondaryButton,
            Link,
            VueLoader,
            Pagination,
            Export,
            moment,
        },

       data() {
            return {
                memberships: reactive(new EloquentSSFP()),
                memberId: null,
                searchModel: '',
                member_type_list: [],
                member_status_list: [],
                member_type: '',
                start_date: null,
                end_date: null,
                status: '',
                exporting: false,
                fileReady: false,
            }
        },
        methods: {
            resetFilters() {
                this.status = '';
                this.member_type = '';
                this.start_date = null;
                this.end_date = null;
                this.memberships.resetAll();
            },
            applyFilters() {
                let options = {...this.memberships.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('plan_expiration');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.member_type) {
                    options['filterProps'].push('member_type');
                    options['filterOperators'].push([null])
                    options['filterValues'].push([this.member_type])
                    options['filterExclusivities'].push(1);
                }

                if (this.status) {
                    options['filterProps'].push('status');
                    options['filterValues'].push([this.status]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }

                this.memberships.refreshData(options);
            },
            updateActivePage(page) {
                this.memberships.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchMemberStatusEnum() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_status_enum'),
                })
                .then(response => {
                    this.member_status_list = response.data;
                })
            },
            printPdf: function (id) {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.memberships.print_pdf'),
                    responseType: "blob",
                    data: {
                        member: id
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-member-${id}.pdf`;
                        link.click();

                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            resetOne: function () {
                this.$refs.customTable.updateActivePage(1);
            },
        },
        mounted() {
            this.memberships.init('/dashboard/motorists/memberships/fetch_members_ssfp', {
                searchableData: ['full_name', 'member_type', 'plan_type', 'membership_number','city_state'],
                itemsPerPage: 50,
            });
        },
        created() {
            this.fetchMemberTypes();
            this.fetchMemberStatusEnum();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
