<template>
    <div class="footer" ref="footer">
        <div class="copyright">
            <div>
            <p>
            <a href="/policies/privacy-policy" title="Internal link in same page to Privacy Policy page" target="_blank">Privacy Policy</a>
            <a href="/policies/terms-of-service" title="internal link in same tab to terms of use page">Terms of Use</a>
            <a href="/policies/web-content-accessibility" title="Internal link in same page to Web Content Accessibility page"  target="_blank">Web Content Accessibility</a>
            <a href="/policies/refund-policy" title="Internal link in same page to Refund Policy page"  target="_blank">Refund Policy</a>
            </p>
           <p> &copy; Motorcycle Towing Services LC.. All Rights Reserved. Custom web solutions provided by <a href="https://goo.gl/Rwf1Zx" title="External link to VGM Forbin's site in a new window" target="_blank">VGM Forbin</a>
            </p> 
            </div>
            <span id="sp-tb-embed-widget" class="btn btn-security" onclick="window.sp.showPrivacyBanner();">Privacy</span>
        </div>
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "AppFooter",
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            Link
        },
        methods: {
            getFooterHeight() {
                if (this.$refs.footer) {
                    document.body.style.setProperty('--footer-height', this.$refs.footer.clientHeight + 'px');
                }
            },
        },
        mounted() {
            this.getFooterHeight();
            window.addEventListener('resize', this.getFooterHeight);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getFooterHeight);
        }
    }
</script>

<style lang="scss">
    .secure-privacy-tb-lower-right {
        display: none !important;
    }
</style>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .footer {
        z-index: 1;
        grid-area: footer;
        background-color: var(--blue-500);
        color: white;

        @include not-desktop { padding-bottom: 40px; }

        .copyright {
            display: flex;
            flex-direction: column;
            text-align: center;

            @include fluid-size(32px, 48px, padding-top);
            @include fluid-size(32px, 48px, padding-right);
            @include fluid-size(32px, 48px, padding-left);
            @include fluid-size(32px, 48px, padding-bottom);

            @include bp-up($bp-md) { 
                text-align: left;
                justify-content: space-between;
                flex-direction: row;
            }

            a {
                text-decoration: underline;
                padding-right: .5rem;
            }
        }
    }
</style>