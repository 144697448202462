<template>
    <AppLayout title="Forms">
        <template #header>
        </template>
        <Card
            title="Forms"
        >

            <FormsTable
                :data="forms"
                :searchable-data="['form_name', 'recipients', 'created_at']"
                @row-deleted="rowDeleted($event)"
                ref="formTable"
            />
        </Card>
    </AppLayout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import FormsTable from './Tables/FormsTable.vue'
    import Card from '@/Components/Card.vue';
    import { Link } from '@inertiajs/inertia-vue3'

    export default defineComponent({
        components: {
            AppLayout,
            Card,
            FormsTable,
            Link,
        },
        data() {
            return {
                forms:[],
            }
        },
        methods: {
            fetchForms() {
                axios
                    .get(route('dashboard.forms.fetch_forms'))
                    .then(response => {
                        this.forms = response.data.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/forms/delete_form_submission/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchForms();
                        this.$refs.formTable.closeModal();
                    }
                })
            },
        },
        mounted() {
            this.fetchForms();
        }
    })
</script>
