<template>
    <div class="family-member">
        <p class="mt-4 font-bold text-blue-500">Family Member</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <jet-label class="mt-4" :for="`family_member_name_${index}`" value="Name" />
                <jet-input :id="`family_member_name_${index}`" type="text" class="mt-1 block w-full" :value="familyMemberName" @input="$emit('update:familyMemberName', $event.target.value)"  required />
            </div>
            <div>
                <jet-label class="mt-4" :for="`family_member_phone_${index}`" value="Phone" />
                <jet-input :id="`family_member_phone_${index}`" type="text" class="mt-1 block w-full" :value="familyMemberPhone" @input="$emit('update:familyMemberPhone', $event.target.value)"  required />
            </div>
        </div>
    </div> <!-- end family member -->
</template>

<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'

export default {
    components: {
        JetInput,
        JetLabel
    },
    props: [
        'index',
        'familyMemberName',
        'familyMemberPhone',
    ]
}
</script>

<style>

</style>
