<template>
    <AppLayout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="User Management"
        >
            <Link
                    :href="`/dashboard/users/add_user`"
                    as="button"
                    type="button"
                >

                <JetButton  class="button button-primary top-buttons">
                    Add User
                </JetButton>
            </Link>
            <div>
                <UsersTable />
            </div>
        </Card>
    </AppLayout>
</template>

<style lang="scss" scoped>
@import '../../../../scss/mixins';
    label {
        @include fluid-size(12px, 16px, font-size);
        font-size: 14px;
        font-weight: 500;
    }
    .search-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;


    }
</style>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import UsersTable from './Tables/UsersTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'

    export default {
        components: {
            AppLayout,
            UsersTable,
            Link,
            Card,
            JetButton,
        }
    }
</script>
