<template>
    <jet-form-section @submitted="updateVendorInformation">
        <template #description>
            <span v-if="isModify" class="font-medium">Update member account profile information. </span>
            <span v-else class="font-medium">Add member account profile information.</span>
        </template>

        <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="member_id" v-if="isModify" type="hidden" name="member_id" v-model="form.id" />
            <!-- Member Info -->
            <h3 class="mt-4 text-blue-500">Company Information</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="company_name" value="Company Name" />
                    <jet-input id="company_name" type="text" class="mt-2 block w-full" v-model="form.company_name" />
                    <jet-input-error :message="form.errors.company_name" class="mt-2" />
                </div>
                <div>
                    <jet-label for="company_first_name" value="First Name" />
                    <jet-input id="company_first_name" type="text" class="mt-2 block w-full" name="company_first_name" v-model="form.company_first_name" required  />
                    <jet-input-error :message="form.errors.company_first_name" class="mt-2" />
                </div>

                <div>
                    <jet-label for="company_last_name" value="Last Name" />
                    <jet-input id="company_last_name" type="text" class="mt-2 block w-full" name="company_last_name" v-model="form.company_last_name" required  />
                    <jet-input-error :message="form.errors.company_last_name" class="mt-2" />
                </div>
            </div>
            <!-- Address -->
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="company_address" value="Address" />
                    <jet-input id="company_address" type="text" class="mt-2 block w-full" v-model="form.company_address" />
                    <jet-input-error :message="form.errors.company_address" class="mt-2" />
                </div>
                <div>
                    <jet-label for="company_address2" value="Address 2" />
                    <jet-input id="company_address2" type="text" class="mt-2 block w-full" v-model="form.company_address2" />
                    <jet-input-error :message="form.errors.company_address2" class="mt-2" />
                </div>
                <div>
                    <jet-label for="company_city" value="City" />
                    <jet-input id="company_city" type="text" class="mt-2 block w-full" v-model="form.company_city" />
                    <jet-input-error :message="form.errors.company_city" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                
                <div>
                    <jet-label for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.company_state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.company_state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="company_zip" value="Zip/Postal Code" />
                    <jet-input id="company_zip" type="text" class="mt-1 block w-full" v-model="form.company_zip" />
                    <jet-input-error :message="form.errors.company_zip" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="company_country" value="Country" />
                    <select id="company_country" class="mt-2 block w-full" v-model="form.company_country" name="country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.company_country" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="company_phone" value="Office Phone" />
                    <vue-tel-input id="company_phone" v-model="form.company_phone" :error-message="form.errors.company_phone" />
                    <jet-input-error :message="form.errors.company_phone" class="mt-2" />
                </div>
                <div>
                    <jet-label for="email" value="Company Email" />
                    <jet-input id="email" type="text" class="mt-2 block w-full" v-model="form.email" />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
                <div>
                    <jet-label for="invoice_id" value="Invoice #" />
                    <jet-input id="invoice_id" type="text" class="mt-2 block w-full" v-model="form.invoice_id" />
                    <jet-input-error :message="form.errors.invoice_id" class="mt-2" />
                </div>
                
            </div>
            <h3 class="mt-4 text-blue-500">Customer Information</h3>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="member_name" value="Member Name" />
                    <jet-input id="member_name" type="text" class="mt-2 block w-full" v-model="form.member_name" />
                    <jet-input-error :message="form.errors.member_name" class="mt-2" />
                </div>
                <div>
                    <jet-label for="membership_number" value="Membership #/ID" />
                    <jet-input id="membership_number" type="text" class="mt-2 block w-full" name="membership_number" v-model="form.membership_number" required  />
                    <jet-input-error :message="form.errors.membership_number" class="mt-2" />
                </div>
            </div>
            <h3 class="mt-4 text-blue-500">Tow Information</h3>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="tow_date" value="Date of Tow" />
                    <jet-input id="tow_date" type="date" class="mt-2 block w-full" v-model="form.tow_date" />
                    <jet-input-error :message="form.errors.tow_date" class="mt-2" />
                </div>
                <div>
                    <jet-label for="mts_po_number" value="MTS/PO #" />
                    <jet-input id="mts_po_number" type="text" class="mt-2 block w-full" name="mts_po_number" v-model="form.mts_po_number" required  />
                    <jet-input-error :message="form.errors.mts_po_number" class="mt-2" />
                </div>
                <div>
                    <jet-label for="rental_agreement" value="RA #" />
                    <jet-input id="rental_agreement" type="text" class="mt-2 block w-full" v-model="form.rental_agreement" />
                    <jet-input-error :message="form.errors.rental_agreement" class="mt-2" />
                </div>
                <div>
                    <jet-label for="break_down_location" value="Breakdown Location" />
                    <jet-input id="break_down_location" type="text" class="mt-2 block w-full" v-model="form.break_down_location" />
                    <jet-input-error :message="form.errors.break_down_location" class="mt-2" />
                </div>
            </div>
            
            <h3 class="mt-4 text-blue-500">Motorcycle Information</h3>
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div>
                    <jet-label for="bike_make" value="Make" />
                    <jet-input id="bike_make" type="text" class="mt-2 block w-full" v-model="form.bike_make" />
                    <jet-input-error :message="form.errors.bike_make" class="mt-2" />
                </div>
                <div>
                    <jet-label for="bike_year" value="Year" />
                    <jet-input id="bike_year" type="text" class="mt-2 block w-full" v-model="form.bike_year" />
                    <jet-input-error :message="form.errors.bike_year" class="mt-2" />
                </div>
                <div>
                    <jet-label for="bike_plate" value="Plate Number" />
                    <jet-input id="bike_plate" type="text" class="mt-2 block w-full" v-model="form.bike_plate" />
                    <jet-input-error :message="form.errors.bike_plate" class="mt-2" />
                </div>
                <div>
                    <jet-label for="bike_state" value="State/Province" />
                    <jet-input id="bike_state" type="text" class="mt-1 block w-full" v-model="form.bike_state" />
                    <jet-input-error :message="form.errors.bike_state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="bike_vin" value="VIN Number" />
                    <jet-input id="bike_vin" type="text" class="mt-2 block w-full" v-model="form.bike_vin" />
                    <jet-input-error :message="form.errors.bike_vin" class="mt-2" />
                </div>
            </div>
            <h3 class="mt-4 text-blue-500">Charges</h3>
            <div class="grid grid-cols-1 md:grid-cols-10 gap-4">
                <div class="col-span-3">
                    <jet-label for="dispatch_fee_charge" value="Base/Hookup Rate" />
                    <jet-input id="dispatch_fee_charge" type="text" class="mt-2 block w-full" v-model="form.dispatch_fee_charge" />
                    <jet-input-error :message="form.errors.dispatch_fee_charge" class="mt-2" />
                </div>
                <div class="col-span-3">
                    <jet-label for="mileage_charge" value="On Route Miles" />
                    <jet-input id="mileage_charge" type="text" class="mt-2 block w-full" v-model="form.mileage_charge" />
                    <jet-input-error :message="form.errors.mileage_charge" class="mt-2" />
                </div>
                <div class="col-span-2">
                    <jet-label for="miles_charge" value="Rate/Mile" />
                    <jet-input id="miles_charge" type="text" class="mt-2 block w-full" v-model="form.miles_charge" />
                    <jet-input-error :message="form.errors.miles_charge" class="mt-2" />
                </div>
                <div class="col-span-2">
                    <h6 class="text-left md:text-right mt-2 md:mt-8">Base Total: ${{ formatPrice(initial_total) }} </h6>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div class="col-span-2">
                    <jet-label for="loaded_rate" value="Loaded Rate/Mile" />
                    <jet-input id="loaded_rate" type="text" class="mt-2 block w-full" v-model="form.loaded_rate" />
                    <jet-input-error :message="form.errors.loaded_rate" class="mt-2" />
                </div>
                <div class="col-span-2">
                    <jet-label for="loaded_miles" value="Loaded Miles" />
                    <jet-input id="loaded_miles" type="text" class="mt-2 block w-full" v-model="form.loaded_miles" />
                    <jet-input-error :message="form.errors.loaded_miles" class="mt-2" />
                </div>
                <div class="span-col-1">
                    <h6 class="text-left md:text-right mt-2 md:mt-8">Loaded Total: ${{ formatPrice(loaded_total) }} </h6>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div class="col-span-2">
                    <jet-label for="storage_rate" value="Storage Rate" />
                    <jet-input id="storage_rate" type="text" class="mt-2 block w-full" v-model="form.storage_rate" />
                    <jet-input-error :message="form.errors.storage_rate" class="mt-2" />
                </div>
                <div class="col-span-2">
                    <jet-label for="storage_days" value="# of Days" />
                    <jet-input id="storage_days" type="text" class="mt-2 block w-full" v-model="form.storage_days" />
                    <jet-input-error :message="form.errors.storage_days" class="mt-2" />
                </div>
                <div class="span-col-1">
                    <h6 class="text-left md:text-right mt-2 md:mt-8">Storage Total: ${{ formatPrice(storage_total) }} </h6>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div class="col-span-2">
                    <jet-label for="additional_charge" value="Goa/Other Charges" />
                    <jet-input id="additional_charge" type="text" class="mt-2 block w-full" v-model="form.additional_charge" />
                    <jet-input-error :message="form.errors.additional_charge" class="mt-2" />
                </div>
                <div class="col-span-2">
                    <jet-label for="additional_charge_notes" value="Goa/Other Charges Notes" />
                    <textarea id="additional_charge_notes" class="mt-2 block w-full" v-model="form.additional_charge_notes" />
                    <jet-input-error :message="form.errors.additional_charge_notes" class="mt-2" />
                </div>
                 <div class="col-span-1">
                    <h6 class="text-left md:text-right mt-2 md:mt-8">Grand Total: ${{ formatPrice(grand_total) }} </h6>
                </div>
            </div>
            <h3 class="mt-4 text-blue-500">Validation of Submission</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="date" value="Date" />
                    <br />
                   {{ localizeDate(form.date)  }}
                </div>
                <div>
                    <jet-label for="full_name" value="Full Name" />
                    <br />
                    {{ form.full_name }}
                </div>
                 <div>
                    <jet-label for="title" value="Title" />
                    <br />
                    {{ form.title }}
                </div>
            </div>
            <input type="hidden" name="date" v-model="form.date" />
            <input type="hidden" name="full_name" v-model="form.full_name" />
            <input type="hidden" name="title" v-model="form.title" />
        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Invoice Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Invoice Added.
            </jet-action-message>

            <jet-button v-if="isModify" @click="updateVendorInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
            <jet-button v-else @click="addVendorInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            VueTelInput,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    company_name: '',
                    company_first_name: '',
                    company_last_name: '',
                    company_phone: '',
                    company_address: '',
                    company_address2: '',
                    company_city: '',
                    company_state: '',
                    company_country: '',
                    company_zip: '',
                    tow_date: '',
                    membership_number: '',
                    break_down_location: '',
                    tow_date: '',
                    invoice_id: '',
                    member_name: '',
                    email: '',
                    rental_agreement: '',
                    bike_make: '',
                    bike_year: '',
                    bike_plate: '',
                    bike_state: '',
                    bike_vin: '',
                    mts_po_number: '',
                    dispatch_fee_charge: '',
                    mileage_charge: '',
                    miles_charge: '',
                    loaded_rate: '',
                    loaded_miles: '',
                    storage_days: '',
                    storage_rate: '',
                    additional_charge: '',
                    additional_charge_notes: '',
                    full_name: '',
                    title: '',
                    date: '',
                }),
                states: [],
                isModify: false,
                showAdd: false,
                countries: ['USA', 'Canada'],
            }
        },
        computed: {
            loaded_total() {
                let [lrate, lmiles] = [this.form.loaded_rate,this.form.loaded_miles];
                return parseFloat(lrate * lmiles).toFixed(2);
            },
            initial_total() {
                var ibase = parseFloat(this.form.dispatch_fee_charge);
                var imiles = parseFloat(this.form.mileage_charge);
                var imilesRate = parseFloat(this.form.miles_charge);
                var unloadTot = imiles * imilesRate;
                var initTotal = ibase + unloadTot;
                return (parseFloat(initTotal) || 0).toFixed(2);
            },
            storage_total() {
                let [sbase, sdays] = [this.form.storage_rate,this.form.storage_days];
                return (parseFloat(sbase * sdays) || 0).toFixed(2);
            },
            grand_total() {
                return (parseFloat(this.initial_total) + parseFloat(this.loaded_total) + (parseFloat(this.storage_total)) + parseFloat(this.form.additional_charge) || 0).toFixed(2);
            }
        },
        methods: {
            fetchInvoiceData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var invoiceId = parseInt(last_segment);
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.vendor_invoices.fetch_vendor_invoice'),
                    data: {
                        invoice: invoiceId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            updateVendorInformation() {
                this.form.post(route('dashboard.towing_companies.vendor_invoices.update'), {
                    errorBag: 'updateInvoice',
                    preserveScroll: true,
                    onSuccess: () => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                    }
                });
            },
            addVendorInformation() {
                this.form.post(route('dashboard.towing_companies.vendor_invoices.store'), {
                    errorBag: 'addModifyInvoice',
                    preserveScroll: true,
                    onSuccess: () => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                    }
                });
            },

            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var invoiceId = parseInt(last_segment);

                if(Number.isNaN(invoiceId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.invoiceId = invoiceId;
                    this.fetchInvoiceData();

                }
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return `${mm}/${dd}/${yyyy}`
            },

        },
        mounted() {
            this.checkModify();
            this.getStates();
        },
    }
</script>
