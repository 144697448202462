<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
            <Card
                title="Plan Types">
                <Link
                    :href="`/dashboard/motorists/plan-types/add_plan`"
                    as="button"
                    type="button"
                >
                    <JetButton  class="button button-primary absolute top-4 right-4">
                        ADD PLAN
                    </JetButton>
                </Link>
                <div v-if="plans.length == 0">
                    <VueLoader  />
                </div>
                <div v-else>
                    <PlanTable
                        :data="plans"
                        :searchable-data="['code', 'name', 'title', 'description']"
                        @row-deleted="rowDeleted($event)"
                        ref="planTable"
                    />
                </div>
            </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import PlanTable from './Tables/PlanTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            PlanTable,
            Link,
            Card,
            JetButton,
            VueLoader,
        },
        data() {
            return {
                plans:[],
            }
        },
        methods: {
            fetchPlans() {
                axios
                    .get(route('dashboard.motorists.plan_types.fetch_plans'))
                    .then(response => {
                        this.plans = response.data.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/motorists/plan-types/delete_plan/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchPlans();
                        this.$refs.planTable.closeModal();
                    }
                })
            },
        },
        mounted() {
            this.fetchPlans();
        }
    })
</script>
