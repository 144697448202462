<template>
    <jet-label :for="id" :value="label" />
    <jet-input
        v-mask="['(###) ###-####', '#(###) ###-####', '+# (###) ###-####', '+## (###) ###-####']"
        :id="id"
        v-bind="$attrs"
        type="tel"
        class="mt-1 block w-full"
        v-model="telModel"
    />
    <jet-input-error :message="errorMessage" class="mt-2" />
</template>

<script>
import { mask } from 'vue-the-mask';
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'

export default {
    components: {
        JetInput,
        JetInputError,
        JetLabel,
    },
    inheritAttrs: false,
    directives: {mask},
    props: ["id", "label", "modelValue", "errorMessage"],
    emits: ['update:modelValue'],
    computed: {
        telModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>