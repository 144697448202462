<template>
    <div class="credit-card">
        <div class="form-group flex flex-col w-1/2">
            <label for="username">Full name (on the card)</label>
            <input type="text" name="username" placeholder="Full Name" required class="form-control">
        </div>
        <div class="form-group">
            <label for="cardNumber">Card number</label>
            <div class="input-group">
                <input type="number" name="cardNumber" placeholder="Your card number" class="form-control w-1/2" required>
                <div class="input-group-append">
                    <div class="ml-1 text-navy-200">
                        <FontAwesomeIcon fixed-width :icon="visaIcon"/>
                        <FontAwesomeIcon fixed-width :icon="mastercardIcon"/>
                        <FontAwesomeIcon fixed-width :icon="amexIcon"/>
                    </div>
                </div>
            </div>
        </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
                <div class="form-group">
                    <label><span class="hidden-xs">Expiration</span></label>
                    <div class="input-group">
                        <input type="number" placeholder="MM" name="" max="12" class="form-control" required>
                        <input type="number" placeholder="YY" name="" class="form-control ml-8" required>
                    </div>
                </div>
            </div>
            <div class="grid grid-col-2 gap-4">
                <div class="form-group mb-4">
                <label title="Three-digits code on the back of your card">CVV</label>
                <input type="text" required class="form-control">
                </div>
            </div>
        </div>
</template>
<style>

</style>
<script>
    import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'


    export default {
        components: {
            FontAwesomeIcon,
            faCcVisa,
            faCcMastercard,
            faCcAmex,
            library
        },
        data() {
            return {
                questionCircle: faQuestionCircle,
                visaIcon : faCcVisa,
                mastercardIcon: faCcMastercard,
                amexIcon: faCcAmex,
            }
        }
    }
</script>

<style>

</style>
