<template>
    <app-layout title="Dashboard - Tows">
        <template #header>
        </template>
        <Card
            title="Tows">
            <Link
                :href="`/dashboard/tow-requests/add_tow_requests_step1`"
                as="button"
                type="button"
            >
                <JetButton class="button button-primary top-buttons">
                        ADD TOW
                </JetButton>
            </Link>
            <TowsTable />
        </Card>
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import TowsTable from './Tables/TowsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'

    export default {
        components: {
            AppLayout,
            TowsTable,
            Link,
            JetButton,
            Card,
        },
    }
</script>
