<template>
    <FrontEndLayout title="Membership Application Form">
        <template #head>
            <meta name="description" content="Membership Application Form">
            <meta name="keywords" content="membership application">
        </template>
        <template #header>
            <h1>MEMBERSHIP APPLICATION</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container">
                <div class="text-center">
            <h2 class="font-bold text-navy-500">Complete Your Application</h2>
            <p> Motorcycle Towing Services is here to answer your questions.  Please give us a call at 800-999-7064 during normal business hours or simply fill out the form below and a representative will contact you as soon as possible.  After submitting your application, you will receive an email confirmation with your membership information and a link to set up your password to access you account.</p>
                </div>
                </div>
            <form @submit.prevent="submit" id="membershipForm">
                <div class="gray-section">
                    <div class="container">
                <h3 class="text-navy-500">Contact Information</h3>
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <jet-label class="mt-4" for="first_name" value="First Name" />
                    <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" name="first_name" required  />
                    <jet-input-error :message="form.errors.first_name" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="last_name" value="Last Name" />
                    <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" name="last_name" required  />
                    <jet-input-error :message="form.errors.last_name" class="mt-2" />
                </div>
            </div>
                <div>
                    <jet-label class="mt-4" for="address" value="Address" />
                    <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" name="address" required  />
                    <jet-input-error :message="form.errors.address" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="address2" value="Address 2" />
                    <jet-input id="address2" type="text" class="mt-1 block w-full" v-model="form.address2" name="address2"  />
                    <jet-input-error :message="form.errors.address2" class="mt-2" />
                </div>
                <div class="grid grid-cols-4 gap-4">
                <div>
                    <jet-label class="mt-4" for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" name="city" required  />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="postal_code" value="Zip/Postal Code" />
                    <jet-input id="postal_code" type="text" class="mt-1 block w-full" v-model="form.postal_code" name="postal_code" required  />
                </div>
                <div>
                    <jet-label for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" name="country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
            </div>
                <div class="grid grid-cols-3 gap-4">
                <div>
                    <vue-tel-input id="phone" label="Phone" v-model="form.phone" :error-message="form.errors.phone" />
                </div>

                <div>
                    <vue-tel-input id="cell" label="Cell" v-model="form.cell" :error-message="form.errors.cell" />
                </div>

                <div>
                    <jet-label class="mt-4" for="email" value="Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" name="email" required  />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
                </div>
                </div>
                </div>
                <div class="container">
                <h3 class="text-navy-500">Motorcycle{{ motorcycles > 1 ? 's' : '' }}</h3>
                 <Motorcycle
                    v-for="n in motorcycles"
                    :key="'member-motorcycle-' + n"
                    :index="n"
                    :states=states
                    v-model:motorcycle-year="form.member_motorcycles[n-1].motorcycle_year"
                    v-model:motorcycle-make="form.member_motorcycles[n-1].motorcycle_make"
                    v-model:motorcycle-model="form.member_motorcycles[n-1].motorcycle_model"
                    v-model:motorcycle-color="form.member_motorcycles[n-1].motorcycle_color"
                    v-model:motorcycle-plate="form.member_motorcycles[n-1].motorcycle_plate"
                    v-model:motorcycle-state="form.member_motorcycles[n-1].motorcycle_state"
                    v-model:motorcycle-country="form.member_motorcycles[n-1].motorcycle_country"
                    v-model:motorcycle-vin="form.member_motorcycles[n-1].motorcycle_vin"
                    v-model:trike-autocycle="form.member_motorcycles[n-1].trike_autocycle"

                />
                <jet-button type="button" @click="addAnotherMotorcycle();" class="my-4">
                    Add Another Bike
                </jet-button>
                <jet-button v-if="motorcycles > 1" type="button" @click="removeMotorcycle();" class="ml-4 my-4">
                    Remove Bike
                </jet-button>
                <div class="flex content-center">
                <h3 class="text-navy-500 my-4">Trailer</h3>
                    <div class="form-group grid grid-cols-4 gap-4 items-center">
                            <jet-input id="trailer_yes" type="radio" class="mt-1 block ml-6" value="Yes" name="has_trailer" required @input="form.has_trailer = 1" :checked="form.has_trailer" />
                            <jet-label for="trailer_yes" value="Yes" />
                            <jet-input id="trailer_no" type="radio" class="mt-1 block ml-6" value="No" name="has_trailer" required @input="form.has_trailer = 0" :checked="!form.has_trailer" />
                           <jet-label for="trailer_no" value="No" />
                            <jet-input-error :message="form.errors.has_tailer" class="mt-2" />
                    </div>
                </div>
                <div class="flex mb-5" v-if="form.has_trailer">
                        <div class="form-group">
                            <h4>Trailer Details</h4>
                            <div>
                                <jet-label class="mt-4" for="trailermake`" value="Make" />
                                <jet-input id="trailermake" v-model="form.trailer_make" type="text" class="mt-1 block" name="trailer_make"  required />
                                <jet-input-error :message="form.errors.trailer_make" class="mt-2" />
                            </div>
                            <div>
                                <jet-label class="mt-4" for="trailerplate" value="Plate Number" />
                                <jet-input id="trailerplate" type="text" v-model="form.trailer_plate" class="mt-1 block" name="trailer_plate" required />
                                <jet-input-error :message="form.errors.tailer_plate" class="mt-2" />
                            </div>
                        </div>
                </div>
                <div class="flex content-center">
                <h3 class="text-navy-500 my-4">Sidecar</h3>
                   <div class="form-group grid grid-cols-4 gap-4 items-center">
                        <jet-input id="sidecar_yes" type="radio" class="mt-1 block ml-6" value="1" @input="form.has_sidecar = 1" :checked="form.has_sidecar" name="has_sidecar" required  />
                        <jet-label for="sidecar_yes" value="Yes" />
                        <jet-input id="sidecar_no" type="radio" class="mt-1 block ml-6" value="0" @input="form.has_sidecar = 0" :checked="!form.has_sidecar" name="has_sidecar" required  />
                        <jet-label for="sidecar_no" value="No" />
                        </div>
                        <jet-input-error :message="form.errors.has_sidecar" class="mt-2" />
                    </div>

                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <jet-label class="mt-4" for="promo_code" value="Promo Code" />
                            <jet-input id="promo_code" type="text" class="mt-1 block w-full" v-model="form.promo_code" name="promo_code" @change="promoCodeChanged" />
                            <jet-input-error :message="promo_code_error" class="mt-2" />
                        </div>

                        <div>
                            <jet-label class="mt-4" for="referred_by" value="Referred By" />
                            <jet-input id="referred_by" type="text" class="mt-1 block w-full" v-model="form.referred_by" name="referred_by" />
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <jet-label class="mt-4" for="rider_group_number" value="Rider Group Number" />
                            <jet-input id="rider_group_number" type="text" class="mt-1 block w-full" v-model="form.rider_group_number" name="rider_group_number" maxlength="50" />
                            <jet-input-error :message="rider_group_number" class="mt-2" />
                        </div>

                        
                    </div>
                </div>
                <div class="gray-section">
                    <div class="container">
                <h2 class="text-navy-500">Available Plans * <span style="color:red" v-if="promo_code_data != null">Promo Code Applied</span></h2>
                <MemberPlans
                    v-model:member-plan="form.plan_type"
                    v-model:member-plan-options="form.plan_options"
                    :plan-id="form.plan_type"
                    :member-plan-options="form.plan_options"
                    :errors="form.errors"
                    :promoCodeData="promo_code_data"
                    :familyPlanSelected="familyPlanSelected"
                />
                <div class="col-span-12" v-if="form.family_plan == 1">
                    <FamilyMembers
                        v-for="n in family_member_count"
                        :key="'family-member-' + n"
                        :index="n"
                        v-model:family-member-name="form.family_members[n-1].name"
                        v-model:family-member-phone="form.family_members[n-1].phone"
                        />
                    <jet-button type="button" @click="addAnotherFamilyMember();" class="ml-4 mt-4">
                        Add Another Family Member
                    </jet-button>
                    <jet-button v-if="family_member_count > 1" type="button" @click="removeFamilyMember();" class="ml-4 mt-4">
                        Remove Family Member
                    </jet-button>
                </div>
                </div>
                </div>
                <div class="container">
                    <h2>Total: ${{ totalCost }}</h2>
                </div>
                <div class="container">
                <h2 class="text-navy-500">Credit Card Information</h2>
                <CreditCard
                    v-model:card-holder="form.card_holder"
                    v-model:card-number="form.card_number"
                    v-model:exp-month="form.exp_month"
                    v-model:exp-year="form.exp_year"
                    v-model:ccv="form.ccv"
                    :errors="form.errors"
                />

                    <p class="mt-5 mb-5"><em>
                    ** For more information
                    Call: 800-999-7064/319-984-6124
                    Email: info@mts-towing.com
                    Monday - Friday, 9am-3pm (CST)<br>

                    You may also print this form out, complete it and enclose your annual membership dues, either by credit card, check or money order made out to Motorcycle Towing Services.</em>
                    </p>
                    <p><em>
                    Mail to: 401 South State Street<br>
                    P.O. Box 559<br>
                    Denver, IA 50622<br>
                    Or Email to info@mts-towing.com
                    </em>
                    </p>
                    <p class="mt-5"><em>
                        You will receive an email confirmation with your membership information (Canadian payments in U.S. funds only).
                        MTS reserves the right to accept or deny membership.</em>
                        </p>

                <div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">
                    <jet-label class="mt-4" for="terms">
                        <div class="flex items-center">
                            <jet-checkbox name="terms" id="terms" />

                            <div class="ml-2">
                                I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
                            </div>
                        </div>
                    </jet-label>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Register
                    </jet-button>
                </div>
            </div>
            </form>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
.plans {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 1em;
}
.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;
        z-index: 2;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: -1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: -1;

        }
    }

</style>

<script>
    import { defineComponent } from 'vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import Motorcycle from '../../DashboardPartials/Motorcycles.vue'; //STOPPED AT MOTORCYCLES, DOWN TO 1
    import MemberPlans from '../../Dashboard/Motorists/Membership/Forms/Partials/MemberPlans.vue';
    import CreditCard from '../../Dashboard/Motorists/Membership/Forms/Partials/CreditCard.vue';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import FamilyMembers from '../../DashboardPartials/FamilyMembers.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            Head,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            Link,
            Motorcycle,
            MemberPlans,
            CreditCard,
            FrontEndLayout,
            FooterGap,
            FamilyMembers,
            FlashMessage,
            VueTelInput,
        },
        computed: {
            totalCost() {
                return parseFloat(this.total_cost).toFixed(2);
            },
            planType() {
                return this.form.plan_type;
            },
            planOptions() {
                return this.form.plan_options;
            },
        },
        data() {
            return {
                form: this.$inertia.form({
                    first_name: '',
                    last_name: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: '',
                    phone: '',
                    cell: '',
                    email: '',
                    has_trailer: 0,
                    has_sidecar: 0,
                    promo_code: '',
                    plan_type: 0,
                    plan_options: [],
                    family_plan: 0,
                    referred_by: '',
                    card_holder: '',
                    card_number: '',
                    exp_month: '',
                    exp_year: '',
                    ccv: '',
                    member_motorcycles: [{
                        motorcycle_year: '',
                        motorcycle_make: '',
                        motorcycle_model: '',
                        motorcycle_color: '',
                        motorcycle_plate: '',
                        motorcycle_state: '',
                        motorcycle_country: '',
                        motorcycle_vin: '',
                        trike_autocycle: 0,
                    }],
                    family_members: [{
                        name: '',
                        phone: '',
                    }],
                    terms: false,
                    rider_group_number: '',
                }),
                motorcycles: 1,
                family_member_count: 1,
                states: [],
                trailer: false,
                countries: ['USA', 'Canada'],
                promo_code_data: null,
                promo_code_error: '',
                total_cost: 0.00,
            }
        },
        methods: {
            submit() {
                this.form.post(route('.motorists_and_rentals.store'), {
                    errorBag: 'memberApplication',
                    onSuccess: (response) => {
                        if(!response.props.flash.error) {
                            this.resetForm();
                        }
                    }
                });
            },
            getTotalCost() {
                axios({
                    method: 'post',
                    url: route('.calc_total'),
                    data: {
                        plan: this.form.plan_type,
                        options: this.form.plan_options,
                        motorcycles: this.motorcycles,
                        promoCode: this.form.promo_code,
                    }
                })
                .then(response => {
                    this.total_cost = response.data;
                })
            },
            familyPlanSelected (e) {
                if(e){
                    this.form.family_plan = 1;
                } else {
                    this.form.family_plan = 0;
                }

            },
            addAnotherMotorcycle () {
                this.motorcycles++
                this.form.member_motorcycles.push({
                    motorcycle_year: '',
                    motorcycle_make: '',
                    motorcycle_model: '',
                    motorcycle_color: '',
                    motorcycle_plate: '',
                    motorcycle_state: '',
                    motorcycle_country: '',
                    motorcycle_vin: '',
                    trike_autocycle: 0,
                })
            },
            removeMotorcycle () {
                this.motorcycles--
            },
            addAnotherFamilyMember ($increment = true) {
                if($increment){
                    this.family_member_count++;
                }

                this.showAddFam = true;
                this.form.family_members.push({
                    name: '',
                    phone: '',
                })
            },
            removeFamilyMember () {
                this.family_member_count--;
                this.form.family_members.pop();
            },
            promoCodeChanged () {
                // Search Promo Codes for the promo code that was entered
                if (this.form.promo_code.trim() == "")
                {
                    this.promo_code_data = null;
                    this.promo_code_error = '';
                }
                else
                {
                    axios.get('/motorists-and-rentals/member-registration/get_promo/' + this.form.promo_code,{
                    }).then(function(response){
                        if (response.data) {
                            this.promo_code_data = response.data;
                            this.promo_code_error = '';
                        } else {
                            this.promo_code_data = null;
                            this.promo_code_error = "Invalid Promo Code";
                        }
                        this.getTotalCost();
                    }.bind(this));
                }
            },
            getStates: function() {
                axios.get('/get_states',{

                    }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox') {
                        allInputs[i].checked = allInputs[i].defaultChecked;
                    } else {
                        allInputs[i].value = "";
                    }
                }
            },
            setRequired() {
                const membershipForm = document.getElementById("membershipForm");
                const inputs         = membershipForm.querySelectorAll("[required]");
                inputs.forEach(input => {
                    let id = input.id;
                    let label = input.closest('label');
                    if (id) label = membershipForm.querySelector(`label[for="${input.id}"]`)
                    let span = document.createElement('span');
                    span.classList.add('text-red-700');
                    span.innerHTML = " *";
                    if (label) label.appendChild(span)
                })
            }
        },
        watch: {
            planType(n) {
                if (n) {
                    this.getTotalCost();
                }
            },
            planOptions(n) {
                if (n) {
                    this.getTotalCost();
                }
            },
            motorcycles(n) {
                if (n) {
                    this.getTotalCost();
                }
            }
        },
        created: function(){
            this.getStates()
        },
        mounted() {
            this.setRequired()
        }
    })
</script>
