<template>
    <div v-if="image">
        <div class="p-4 m-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <h5 class="text-blue-500">"{{ testimonial }}"</h5>
                <h6 class="text-navy-500">{{ author }}</h6>
            </div>
            <img class="extraTesti-img" :src="`${image}`" :alt="`${imageAlt}`" />
        </div>
    </div>
    <div v-else> 
        <div class="p-4 m-4">
            <div>
                <h5 class="text-blue-500">"{{ testimonial }}"</h5>
                <h6 class="text-navy-500">{{ author }}</h6>
            </div>
        </div>
    </div> 
</template>
<style lang="scss" scoped>
    .extraTesti-img {
        height: auto;
        width: auto;
    }
</style>
<script>
    export default ({
        name: "ExtraTestimonials",
        props: {
            testimonial: {
                type: String,
                required: true,
            },
            author: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            image: {
                type: String,
                required: false,
            },
            imageAlt: {
                type: String,
                required: false,
            }

        },
         data() {
            return {
                testimonials: [],
            }
        },
        methods: {
            fetchTestimonials() {
                axios({
                    method: 'get',
                    url: route('.testimonials.fetch_testimonials'),
                })
                .then(response => {
                    this.testimonials = response.data.data;
                })
            },
        },
        mounted() {
            this.fetchTestimonials();
        },
    })
</script>