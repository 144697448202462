<template>
    <AppLayout title="Dashboard - Renewals">
        <template #header>
        </template>

            <Card
                title="Renewals"
            >
            <p class="w-3/4 pb-3 text-sm">Below you can find the list of MTS motorist members. To renew a membership, click each motorist's names. Multiple names can be clicked at the same time. Once you've selected each of the motorists, please click the print button to generate a PDF of the proof of renewal.</p>
            <div>
                <RenewalsTable />
            </div>
        </Card>
    </AppLayout>
</template>

<style lang="scss" scoped>
@import '../../../../../scss/mixins';
    label {
        @include fluid-size(12px, 16px, font-size);
        font-size: 14px;
        font-weight: 500;
    }
    .search-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;


    }
</style>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import RenewalsTable from './Tables/RenewalsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'


    export default defineComponent({
        components: {
            AppLayout,
            RenewalsTable,
            Link,
            Card,
            JetButton,
            JetSecondaryButton,
        },
        methods: {
            exportCSV() {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.memberships.export'),
                    responseType: "blob",
                    // data: {
                    //     tow: id
                    // }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-breakdown-export.xlsx`;
                        link.click();
                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
        },
    })
</script>
