<template>
    <Head title="Register" />

    <jet-authentication-card>
        <template #logo>
            <img src="/images/mts-logo.png" alt="">
        </template>

        <jet-validation-errors class="mb-4" />

        <form @submit.prevent="submit">
            <h3>Contact Information</h3>
            <div>
                <jet-label for="first_name" value="First Name" />
                <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" required autofocus autocomplete="first_name" />
            </div>

            <div>
                <jet-label for="last_name" value="Last Name" />
                <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" required autofocus autocomplete="last_name" />
            </div>

            <div>
                <jet-label for="age" value="Age" />
                <jet-input id="age" type="text" class="mt-1 block w-full" v-model="form.age" required autofocus autocomplete="age" />
            </div>

            <div>
                <jet-label for="address" value="Address" />
                <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" required autofocus autocomplete="address" />
            </div>

            <div>
                <jet-label for="city" value="City" />
                <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" required autofocus autocomplete="city" />
            </div>

            <div>
                <jet-label for="state" value="State/Province" />
                <jet-input id="state" type="text" class="mt-1 block w-full" v-model="form.state" required autofocus autocomplete="state" />
            </div>

            <div>
                <jet-label for="zip" value="Zip/Postal Code" />
                <jet-input id="zip" type="text" class="mt-1 block w-full" v-model="form.zip" required autofocus autocomplete="zip" />
            </div>

            <div>
                <jet-label for="phone" value="Phone" />
                <jet-input id="phone" type="text" class="mt-1 block w-full" v-model="form.phone" required autofocus autocomplete="phone" />
            </div>

            <div>
                <jet-label for="cell" value="Cell" />
                <jet-input id="cell" type="text" class="mt-1 block w-full" v-model="form.cell" autofocus autocomplete="cell" />
            </div>

            <div>
                <jet-label for="email" value="Email" />
                <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus autocomplete="email" />
            </div>
            <h3>Motorcycle</h3>
            <div class="motorcycle-1">
                <div>
                    <jet-label for="motorcycle_year" value="Year" />
                    <jet-input id="motorcycle_year" type="text" class="mt-1 block w-full" v-model="form.motorcycle_year" required autofocus autocomplete="motorcycle_year" />
                </div>
                <div>
                    <jet-label for="motorcycle_make" value="Make" />
                    <jet-input id="motorcycle_make" type="text" class="mt-1 block w-full" v-model="form.motorcycle_make" required autofocus autocomplete="motorcycle_make" />
                </div>
                <div>
                    <jet-label for="motorcycle_model" value="Model" />
                    <jet-input id="motorcycle_model" type="text" class="mt-1 block w-full" v-model="form.motorcycle_model" required autofocus autocomplete="motorcycle_model" />
                </div>
                <div>
                    <jet-label for="motorcycle_color" value="Color" />
                    <jet-input id="motorcycle_color" type="text" class="mt-1 block w-full" v-model="form.motorcycle_color" autofocus autocomplete="motorcycle_color" />
                </div>
                <div>
                    <jet-label for="motorcycle_plate" value="Plate" />
                    <jet-input id="motorcycle_plate" type="text" class="mt-1 block w-full" v-model="form.motorcycle_plate" required autofocus autocomplete="motorcycle_plate" />
                </div>

                <div>
                    <jet-label for="username" value="Username" />
                    <jet-input id="username" type="text" class="mt-1 block w-full" v-model="form.username" required autofocus autocomplete="username" />
                </div>

                <button id="addAnotherBike" class="btn btn-success btn-sm">Add Another Bike</button>
                <h3>Trailer</h3>
                <div>
                    <div class="form-group">
                        <jet-label for="trailer_yes" value="Yes" />
                        <jet-input id="trailer_yes" type="radio" class="mt-1 block" value="Yes" v-model="form.has_trailer" required autofocus autocomplete="trailer_yes" />
                        <jet-label for="trailer_no" value="No" />
                        <jet-input id="trailer_no" type="radio" class="mt-1 block" value="No" v-model="form.has_trailer" required autofocus autocomplete="trailer_no" />
                    </div>
                </div>

                <div>
                    <div class="form-group">
                        <jet-label for="sidecar_yes" value="Yes" />
                        <jet-input id="sidecar_yes" type="radio" class="mt-1 block" value="Yes" v-model="form.motorcycle[i].has_sidecar" required autofocus autocomplete="sidecar_yes" />
                        <jet-label for="sidecar_no" value="No" />
                        <jet-input id="sidecar_no" type="radio" class="mt-1 block" value="No" v-model="form.motorcycle[i].has_sidecar" required autofocus autocomplete="sidecar_no" />
                    </div>
                </div>
            </div>

            <div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">
                <jet-label for="terms">
                    <div class="flex items-center">
                        <jet-checkbox name="terms" id="terms" v-model:checked="form.terms" />

                        <div class="ml-2">
                            I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
                        </div>
                    </div>
                </jet-label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link :href="route('login')" class="underline text-sm text-gray-600 hover:text-gray-900">
                    Already registered?
                </Link>

                <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Register
                </jet-button>
            </div>
        </form>
    </jet-authentication-card>
</template>

<script>
    import { defineComponent } from 'vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            Link,
        },

        data() {
            return {
                form: this.$inertia.form({
                    name: '',
                    username: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    terms: false,
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('register'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            }
        }
    })
</script>
