<template>
    <FrontEndLayout title="Refund Policy">
        <template #head>
            <meta name="description" content="Refund Policy">
            <meta name="keywords" content="Refund policy">
        </template>
        <template #header>
            <h1>REFUND POLICY</h1>
        </template>
        <template #fepage-content>
            <div class="container">
                <h2 class="font-bold text-navy-500 mb-2">Refund Policy</h2>
            <p>In the event that an individual requires a refund prior to the expiration date of the MTS membership, MTS must be notified of the reason <strong>in writing, by email or by phone during normal business hours</strong>. The refund will be pro-rated based on the unused portion of the membership, minus a $10.00 handling charge.
            </p>
            </div>
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
        },
    })
</script>
