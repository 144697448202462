<template>
    <app-layout title="Profile">
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Profile
            </h2>
        </template>

        <div class="profile-cards">
            <div class="profile-card" v-if="$page.props.jetstream.canUpdateProfileInformation">
                <update-profile-information-form :user="$page.props.user" />
            </div>

            <div class="profile-card">
+                <update-member-information-form :user="$page.props.user" />
+            </div>

            <div class="profile-card" v-if="$page.props.jetstream.canUpdatePassword">
                <update-password-form />
            </div>

            <div class="profile-card" v-if="$page.props.jetstream.canManageTwoFactorAuthentication">
                <two-factor-authentication-form />
            </div>
            <div class="profile-card">
                <logout-other-browser-sessions-form :sessions="sessions" />
            </div>

            <div class="profile-card">
                 <template class="profile-card" v-if="$page.props.jetstream.hasAccountDeletionFeatures">

                <delete-user-form />
                </template>
            </div>
        </div>
    </app-layout>
</template>
<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .profile-cards {
        column-gap: 0;

        @include bp-up($bp-xl + 1) { column-count: 2; }
        @include bp-down($bp-xl) { column-count: 1; }

        .profile-card {
            break-inside: avoid;
            padding: 1rem;
        }
    }

</style>
<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import DeleteUserForm from '@/Pages/Profile/Partials/DeleteUserForm.vue'
    import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
    import LogoutOtherBrowserSessionsForm from '@/Pages/Profile/Partials/LogoutOtherBrowserSessionsForm.vue'
    import TwoFactorAuthenticationForm from '@/Pages/Profile/Partials/TwoFactorAuthenticationForm.vue'
    import UpdatePasswordForm from '@/Pages/Profile/Partials/UpdatePasswordForm.vue'
    import UpdateProfileInformationForm from '@/Pages/Profile/Partials/UpdateProfileInformationForm.vue'
    import UpdateMemberInformationForm from '@/Pages/Profile/Partials/UpdateMemberInformationForm.vue'

    export default defineComponent({
        props: ['sessions'],

        components: {
            AppLayout,
            DeleteUserForm,
            JetSectionBorder,
            LogoutOtherBrowserSessionsForm,
            TwoFactorAuthenticationForm,
            UpdatePasswordForm,
            UpdateProfileInformationForm,
            UpdateMemberInformationForm,
        },
    })
</script>
