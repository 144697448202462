<template>
    <TableDropdownWrapper
        v-bind="wrapperBinder"
    >
        <form @submit.prevent="updateFamilyMember()">
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="name" value="Name" />
                <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" />
                <jet-input-error :message="form.errors.name" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="phone" value="Phone" />
                <jet-input id="phone" type="text" class="mt-1 block w-full" v-model="form.phone" />
                <jet-input-error :message="form.errors.phone" class="mt-2" />
            </div>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                Family Member Updated.
            </jet-action-message>
           <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
            </jet-button>
        </form>
    </TableDropdownWrapper>
</template>

<script>
    import TableDropdownWrapper from '@/Components/VueTable/TableDropdownWrapper.vue';
    import tableDropdownMixin from '@/Components/VueTable/mixins/tableDropdownMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import axios from 'axios';

    export default {
        mixins: [tableDropdownMixin],
        components: {
            TableDropdownWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: this.row.id,
                    name: this.row.name,
                    phone: this.row.phone,
                }),
            }
        },
        props: ['states'],
        methods: {
            updateFamilyMember() {
                let vm = this;
                axios({
                    method: 'put',
                    url: '/dashboard/motorists/memberships/edit_member/update_family_member',
                    data: vm.form,
                })
                .then(function (response){
                    vm.$emit('family-member-updated');
                });
            },
        }
    }
</script>
