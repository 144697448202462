<template>
    <FrontEndLayout title="Web Content Accessibility">
        <template #head>
            <meta name="description" content="Web Content Accessibility">
            <meta name="keywords" content="wcag">
        </template>
        <template #header>
            <h1>WEB CONTENT ACCESSIBILITY</h1>
        </template>
        <template #fepage-content>
            <div class="container">
            <p> WCAG: MTS-Motorcycle Towing Services strives for the content of our website to be accessible for all individuals, regardless of ability.</p><br>
            <p>In order to assure the MTS-Motorcycle Towing Services website is an accessible experience for all, we follow the Web Content Accessibility Guidelines (WCAG) 2.0, which provide website standards that are both user-friendly and accessible for individuals with disabilities.</p><br>
            <p>WCAG 2.0 guidelines include three tiers of accessibility: A, AA and AAA. The MTS-Motorcycle Towing Services website follows AA standards.
            At MTS-Motorcycle Towing Services, we strongly believe in making our website accessible for all users, and feel we’ve achieved this through AA standards set by WCAG 2.0. On a continual basis, we are monitoring the changes on our website to maintain these standards. However, if you experience any problems with our website, please do not hesitate to reach out to our web provider, VGM Forbin.</p><br>
            <p>Email our web provider, VGM Forbin, at accessibility@forbin.com.<br>
            Call our web provider, VGM Forbin, at 864-881-2823.</p>
            </div>
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
        },
    })
</script>
