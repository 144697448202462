<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('event')" @click="sortBy('event')">Event</p>
            <p :class="sortClass('ip')" @click="sortBy('ip')">IP</p>
            <p :class="sortClass('details')" @click="sortBy('details')" >Details</p>
        </div>
        <div class="simple-table" style="--cols:3">
            <p class="st-header first-col" :class="sortClass('event')" @click="sortBy('event')" >Event</p>
            <p class="st-header" :class="sortClass('ip')" @click="sortBy('ip')">IP</p>
            <p class="st-header" :class="sortClass('details')" @click="sortBy('details')" >Details</p>

            <template v-for="row in displayedList" :key="'row' + row.id">
                <p ><span class="first-col st-hidden">Event</span>{{ row.event }}</p>
                <p ><span class="st-hidden">IP</span>{{ row.ip }}</p>
                <p ><span class="st-hidden last-col">Details</span>{{ row.details }}</p>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            TableWrapper,
            Status,
            JetSecondaryButton,
            Link
        },

        data() {
            return {
            }
        },
        props: ['data'],
        methods: {

        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>