<template>
    <FrontEndLayout title="About Us">
        <template #head>
            <meta name="description" content="About MTS Towing">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>About Us</h1>
        </template>
        <template #fepage-content>
            <div class="container">
            <h2 class="font-bold text-navy-500 text-center pb-4">Serving Our Customers Since 1988 </h2>
            <p>Motorcycle Towing Services started in 1988 in Las Vegas, Nevada. Since then, we have been proud to provide our members with roadside assistance for more than three decades.</p><br>
            <p> MTS was founded by Gene Wagner, a Vietnam War veteran. In 1987, Gene was riding his Goldwing in New Mexico when he broke down, and there was no suitable tow to be found anywhere. It was then Gene recognized the desperate need for quality roadside assistance for himself and other motorcycle riders. He built the company from the ground up by attending bike rallies and connecting with other motorcyclists. In 2006, the company was passed on to Gene’s brother Jeff, who is also a Vietnam War veteran.</p><br>

            <p>Over more than 30 years in business, MTS has grown and expanded. Our membership goes beyond the thousands of individual riders we serve. It now includes motorcycle manufacturers, dealerships, and insurance companies.</p><br>
            <p>In 2016, the company was purchased by Jeff Riley, a long-time MTS employee and son-in-law to the original MTS owners’ family. Since then, Jeff has elevated to company to fit his vision of providing customized plans that include rental companies and motorcycle owners’ groups.</p><br>
            <p>MTS continues to operate as a family-owned business. This allows us to provide personalized customer service that’s only found in a small business setting. MTS offers the latest technology and equipment for all motorcycle roadside assistance needs. MTS is not just a provider, we are a “Motorcycle Provider.”
            </p>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
        },
    })
</script>