<template>
    <FrontEndLayout title="Vendor Application">
        <template #head>
            <meta name="description" content="MTS Towing Vendor Application">
            <meta name="keywords" content="vendor application">
        </template>
        <template #header>
            <h1>VENDOR APPLICATION</h1>
            <div>
                <Teleport to="body"><FlashMessage /></Teleport>
            </div>
        </template>
        <template #fepage-content>
            <div class="container">
                <div class="text-center">
                    <h2 class="font-bold text-navy-500">Complete Our Application</h2>
                    <p>Complete the form below to submit an application to join the MTS towing network</p>
                </div>
            </div>
            <form @submit.prevent="submit" id="vendorApplicationForm">
                 <div class="gray-section">
                    <div class="container">
                <h3 class="text-navy-500">Company Information</h3>
                <div>
                    <jet-label class="mt-4" for="company_name" value="Company Name" />
                    <jet-input id="company_name" type="text" class="mt-1 block w-full" v-model="form.company_name" name="company_name" required  />
                    <jet-input-error :message="form.errors.company_name" class="mt-2" />
                </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label class="mt-4" for="first_name" value="First Name" />
                    <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" name="first_name" required  />
                    <jet-input-error :message="form.errors.first_name" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="last_name" value="Last Name" />
                    <jet-input id="last_name" type="text" class="mt-1 block w-full" name="last_name" v-model="form.last_name" required  />
                    <jet-input-error :message="form.errors.last_name" class="mt-2" />
                </div>
            </div>

                <div>
                    <jet-label class="mt-4" for="address" value="Address" />
                    <jet-input id="address" type="text" class="mt-1 block w-full" name="address" v-model="form.address" required  />
                    <jet-input-error :message="form.errors.address" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="address2" value="Address 2" />
                    <jet-input id="address2" type="text" class="mt-1 block w-full" name="address2" v-model="form.address2"  />
                    <jet-input-error :message="form.errors.address2" class="mt-2" />
                </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label class="mt-4" for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" name="city" v-model="form.city" required  />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" name="state" v-model="form.state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>

                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="zip" value="Zip/Postal Code" />
                    <jet-input id="zip" type="text" class="mt-1 block w-full" name="zip" v-model="form.zip" required  />
                    <jet-input-error :message="form.errors.zip" class="mt-2" />
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label class="mt-4" for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" name="country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
                <div>
                    <vue-tel-input id="office_phone" label="Phone" v-model="form.office_phone" :error-message="form.errors.office_phone" />
                </div>
                <div>
                    <vue-tel-input id="fax" label="Fax" v-model="form.fax" :error-message="form.errors.fax" />
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <vue-tel-input id="cell" label="Cell" v-model="form.cell" :error-message="form.errors.cell" />
                </div>

                <div>
                    <jet-label class="mt-4" for="email" value="Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" name="email" v-model="form.email" required  />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="website" value="Website" />
                    <jet-input id="website" type="text" class="mt-1 block w-full" name="website" v-model="form.website"  />
                    <jet-input-error :message="form.errors.website" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label class="mt-4" for="social_url1" value="Social Medial Link" />
                    <jet-input id="social_url1" type="text" class="mt-1 block w-full" name="social_url1" v-model="form.social_url1"  />
                    <jet-input-error :message="form.errors.social_url1" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="social_url2" value="Social Media Link" />
                    <jet-input id="social_url2" type="text" class="mt-1 block w-full" name="social_url2" v-model="form.social_url2"  />
                    <jet-input-error :message="form.errors.social_url2" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="social_url3" value="Social Media Link" />
                    <jet-input id="social_url3" type="text" class="mt-1 block w-full" name="social_url3" v-model="form.social_url3"  />
                    <jet-input-error :message="form.errors.social_url3" class="mt-2" />
                </div>
            </div>
                </div>
                </div>
                <div class="container">
                <h3 class="text-navy-500">Mailing Address</h3>
                <div class="grid md:grid-cols-12 grid-cols-2 gap-1">
                    <jet-label class="col-span-1 md:col-span-3 xl:col-span-2 h4 text-lg" for="same_as_company_address" value="Same as Company Address" />
                    <jet-input id="same_as_company_address" type="checkbox" class="mt-1 xl:col-span-10 md:col-span-9 col-span-1"  v-model="form.same_as_company_address" name="same_as_company_address" @change="copyToMailingAddress()"  />
                    <jet-input-error :message="form.errors.same_as_company_address" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="mailing_address" value="Address" />
                    <jet-input id="mailing_address" type="text" class="mt-1 block w-full" v-model="form.mailing_address" name="mailing_address" required  />
                    <jet-input-error :message="form.errors.mailing_address" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="mailing_address2" value="Address 2" />
                    <jet-input id="mailing_address2" type="text" class="mt-1 block w-full" name="mailing_address2" v-model="form.mailing_address2"  />
                    <jet-input-error :message="form.errors.mailing_address2" class="mt-2" />
                </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label class="mt-4" for="mailing_city" value="City" />
                    <jet-input id="mailing_city" type="text" class="mt-1 block w-full" name="mailing_city" v-model="form.mailing_city" required  />
                    <jet-input-error :message="form.errors.mailing_city" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="mailing_state" value="State/Province" />
                    <select id="mailing_state" class="mt-1 block w-full" name="mailing_state" v-model="form.mailing_state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>

                    </select>
                    <jet-input-error :message="form.errors.mailing_state" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="mailing_zip" value="Zip/Postal Code" />
                    <jet-input id="mailing_zip" type="text" class="mt-1 block w-full" name="mailing_zip" v-model="form.mailing_zip" required  />
                    <jet-input-error :message="form.errors.mailing_zip" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label class="mt-4" for="mailing_country" value="Country" />
                <select id="mailing_country" class="mt-1 block w-full" v-model="form.mailing_country" name="mailing_country" required >
                    <option value=""> -- </option>
                    <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                </select>
                <jet-input-error :message="form.errors.mailing_country" class="mt-2" />
            </div>
        </div>
        <div class="gray-section">
            <div class="container">
                <h3 class="text-navy-500">Insurance Information</h3>
                <div>
                    <jet-label class="mt-4" for="insurance_company" value="Insurance Company" />
                    <jet-input id="insurance_company" type="text" class="mt-1 block w-full" name="insurance_company" v-model="form.insurance_company"  />
                    <jet-input-error :message="form.errors.insurance_company" class="mt-2" />
                </div>

                <div>
                    <jet-label class="mt-4" for="insurance_agent" value="Agent/Contact Name" />
                    <jet-input id="insurance_agent" type="text" class="mt-1 block w-full" name="insurance_agent" v-model="form.insurance_agent"  />
                    <jet-input-error :message="form.errors.insurance_agent" class="mt-2" />
                </div>

                <div>
                    <vue-tel-input id="insurance_agent_number" label="Agent/Contact Number" v-model="form.insurance_agent_number" :error-message="form.errors.insurance_agent_number" />
                </div>

                <div>
                    <jet-label class="mt-4" for="insurance_expire_date" value="Insurance Expires" />
                    <jet-input id="insurance_expire_date" type="date" class="mt-1 block w-full" name="insurance_expire_date" v-model="form.insurance_expire_date"  />
                    <jet-input-error :message="form.errors.insurance_expire_date" class="mt-2" />
                </div>
            </div>
        </div>

            <div class="container">
                <h3 class="text-navy-500 mb-3">Service Charges</h3>
                <table class="w-100">
                    <tr class="mt-2">
                        <th class="text-blue-500 h4">Type/Service</th>
                        <th>Base Rate</th>
                        <th>Charge / En Route Miles</th>
                        <th>Charge/Loaded Mile</th>
                    </tr>
                    <tr class="mt-2">
                        <th>Hook-up/Dispatch</th>
                        <td class="p-2"><jet-input id="service_coverage_hookup_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_base_rate" v-model="form.service_coverage_hookup_base_rate" /></td>
                        <td class="p-2"><jet-input id="service_coverage_hookup_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_mileage_allowance" v-model="form.service_coverage_hookup_mileage_allowance" /></td>
                        <td class="p-2"><jet-input id="service_coverage_hookup_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_hookup_charge_mile" v-model="form.service_coverage_hookup_charge_mile" /></td>
                    </tr>
                    <tr class="mt-2">
                        <th>Jump Start</th>
                        <td class="p-2"><jet-input id="service_coverage_jump_start_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_base_rate" v-model="form.service_coverage_jump_start_base_rate" /></td>
                        <td class="p-2"><jet-input id="service_coverage_jump_start_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_mileage_allowance" v-model="form.service_coverage_jump_start_mileage_allowance" /></td>
                        <td class="p-2"><jet-input id="service_coverage_jump_start_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_jump_start_charge_mile" v-model="form.service_coverage_jump_start_charge_mile" /></td>
                    </tr>
                    <tr class="mt-2">
                        <th>Fuel Delivery</th>
                        <td class="p-2"><jet-input id="service_coverage_fuel_delivery_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_base_rate" v-model="form.service_coverage_fuel_delivery_base_rate" /></td>
                        <td class="p-2"><jet-input id="service_coverage_fuel_delivery_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_mileage_allowance" v-model="form.service_coverage_fuel_delivery_mileage_allowance" /></td>
                        <td class="p-2"><jet-input id="service_coverage_fuel_delivery_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_fuel_delivery_charge_mile" v-model="form.service_coverage_fuel_delivery_charge_mile" /></td>
                    </tr>
                    <tr class="mt-2">
                        <th>GOA</th>
                        <td class="p-2"><jet-input id="service_coverage_goa_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_base_rate" v-model="form.service_coverage_goa_base_rate" /></td>
                        <td class="p-2"><jet-input id="service_coverage_goa_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_mileage_allowance" v-model="form.service_coverage_goa_mileage_allowance" /></td>
                        <td class="p-2"><jet-input id="service_coverage_goa_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_goa_charge_mile" v-model="form.service_coverage_goa_charge_mile" /></td>
                    </tr>
                    <tr class="mt-2">
                        <th>Storage/Day</th>
                        <td class="p-2"><jet-input id="service_coverage_storage_base_rate" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_base_rate" v-model="form.service_coverage_storage_base_rate" /></td>
                        <td class="p-2"><jet-input id="service_coverage_storage_mileage_allowance" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_mileage_allowance" v-model="form.service_coverage_storage_mileage_allowance" /></td>
                        <td class="p-2"><jet-input id="service_coverage_storage_charge_mile" type="number" step=".01" class="mt-1 block w-full" name="service_coverage_storage_charge_mile" v-model="form.service_coverage_storage_charge_mile" /></td>
                    </tr>
                </table>
            </div>
        <div class="gray-section">
            <div class="container">
                <h3 class="text-navy-500">Additional Charges/Exceptions</h3>
                <div>
                    <jet-label class="mt-4" for="additional_charges" value="Additional Charges/Exceptions" />
                    <textarea id="additional_charges" class="mt-1 block w-full" name="additional_charges" v-model="form.additional_charges"></textarea>
                    <jet-input-error :message="form.errors.additional_charges" class="mt-2" />
                </div>
            </div>
        </div>
            <div class="container">
                <h3 class="text-navy-500">Company Recommendations</h3>
                <div>
                    <jet-label class="mt-4" for="company_recommendation" value="Is there another company you recommend in the event you are not available?" />
                    <textarea id="company_recommendation" class="mt-1 block w-full" name="company_recommendation" v-model="form.company_recommendation"></textarea>
                    <jet-input-error :message="form.errors.company_recommendation" class="mt-2" />
                </div>
                    </div>
        <div class="gray-section">
            <div class="container">
                <h3 class="text-navy-500">Equipment Information</h3>
                <div class="grid grid-cols-2 md:grid-cols-5">
                    <div v-for="data in towing_equipment" v-bind:key="data.id" class="grid grid-cols-4 items-end">
                        <jet-label class="mt-4 text-right mr-2 col-span-3" :for="`equipment${data.id}`" :value="data.name" />
                        <jet-input :id="`equipment${data.id}`" :name="`Equipment-${data.id}`" type="checkbox" class="mt-1 col-span-1" v-model="towEquipment" :value="`${data.id}`"  />
                    </div>
                </div>
             </div>
        </div>
            <div class="container">
                <h3 class="text-navy-500">Terms</h3>
                <div class="grid grid-cols-12">
                    <jet-input id="terms" type="checkbox" class="mt-1 col-span-1 checkbox" name="terms" v-model="form.terms" value="true" required />
                    <jet-input-error :message="form.errors.terms" class="mt-2" />
                    <p class="col-span-11">
                    By submitting this information, you authorize the company named above to commit to towing for Motorcycle Towing Services, Inc., and certify that the company will respond to authorized towing requests from MTS Dispatchers with the proper equipment, and the fastest time possible.</p>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Submit Application
                    </jet-button>
                </div>
            </div>
            </form>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;
        z-index: 2;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: -1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: -1;

        }
    }

</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import FlashMessage from '@/Pages/DashboardPartials/FlashMessages'
    import FooterGap from '@/Components/FooterGap.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            Head,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetInputError,
            FrontEndLayout,
            Link,
            FlashMessage,
            FooterGap,
            VueTelInput,
        },
        data(){
            return{
                form: this.$inertia.form({
                    company_name:'',
                    first_name: '',
                    last_name: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    office_phone: '',
                    fax: '',
                    cell: '',
                    email: '',
                    website: '',
                    social_url1: '',
                    social_url2: '',
                    social_url3: '',
                    same_as_company_address: false,
                    mailing_address: '',
                    mailing_address2: '',
                    mailing_city: '',
                    mailing_state: '',
                    mailing_zip: '',
                    mailing_country: '',
                    insurance_company: '',
                    insurance_agent: '',
                    insurance_agent_number: '',
                    insurance_expire_date: null,
                    service_coverage_hookup_base_rate: '',
                    service_coverage_hookup_mileage_allowance: '',
                    service_coverage_hookup_charge_mile: '',
                    service_coverage_jump_start_base_rate: '',
                    service_coverage_jump_start_mileage_allowance: '',
                    service_coverage_jump_start_charge_mile: '',
                    service_coverage_fuel_delivery_base_rate: '',
                    service_coverage_fuel_delivery_mileage_allowance: '',
                    service_coverage_fuel_delivery_charge_mile: '',
                    service_coverage_goa_base_rate: '',
                    service_coverage_goa_mileage_allowance: '',
                    service_coverage_goa_charge_mile: '',
                    service_coverage_storage_base_rate: '',
                    service_coverage_storage_mileage_allowance: '',
                    service_coverage_storage_charge_mile: '',
                    additional_charges: '',
                    company_recommendation: '',
                    equipment: [],
                    terms: false,
                }),
                states: [],
                countries: ['USA', 'Canada'],
                towing_equipment: [],
            }
        },
        computed: {
            towEquipment: {
                get() {
                    return this.form.equipment
                },
                set(value) {
                    let combined = [...new Set([...this.form.equipment, value])];
                    if (this.form.equipment.includes(value)) combined.splice(combined.indexOf(value), 1);
                    this.form.equipment = combined;
                }
            }
        },
        methods:{
            submit() {
                this.form.post(route('.towing-companies.vendor_app_store'), {
                    errorBag: 'vendorApplicationForm',
                    preserveScroll: true,
                    onSuccess: () => this.resetForm(),
                });
            },
            copyToMailingAddress: function() {
                if (this.form.same_as_company_address){
                    this.form.mailing_address = this.form.address;
                    this.form.mailing_address2 = this.form.address2;
                    this.form.mailing_city = this.form.city;
                    this.form.mailing_state = this.form.state;
                    this.form.mailing_zip = this.form.zip;
                    this.form.mailing_country = this.form.country;
                } else {
                    this.form.mailing_address = "";
                    this.form.mailing_address2 = "";
                    this.form.mailing_city = "";
                    this.form.mailing_state = "";
                    this.form.mailing_zip = "";
                    this.form.mailing_country = "";
                }
            },

            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },

            getEquipment: function() {
                axios.get(route('.towing-companies.fetch_equipment'),{

                }).then(function(response){
                    this.towing_equipment = response.data;
                }.bind(this));
            },
            resetForm() {
                this.form.reset();

                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
            setRequired() {
                const membershipForm = document.getElementById("vendorApplicationForm");
                const inputs         = membershipForm.querySelectorAll("[required]");
                inputs.forEach(input => {
                    let id = input.id;
                    let label = input.closest('label');
                    if (id) label = membershipForm.querySelector(`label[for="${input.id}"]`)
                    let span = document.createElement('span');
                    span.classList.add('text-red-700');
                    span.innerHTML = " *";
                    if (label) label.appendChild(span)
                })
            }
        },
        mounted() {
            this.setRequired()
        },
        created: function(){
            this.getStates();
            this.getEquipment();
        },
    })
</script>
