<template>
    <CustomTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
        ref="customTable"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('name')" @click="sortBy('name')">Company Name</p>
            <p :class="sortClass('notes')" @click="sortBy('alert_notes')" >Alert Notes</p>
            <p :class="sortClass('phone')" @click="sortBy('phone', 'number')">Phone</p>
            <p :class="sortClass('city')" @click="sortBy('city')">City</p>
            <p :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p :class="sortClass('distance')" @click="sortBy('distance')">Distance</p>
        </div>
        <div class="simple-table" style="--cols:8">
            <p class="st-header first-col" :class="sortClass('name')" @click="sortBy('name')">Company Name</p>
            <p class="st-header" :class="sortClass('alert_notes')" @click="sortBy('alert_notes')" >Alert Notes / Equipment</p>
            <p class="st-header" :class="sortClass('phone')" @click="sortBy('phone', 'number')">Phone</p>
            <p class="st-header" :class="sortClass('city')" @click="sortBy('city')">City</p>
            <p class="st-header" :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p class="st-header" :class="sortClass('distance')" @click="sortBy('distance')">Distance</p>
            <p class="st-header">Drive Distance</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Company Name</span>{{ row.name }}</p>
                <p><span class="st-hidden">Alert Notes</span><p v-if="row.alert_notes" class="text-red-900">{{ row.alert_notes }} </p><p v-if="row.equipment">{{ row.equipment }}</p></p>
                <p><span class="st-hidden">Phone</span>{{ row.phone }}</p>
                <p><span class="st-hidden">City</span>{{ row.city }} </p>
                <p><span class="st-hidden">State</span>{{ row.state }}</p>
                <p><span class="st-hidden">Distance</span>{{ parseFloat(row.distance).toFixed(2) }} miles</p>
                <p><span class="st-hidden">Drive Distance</span>
                    <div class="st-buttons" v-if="!row.drive">
                        <div class="st-buttons">
                            <jet-secondary-button @click="getDistance(row, index)">
                                Get Miles
                            </jet-secondary-button>
                        </div>
                    </div>
                    <div v-if="row.drive">
                        {{ row.drive }}
                    </div>
                </p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/towing-companies/add_edit_towing_company/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-secondary-button>View</jet-secondary-button>
                        </Link>
                    </div>
                </div>
            </template>
        </div>
    </CustomTableWrapper>
</template>

<script>
    import CustomTableWrapper from '@/Components/VueTable/CustomTableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            CustomTableWrapper,
            Status,
            JetSecondaryButton,
            Link
        },

        props: ['data'],
        methods: {
            viewTowingCompany(id) {

            },
            getDistance(row, index) {
                this.$emit('getDistance', index);
            }
        },
        watch: {
            activePage(n) {
                if (n) {
                    sessionStorage.setItem('mapActivePage', n);
                }
            }
        },
        mounted() {
            if (Number(sessionStorage.getItem('mapActivePage'))) {
                this.activePage = Number(sessionStorage.getItem('mapActivePage'));
            }
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>