<template>
    <app-layout title="Dashboard - Dealership Types">
        <template #header>
        </template>
        <Card
            title="Dealerships"
        >
                <JetButton
                    class="button button-primary top-buttons"
                    v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff'"
                >
                    <Link
                        href="/dashboard/dealerships/add_dealer"
                        as="button"
                        type="button"
                        >
                        ADD DEALERSHIP
                    </Link>
                </JetButton>
                <div v-if="dealers.length == 0">
                    <VueLoader />
                </div>
                <div v-else>
                    <DealershipsTable
                        :data="dealers"
                        @row-deleted="rowDeleted($event)"
                        ref="dealerTable"
                        :searchable-data="['company_name', 'location', 'added_date', 'last_login', 'status']"
                    />
                </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import DealershipsTable from './Tables/DealershipsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            DealershipsTable,
            Link,
            Card,
            JetButton,
            VueLoader,
        },
        data() {
            return {
                dealers:[],
            }
        },
        methods: {
            fetchDealers() {
                axios
                    .get(route('dashboard.dealerships.fetch_dealers'))
                    .then(response => {
                        this.dealers = response.data.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/dealerships/delete_dealer/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchDealers();
                        this.$refs.dealerTable.closeModal();
                    }
                })
            }
        },
        mounted() {
            this.fetchDealers();
        }
    })
</script>
