<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!vendor_invoices.isLoading">
         <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center">
                <label for="date" class="m-1">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
            </div>
            <div>
            <jet-button type="submit" class="button button-primary ml-2"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton></div>
        </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="vendor_invoices.sortClass('company_name')" @click="vendor_invoices.sortBy('company_name')">Company Name</p>
            <p :class="vendor_invoices.sortClass('company_phone')" @click="vendor_invoices.sortBy('company_phone',null, 'number')">Company Phone</p>
            <p :class="vendor_invoices.sortClass('tow_date')" @click="vendor_invoices.sortBy('tow_date',null, 'date')">Tow Date</p>
            <p :class="vendor_invoices.sortClass('membership_number')" @click="vendor_invoices.sortBy('membership_number')">Member #</p>
            <p :class="vendor_invoices.sortClass('member_name')" @click="vendor_invoices.sortBy('member_name')">Member Name</p>
            <p :class="vendor_invoices.sortClass('total_cost')" @click="vendor_invoices.sortBy('total_cost',null, 'number')" >Total</p>
        </div>
        <div class="simple-table" style="--cols:7">
            <p class="st-header first-col" :class="vendor_invoices.sortClass('company_name')" @click="vendor_invoices.sortBy('company_name')">Company Name</p>
            <p class="st-header" :class="vendor_invoices.sortClass('company_phone')" @click="vendor_invoices.sortBy('company_phone',null, 'number')" >Company Phone</p>
            <p class="st-header" :class="vendor_invoices.sortClass('tow_date')" @click="vendor_invoices.sortBy('tow_date',null, 'date')" >Tow Date</p>
            <p class="st-header" :class="vendor_invoices.sortClass('membership_number')" @click="vendor_invoices.sortBy('membership_number')" >Member #</p>
            <p class="st-header" :class="vendor_invoices.sortClass('member_name')" @click="vendor_invoices.sortBy('member_name')">Member Name</p>
            <p class="st-header" :class="vendor_invoices.sortClass('total_cost')" @click="vendor_invoices.sortBy('total_cost',null, 'number')" >Total</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in vendor_invoices.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Company Name</span>{{ row.company_name }}</p>
                <p ><span class="st-hidden">Company Phone</span>{{ row.company_phone }}</p>
                <p ><span class="st-hidden">Tow Date</span>{{ row.tow_date }}</p>
                <p ><span class="st-hidden">Member #</span>{{ row.membership_number }}</p>
                <p ><span class="st-hidden">Member Name</span>{{ row.member_name }}</p>
                <p ><span class="st-hidden">Total</span>${{ row.total_cost }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link class="flex"
                            :href="`/dashboard/towing-companies/vendor-invoices/view-vendor-invoice/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                VIEW
                            </jet-button>
                        </Link>


                        <jet-secondary-button class="button button-primary" @click="printPDF(row.id)">Print PDF</jet-secondary-button>
                        <jet-danger-button @click="confirmInvoiceDeletion(index, row.id)">
                            DELETE
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ vendor_invoices.rangeText }}</p>
            <Pagination
                v-if="vendor_invoices.itemCount || vendor_invoices.itemCount <= vendor_invoices.itemsPerPage"
                :active-page="vendor_invoices.activePage"
                :item-count="vendor_invoices.itemCount"
                :items-per-page="vendor_invoices.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete Invoice Confirmation Modal -->
    <jet-dialog-modal :show="confirmingInvoiceDeletion" @close="closeModal">
        <template #title>
            Delete Invoice
        </template>
        <template #content>
            Are you sure you want to delete this Invoice? (You will still be able to restore this invoice if needed.)
        </template>
        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>
            <jet-danger-button class="ml-2" @click="deleteInvoice(this.invoiceId)" >
                Delete Invoice
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import Pagination from '@/Components/Pagination.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import VueLoader from '@/Components/VueLoader'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            FlashMessage,
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link,
            Pagination,
            VueLoader,
            moment,
        },

        data() {
            return {
                vendor_invoices: reactive(new EloquentSSFP()),
                searchModel: '',
                confirmingInvoiceDeletion: false,
                invoiceId: null,
                start_date: null,
                end_date: null,
            }
        },
        emits: ['printPDF'],
        methods: {
            applyFilters() {
                let options = {...this.vendor_invoices.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('tow_date');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                this.vendor_invoices.refreshData(options);
            },
            updateActivePage(page) {
                this.vendor_invoices.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            confirmInvoiceDeletion(index, id) {
                this.confirmingInvoiceDeletion = true;
                this.invoiceId = id;
                this.index = index;
            },
            printPDF(id) {
                this.$emit('printPDF', id)
            },
            viewInvoice: function (index, id) {
               this.$inertia.visit('/dashboard/tow-companies/vendor-invoices/view-invoice/' + id);
            },
            closeModal: function () {
                this.confirmingInvoiceDeletion = false;
            },
            deleteInvoice(id) {
                this.$inertia.post('/dashboard/towing-companies/vendor-invoices/delete_invoice/' + id, id, {
                    onSuccess: (id) => {
                        this.closeModal();
                        this.vendor_invoices.refreshData(this.vendor_invoices.options);
                    }
                })
            },
            resetFilters() {
                this.vendor_invoices.resetAll();
                this.start_date = null;
                this.end_date = null;
            },
        },
        mounted() {
            this.vendor_invoices.init('/dashboard/towing-companies/vendor-invoices/fetch_vendor_invoices_ssfp', {
                searchableData: ['company_name', 'company_phone', 'tow_date', 'membership_number', 'member_name', 'total_cost'],
                itemsPerPage: 50,
                sortDirs: ['desc'],
                activeSorts: ['tow_date'],
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
