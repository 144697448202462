<template>
    <form id="inputForm" @submit.prevent>
            <p class="pb-3 text-sm">
                This file upload tool allows you to load your dealer and manufacturer excel files. You can select multiple files to load at the same time and the system will automatically upload your list to the correct designation. You will be notified once each file has been processed. Please review the notifications for any errors.
            </p>
            <div class="grid grid-cols-2 gap-4 items-end">
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Select Import Files</h6>
                    <input type="file" ref="file" multiple="multiple" accept=".xlsx" />
                </div>
                <div class="flex justify-end">

                    <jet-button  @click="submitFiles()" :class="{ 'opacity-25': processing }" :disabled="processing">
                        {{  buttonText }}
                    </jet-button>
                </div>
            </div>
            <div class="mt-2 bg-gray-150 p-2" v-for="(msg, index) in messages" :key="index">
                <span v-if="msg.success" class="text-green">{{ msg.message }}</span>
                <span v-else-if="msg.message.includes('<br />')" class="text-red-500" v-for="item in msg.message.split('<br />')">
                    {{ item }}<br />
                </span>
                <span v-else class="text-red-500">
                    {{ msg.message }}
                </span>
            </div>
            <div class="grid grid-cols-2 gap-4 items-end">
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Available Templates</h6>
                    <a href="/templates/DealershipTemplate.xlsx">Dealership Template</a><br/>
                    <a href="/templates/DucatiTemplate.xlsx">Ducati Template</a><br/>
                    <a href="/templates/MVAgustaImportTemplate.xlsx">MV Agusta Template</a><br/>
                </div>
            </div>
    </form>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import moment from 'moment'
    import { ref } from 'vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            moment,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'POST',
                    files: ref([]),
                }),
                messages: [],
                uniqid: null,
                processing: false,
                buttonText: "Submit File(s)",
                loopCount: 0,
            }
        },
        
        methods: {
            getUniqId() {
                 axios({
                    method: 'get',
                    url: route('dashboard.back_office.imports.generate_uniqid'),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: null
                })
                .then(response => {
                    this.uniqid = response['data'];
                })
            },
            submitFiles() {
                this.toggleButtonAndClearMessages();
                let $this = this;

                // Lets get a unique id
                axios({
                    method: 'get',
                    url: route('dashboard.back_office.imports.generate_uniqid'),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: null
                })
                .then(response => {
                    // lets send the data to the back end with the unique id
                    this.uniqid = response['data'];

                    let formData = new FormData();
                    for( var i = 0; i < this.$refs.file.files.length; i++ ){
                        let file = this.$refs.file.files[i];
                        formData.append('files[' + i + ']', file);
                    }
                    formData.append('uniqid', this.uniqid)
                    axios({
                        method: 'post',
                        url: route('dashboard.back_office.imports.import_files'),
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData
                    })
                    .then(response => {
                        if (response.data) {
                            this.messages = response.data;
                            this.form.reset();
                            this.toggleButtonAndClearMessages();
                        } else {
                            $this.checkUploadStatus();
                        }
                    })
                    .catch(function (error) {
                        // Lets check on the status...  
                        $this.checkUploadStatus();
                    })
                })

            },
            checkUploadStatus()
            {
                this.loopCount ++;
                let formData = new FormData();
                formData.append('uniqid', this.uniqid);

                axios({
                    method: 'post',
                    url: route('dashboard.back_office.imports.import_status'),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                })
                .then(response => {
                        if (response.data == false)
                        {
                            this.messages = [{'message': 'Checking on batch process.  Attempt: ' + this.loopCount, 'success' : true}];
                            console.log(this.messages);
                            setTimeout(() => this.checkUploadStatus(), 10000);
                        }
                        else
                        {
                            this.messages = response.data;
                            this.form.reset();
                            this.toggleButtonAndClearMessages();
                            this.loopCount = 0;
                        }
                })
                .catch(function (error) {
                })
            },
            toggleButtonAndClearMessages()
            {
                if (!this.processing)
                {
                    this.processing = true;
                    this.buttonText = "Processing...";
                    this.messages = [];
                }
                else
                {
                    this.processing = false;
                    this.buttonText = "Submit File(s)";
                }
            },
        },
        
    }
</script>
