<template>
    <FrontEndLayout title="About Us">
        <template #head>
            <meta name="description" content="About MTS Towing">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>MOTORIST &amp; RENTAL SERVICES</h1>
        </template>
        <template #fepage-content>
            <div class="container text-center mx-5">
            <h2 class="font-bold text-navy-500">The Benefits You Enjoy as an MTS Member</h2>
            <p>Motorcycle Towing Services has you covered no matter where you love to ride. We have a network of thousands of towing companies across the US and Canada. In the event of a breakdown, we will dispatch one of our experienced professional providers to your location. The most important thing to remember is that When You Ride With MTS, You Never Ride Alone. Browse our membership options to see which one is the best choice for you.</p>
            <a href="/motorists-and-rentals/membership-options" title="internal link in same tab to Membership Options" class="btn btn-arrow-tertiary">Browse Membership Options</a>
            </div>
            <div class="gray-section">
                <div class="container md:px-0 grid grid-cols-1 gap-4 md:grid-cols-3 items-center">
                    <div class="col-span-2">
                        <h3 class="text-navy-500"> What We Cover</h3>
                            <p class="text-navy-500 w-3/4">No matter what you ride, it’s covered with our membership. We have membership options that will cover:</p>
                    </div>
                    <div>
                        <ul class="red-check list-outside mx-auto">
                            <li>Motorcycles</li>
                            <li>Trikes</li>
                            <li>Scooters</li>
                            <li>Side cars</li>
                            <li>3-wheeled vehicles</li>
                            <li>Any vehicle classified as a motorcycle</li>
                            </ul>
                    </div>
                </div>
            </div>
            <div class="container">
                <h3 class="pt-8 pl-2 md:pl-0 text-navy-500">Membership Benefits</h3>
                <div class="py-2 px-5">
                <h5 class="text-blue-500">Emergency Roadside Assistance</h5>
                <p>It can happen to anyone. You run out of gas on the road, or your battery dies. It always seems to happen at the worst time. With your MTS membership we’ll make sure you get the emergency roadside assistance you need. We will dispatch a professional provider to bring you gas or jump start your motorcycle and get you back on the road.</p></div>
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="py-2 px-5"><h5 class="text-blue-500">Emergency Towing Service</h5>
                    <p>When you break down, you need someone to call. MTS members know they can call us any time and we’ll make sure they get picked up as soon as possible. We’ll get you a professional provider that will take your bike to the nearest repair facility or back home. Costs are covered based on the membership plan you’ve chosen.</p></div>
                    <div class="py-2 px-5"><h5 class="text-blue-500">Emergency Service Plan</h5>
                    <p>This plan will pay for 100% of the replacement cost of the parts listed below, excluding labor.  This only applies to an MTS registered motorcycle when you are at least 100 miles from your home.  This must be used in conjunction with an MTS membership. We encourage this option for members who are often more than 100 miles away from home.
                    </p>
                    <div class="grid grid-cols-3 text-center">
                    <ul class="red-check list-outside">
                        <li>Tires</li>
                    </ul>
                    <ul class="red-check list-outside">
                        <li>Battery</li>
                    </ul>
                    <ul class="red-check list-outside">
                        <li>Cables</li>
                        </ul>
                    </div>
                    </div>
                </div>

                <h5 class="text-blue-500">PASS IT ON AND EARN FREE MONTHS ON YOUR CURRENT MEMBERSHIP! </h5>
                <p>
                    You will receive 3 free months for each riding buddy that you refer to MTS. Refer 4 riding buddies and you will earn a free year on your membership. Simply have them mention you by name or if signing up at www.mts-towing.com, put your name under &quot;Referred By&quot;.
                </p>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
            </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }

</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
        },
    })
</script>
