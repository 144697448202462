<template>
    <Head title="Register" />
    <FrontEndLayout title="Register">
        <template #header>
            <h1>Register</h1>
        </template>
        <template #fepage-content>
          <div class="container py-6">
               <h2 class="text-navy-500 font-bold text-center uppercase">
                    Please Fill Out Form to Register
                </h2>
                <JetAuthenticationCard class="mx-10" style="margin-bottom: 100px;" color="gray-300">
                    <JetValidationErrors class="mb-4" />

                    <form @submit.prevent="submit">
                        <div>
                            <jet-label for="name" value="Name" />
                            <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" required autofocus autocomplete="name" />
                        </div>

                        <div>
                            <jet-label for="username" value="Username" />
                            <jet-input id="username" type="text" class="mt-1 block w-full" v-model="form.username" required autofocus autocomplete="username" />
                        </div>

                        <div class="mt-4">
                            <jet-label for="email" value="Email" />
                            <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" required />
                        </div>

                        <div class="mt-4">
                            <jet-label for="password" value="Password" />
                            <jet-input id="password" type="password" class="mt-1 block w-full" v-model="form.password" required autocomplete="new-password" />
                        </div>

                        <div class="mt-4">
                            <jet-label for="password_confirmation" value="Confirm Password" />
                            <jet-input id="password_confirmation" type="password" class="mt-1 block w-full" v-model="form.password_confirmation" required autocomplete="new-password" />
                        </div>

                        <div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">
                            <jet-label for="terms">
                                <div class="flex items-center">
                                    <jet-checkbox name="terms" id="terms" v-model:checked="form.terms" />

                                    <div class="ml-2">
                                        I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
                                    </div>
                                </div>
                            </jet-label>
                        </div>

                        <div class="flex items-center justify-end mt-4 flex-col md:flex-row">                
                            <JetButton class="button button-primary my-2">
                                <Link :href="route('login')">
                                    Already registered?
                                </Link>
                            </JetButton>

                            <JetButton class="bg-red-500 ml-4 my-2">
                                <Link :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Register
                                </Link>
                            </JetButton>
                        </div>
                    </form>
                </JetAuthenticationCard>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
@import '../../../scss/_mixins.scss';

main {
  position: relative;
  top: calc(var(--header-size) + 15px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SecondaryButton  from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            SecondaryButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            Link,
            FrontEndLayout,
            FooterGap,
        },

        data() {
            return {
                form: this.$inertia.form({
                    name: '',
                    username: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    terms: false,
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('register'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            }
        }
    })
</script>
