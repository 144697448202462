<template>
    <div class="flex-end top-buttons mb-1">
        <jet-secondary-button class="m-1 w-100 md:w-auto" @click="printSelectedToPDF(true)">Print All Items</jet-secondary-button>
        <jet-secondary-button class="m-1 w-100 md:w-auto" @click="selectStartingPosition(true)">Print All Labels</jet-secondary-button>
        <JetButton class="m-1 w-100 md:w-auto" @click="printSelectedToPDF(false)">Print Selected Items</JetButton>
        <JetButton class="m-1 w-100 md:w-auto" @click="selectStartingPosition(false)">Print Labels for Selected Items</JetButton>
    </div>
        <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields md:align-center search flex justify-between flex-wrap flex-col md:flex-row md:items-end">
                <input
                    class="st-search"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <div class="flex flex-col md:flex-row md:items-center justify-between">
                    <label for="date" class="m-1">Date Range</label>
                    <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                    <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                    <div class="m-1 w-100 md:w-auto">
                    <label for="member_type" class="m-1">Member Type </label>
                    <select v-model="member_type" class="border py-2 pl-3 pr-8 text-grey-800 m-1 w-100 md:w-auto">
                        <option value=""> - </option>
                        <option
                            v-for="option in member_type_list"
                            :key="option.value"
                            :value="option.name"
                        >{{option.name}}</option>
                    </select>
                    </div>
                </div>
                <div>
            <jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetButton></div>
            </div>
        </form>
        <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!memberships.isLoading">
            <div class="st-hidden-sorts">
                <p :class="memberships.sortClass('member_number')" @click="memberships.sortBy('member_number')">Membership #</p>
                <p :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
                <p :class="memberships.sortClass('email')" @click="memberships.sortBy('email')" >Email</p>
                <p :class="memberships.sortClass('join_date')" @click="memberships.sortBy('join_date','date')">Join Date</p>
                <p :class="memberships.sortClass('exp_date')" @click="memberships.sortBy('exp_date','date')">Expiration</p>
            </div>
            <div class="simple-table" style="--cols:5">
                <p class="st-header first-col" :class="memberships.sortClass('member_number')" @click="sortBy('member_number')">Membership #</p>
                <p class="st-header" :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
                <p class="st-header" :class="memberships.sortClass('email')" @click="memberships.sortBy('email')" >Email</p>
                <p class="st-header" :class="memberships.sortClass('join_date')" @click="memberships.sortBy('join_date','date')">Join Date</p>
                <p class="st-header" :class="memberships.sortClass('exp_date')" @click="memberships.sortBy('exp_date','date')">Expiration</p>
                <template v-for="row in memberships.data" :key="'row' + row.id">
                    <p @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="first-col cursor-pointer"><span class="st-hidden">Membership #</span>{{ row.member_number }}</p>
                    <p @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Motorist</span>{{ row.full_name }}</p>
                    <p :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Email</span><a :href="'mailto:' + row.email + '?subject=Membership%20Renewal'">{{ row.email }}</a></p>
                    <p @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Joined</span>{{ row.join_date }}</p>
                    <p v-if="row.status == 'Expired'" @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Expiration</span><span style="color: red;">{{ row.exp_date }}</span></p>
                    <p v-else-if="row.status == 'Current'" @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Expiration</span><span style="color: green;">{{ row.exp_date }}</span></p>
                    <p v-else @click="memberships.selectRow(row.id)" :class="{selected: memberships.isRowSelected(row.id)}" class="cursor-pointer"><span class="st-hidden">Expiration</span>{{ row.exp_date }}</p>
                </template>
            </div>
            <div class="st-bottom">
                <p>{{ memberships.rangeText }}</p>
                <Pagination
                    v-if="memberships.itemCount || memberships.itemCount <= memberships.itemsPerPage"
                    :active-page="memberships.activePage"
                    :item-count="memberships.itemCount"
                    :items-per-page="memberships.itemsPerPage"
                    @on-update="updateActivePage($event)"
                />
            </div>
        </div>
        <div class="position-relative py-5" v-else>
            <VueLoader />
        </div>
        <!-- Confirm Starting location Modal -->
        <jet-dialog-modal :show="this.confirmStartingLocation" @close="closeModal">
            <template #title>
                Confirm starting label on the first sheet:
            </template>

            <template #content>
                <StartingGrid />
            </template>

            <template #footer>
                <jet-secondary-button @click="closeModal">
                    Cancel
                </jet-secondary-button>

                <jet-button class="ml-2" @click="printSelectedToLabels(this.allLabels)" >
                    Confirm and Generate Labels
                </jet-button>
            </template>
        </jet-dialog-modal>
</template>

<style lang="scss" scoped>
    @import '../../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }

    .selected {
        background: var(--gray-200);
        font-weight: bold;
    }

    *.first-col {
        padding-left: 1rem !important;
    }
    label {
        @include fluid-size(12px, 16px, font-size);
        font-size: 14px;
        font-weight: 500;
    }

    .search-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: initial;

         @include bp-up ($bp-md + 1) {
        align-items: center;
         }
    }

    .top-buttons {
        position: relative;

        @include bp-up ($bp-xl + 20) {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }

</style>

<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import Pagination from '@/Components/Pagination.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import StartingGrid from '../Partials/StartingGrid.vue'
    import VueLoader from '@/Components/VueLoader'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Status,
            JetSecondaryButton,
            JetButton,
            Link,
            VueLoader,
            Pagination,
            JetDialogModal,
            StartingGrid,
            moment,
        },
        data() {
            return {
                memberId: null,
                memberships: reactive(new EloquentSSFP()),
                searchModel: '',
                member_type_list: [],
                member_type: '',
                start_date: null,
                end_date: null,
                confirmStartingLocation: false,
                all: false,
                allLabels: false,
            }
        },
        methods: {
            resetOne: function () {
                this.$refs.customTable.updateActivePage(1);
            },
            resetFilters() {
                this.status = '';
                this.member_type = '';
                this.start_date = null;
                this.end_date = null;
                this.memberships.resetAll();
            },
            applyFilters() {
                let options = {...this.memberships.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('plan_expiration');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.member_type) {
                    options['filterProps'].push('member_type');
                    options['filterOperators'].push([null])
                    options['filterValues'].push([this.member_type])
                    options['filterExclusivities'].push(1);
                }

                if (this.status) {
                    options['filterProps'].push('status');
                    options['filterValues'].push([this.status]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }

                this.memberships.refreshData(options);
            },
            updateActivePage(page) {
                this.memberships.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            selectStartingPosition(allLabel) {
                this.allLabels = allLabel;
                this.confirmStartingLocation = true;
            },
            printSelectedToLabels(all) {
                var startPosition = document.querySelector('input[name="start_grid"]:checked').value;

                axios({
                    method: 'post',
                    url: route('dashboard.back_office.renewals.print_renewal_labels'),
                    responseType: "blob",
                    data: {
                        all: all,
                        options: this.memberships.options,
                        rows: this.memberships.selectedRows,
                        starting_position: startPosition
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-renewal-labels.pdf`;
                        link.click();
                        link.remove();
                        this.closeModal();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            printSelectedToPDF(all) {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.renewals.print_renewal_pdf'),
                    responseType: "blob",
                    data: {
                        all: all,
                        options: this.memberships.options,
                        rows: this.memberships.selectedRows,
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-renewals.pdf`;
                        link.click();

                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            closeModal: function () {
                this.confirmStartingLocation = false;
            },
        },
        watch: {
            selectedRows: {
                deep: true,
                handler(n) {
                    this.$emit('update-selection', n);
                }
            }
        },
        mounted() {
            this.memberships.init('/dashboard/back-office/memberships/fetch_members_ssfp', {
                searchableData: ['full_name','membership_number'],
                itemsPerPage: 50,
            });
        },
        created() {
            this.fetchMemberTypes();
        }
    }
</script>
