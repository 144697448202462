<template>
    <div id="map"></div>

    <TowLocatorTable
        :data="towing_companies"
        class="mt-10"
        v-if="towing_companies.length > 0"
        @getDistance="getDistance($event)"
    />
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader'
import JetButton from '@/Jetstream/Button.vue'
import TowLocatorTable from '../TowLocatorTable.vue'

const loader = new Loader({
    // MTS API KEY: AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ
    apiKey: "AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ",
    version: "weekly",
    libraries: ["places"]
});

const mapOptions = {
    center: {
        lat: 42.4962397,
        lng: -92.4830837,
    },
    zoom: 7,
    mapTypeControl: false,
    mapTypeId: 'roadmap'
};


export default {
    components: {
        Loader,
        JetButton,
        TowLocatorTable,
    },
    data() {
        return {
            map: {},
            markers: [],
            towing_companies: [],
            equipment_list: [],
            search_lat: 0,
            search_lng: 0,
        }
    },
    methods: {
        setSearchLocation(lat, lng) {
            this.search_lat = lat;
            this.search_lng = lng;
            axios({
                method: 'post',
                url: route('dashboard.towing_companies.tows.towing_companies_in_range'),
                data: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                }
            })
            .then(response => {
                this.showTowingCompanies(response.data, this.map)
                this.towing_companies = response.data
                this.getDistanceCache();
            })
        },
        showTowingCompanies(locationList) {
            locationList.forEach((location, id) => {
                const contentString =
                `<div class="gmap-info-window">
                    <div class="window-wrapper">
                        <div class="content">
                            <p class="title">${location.name}</p>
                            <p>${location.address}</p><p>${location.address2}</p>
                            <p>${location.city} ${location.state}, ${location.postal_code}</p>
                            <p>Phone: ${location.phone}</p>
                            <p>After Hours: ${location.phone_24hrs}</p>
                            <p>Distance: ${parseFloat(location.distance).toFixed(2)} miles</p>
                        </div>
                    </div>
                </div>`;
                //create info window foreach location
                let infoWindow = new google.maps.InfoWindow({
                    content: contentString
                });

                let prevWindow = false;

                //set up markers for each location
                let marker = new google.maps.Marker({
                    clickable: true,
                    title: location.name,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    animation: google.maps.Animation.DROP,
                    position: {
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude)
                    },
                });

                marker.addListener('click', function(){
                    if (prevWindow) prevWindow.close();
                    prevWindow = infoWindow;
                    infoWindow.open(this.map, marker);
                });
                marker.set('id', id);
                marker.setMap(this.map);
                this.markers.push(marker);
            });
        },
        clearTCTable(){
            this.towing_companies = [];
        },
        getDistance(index){
            let towLatitude = this.towing_companies[index].latitude;
            let towLongitude = this.towing_companies[index].longitude;

            axios({
                method: 'post',
                url: route('dashboard.towing_companies.tows.towing_company_drive_distance'),
                data: {
                    searchLat: parseFloat(this.search_lat),
                    searchLng: parseFloat(this.search_lng),
                    towLat: parseFloat(towLatitude),
                    towLng: parseFloat(towLongitude),
                }
            })
            .then(response => {
                let drive = response.data.distance; // + ' (' + response.data.time + ')'; // Uncomment if you want drive time as well
                this.towing_companies[index]['drive'] = drive;
                this.setDistanceCache(index, drive);
            })
        },
        setDistanceCache(index, drive) {
            // Get the cached data
            let mapDriveData = JSON.parse(sessionStorage.getItem('mapDriveData'))??[];
            
            // Add the new cache data
            mapDriveData.push({'index': index, 'drive': drive});

            // Save the cache data
            sessionStorage.setItem('mapDriveData', JSON.stringify(mapDriveData));
        },
        getDistanceCache() {
            let mapDriveData = JSON.parse(sessionStorage.getItem('mapDriveData'))??[];
            if (mapDriveData)
            {
                mapDriveData.forEach((mdd, index) => {
                    console.log(mdd);
                    this.towing_companies[mdd.index]['drive'] = mdd.drive;
                });
            }
        }
    },
    mounted() {
        const vm = this;
        // Promise
        loader.load()
        .then((google) => {
            vm.map = new google.maps.Map(document.getElementById("map"), mapOptions)

            let centerMarker;
            let geocoder;
            let response;

            geocoder = new google.maps.Geocoder();

            const inputText = document.createElement("input");

            inputText.type = "text";
            inputText.placeholder = "Enter a location";
            inputText.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;line-height: 40px;margin-right: 0;min-width: 25%;';
            const submitButton = document.createElement("input");

            submitButton.type = "button";
            submitButton.value = "Search";
            submitButton.classList.add("button", "button-primary");
            submitButton.style.cssText = 'background-color: #1a73e8;color: white;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;height: 40px;cursor: pointer;margin-left: 5px;';
            const clearButton = document.createElement("input");

            clearButton.type = "button";
            clearButton.value = "Clear";
            clearButton.classList.add("button", "button-secondary");
            clearButton.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;height: 40px;cursor: pointer;margin-left: 5px;background-color: white;color: #1a73e8;';
            response = document.createElement("pre");
            response.id = "response";
            response.innerText = "";


            const instructionsElement = document.createElement("p");

            instructionsElement.id = "instructions";
            instructionsElement.innerHTML =
                "<strong>Instructions</strong>: Enter an address in the textbox or click on the map to reverse geocode.";
            instructionsElement.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;padding: 1rem;font-size: medium;';
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText);
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton);
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton);
            vm.map.controls[google.maps.ControlPosition.LEFT_TOP].push(instructionsElement);

            centerMarker = new google.maps.Marker({
                map: vm.map,
                icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            });
            vm.map.addListener("click", (e) => {
                geocode({ location: e.latLng });
            });
            submitButton.addEventListener("click", () => {
                geocode({ address: inputText.value });

                // Lets store the text value in session
                sessionStorage.setItem('mapInputText', inputText.value);
            });
            clearButton.addEventListener("click", () => {
                clear();
                inputText.value="";

                // Lets remove the text value from session
                sessionStorage.removeItem('mapInputText');
                sessionStorage.removeItem('mapActivePage');
                sessionStorage.removeItem('mapDriveData');
            });
            clear();

            // Lets restore the last searched value
            inputText.value = getSearchTerm();
            if (inputText.value) {
                // we did populate it...  Lets initiate the search.
                submitButton.click();
            }


            function clear() {
                centerMarker.setMap(null);
                for (var i = 0; i < vm.markers.length; i++) {
                    vm.markers[i].setMap(null);
                    vm.markers[i].setVisible(false);
                }
                vm.markers = [];
                vm.clearTCTable();
            }

            function geocode(request) {
                clear();
                geocoder
                    .geocode(request)
                    .then((result) => {
                        const { results } = result;

                        vm.map.setCenter(results[0].geometry.location);
                        centerMarker.setPosition(results[0].geometry.location);
                        centerMarker.setMap(vm.map);

                        vm.setSearchLocation(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                        return results;
                    })
                    .catch((e) => {
                        alert("Geocode was not successful for the following reason: " + e);
                    });
            }
            
            function getSearchTerm() {
                return sessionStorage.getItem("mapInputText") ?  sessionStorage.getItem("mapInputText") : "";
            }
        })
        .catch(e => {
            console.log(e);
        });
    },
}
</script>
<style lang="scss" scoped>

#map {
    height: 700px;
}
</style>
