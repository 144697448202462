<template>
    <jet-form-section @submit.prevent="addMembershipType()">
        <template #description>
            <p v-if="isModify" class="font-medium text-navy-300">Update Membership Type. </p>
            <p v-else class="font-medium text-navy-300"> Add Membership Type.</p>
        </template>

        <template #form @submit.prevent="form.post('/add-membership-type')" >
            <jet-input v-if="isModify" id="member_type_id" type="hidden" name="member_type_id" v-model="form.id" />
            <!-- Member Type Info -->
            <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                <div class="col-span-1 md:col-span-6">
                    <div class="col-span-1 sm:col-span-6">
                        <jet-label for="name" value="Name" />
                        <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" required />
                        <jet-input-error :message="form.errors.name" class="mt-2" />
                    </div>
                    <div class="col-span-1 sm:col-span-6">
                        <jet-label for="upload_name" value="Upload Name" />
                        <jet-input id="upload_name" type="text" class="mt-1 block w-full" v-model="form.upload_name" required />
                        <jet-input-error :message="form.errors.upload_name" class="mt-2" />
                    </div>
                </div>
                <div class="col-span-1 mt-4">
                    <div class="col-span-6 sm:col-span-4 mt-2">
                        <jet-checkbox name="can_add" v-model:checked="form.can_add" true-value="true" false-value="false" />
                        <span class="ml-2 font-normal">Can Add</span>
                        <jet-input-error :message="form.errors.can_add" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-4 mt-2">
                        <jet-checkbox name="can_update" v-model:checked="form.can_update" true-value="true" false-value="false" />
                        <span class="ml-2 font-normal">Can Update</span>
                        <jet-input-error :message="form.errors.can_update" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-4 mt-2">
                        <jet-checkbox name="can_search" v-model:checked="form.can_search" true-value="true" false-value="false" />
                        <span class="ml-2 font-normal">Can Search</span>
                        <jet-input-error :message="form.errors.can_search" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-4 mt-2">
                        <jet-checkbox name="can_renew" v-model:checked="form.can_renew" true-value="true" false-value="false" />
                        <span class="ml-2 font-normal">Can Renew</span>
                        <jet-input-error :message="form.errors.can_renew" class="mt-2" />
                    </div>
                </div>
            </div>
            <div>
                <jet-label for="download_name" value="Download Name" />
                <jet-input id="download_name" type="text" class="mt-1 block w-full" v-model="form.download_name" />
                <jet-input-error :message="form.errors.download_name" class="mt-2" />
            </div>
            <div>
                <label for="default_expiration" class="m-1">Default Expiration Date</label>
                    <select v-model="form.default_expiration" class="border py-2 pl-3 pr-8 text-grey-800 m-1">
                        <option
                            v-for="option in default_expiration_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                <jet-input-error :message="form.errors.default_expiration" class="mt-2" />
            </div>
            <template v-if="isModify">
                <div>
                    <jet-checkbox name="abi_enable" v-model:checked="form.api_enabled" @true-value="true" @false-value="false" />
                    <span class="ml-2 font-normal">Enable API</span>
                    <jet-input-error :message="form.errors.api_enabled" class="mt-2" />
                </div>
                <div v-show="form.api_enabled">
                    <jet-label for="dealer_id" value="Dealer ID" />
                    <jet-input id="dealer_id" type="text" class="mt-1 block w-full" v-model="form.id" readonly />
                </div>
                <div v-show="form.api_enabled">
                    <jet-label for="api_key" value="API Key" />
                    <jet-input id="api_key" type="text" class="mt-1 block w-full" v-model="form.api_key" readonly />
                    <jet-input-error :message="form.errors.api_key" class="mt-2" />
                    <jet-button  v-if="isModify" @click="openModal()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Generate New API Key
                    </jet-button>
                </div>
            </template>
        </template>
        <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Member Type Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Member Type Added.
            </jet-action-message>

            <jet-button  v-if="isModify" @click="updateMembershipType()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Update
            </jet-button>


            <jet-button v-else @click="addMembershipType()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>

            <jet-dialog-modal :show="confirmingGenerateApiKey" @close="closeModal">
                <template #title>
                    Generate API Key
                </template>

                <template #content>
                    Are you sure you want to generate a new API Key?


                </template>

                <template #footer>
                    <jet-secondary-button @click="closeModal">
                        Cancel
                    </jet-secondary-button>

                    <jet-danger-button class="ml-2" @click="generateApiKey()" >
                        Generate API Key
                    </jet-danger-button>
                </template>
            </jet-dialog-modal>
        </template>

        
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetCheckbox,
            JetDialogModal,
            JetDangerButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    name: '',
                    upload_name: '',
                    download_name: '',
                    can_add: false,
                    can_update: false,
                    can_delete: false,
                    can_search: false,
                    can_renew: false,
                    default_expiration: 1,
                    api_enabled: false,
                    api_key: ''
                }),
                isModify: false,
                typeId: '',
                default_expiration_list: [],
                confirmingGenerateApiKey: false
            }
        },
        methods: {
            addMembershipType(event)
            {
                this.form.post(route('dashboard.motorists.membership_types.store'),
                {
                    errorBag: 'addMembershipType',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset('name', 'upload_name', 'download_name', 'order_by', 'can_add', 'can_add', 'can_update', 'can_delete', 'can_search', 'can_renew'),
                })
            },
            updateMembershipType(event)
            {
                this.form.post(route('dashboard.motorists.membership_types.update'),
                {
                    errorBag: 'addMembershipType',
                    preserveScroll: true,
                })
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.typeId = typeId;
                    this.loadMemberTypeData();
                }
            },
            loadMemberTypeData()
            {
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.membership_types.fetch_member_type_data'),
                    data: {
                        member_type: this.typeId
                    }
                })
                .then(response => {
                    Object.entries(response.data).forEach(([key, value]) => {
                        //loop checkbox values
                        if(key == 'can_add' || key == 'can_update' || key == 'can_search' || key == 'can_renew' || key == 'api_enabled'){
                            value = value == 1 ? true : false;
                        }
                        this.form[key] = value;
                    });

                    // If there isn't an API Key, generate one.
                    if (!this.form.api_key)
                    {
                        this.generateApiKey();
                    }
                })
            },
            generateApiKey()
            {
                this.form.api_key = this.generateKeyPart()+this.generateKeyPart()+this.generateKeyPart();
                this.closeModal();
            },
            generateKeyPart()
            {
                return "10000000100040008000100000000000".replace(/[018]/g, c =>
                    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
                );
            },
            openModal()
            {
                this.confirmingGenerateApiKey = true;
            },
            closeModal() {
                this.confirmingGenerateApiKey = false
            },
            populateExpireDropdown()
            {
                var count = 1;
                while (count <= 10)
                {
                    var obj = {};
                    obj.name = count + ' Year' + (count > 1 ? 's' : '');
                    obj.value = count;
                    this.default_expiration_list.push(obj);
                    count ++;
                }
            }
        },
        mounted() {
            this.checkModify();
            this.populateExpireDropdown();
        }
    }
</script>
