<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Search &amp; Manage Towing Companies"
        >
            <JetButton class="button button-primary top-buttons"
                v-if="$page.props.user.current_team_id != 3"
            >
                <Link
                    :href="`/dashboard/towing-companies/add_edit_towing_company`"
                    as="button"
                    type="button"
                >
                    ADD TOWING COMPANY
                </Link>
            </JetButton>
            <Grid />
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Grid from './Grid.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'

    export default defineComponent({
        components: {
            AppLayout,
            Grid,
            Link,
            Card,
            JetButton,
        },
        data() {
            return {

            }
        },
        computed() {

        }
    })
</script>
