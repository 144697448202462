<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Search &amp; Manage Members"
            >
            <Link
                :href="`/dashboard/motorists/memberships/add_member`"
                as="button"
                type="button"
            >
                <JetButton
                    class="button button-primary top-buttons"
                    v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff'"
                >
                        Add Member
                </JetButton>
            </Link>
            <grid />
            </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Grid from './Grid.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'

    export default defineComponent({
        components: {
            AppLayout,
            Grid,
            Link,
            Card,
            JetButton
        },
    })
</script>
