<template>
    <AppLayout title="Back Office - Breakdowns">
        <template #header>
        </template>
        <Card
            title="Breakdowns"
        >
            <BreakdownsTable />
        </Card>
    </AppLayout>
</template>

<style lang="scss" scoped>
@import '../../../../../scss/mixins';
    label {
        @include fluid-size(12px, 16px, font-size);
        font-size: 14px;
        font-weight: 500;
    }
    .search-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;


    }
</style>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import BreakdownsTable from './Tables/BreakdownsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';

    export default {
        components: {
            AppLayout,
            BreakdownsTable,
            Link,
            Card,
        },
    }
</script>
