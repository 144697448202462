<template>
    <app-layout title="Dashboard - Tow Requests">
        <template #header>
        </template>
        <Card
            title="Tow Requests"
        >
            <TowRequestsTable />
        </Card>
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import TowRequestsTable from './Tables/TowRequestsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        components: {
            AppLayout,
            TowRequestsTable,
            Link,
            Card,
            JetButton,
            JetSecondaryButton,
        },
    }
</script>
