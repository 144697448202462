<template>
<FrontEndLayout title="Login" class="z100">
    <Head title="Log in" />
<template #fepage-content>
    <div class="loginBG">
        <div class="login-box">
            <h2 class="text-white font-bold text-center uppercase">Portal Login</h2>
            <JetAuthenticationCard
                color="gray-300">
                <jet-validation-errors class="mb-4" />

                <div v-if="status" class="mb-4 font-medium text-sm text-green">
                    {{ status }}
                </div>

                <form @submit.prevent="submit">
                    <div>
                        <jet-label for="username" value="Username" />
                        <jet-input id="username" type="text" class="mt-1 block w-full" v-model="form.username" required autofocus />
                    </div>

                    <div class="mt-4">
                        <jet-label for="password" value="Password" />
                        <jet-input id="password" type="password" class="mt-1 block w-full" v-model="form.password" required autocomplete="current-password" />
                    </div>

                    <div class="block mt-4">
                        <div class="flex items-center">
                            <label >
                                <jet-checkbox name="remember" v-model:checked="form.remember" />
                                <span class="ml-2 text-sm text-gray-600">Remember me</span>
                            </label>
                            <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-600 hover:text-gray-900 ml-auto">
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <jet-button class="ml-4 bg-red-500 hover:bg-red-200" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Log in
                        </jet-button>
                    </div>
                </form>
            </JetAuthenticationCard>
            <div class="apply-now-sec grid grid-cols-5">
            <h5 class="text-white col-span-1 pl-2">Apply Now:</h5>
            <div class="col-span-4 grid-cols-2 gap-1 md:grid-cols-5">
            <a class="text-white app-links" title="Internal link in same tab to Motorist Application Page" href="/motorists-and-rentals/member-registration" target="_self">Motorist</a>
            <span class="text-white font-bold">|</span>
            <a class="text-white app-links" title="Internal link in same tab to Towing Companies Application Page" href="/towing-companies/vendor-application" target="_self">Towing Companies</a>
            <span class="text-white font-bold">|</span>
            <a class="text-white app-links" title="Internal link in same tab to Dealership Application Page" href="/dealerships/dealership-application" target="_self">Dealerships</a>
            </div>
            </div>
        </div>
        <div class="login-img" style="background-image: url(/images/img-login-bg.png)" alt="twisting road"/>
    </div>
    </template>
    </FrontEndLayout>
</template>
<style lang="scss" scoped>
@import '../../../scss/_mixins.scss';

.loginBG {
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 85vh;
    top: var(--header-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

        .login-img {
        position: absolute;
        height: 120vh;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: var(--mm-overlay);
            }
        }
}

.login-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0 1rem;
    pointer-events: all;

    .apply-now-sec {
        background-color: var(--blue-500);
        padding: 1rem;
        justify-items: center;

        @mixin not-mobile {
           width: 25vw;
       }

        .app-links {
            position: relative;
            font-size: 16px;
            text-decoration: underline;
            font-weight: bold;
            padding: 0 .5rem;


            &:last-child:after {
                content: '';
                position: absolute;
            }
        }
    }
}
</style>
<script>
    import { defineComponent } from 'vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            Link,
            FrontEndLayout,
        },

        props: {
            canResetPassword: Boolean,
            status: String
        },

        data() {
            return {
                form: this.$inertia.form({
                    email: '',
                    username: '',
                    password: '',
                    remember: false
                })
            }
        },

        methods: {
            submit() {
                this.form
                    .transform(data => ({
                        ... data,
                        remember: this.form.remember ? 'on' : ''
                    }))
                    .post(this.route('login'), {
                        onFinish: () => this.form.reset('password'),
                    })
            }
        }
    })
</script>
