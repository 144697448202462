<template>
    <jet-secondary-button
        v-if="$page.props.user.current_team.name == 'Dealer' && form.id > 0"
        @click="renew" >RENEW MEMBER</jet-secondary-button
    >
    <JetButton class="button button-primary absolute right-4" @click="sendResetEmail" v-if="resetEmailButtonText">
        {{ resetEmailButtonText }}
    </JetButton>
    <jet-form-section @submitted="updateMemberInformation">
        <template #description>
            <span v-if="isModify" class="font-medium">Update member account profile information. </span>
            <span v-else class="font-medium">Add member account profile information.</span>
        </template>

        <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="member_id" v-if="isModify" type="hidden" name="member_id" v-model="form.id" :readonly="readonly"/>
            <jet-input id="dealer_id" v-if="$page.props.dealer.length > 0 && dealer_id != ''" type="hidden" name="dealer_id" v-model="form.dealer_id" :readonly="readonly"/>
            <!-- Member Info -->
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div class="md:col-span-2">
                    <jet-label for="first_name" value="First Name" />
                    <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.first_name" class="mt-2" />
                </div>
                <div class="md:col-span-2">
                    <jet-label for="last_name" value="Last Name" />
                    <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.last_name" class="mt-2" />
                </div>
                <div class="md:col-span-1">
                    <jet-label for="middle_initial" value="Middle Initial" />
                    <jet-input id="middle_initial" type="text" class="mt-1 block w-full" v-model="form.middle_initial" maxlength="1" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.middle_initial" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <vue-tel-input id="phone" label="Phone" v-model="form.phone" :error-message="form.errors.phone" />
                </div>
                <div>
                    <vue-tel-input id="cell" label="Cell" v-model="form.cell" :error-message="form.errors.cell" />
                </div>
                <div>
                    <jet-label for="email" value="Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
            </div>
            <!-- Address -->
            <div>
                <jet-label for="address" value="Address" />
                <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" :readonly="readonly"/>
                <jet-input-error :message="form.errors.address" class="mt-2" />
            </div>
            <div>
                <jet-label for="address2" value="Address 2" />
                <jet-input id="address2" type="text" class="mt-1 block w-full" v-model="form.address2" :readonly="readonly"/>
                <jet-input-error :message="form.errors.address2" class="mt-2" />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>
                <div>
                    <jet-label for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="postal_code" value="Zip/Postal Code" />
                    <jet-input id="postal_code" type="text" class="mt-1 block w-full" v-model="form.postal_code" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.postal_code" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div v-if="$page.props.user.current_team_id != 4">
                    <jet-label for="status" value="Status" />
                    <select v-model="form.status" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in member_status_list"
                            :key="option"
                            :value="option"
                        >{{option}}</option>
                    </select>
                    <jet-input-error :message="form.errors.status" class="mt-2" />
                </div>
                <div>
                    <jet-label for="plan_expiration" value="Expiration Date" />
                    <jet-input id="plan_expiration" type="date" class="mt-1 block w-full" v-model="form.plan_expiration" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.plan_expiration" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4" v-if="$page.props.user.current_team_id != 4">
                <div>
                    <jet-label for="member_type" value="Member Type" />
                    <select v-model="form.member_type" class="border py-2 px-3 text-grey-800 w-full" @change="selectOptionsAndUpdateTerms()">
                        <option
                            v-for="option in member_type_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.member_type" class="mt-2" />
                </div>
                 <div>
                    <jet-label for="plan_type" value="Plan Type" />
                    <select id="plan_type" v-model="form.plan_type" @change="updateTerms(true)" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in planTypeList"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.plan_type" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="club" value="Club" />
                    <jet-input id="club" type="text" class="mt-1 block w-full" v-model="form.club" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.club" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                    <jet-label for="member_number" value="Member #" />
                    <jet-input id="member_number" type="text" class="mt-1 block w-full" v-model="form.member_number" />
                    <jet-input-error :message="form.errors.member_number" class="mt-2" />
                    </div>
                    <div>
                        <jet-button type="button" @click="generateMemberNumber();" class="ml-4 mt-8" v-show="!readonly && showGenerateMemberNumber">
                            Generate Member Number
                        </jet-button>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                    <jet-label for="rider_group_number" value="Rider Group Number" />
                    <jet-input id="rider_group_number" type="text" class="mt-1 block w-full" v-model="form.rider_group_number" />
                    <jet-input-error :message="form.errors.rider_group_number" class="mt-2" />
                    </div>
                    
                </div>
            </div>

            <h3 class="mt-10">Terms</h3>
            <div class="grid grid-cols-1 md gap-1">
                <textarea id="terms" type="text" class="mt-1 block w-full font-medium" v-model="form.terms" disabled />
            </div>
            <h3 class="mt-10">Plan Options</h3>
            <PlanOptions
                v-model:member-plan-options="form.plan_options"
                :member-plan-options="form.plan_options"
                :errors="form.errors"
            />


            <h3 class="mt-10">Family Plan</h3>
            <div class="grid grid-cols-4 md gap-1">
                <div>
                    <p class="font-medium text-navy-300 mt-3">Family Plan</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="family_yes" value="Yes" />
                            <jet-input id="family_yes" type="radio" class="mt-1 block" value="Yes" name="family_plan" @click="addAnotherFamilyMember(false)" required @input="form.family_plan = 1" :checked="form.family_plan" :readonly="readonly"/>
                        </div>
                        <div>
                            <jet-label for="family_no" value="No" />
                            <jet-input id="family_no" type="radio" class="mt-1 block" value="No" name="family_plan" @click="removeFamilyMember(true)" required @input="form.family_plan = 0" :checked="!form.family_plan" :readonly="readonly"/>
                        </div>
                    </div>
                    <jet-input-error :message="form.errors.family_plan" class="mt-2" />
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4" v-if="isModify === false || showAddFam === true || form.family_plan == 1">
                <FamilyMembers
                    v-for="n in family_member_count"
                    :key="'family-member-' + n"
                    :index="n"
                    v-model:family-member-name="form.family_members[n-1].name"
                    v-model:family-member-phone="form.family_members[n-1].phone"
                    />
                <jet-button type="button" @click="addAnotherFamilyMember();"  v-if="$page.props.user.current_team_id != 3 && family_member_count > 0" class="ml-4 mt-4">
                    Add Another Family Member
                </jet-button>
                <jet-button v-if="family_member_count > 1 && $page.props.user.current_team_id != 3" type="button" @click="removeFamilyMember();" class="ml-4 mt-4">
                    Remove Family Member
                </jet-button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="alert" value="Alert" />
                    <textarea id="alert" type="text" class="mt-1 block w-full font-medium text-red-700" v-model="form.alert" />
                    <jet-input-error :message="form.errors.alert" class="mt-2" />
                </div>
                <div>
                    <jet-label for="notes" value="Notes" />
                    <textarea id="notes" type="text" class="mt-1 block w-full" v-model="form.notes" />
                    <jet-input-error :message="form.errors.notes" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <jet-label for="comment" value="Comments" />
                    <textarea id="comment" type="text" class="mt-1 block w-full" v-model="form.comment" />
                    <jet-input-error :message="form.errors.comment" class="mt-2" />
                </div>
                <div>
                    <jet-label for="referred_by" value="Referred By" />
                    <jet-input id="referred_by" type="text" class="mt-1 block w-full" v-model="form.referred_by" :readonly="readonly"/>
                    <jet-input-error :message="form.errors.referred_by" class="mt-2" />
                </div>
            </div>
            <div v-if="isModify === false || showAdd === true" class="col-span-6 sm:col-span-4">
                <h3 class="mt-4 font-medium text-navy-300">Motorcycle{{ motorcycles > 1 ? 's' : '' }}</h3>
                <Motorcycle
                    v-for="n in motorcycles"
                    :key="'member-motorcycle-' + n"
                    :index="n-1"
                    :states="states"
                    v-model:motorcycle-year="form.member_motorcycles[n-1].motorcycle_year"
                    v-model:motorcycle-make="form.member_motorcycles[n-1].motorcycle_make"
                    v-model:motorcycle-model="form.member_motorcycles[n-1].motorcycle_model"
                    v-model:motorcycle-color="form.member_motorcycles[n-1].motorcycle_color"
                    v-model:motorcycle-plate="form.member_motorcycles[n-1].motorcycle_plate"
                    v-model:motorcycle-state="form.member_motorcycles[n-1].motorcycle_state"
                    v-model:motorcycle-country="form.member_motorcycles[n-1].motorcycle_country"
                    v-model:motorcycle-vin="form.member_motorcycles[n-1].motorcycle_vin"
                    v-model:trike-autocycle="form.member_motorcycles[n-1].trike_autocycle"
                    :errors="form.errors"
                />
                <jet-button type="button" @click="addAnotherMotorcycle();" v-if="isViewOnly === false && $page.props.user.current_team_id != 3" class="ml-4 mt-4">
                    Add Another Bike
                </jet-button>
                <jet-button v-if="motorcycles > 1 && isViewOnly === false" type="button" @click="removeMotorcycle();" class="ml-4 mt-4">
                    Remove Bike
                </jet-button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <p class="font-medium text-navy-300 mt-3">Has Trailer</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="trailer_yes" value="Yes" />
                            <jet-input id="trailer_yes" type="radio" class="mt-1 block" value="Yes" name="has_trailer" required @input="form.has_trailer = 1" :checked="form.has_trailer" :readonly="readonly"/>
                        </div>
                        <div>
                            <jet-label for="trailer_no" value="No" />
                            <jet-input id="trailer_no" type="radio" class="mt-1 block" value="No" name="has_trailer" required @input="form.has_trailer = 0" :checked="!form.has_trailer" :readonly="readonly"/>
                            <jet-input-error :message="form.errors.has_trailer" class="mt-2" />
                        </div>
                    </div>
                </div>
                <div>
                    <p class="font-medium text-navy-300 mt-3">Has Sidecar</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="sidecar_yes" value="Yes" />
                            <jet-input id="sidecar_yes" type="radio" class="mt-1 block" value="Yes" @input="form.has_sidecar = 1" :checked="form.has_sidecar" name="has_sidecar" required  :readonly="readonly"/>
                        </div>
                        <div>
                            <jet-label for="sidecar_no" value="No" />
                            <jet-input id="sidecar_no" type="radio" class="mt-1 block" value="No" @input="form.has_sidecar = 0" :checked="!form.has_sidecar" name="has_sidecar" required  :readonly="readonly"/>
                            <jet-input-error :message="form.errors.has_sidecar" class="mt-2" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
         <template #actions v-if="isViewOnly === false">
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Member Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Member Added.
            </jet-action-message>

            <jet-button v-if="isModify" v-show="!isViewOnly" @click="updateMemberInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
            <jet-button v-else @click="addMemberInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
    <div v-if="isModify">
        <h3 class="mt-3">Family Members</h3>
        <FamilyTable
            :data="family"
            ref="familyTable"
            @family-updated="fetchMemberData()"
            @row-deleted="rowDeleted($event, 'family')"
        />
        <jet-button @click="showAddFamily()" v-if="$page.props.user.current_team_id != 3 && form.family_plan == 1">
            Add Another Family Member
        </jet-button>
        <h3 class="mt-3">Bikes</h3>
        <BikeTable
            :data="bikes"
            ref="bikeTable"
            :currentteam="$page.props.user.current_team_id"
            :searchable-data="['plate', 'vin', 'make', 'model']"
            :states="states"
            @bikes-updated="fetchMemberData()"
            @row-deleted="rowDeleted($event, 'bike')"
        />
        <jet-button @click="showAddMotorcycle()" v-if="$page.props.user.current_team_id != 3">
            Add Another Bike
        </jet-button>
        <h3 class="mt-3">Tows</h3>
        <TowTable
            :data="tows"
            :bikes="bikes"
            :currentteam="$page.props.user.current_team_id"
            :searchable-data="['service_date', 'problem']"
            @tows-updated="fetchMemberData()"
        />
    </div>
    <!-- Renew Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingMemberRenewal" @close="closeRenewModal">
        <template #title>
            Renew Member
        </template>

        <template #content>
            Are you sure you want to renew <strong>{{ this.form.first_name}} {{ this.form.last_name }}</strong> until <strong>{{ futureExpiration }}</strong>?
        </template>

        <template #footer>
            <jet-secondary-button @click="closeRenewModal">
                Cancel
            </jet-secondary-button>

            <jet-button class="ml-2" @click="renewMember" >
                Renew
            </jet-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import BikeTable from '../Tables/BikeTable.vue'
    import FamilyTable from '../../../../DashboardPartials/Tables/FamilyTable.vue'
    import TowTable from '../Tables/TowTable.vue'
    import Motorcycle from './Partials/Motorcycles.vue'
    import FamilyMembers from '../../../../DashboardPartials/FamilyMembers.vue'
    import PlanOptions from '../../../../DashboardPartials/PlanOptionsModify.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'
    import moment from 'moment'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDialogModal,
            BikeTable,
            TowTable,
            FamilyTable,
            Motorcycle,
            FamilyMembers,
            PlanOptions,
            VueTelInput,
            moment,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    dealer_id: this.dealer_id,
                    first_name: '',
                    last_name: '',
                    middle_initial: '',
                    phone: '',
                    cell: '',
                    email: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    club: '',
                    alert: '',
                    status: null,
                    member_number: '',
                    member_type: '',
                    plan_type: '',
                    plan_options: [],
                    plan_expiration: '',
                    family_plan: 0,
                    family_members: [{
                        name: '',
                        phone: '',
                    }],
                    has_trailer: 0,
                    has_sidecar: 0,
                    referred_by: '',
                    notes: '',
                    member_motorcycles: [{
                        motorcycle_year: '',
                        motorcycle_make: '',
                        motorcycle_model: '',
                        motorcycle_color: '',
                        motorcycle_plate: '',
                        motorcycle_state: '',
                        motorcycle_country: '',
                        motorcycle_vin: '',
                        trike_autocycle: 0,
                    }],
                    isTowRequest: false,
                    terms: '',
                    comment: '',
                    rider_group_number: '',
                }),
                bikes: [],
                tows: [],
                family: [],
                states: [],
                member_status_list: [],
                member_type_list: [],
                plan_type_list: [],
                isModify: false,
                isViewOnly: false,
                showAdd: false,
                showAddFam: false,
                motorcycles: 1,
                family_member_count: 0,
                countries: ['USA', 'Canada'],
                confirmingMemberRenewal: false,
                resetEmailButtonText: null,
            }
        },
        computed: {
            readonly() {
                return this.isViewOnly === true;
            },
            dealer_id() {
                if(this.$page.props.dealer.dealer){
                    this.form.dealer_id = this.$page.props.dealer.dealer.dealer_id;
                    return this.$page.props.dealer.dealer.dealer_id;
                } else {
                    return "";
                }
            },
            futureExpiration() {
                return moment(this.form.plan_expiration).add(1, 'Y').format('MM/DD/YYYY');
            },
            planTypeList() {
                // Reduce the plan type to the items associated to the selected member type
                return this.plan_type_list.filter(obj => {
                    return obj.plan_type == this.form.member_type
                })
            },
            showGenerateMemberNumber() {
                return this.form.member_type && this.planTypeList.find(type => type.value == this.form.plan_type);
            }
        },
        methods: {
            selectOptionsAndUpdateTerms() {
                if (this.planTypeList.length == 1) // Only 1 option, lets go ahead and select it
                {
                    this.form.plan_type = this.planTypeList[0].value;
                    // Update the terms
                    this.form.terms = this.plan_type_list.find(type => type.value == this.planTypeList[0].value)?.terms;
                } else {
                    this.form.terms = "";
                }
            },
            fetchMemberData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var memberId = parseInt(last_segment);

                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.memberships.fetch_member_data'),
                    data: {
                        member: memberId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        if(key != 'bikes' && key != 'tows' && key != 'family_members'){
                            this.form[key] = value;
                        }
                    });
                    this.family = response.data.data.family_members;
                    this.bikes = response.data.data.bikes;
                    this.tows = response.data.data.tows;
                    this.updateTerms();
                    this.updateResetEmailButtonText(memberId);
                })
            },
            generateMemberNumber() {
                axios({
                    method: 'put',
                    url: route('dashboard.motorists.memberships.fetch_new_member_number'),
                    data: this.form
                })
                .then(response => {
                    this.form.member_number = response.data;
                })

            },
            updateMemberInformation() {
                this.form.post(route('dashboard.motorists.memberships.update'), {
                    errorBag: 'addModifyMember',
                    preserveScroll: true,
                    onSuccess: () => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                        this.resetForm();
                        this.fetchMemberData();
                    }
                });
            },
            addMemberInformation() {
                this.form.post(route('dashboard.motorists.memberships.store'), {
                    errorBag: 'addModifyMember',
                    preserveScroll: true
                });
            },
            fetchMemberStatusEnum() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_status_enum'),
                })
                .then(response => {
                    this.member_status_list = response.data;
                })
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchPlanTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_plan_type_list'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                    this.checkModify();
                })
            },

            checkModify() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var second_to_last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if (last_segment == 'add_member' && second_to_last_segment == 'tow-requests')
                {
                    this.form.isTowRequest = true;
                }

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    if(segment_array.includes('view_member')){
                        this.isViewOnly = true;
                    } else {
                        this.isModify = true;
                    }
                    this.typeId = typeId;
                    this.fetchMemberData();
                }
            },
            addAnotherMotorcycle () {
                this.motorcycles++;
                this.form.member_motorcycles.push({
                    motorcycle_year: '',
                    motorcycle_make: '',
                    motorcycle_model: '',
                    motorcycle_color: '',
                    motorcycle_plate: '',
                    motorcycle_state: '',
                    motorcycle_country: '',
                    motorcycle_vin: '',
                    trike_autocycle: 0,
                })
            },
            removeMotorcycle () {
                this.motorcycles--;
                this.form.member_motorcycles.pop();
            },
            addAnotherFamilyMember ($increment = true) {
                if($increment){
                    this.family_member_count++;
                } else {
                    if (this.family_member_count <= 0) {
                        this.family_member_count = 1;
                    }
                }

                this.showAddFam = true;
                this.form.family_members.push({
                    name: '',
                    phone: '',
                })
            },
            removeFamilyMember ($all = false) {
                if ($all) {
                    while(this.family_member_count > 0) {
                        this.family_member_count--;
                        this.form.family_members.pop();
                    }
                } else {
                    this.family_member_count--;
                    this.form.family_members.pop();
                }
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            showAddMotorcycle () {
                this.showAdd = true;
            },
            showAddFamily () {
                this.showAddFam = true;
            },
            rowDeleted(id, table) {
                if(table == 'family'){
                    this.$inertia.post('/dashboard/motorists/memberships/delete_family_member/' + id, id, {
                        onSuccess: (id) => {
                            this.fetchMemberData();
                            this.$refs.familyTable.closeModal();
                        }
                    })
                }
                if(table == 'bike') {
                    this.$inertia.post('/dashboard/motorists/memberships/delete_bike/' + id, id, {
                        onSuccess: (id) => {
                            this.fetchMemberData();
                            this.$refs.bikeTable.closeModal();
                        }
                    })
                }
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
            updateTerms(onChange = false) {
                if (onChange) {
                    this.form.terms = this.plan_type_list.find(type => type.value == event.target.value)?.terms;
                } else {
                    this.form.terms = this.plan_type_list.find(type => type.value == parseInt(this.form.plan_type))?.terms;
                }
            },
            closeRenewModal: function () {
                this.confirmingMemberRenewal = false;
            },
            renew(id) {
                this.confirmingMemberRenewal = true;
            },
            renewMember() {
                var id = this.form.member_number;
                this.$inertia.post('/dashboard/dealerships/renew_customer/' + id, id, {
                    onSuccess: () => {
                        this.fetchMemberData();
                        this.closeRenewModal();
                    }
                })
            },
            updateResetEmailButtonText(id) {
                axios.get('/dashboard/motorists/memberships/fetch_member_has_login/' + id,{
                }).then(function(response){
                    if (response.data){
                        this.resetEmailButtonText = 'Send Password Reset Email';
                    } else {
                        this.resetEmailButtonText = 'Send Invite Email';
                    }
                }.bind(this));
            },
            sendResetEmail() {
                var id = this.form.id;
                this.$inertia.post('/dashboard/motorists/memberships/send_password_reset_email/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchMemberData();
                        this.$refs.familyTable.closeModal();
                    }
                })
            },
        },
        mounted() {
            this.fetchMemberStatusEnum();
            this.fetchMemberTypes();
            this.fetchPlanTypes();
            this.getStates();
        },
    }
</script>
