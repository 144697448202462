<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!tows.isLoading">
        <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center">
                <label for="date" class="m-1">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
            </div>

            <div>
                <jet-button type="submit" class="button button-primary ml-2"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton>
            </div>
        </div>
    </form>
        <div class="st-hidden-sorts">
            <p :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date', null, 'date')">Service Date</p>
            <p :class="tows.sortClass('problem')" @click="tows.sortBy('problem')">Problem</p>
            <p :class="tows.sortClass('tow_company_name')" @click="tows.sortBy('tow_company_name')">Towing Company</p>
        </div>
        <div class="simple-table" style="--cols:4">
            <p class="st-header first-col" :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date',null, 'date')">Service Date</p>
            <p class="st-header" :class="tows.sortClass('problem')" @click="tows.sortBy('problem')" >Problem</p>
            <p class="st-header" :class="tows.sortClass('tow_company_name')" @click="tows.sortBy('tow_company_name')">Towing Co</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in tows.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Service Date</span>{{ row.service_date }}</p>
                <p ><span class="st-hidden">Problem</span>{{ row.problem }}</p>
                <p><span class="st-hidden">Towing Company</span>{{ row.towing_company }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                                :href="`/dashboard/tow-requests/add_tow_requests_step3/edit/${row.id}`"
                                as="button"
                                type="button"
                            >
                            <jet-button>
                                EDIT TOW
                            </jet-button>
                        </Link>
                        <jet-danger-button @click="confirmTowDeletion(index, row.id)">
                            DELETE
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ tows.rangeText }}</p>
            <Pagination
                v-if="tows.itemCount || tows.itemCount <= tows.itemsPerPage"
                :active-page="tows.activePage"
                :item-count="tows.itemCount"
                :items-per-page="tows.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>

    <!-- Delete Tow Confirmation Modal -->
    <jet-dialog-modal :show="confirmingTowDeletion" @close="closeModal">
        <template #title>
            Delete Tow
        </template>

        <template #content>
            Are you sure you want to delete this tow?

        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteTow(this.towId)" >
                Delete Tow
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import { reactive, toRefs } from 'vue'
    import EloquentSSFP from '@/EloquentSSFP'
    import Pagination from '@/Components/Pagination.vue'
    import VueLoader from '@/Components/VueLoader'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Pagination,
            VueLoader,
            FlashMessage,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link,
            moment,
        },
        data() {
            return {
                tows: reactive(new EloquentSSFP()),
                searchModel: '',
                start_date: null,
                end_date: null,
                confirmingTowDeletion: false,
                towId: 0,
                index: null,
            }
        },
        methods: {
            confirmTowDeletion(index, id) {
                this.confirmingTowDeletion = true;
                this.towId = id;
                this.index = index;
            },
            closeModal: function () {
                this.confirmingTowDeletion = false
            },
            deleteTow(id) {
                 this.$inertia.post('/dashboard/tow-requests/delete_tow/' + id, id, {
                    onSuccess: (id) => {
                        this.tows.refreshData(this.tows.options);
                        this.closeModal();
                    }
                });
            },
            applyFilters() {
                let options = {...this.tows.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('service_date');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                this.tows.refreshData(options);
            },
            updateActivePage(page) {
                this.tows.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            resetFilters() {
                this.start_date = null;
                this.end_date = null;
                this.tows.resetAll();
            },
        },
        mounted() {
            this.tows.init('/dashboard/towing-companies/tows/fetch_tows_ssfp', {
                searchableData: ['problem', 'tow_company_name', 'service_date'],
                sortDirs: ['desc'],
                activeSorts: ['service_date'],
                itemsPerPage: 50,
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>
