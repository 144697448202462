<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p :class="sortClass('member_type')" @click="sortBy('member_type')">Member Type</p>
            <p :class="sortClass('plan_type')" @click="sortBy('plan_type')">Plan</p>
            <p :class="sortClass('price_1_year')" @click="sortBy('price_1_year')">Price 1 Year</p>
            <p :class="sortClass('price_2_year')" @click="sortBy('price_2_year')">Price 2 Year</p>
            <p :class="sortClass('extra_pr_mc')" @click="sortBy('extra_pr_mc')">Extra Per MC</p>
        </div>
        <div class="simple-table" style="--cols:7">
            <p class="st-header first-col" :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p class="st-header" :class="sortClass('member_type')" @click="sortBy('member_type')">Member Type</p>
            <p class="st-header" :class="sortClass('plan_type')" @click="sortBy('plan_type')">Plan</p>
            <p class="st-header" :class="sortClass('price_1_year')" @click="sortBy('price_1_year')" >Price 1 Year</p>
            <p class="st-header" :class="sortClass('price_2_year')" @click="sortBy('price_2_year')">Price 2 Year</p>
            <p class="st-header" :class="sortClass('extra_pr_mc')" @click="sortBy('extra_pr_mc')">Extra Per MC</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.name }}</p>
                <p ><span class="st-hidden">Member Type</span>{{ row.member_type }}</p>
                <p ><span class="st-hidden">Plan</span>{{ row.plan_type }}</p>
                <p ><span class="st-hidden">Price 1 Year</span>{{ row.price_1_year }}</p>
                <p ><span class="st-hidden">Price 2 Year</span>{{ row.price_2_year }}</p>
                <p ><span class="st-hidden">Extra Per MC</span>{{ row.extra_pr_mc }}</p>

                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/motorists/plan-pricing/add_modify_plan_pricing/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>

                        <jet-danger-button @click="confirmPlanPricingeDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Confirmation Modal -->
    <jet-dialog-modal :show="confirmingPlanPricingDeletion" @close="closeModal">
        <template #title>
            Delete Plan Pricing
        </template>

        <template #content>
            Are you sure you want to delete this Plan Pricing? (You will still be able to restore this Plan Pricing if needed.)


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deletePlan(this.index, this.planId)" >
                Delete Plan
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            MemberHeader,
            FlashMessage,
            TableWrapper,
            Status,
            JetDangerButton,
            JetButton,
            JetDialogModal,
            JetSecondaryButton,
            Link
        },

        data() {
            return {
                confirmingPlanPricingDeletion: false,
                planId: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            confirmPlanPricingeDeletion(index, id) {
                this.confirmingPlanPricingDeletion = true;
                this.planId = id;
                this.index = index;
            },

            deletePlan(index, id) {
               this.$emit('row-deleted', id);
            },

            closeModal: function () {
                this.confirmingPlanPricingDeletion = false
            },
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
