<template>
    <Card
        title="Edit Form">
        <JetButton class="button button-primary absolute top-4 right-4" @click="back">
            BACK
        </JetButton>
        <jet-form-section @submit.prevent="updateForm()">
            <template #description>
            <p class="font-medium"> Change Form Name, recipients of notifications, and the success message shown after a successful submission.</p>
            </template>

            <template #form @submit.prevent="form.post('/edit-form')" >
                <jet-input v-if="isModify" id="member_type_id" type="hidden" name="member_type_id" v-model="form.id" />
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="form_name" value="Form Name" />
                        <jet-input id="form_name" type="text" class="mt-1 block w-full" name="form_name" v-model="form.form_name" />
                        <jet-input-error :message="form.errors.form_name" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="recipients" value="Recipients" />
                        <jet-input id="recipients" type="text" class="mt-1 block w-full" name="recipients" v-model="form.recipients" />
                        <jet-input-error :message="form.errors.recipients" class="mt-2" />
                    </div>
                </div>
                <div>
                    <jet-label for="success_message" value="Success Message" />
                    <textarea name="success_message" id="success_message" type="textarea" class="mt-1 block w-full" v-model="form.success_message" />
                    <jet-input-error :message="form.errors.success_message" class="mt-2" />
                </div>
            </template>
            <template #actions>
                <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                    Form Updated Successfully.
                </jet-action-message>

                <jet-button  v-if="isModify" @click="updateForm()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Update
                </jet-button>

            </template>
        </jet-form-section>
    </Card>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import TextArea from '@/Components/FormElements/TextArea.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetCheckbox,
            TextArea,
            Card,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    form_name: '',
                    recipients: '',
                    success_message: '',
                }),
                formId: '',
                isModify: true,
            }
        },
        methods: {
            fetchForm() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var formId = parseInt(last_segment);
                this.formId = formId;
                axios({
                    method: 'post',
                    url: route('dashboard.forms.fetch_form'),
                    data: {
                        form_id: this.formId
                    }
                })
                .then(response => {
                    Object.entries(response.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            updateForm(event)
            {
                this.form.post(route('dashboard.forms.update'),
                {
                    errorBag: 'updateForm',
                    preserveScroll: true,
                })
            },
            back() {
              window.history.back();
            },
        },
        mounted() {
            this.fetchForm();
        }
    }
</script>
