<template>
    <div class="motorcycle">
        <div>
            <jet-label for="motorcycle_year" value="Year" />
            <jet-input id="motorcycle_year" type="text" class="mt-1 block w-full" :value="motorcycleYear" @input="$emit('update:motorcycleYear', $event.target.value)" required />
            <jet-input-error :message="errors.motorcycle_year" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_make" value="Make" />
            <jet-input id="motorcycle_make" type="text" class="mt-1 block w-full" :value="motorcycleMake" @input="$emit('update:motorcycleMake', $event.target.value)"  required />
            <jet-input-error :message="errors.motorcycle_make" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_model" value="Model" />
            <jet-input id="motorcycle_model" type="text" class="mt-1 block w-full" :value="motorcycleModel" @input="$emit('update:motorcycleModel', $event.target.value)"  required />
            <jet-input-error :message="errors.cmotorcycle_model" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_color" value="Color" />
            <jet-input id="motorcycle_color" type="text" class="mt-1 block w-full" :value="motorcycleColor" @input="$emit('update:motorcycleColor', $event.target.value)" required />
            <jet-input-error :message="errors.cmotorcycle_color" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_plate" value="Plate Number" />
            <jet-input id="motorcycle_plate" type="text" class="mt-1 block w-full" :value="motorcyclePlate" @input="$emit('update:motorcyclePlate', $event.target.value)" required />
            <jet-input-error :message="errors.cmotorcycle_plate" class="mt-2" />
        </div>
        <div>
        <jet-label for="motorcycle_state" value="State/Province" />
            <select id="motorcycle_state" class="mt-1 block w-full" :value="motorcycleState" @input="$emit('update:motorcycleState', $event.target.value)" required >
                <option value=""> -- </option>
                <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
            </select>
            <jet-input-error :message="errors.motorcycle_state" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_country" value="Country" />
            <jet-input id="motorcycle_country" type="text" class="mt-1 block w-full" :value="motorcycleCountry" @input="$emit('update:motorcycleCountry', $event.target.value)" required />
            <jet-input-error :message="errors.motorcycle_country" class="mt-2" />
        </div>
        <div>
            <jet-label for="motorcycle_vin" value="VIN" />
            <jet-input id="motorcycle_vin" type="text" class="mt-1 block w-full" :value="motorcycleVin" @input="$emit('update:motorcycleVin', $event.target.value)" required />
            <jet-input-error :message="errors.motorcycle_vin" class="mt-2" />
        </div>
        <div>
            <jet-label value="Trike / Autocycle" />
            <div class="form-group">
                <jet-label for="trike_autocycle_yes" value="Yes" />
                <jet-input id="trike_autocycle_yes" type="radio" class="mt-1 block" :value="trikeAutocycle" @click="$emit('update:trikeAutocycle', 1)" name="trike_autocycle" :checked="trikeAutocycle" required  />
                <jet-label for="trike_autocycle_no" value="No" />
                <jet-input id="trike_autocycle_no" type="radio" class="mt-1 block" :value="trikeAutocycle" @click="$emit('update:trikeAutocycle', 0)" name="trike_autocycle" :checked="!trikeAutocycle" required  />
            </div>
        </div>
    </div> <!-- end motorcycle1 -->
</template>

<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInputError from '@/Jetstream/InputError.vue'

export default {
    components: {
        JetInput,
        JetLabel,
        JetInputError
    },
    props: [
        'index',
        'motorcycleYear',
        'motorcycleMake',
        'motorcycleModel',
        'motorcycleColor',
        'motorcyclePlate',
        'motorcycleState',
        'motorcycleCountry',
        'motorcycleVin',
        'trikeAutocycle',
        'states',
        'errors'
    ]
}
</script>

<style>

</style>
