<template>
    <app-layout title="Dashboard - Plan Pricings">
        <template #header>
        </template>
        <Card
            title="Plan Pricing - NOTE: All items in here show up on the Membership Application" >
            <Link
                :href="`/dashboard/motorists/plan-pricing/add_modify_plan_pricing`"
                as="button"
                type="button"
            >
                <JetButton  class="button button-primary absolute top-4 right-4">
                    ADD PLAN
                </JetButton>
            </Link>
            <div v-if="plans.length == 0">
                <VueLoader  />
            </div>
            <div v-else>
                <PlanPricingTable
                    :data="plans"
                    :searchable-data="['name', 'plan_type']"
                    @row-deleted="rowDeleted($event)"
                    ref="planPricingTable"
                />
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import PlanPricingTable from './Tables/PlanPricingTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            PlanPricingTable,
            Link,
            JetButton,
            Card,
            VueLoader,
        },
        data() {
            return {
                plans:[],
            }
        },
        methods: {
            fetchPlans() {
                axios
                    .get(route('dashboard.motorists.plan_pricing.fetch_plan_pricing_list'))
                    .then(response => {
                        this.plans = response.data.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/motorists/plan-pricing/delete_plan/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchPlans();
                        this.$refs.planPricingTable.closeModal();
                    }
                })
            },
        },
        mounted() {
            this.fetchPlans();
        }
    })
</script>
