<template>
    <button :type="type" class="items-center px-4 py-2 bg-white border border-navy-300 font-semibold text-xs text-navy-700 uppercase tracking-widest shadow-sm hover:text-navy-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:text-navy-800 active:bg-navy-50 disabled:opacity-25 transition">
        <slot></slot>
    </button>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: {
            type: {
                type: String,
                default: 'button',
            },
        }
    })
</script>
