<template>
    <span class="flex items-center" :class="color">{{ status }}</span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'

export default {
    components: {
        FontAwesomeIcon
    },
    props: ['status'],
    data() {
        return {
            circle: faDotCircle
        }
    },
    computed: {
        color() {
            // if positive status
            if (this.checkGroup(['Approved' , 'Active', 'Current' , 'Completed'])) return 'text-green';
            // if pending status
            else if (this.checkGroup(['Pending' , 'On Hold'])) return 'text-yellow';
            // if negative status
            else if (this.checkGroup(['Denied' , 'Expired', 'Archived' , 'Cancelled By Member'])) return 'text-red-700';
            // if neutral/no status
            else if (this.checkGroup(['New Tow Company'])) return 'text-blue-500';
            // if New Company
            else return 'text-gray-400';
        }
    },
    methods: {
        checkGroup($arr) {
            return $arr.some(q=>this.status.toLowerCase()==q.toLowerCase());
        }
    }
}
</script>

<style>

</style>
