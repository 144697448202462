<template>
    <app-layout title="Audit Logs">
        <Card
            title="Audit Logs">
            <template #header>
            </template>
            <AuditLogsTable />
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import AuditLogsTable from './Tables/AuditLogsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'

    export default defineComponent({
        components: {
            AppLayout,
            AuditLogsTable,
            Link,
            Card,
        },
    })
</script>
