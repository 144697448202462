<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <app-layout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Members Management"
            >
            <Link
                :href="`/dashboard/motorists/memberships/add_member`"
                as="button"
                type="button"
            >
                <JetButton
                    class="button button-primary absolute top-4 right-4"
                    v-if="$page.props.user.current_team.name == 'Dealer'"
                >
                    Add Member
                </JetButton>
            </Link>
            <ManageCustomersTable
                respond-at="xl"
                :data="members"
                :searchable-data="['full_name', 'membership_number', 'city_state', 'status', 'member_type', 'plan_type']"
                @row-deleted="rowDeleted($event)"
                @refresh-table="refreshTable($event)"
                ref="memberTable"
            />
            </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import ManageCustomersTable from './Tables/ManageCustomersTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages'

    export default defineComponent({
        components: {
            AppLayout,
            ManageCustomersTable,
            Link,
            Card,
            JetButton,
            FlashMessage
        },
        data() {
            return {
                members: [],
            }
        },
         methods: {
            fetchMembers() {
                var dealerId = this.$page.props.dealer.dealer.dealer_id;
                axios({
                    method: 'post',
                    url: route('dashboard.dealerships.fetch_dealer_members'),
                    data: {
                        dealer: dealerId
                    }
                })
                .then(response => {
                    this.members = response.data.data;
                })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/dealerships/delete_member/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchMembers();
                        this.$refs.memberTable.closeModal();
                    }
                })
            },
            refreshTable() {
                this.fetchMembers();
            }
        },
        mounted() {
            this.fetchMembers();
        }
    })
</script>
