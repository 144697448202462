<template>
    <TableDropdownWrapper
        v-bind="wrapperBinder"
    >
        <form @submit.prevent="updateTow()">
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="bike_id" value="Bike" />
                <select id="bike_id" v-model="form.bike_id" class="border py-2 px-3 text-grey-800 w-full">
                    <option
                        v-for="option in bikeOptions"
                        :key="option.id"
                        :value="option.id"
                    >{{option.year}}, {{option.make}}, {{option.model}}, {{option.plate}}</option>
                </select>
                <jet-input-error :message="form.errors.bike_id" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="service_date" value="Service Date" />
                <jet-input id="service_date" type="date" class="mt-1 block w-full" v-model="form.service_date" />
                <jet-input-error :message="form.errors.service_date" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="time_called" value="Time Call received" />
                <jet-input id="time_called" type="time" class="mt-1 block w-full" v-model="form.time_called" />
                <jet-input-error :message="form.errors.time_called" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="problem" value="Problem" />
                <jet-input id="problem" type="text" class="mt-1 block w-full" v-model="form.problem" />
                <jet-input-error :message="form.errors.problem" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="problem_basis" value="Problem Type" />
                <jet-input id="problem_basis" type="text" class="mt-1 block w-full" v-model="form.problem_basis" />
                <jet-input-error :message="form.errors.problem_basis" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="cod" value="COD" />
                <jet-input id="cod" type="text" class="mt-1 block w-full" v-model="form.cod" />
                <jet-input-error :message="form.errors.cod" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="break_down_location" value="Breakdown Location" />
                <jet-input id="break_down_location" type="text" class="mt-1 block w-full" v-model="form.break_down_location" />
                <jet-input-error :message="form.errors.break_down_location" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <!-- Needs to be dropdown of all towing companies -->
                <jet-label for="tow_company_id" value="Towing Company" />
                <jet-input id="tow_company_id" type="text" class="mt-1 block w-full" v-model="form.tow_company_id" />
                <jet-input-error :message="form.errors.tow_company_id" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="notes" value="Notes" />
                <jet-input id="notes" type="text" class="mt-1 block w-full" v-model="form.notes" />
                <jet-input-error :message="form.errors.notes" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="code" value="Code" />
                <jet-input id="code" type="text" class="mt-1 block w-full" v-model="form.code" />
                <jet-input-error :message="form.errors.code" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="po" value="PO" />
                <jet-input id="po" type="text" class="mt-1 block w-full" v-model="form.po" />
                <jet-input-error :message="form.errors.po" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="ra" value="RA" />
                <jet-input id="ra" type="text" class="mt-1 block w-full" v-model="form.ra" />
                <jet-input-error :message="form.errors.ra" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="date_invoiced" value="Invoice Date" />
                <jet-input id="date_invoiced" type="date" class="mt-1 block w-full" v-model="form.date_invoiced" />
                <jet-input-error :message="form.errors.date_invoiced" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="amount_invoiced" value="Amount" />
                <jet-input id="amount_invoiced" type="text" class="mt-1 block w-full" v-model="form.amount_invoiced" />
                <jet-input-error :message="form.errors.amount_invoiced" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="date_paid" value="Date Paid" />
                <jet-input id="date_paid" type="date" class="mt-1 block w-full" v-model="form.date_paid" />
                <jet-input-error :message="form.errors.date_paid" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="check_number" value="Check Number" />
                <jet-input id="check_number" type="text" class="mt-1 block w-full" v-model="form.check_number" />
                <jet-input-error :message="form.errors.check_number" class="mt-2" />
            </div>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                Tow Updated.
            </jet-action-message>
           <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
            </jet-button>
        </form>
    </TableDropdownWrapper>
</template>

<script>
    import TableDropdownWrapper from '@/Components/VueTable/TableDropdownWrapper.vue';
    import tableDropdownMixin from '@/Components/VueTable/mixins/tableDropdownMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableDropdownMixin],
        components: {
            TableDropdownWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        props: ["bikes"],
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: this.row.id,
                    bike_id: this.row.bike_id,
                    service_date: this.row.service_date,
                    service_time: this.row.service_time,
                    time_called:   this.row.time_called,
                    problem: this.row.problem,
                    problem_basis: this.row.problem_basis,
                    cod: this.row.cod,
                    break_down_location: this.row.break_down_location,
                    towing_company: this.row.towing_company,
                    tow_company_id: this.row.tow_company_id,
                    notes: this.row.notes,
                    code: this.row.code,
                    po: this.row.po,
                    date_invoiced: this.row.date_invoiced,
                    amount_invoiced: this.row.amount_invoiced,
                    date_paid: this.row.date_paid,
                    check_number: this.row.check_number,
                }),
                bikeOptions: this.bikes,
            }
        },
        methods: {
            updateTow() {
                let vm = this;
                axios({
                    method: 'put',
                    url: '/dashboard/dealerships/edit_dealer/update_tow',
                    data: vm.form,
                })
                .then(function (response){
                    vm.$emit('tows-updated');
                });
            },
        }
    }
</script>
