<template>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div>
            <jet-label class="mt-4" :for="`setting_name_{index}`" value="Name" />
            <jet-input :id="`setting_name_${index}`" type="text" class="mt-1 block w-full" :value="settingName" @input="$emit('update:settingName', $event.target.value)"  required />
        </div>
        <div>
            <jet-label class="mt-4" :for="`settings_name_{index}`" value="Value" />
            <jet-input :id="`settings_value_${index}`" type="text" class="mt-1 block w-full" :value="settingValue" @input="$emit('update:settingValue', $event.target.value)"  required />
        </div>
        <div class="grid grid-row">
            <jet-label :for="`settings_description_{index}`" value="Description" />
            <textarea :id="`settings_description_${index}`" :value="settingDescription" @input="$emit('update:settingDescription', $event.target.value)" />
        </div>
    </div>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'

export default {
    components: {
        JetInput,
        JetLabel
    },
    props: [
        'index',
        'settingName',
        'settingValue',
        'settingDescription',
    ],
}
</script>

<style>

</style>
