<template>
    <div class="footer-img">
        <div class="pt-bg" style="background-image: url(/images/footer-img.png)" alt="open road leading to mountains"/>
    </div>
</template>

<style lang="scss" scoped>
@import '../../scss/_mixins.scss';

.footer-img {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 45vh;
    pointer-events: none;
    z-index: -3;

        &:after {
        content: '';
        background: url('/images/footer-img-top.png') no-repeat;
        background-size: 100% auto;
        height: 150px;
        width: 100%;
        position: absolute;
        top: -123px;
        left: 0;
        display: block;
        background-position: bottom center;
        z-index: -2;
        filter: drop-shadow(4px 16px 6px #0c26549a);
    
        @mixin bp-between($bp-md, $bp-lg) {
            top: -85px;
        }

        @include bp-up($bp-lg + 1) {
            top: -30px;
        }
    }


    .pt-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: var(--mm-overlay);
        }
    }
}
</style>

<script>
import '../../scss/_mixins.scss'; 

     export default {
        name: "TestimonialCard",

}
</script>
