<template>
    <app-layout title="Dashboard - Manage User">
        <template #header>
        </template>
        <Card
        title='User Information'
        >
            <div>
                <Teleport to="body"><FlashMessage /></Teleport>
                <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                    BACK
                </JetButton>
            </div>
            <jet-form-section @submitted="updateUserInformation">
                <template #description>
                    <span v-if="isModify" class="font-medium">Update member account profile information. </span>
                    <span v-else class="font-medium">Add member account profile information.</span>
                </template>

                <template #form @submit.prevent="form.post('/update_user')" >
                    <jet-input id="user_id" type="hidden" name="user_id" v-model="form.id" />
                    <!-- Member Info -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <jet-label for="name" value="Name" />
                            <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" />
                            <jet-input-error :message="form.errors.name" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="username" value="Username" />
                            <jet-input id="username" type="text" class="mt-1 block w-full" v-model="form.username" />
                            <jet-input-error :message="form.errors.username" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <jet-label for="current_team" value="User Role" />
                            <select v-model="form.current_team" class="border py-2 px-3 text-grey-800 w-full">
                                <option
                                    v-for="option in user_type_list"
                                    :key="option.value"
                                    :value="option.id"
                                >{{option.name}}</option>
                            </select>
                            <jet-input-error :message="form.errors.current_team" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="email" value="Email" />
                            <jet-input id="email" type="text" class="mt-1 block w-full" v-model="form.email" />
                            <jet-input-error :message="form.errors.email" class="mt-2" />
                        </div>
                        <div v-if="isModify">
                            <jet-label for="last_logged_in" value="Last Logged In" />
                            <jet-input id="last_logged_in" type="text" class="mt-1 block w-full" v-model="form.last_logged_in" disabled />
                            <jet-input-error :message="form.errors.last_logged_in" class="mt-2" />
                        </div>
                    </div>
                </template>
                <template #actions>
                    <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                        Member Updated.
                    </jet-action-message>
                    <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                        Member Added.
                    </jet-action-message>

                    <jet-button v-if="isModify" @click="updateUserInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Save
                    </jet-button>
                    <jet-button v-else @click="addMemberInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Save
                    </jet-button>
                </template>
            </jet-form-section>
            <AuditLogsTable
                :data="logs"
                v-if="isModify"
            />
        </Card>

    </app-layout>
</template>


<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../DashboardPartials/FlashMessages'
    import Card from '@/Components/Card.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import AuditLogsTable from './Tables/UserAuditLogsTable.vue'

    export default {
        mixins: [formattingMixin],
        components: {
            AppLayout,
            JetApplicationLogo,
            MemberHeader,
            FlashMessage,
            Card,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            AuditLogsTable,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    name: '',
                    username: '',
                    current_team: '',
                    email: '',
                    last_logged_in: '',
                }),
                isModify: false,
                logs: [],
                user_type_list: [],
            }
        },
        methods: {
            fetchUserData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var userId = parseInt(last_segment);
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.users.fetch_user_data'),
                    data: {
                        user: userId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        if(key != 'logs'){
                            this.form[key] = value;
                        }
                        this.logs = response.data.data.logs;
                    });
                })
            },
            updateUserInformation() {
                this.form.post(route('dashboard.users.update'), {
                    errorBag: 'addModifyUser',
                    preserveScroll: true,
                });
            },
            addUser() {
                this.form.post(route('dashboard.users.add'), {
                    errorBag: 'addModifyUser',
                    preserveScroll: true,
                });
            },
            checkModify() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var userId = parseInt(last_segment);

                if(Number.isNaN(userId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.userId = userId;
                    this.fetchUserData();
                }
            },
            fetchUserTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.users.fetch_user_types'),
                })
                .then(response => {
                    this.user_type_list = response.data.data;
                })
            },
            back() {
                window.history.back();
            },
        },
        mounted() {
            this.checkModify();
            this.fetchUserTypes();
        },
    }
</script>
