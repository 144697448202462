<template>
    <app-layout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Submitted Form">
            <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                BACK
            </JetButton>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div>
                <jet-label for="first_name" value="First Name" />
                <jet-input id="first_name" type="text" class="mt-1 block w-full" name="first_name" readonly v-model="form.first_name" />
                <jet-input-error :message="form.errors.first_name" class="mt-2" />
            </div>

            <div>
                <jet-label for="last_name" value="Last Name" />
                <jet-input id="last_name" type="text" class="mt-1 block w-full" name="last_name" readonly v-model="form.last_name" />
                <jet-input-error :message="form.errors.last_name" class="mt-2" />
            </div>
            <div>
                <jet-label for="phone" value="Phone" />
                <jet-input id="phone" type="text" class="mt-1 block w-full" name="phone" readonly  v-model="form.phone" />
                <jet-input-error :message="form.errors.phone" class="mt-2" />
            </div>

            <div>
                <jet-label for="email" value="Email" />
                <jet-input id="email" type="email" class="mt-1 block w-full" name="email" readonly v-model="form.email" />
                <jet-input-error :message="form.errors.email" class="mt-2" />
            </div>
        </div>
            <div>
                <jet-label for="message" value="Message" />
                <textarea name="message" id="message" type="textarea" class="mt-1 block w-full" readonly v-model="form.message" />
                <jet-input-error :message="form.errors.message" class="mt-2" />
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import FormSubmissionsTable from './Tables/FormSubmissionsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import TextArea from '@/Components/FormElements/TextArea.vue'
    import Card from '@/Components/Card.vue'

    export default defineComponent({
        components: {
            AppLayout,
            FormSubmissionsTable,
            Link,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            TextArea,
            JetLabel,
            Card,
        },
        data() {
            return {
                form: this.$inertia.form({
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    message: '',
                }),
                submissionId: '',
            }
        },
        methods: {
            fetchForm() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var submissionId = parseInt(last_segment);
                this.submissionId = submissionId;
                axios({
                    method: 'post',
                    url: route('dashboard.forms.fetch_submission'),
                    data: {
                        submission: this.submissionId
                    }
                })
                .then(response => {
                    Object.entries(response.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            back() {
              window.history.back();
            },
        },
        mounted() {
            this.fetchForm();
        }
    })
</script>
