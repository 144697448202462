<template>
    <AppLayout title="Dashboard">
        <template #header>
        </template>

        <Card
            title="Add Tow Step One - Choose Member Info"
        >
            <div>
                <strong class="text-red-700" v-if="$page.props.user.current_team.name == 'Dealer'">
                    If this is a roadside emergency, please call 1-800-234-1353 to be connected with our dispatch team and request a tow.
                </strong>
                <Teleport to="body"><FlashMessage /></Teleport>
                <div class="top-buttons">
                    <jet-button class="button button-primary md:w-auto w-full m-1" @click="back">
                        BACK
                    </jet-button>
                    <Link
                        href="/dashboard/tow-requests/add_member"
                        as="button"
                        v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff'"
                        type="button"
                        style="font-weight: 600;"
                        >
                        <jetButton class="m-1 md:w-auto w-full">
                            ADD MEMBER
                        </jetButton>
                    </Link>
                    <jet-secondary-button class="m-1 md:w-auto w-full" @click="addOrphanTow"
                        v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff' || $page.props.user.current_team.name == 'CSR'"
                    >
                        ADD ORPHAN
                    </jet-secondary-button>
                </div>
            </div>
            <MembershipsTable />
            <!-- Add Orphan Tow Modal -->
            <jet-dialog-modal :show="addOrphan" @close="closeModal">
                <template #title>
                    <h4 class="text-blue-500 mb-0">Add Orphan Tow</h4>
                </template>

                <template #content class="mt-0">
                    <jet-form-section @submitted="submitTowRequest">
                        <template #form @submit.prevent="submit" >
                            <div class="bg-gray-150 p-5 mb-4">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <jet-label for="first_name">First Name <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" />
                                        <jet-input-error :message="form.errors.first_name" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="last_name">Last Name <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" />
                                        <jet-input-error :message="form.errors.last_name" class="mt-2" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <jet-label for="member_type">Member Type <span class="text-red-700"> *</span></jet-label>
                                        <select v-model="form.member_type" class="border py-2 px-3 text-grey-800 w-full">
                                            <option
                                                v-for="option in member_type_list"
                                                :key="option.value"
                                                :value="option.value"
                                            >{{option.name}}</option>
                                        </select>
                                        <jet-input-error :message="form.errors.member_type" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="plan_type"  >Plan Type <span class="text-red-700"> *</span></jet-label>
                                        <select id="plan_type" v-model="form.plan_type" class="border py-2 px-3 text-grey-800 w-full">
                                            <option
                                                v-for="option in planTypeList"
                                                :key="option.value"
                                                :value="option.value"
                                            >{{option.name}}</option>
                                        </select>
                                        <jet-input-error :message="form.errors.plan_type" class="mt-2" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

                                    <div>
                                        <jet-label for="motorcycle_make">Make <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="motorcycle_make" type="text" class="mt-1 block w-full" v-model="form.bike_make"  required />
                                        <jet-input-error :message="form.errors.bike_make" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="motorcycle_model">Model <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="motorcycle_model" type="text" class="mt-1 block w-full" v-model="form.bike_model"  required />
                                        <jet-input-error :message="form.errors.bike_model" class="mt-2" />

                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">

                                    <div>
                                        <jet-label for="motorcycle_year">Year <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="motorcycle_year" type="number" class="mt-1 block w-full" v-model="form.bike_year" required />
                                        <jet-input-error :message="form.errors.bike_year" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="motorcycle_color" value="Color" />
                                        <jet-input id="motorcycle_color" type="text" class="mt-1 block w-full" v-model="form.bike_color" />
                                        <jet-input-error :message="form.errors.bike_color" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="motorcycle_plate">Plate Number</jet-label>
                                        <jet-input id="motorcycle_plate" type="text" class="mt-1 block w-full" v-model="form.bike_plate" required />
                                        <jet-input-error :message="form.errors.bike_plate" class="mt-2" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <jet-label for="motorcycle_state" value="State/Province" />
                                        <select id="motorcycle_state" class="mt-1 block w-full" v-model="form.bike_state" >
                                            <option value=""> -- </option>
                                            <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                                        </select>
                                        <jet-input-error :message="form.errors.bike_state" class="mt-2" />
                                    </div>
                                    <div>
                                        <jet-label for="motorcycle_country" value="Country" />
                                        <select id="motorcycle_country" class="mt-1 block w-full" v-model="form.bike_country" >
                                            <option value=""> -- </option>
                                            <option v-for='data in motorcycle_countries' :value='data' :key="data">{{ data }}</option>
                                        </select>
                                        <jet-input-error :message="form.errors.bike_country" class="mt-2" />
                                    </div>
                                </div>
                                    <div>
                                        <jet-label for="motorcycle_vin">VIN <span class="text-red-700"> *</span></jet-label>
                                        <jet-input id="motorcycle_vin" type="text" class="mt-1 block w-full" v-model="form.bike_vin" required />
                                        <jet-input-error :message="form.errors.bike_vin" class="mt-2" />
                                    </div>
                            </div>
                        </template>
                    </jet-form-section>
                </template>
                <template #footer>
                    <jet-danger-button @click="closeModal">
                        Cancel
                    </jet-danger-button>

                    <jet-button class="ml-2" @click="submitOrphanTow()" >
                        Add Tow
                    </jet-button>
                </template>
            </jet-dialog-modal>
        </Card>
    </AppLayout>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import MembershipsTable from './Tables/MembershipsTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../DashboardPartials/FlashMessages'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue';
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import { Link } from '@inertiajs/inertia-vue3'

    export default ({
        mixins: [formattingMixin],
        components: {
            JetApplicationLogo,
            JetButton,
            JetSecondaryButton,
            JetDangerButton,
            JetInput,
            JetInputError,
            JetLabel,
            MemberHeader,
            MembershipsTable,
            FlashMessage,
            AppLayout,
            Card,
            Link,
            JetDialogModal,
            JetFormSection,

        },
        data () {
            return  {
                form: this.$inertia.form({
                    _method: 'POST',
                    first_name: '',
                    last_name: '',
                    member_type: '',
                    plan_type: '',
                    bike_year: '',
                    bike_make: '',
                    bike_model: '',
                    bike_plate: '',
                    bike_vin: '',
                    bike_state: '',
                    bike_country: '',
                    bike_color: '',
                }),
                addOrphan: false,
                member_type_list: [],
                plan_type_list: [],
                motorcycle_countries: ['USA', 'Canada'],
                states: [],
            }
        },
        methods: {
            back() {
                window.location.replace('/dashboard/tow-requests');
            },
            addOrphanTow() {
                this.fetchMemberTypes();
                this.fetchPlanTypes();
                this.getStates();
                this.addOrphan = true;
            },
            closeModal: function () {
                this.addOrphan = false;
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchPlanTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_plan_type_list'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            submitOrphanTow() {
                this.form.post(route('dashboard.tow_requests.add_orphan'), {
                    errorBag: 'addOrphan',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.closeModal();
                    }
                });
            }
        },
        computed: {
            planTypeList() {
                // Reduce the plan type to the items associated to the selected member type
                var result = this.plan_type_list.filter(obj => {
                    return obj.plan_type == this.form.member_type
                })

                if (result.length == 1) // Only 1 option, lets go ahead and select it
                {
                    this.form.plan_type = result[0].value;
                }
                return result;
            }
        },
    })
</script>
