<template>
    <app-layout title="Dashboard - Imports">
        <template #header>
        </template>
        <div>
            <Teleport to="body"><FlashMessage /></Teleport>
        </div>
        <Card 
            title="Imports">
            <imports-form />
        </Card>
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import ImportsForm from './Forms/ImportForm.vue'
    import MemberHeader from '../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import Card from '@/Components/Card.vue'

    export default ({
        mixins: [formattingMixin],
        components: {
            AppLayout,
            formattingMixin,
            Link,
            ImportsForm,
            MemberHeader,
            FlashMessage,
            Card,
        },
        data() {
            return {
            }
        },
    })
</script>