<template>
    <Head title="Two-factor Confirmation" />
    <FrontEndLayout title="Two-Factor Confirmation">
        <template #header>
            <h1>Two-factor Confirmation</h1>
        </template>
        <template #fepage-content>
            <div class="container w-4/5 md:w-1/2">
                <Card
                    title="Two-Factor Confirmation">

                    <div class="mb-4 text-sm text-gray-600">
                        <template v-if="! recovery">
                            Please confirm access to your account by entering the authentication code provided by your authenticator application.
                        </template>

                        <template v-else>
                            Please confirm access to your account by entering one of your emergency recovery codes.
                        </template>
                    </div>

                    <jet-validation-errors class="mb-4" />

                    <form @submit.prevent="submit">
                        <div v-if="! recovery">
                            <jet-label for="code" value="Code" />
                            <jet-input ref="code" id="code" type="text" inputmode="numeric" class="mt-1 block w-full" v-model="form.code" autofocus autocomplete="one-time-code" />
                        </div>

                        <div v-else>
                            <jet-label for="recovery_code" value="Recovery Code" />
                            <jet-input ref="recovery_code" id="recovery_code" type="text" class="mt-1 block w-full" v-model="form.recovery_code" autocomplete="one-time-code" />
                        </div>

                        <div class="flex items-center justify-end mt-4">
                            <button type="button" class="text-sm text-gray-600 hover:text-gray-900 underline cursor-pointer" @click.prevent="toggleRecovery">
                                <template v-if="! recovery">
                                    Use a recovery code
                                </template>

                                <template v-else>
                                    Use an authentication code
                                </template>
                            </button>

                            <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Log in
                            </jet-button>
                        </div>
                    </form>
                </Card>
            </div>
        </template>
    </FrontEndLayout>
</template>

<script>
    import { defineComponent } from 'vue';
    import { Head } from '@inertiajs/inertia-vue3';
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import Card from '../../Components/Card.vue';

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetLabel,
            JetValidationErrors,
            FrontEndLayout,
            Card,
        },

        data() {
            return {
                recovery: false,
                form: this.$inertia.form({
                    code: '',
                    recovery_code: '',
                })
            }
        },

        methods: {
            toggleRecovery() {
                this.recovery ^= true

                this.$nextTick(() => {
                    if (this.recovery) {
                        this.$refs.recovery_code.focus()
                        this.form.code = '';
                    } else {
                        this.$refs.code.focus()
                        this.form.recovery_code = ''
                    }
                })
            },

            submit() {
                this.form.post(this.route('two-factor.login'))
            }
        }
    })
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .container {
        position: relative!important;
        top: calc(var(--header-size) + 30px )!important;
    }

</style>
