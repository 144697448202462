<template>
    <app-layout title="Dashboard - Coupon Codes">
        <template #header>
        </template>
        <Card
            title="Coupon Codes"
        >
                    <Link
                        href="/dashboard/motorists/coupon_codes/add_coupon_code"
                        as="button"
                        type="button"
                        >
                    <JetButton
                        class="button button-primary absolute top-4 right-4"
                    >
                        Add Coupon Code
                    </JetButton>
                </Link>
                <div v-if="coupon_codes.length == 0">
                    <VueLoader />
                </div>
                <div v-else>
                    <CouponCodesTable
                        :data="coupon_codes"
                        @row-deleted="rowDeleted($event)"
                        ref="couponCodeTable"
                        :searchable-data="['coupon_code', 'description', 'date_start', 'date_end', 'is_active']"
                    />
                </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import CouponCodesTable from '../../Motorists/CouponCodes/Tables/CouponCodesTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            CouponCodesTable,
            Link,
            Card,
            JetButton,
            VueLoader,
        },
        data() {
            return {
                coupon_codes:[],
            }
        },
        methods: {
            fetchCouponCodes() {
                axios
                    .get(route('dashboard.motorists.coupon_codes.fetch_coupon_codes'))
                    .then(response => {
                        this.coupon_codes = response.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/motorists/coupon_codes/delete_coupon_code/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchCouponCodes();
                        this.$refs.couponCodeTable.closeModal();
                    }
                })
            }
        },
        mounted() {
            this.fetchCouponCodes();
        }
    })
</script>
