<template>
    <TowUpdateLayout title="Tow Update">
        <template #head>
            <meta name="description" content="Tow Update">
            <meta name="keywords" content="Tow Update">
        </template>
        <template #header>
            <h1>Tow Update</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container">
                <div v-if="error">
                    <h2>There was an error with your link. Please contact your dispatcher at <strong>(800) 999-7064</strong>.</h2>
                </div>
                <div v-else>
                    <div v-if="!successful && !loading">
                        <div v-if="lat == 0 && lng == 0">
                            <h2 class="font-bold text-navy-500 mb-2">Hello {{ tow_data.person_calling }}, We need your permission to locate you</h2>
                            <p>Please click the button below so that we can locate you using your phone's GPS.</p>

                            <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <jet-button class="mt-4" @click="retrieveCoordinates()">
                                        Use My GPS
                                    </jet-button>
                                </div>
                            </div>
                        </div>
                        <div class="w-100">
                            <div v-if="lat != 0 && lng != 0" class="grids">
                                <Map
                                    ref="googleMap"
                                    :lat="lat"
                                    :lng="lng"
                                    :uuid="tow_uuid"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!loading" >
                        <h2 class="font-bold text-navy-500 mb-2">Thank you{{ tow_data.person_calling != '' ? ' ' + tow_data.person_calling + '.' : '.' }} A towing company has been given your direct coordinates and will be on their way shortly.</h2>
                    </div>
                </div>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap class="hidden md:block"/>
        </template>
    </TowUpdateLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import TowUpdateLayout from '@/Layouts/TowUpdateLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import FlashMessage from '../DashboardPartials/FlashMessages.vue'
    import { Head } from '@inertiajs/inertia-vue3'
    import Map from './Partials/Map.vue'


    export default defineComponent({
        components: {
            Head,
            TowUpdateLayout,
            FooterGap,
            JetButton,
            FlashMessage,
            FooterGap,
            Map,
        },
        data() {
            return {
                tow_uuid: 0,
                tow_data: [],
                lat: 0,
                lng: 0,
                successful: false,
                error: false,
                loading: true,
            }
        },
        methods: {
            fetchTow() {
                axios({
                    method: 'post',
                    url: route('.uuid_tow_fetch'),
                    data: {
                        uuid: this.tow_uuid
                    }
                })
                .then(response => {
                    if(response.data == 'error'){
                        this.error = true;
                    } else {
                        this.tow_data = response.data;
                        this.loading = false;
                    }

                })
            },
            getUuid() {
                var segment_str = window.location.search; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '&' );
                var uuid = segment_array[0].replace('?uuid=', '');
                this.tow_uuid = uuid;
                this.fetchTow();
            },
            retrieveCoordinates() {
                navigator.geolocation.getCurrentPosition(position => {
                    const { latitude, longitude } = position.coords;
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                });

            },
            checkSuccess() {
                let params = (new URL(document.location)).searchParams;
                let isSuccess = params.get("success");
                if(isSuccess){
                    this.successful = true;
                }
            }
        },
        mounted() {
            let vm = this;
            this.getUuid();
            this.checkSuccess();
        }
    })
</script>
