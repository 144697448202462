<template>
    <JetSectionTitle>
        <template #title><slot name="title"></slot></template>
        <template #description><slot name="description"></slot></template>
    </JetSectionTitle>

    <div class="mt-5 md:mt-0">
        <form @submit.prevent="$emit('submitted')">
            <div class="px-4 py-5"
                :class="hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md'">
                    <slot name="form"></slot>
            </div>

            <div class="flex items-center justify-end px-4 py-3 text-right sm:px-6" v-if="hasActions">
                <slot name="actions"></slot>
            </div>
        </form>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'
    import JetSectionTitle from './SectionTitle.vue'

    export default defineComponent({
        emits: ['submitted'],

        components: {
            JetSectionTitle,
        },

        computed: {
            hasActions() {
                return !! this.$slots.actions
            }
        }
    })
</script>
