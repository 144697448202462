<template>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!members.isLoading">
        <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
                <input
                    class="st-search"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <div>
                    <jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton></div>
        </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="members.sortClass('membership_number')" @click="members.sortBy('membership_number')">Membership #</p>
            <p :class="members.sortClass('motorist')" @click="members.sortBy('first_name')" >Motorist</p>
            <p :class="members.sortClass('member_type')" @click="members.sortBy('member_type')">Member Type</p>
            <p :class="members.sortClass('plan_type')" @click="members.sortBy('plan_type')">Plan Type</p>
            <p :class="members.sortClass('city_state')" @click="members.sortBy('city_state')">Location</p>
            <p :class="members.sortClass('status')" @click="members.sortBy('status')">Status</p>
</div>
        <div class="simple-table" style="--cols:6">
            <p class="st-header first-col" :class="members.sortClass('Membership_No')" @click="members.sortBy('membership_number')">Membership #</p>
            <p class="st-header" :class="members.sortClass('motorist')" @click="members.sortBy('first_name')" >Motorist</p>
            <p class="st-header" :class="members.sortClass('member_type')" @click="members.sortBy('member_type')">Member Type</p>
            <p class="st-header" :class="members.sortClass('plan_type')" @click="members.sortBy('plan_type')">Plan Type</p>
            <p class="st-header" :class="members.sortClass('addresses')" @click="members.sortBy('city_state')">Location</p>
            <p class="st-header last-col" :class="members.sortClass('status')" @click="members.sortBy('status')">Status</p>

            <template v-for="(row, index) in members.data" :key="'row' + row.id">
                <p class="first-col cursor-pointer" @click="selectMember(row.id)"><span class="st-hidden">Membership #</span>{{ row.membership_number }}</p>
                <p @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden" >Motorist</span>{{ row.full_name }}</p>
                <p @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden" >Member Type</span>{{ row.member_type }}</p>
                <p @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden" >Plan Type</span>{{ row.plan_type }} </p>
                <p @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden" >Location</span>{{ row.city_state }}</p>
                <p v-if="row.status == 'Expired'" @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden last-col" >Status</span><span style="color: red;">{{ row.status }}</span></p>
                <p v-else-if="row.status == 'Current'" @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden last-col" >Status</span><span style="color: green;">{{ row.status }}</span></p>
                <p v-else @click="selectMember(row.id)" class="cursor-pointer"><span class="st-hidden last-col" >Status</span><span>{{ row.status }}</span></p>
            </template>
        </div>
         <div class="st-bottom">
            <p>{{ members.rangeText }}</p>
            <Pagination
                v-if="members.itemCount || members.itemCount <= members.itemsPerPage"
                :active-page="members.activePage"
                :item-count="members.itemCount"
                :items-per-page="members.itemsPerPage"
                @on-update="members.updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
</template>

<script>
    import { reactive, toRefs } from 'vue'
    import EloquentSSFP from '@/EloquentSSFP'
    import Pagination from '@/Components/Pagination.vue'
    import VueLoader from '@/Components/VueLoader'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import { Link } from '@inertiajs/inertia-vue3'


    export default {
        mixins: [formattingMixin],
        components: {
            JetDangerButton,
            JetButton,
            JetDialogModal,
            Status,
            JetSecondaryButton,
            FlashMessage,
            Link,
            Pagination,
            VueLoader,
        },

        data() {
            return {
                members: reactive(new EloquentSSFP()),
                searchModel: '',
                memberId: null,
            }
        },
        props: ['data'],
        methods: {
            setSearchModel() {
                this.members.searchModel = this.searchModel;
            },
            selectMember(id) {
                this.$inertia.visit('/dashboard/tow-requests/add_tow_requests_step2/' + id);
            },
            applyFilters() {
                let options = {...this.members.options};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;


                this.members.refreshData(options);
            },
        },
        mounted() {
            this.members.init('/dashboard/motorists/memberships/fetch_members_ssfp', {
                searchableData: ['full_name', 'plan_type', 'member_type', 'membership_number', 'alert', 'city_state'],
                itemsPerPage: 50,
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
