<template>
    <div class="contact">
        <h6 class="font-medium text-blue-500">Contact {{ index }}</h6>
        <div>
            <jet-label :for="`contact_name_${index}`" value="Name" />
            <jet-input :id="`contact_name_${index}`" type="text" class="mt-1 block w-full" :value="contactName" @input="$emit('update:contactName', $event.target.value)" required />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
            <div class="col-span-2">
                <jet-label :for="`contact_phone_${index}`" value="Phone" />
                <jet-input :id="`contact_phone_${index}`" type="text" class="mt-1 block w-full" :value="contactPhone" @input="$emit('update:contactPhone', $event.target.value)"  required />
            </div>
            <div>
                <jet-label value="After Hours Contact?" />
                <div class="form-group flex">
                    <jet-label :for="`after_hours_yes_${index}`" value="Yes" />
                    <jet-input :id="`after_hours_yes${index}`" type="radio" :name="`contactAfterHours${index}`" class="mt-1 mx-2 block" value="Yes" :checked="contactAfterHours == 'Yes'" required @input="$emit('update:contactAfterHours', $event.target.value)"/>
                    <jet-label :for="`after_hours_no_${index}`" value="No" />
                    <jet-input :id="`after_hours_no_${index}`" type="radio" :name="`contactAfterHours${index}`" class="mt-1 mx-2 block" value="No" :checked="contactAfterHours == 'No'" required @input="$emit('update:contactAfterHours', $event.target.value)"/>
                </div>
            </div>
        </div>
    </div> <!-- end contact -->
</template>

<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'

export default {
    components: {
        JetInput,
        JetLabel
    },
    props: [
        'index',
        'contactName',
        'contactPhone',
        'contactAfterHours',
    ]
}
</script>

<style>

</style>
