<template>
    <div class="grid grid-cols-3">
        <div v-for="(n, i) in 30" class="startGridRow">
            <div class="col-span-1 border-2 p-2 text-center">
                <jet-label :for="`start_grid${i + 1}`" :value="`${i + 1}`" />
                <jet-input :id="`start_grid${i + 1}`" type="radio" class="my-1 mx-2" :value="`${i + 1}`" name="start_grid" :checked="i == 0" required />
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default defineComponent({
        components: {
            JetInput,
            JetLabel,
        },
    })
</script>