<template>
    <AppLayout title="Dashboard">
        <template #header>
        </template>
        <Card
            title="Exports"
        >
            <h1 class="mb-5">Your export is ready</h1>
            <p><JetButton><a href="/dashboard/back-office/memberships/download_export" target="_blank" download >Download memberships-export.csv</a></JetButton>
            </p>

        </Card>
    </AppLayout>
</template>

<style lang="scss" scoped>
@import '../../../../../scss/mixins';

</style>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'
    import formattingMixin from '@/Mixins/formattingMixin.js'

    export default defineComponent({
        mixins: [formattingMixin],
        components: {
            AppLayout,
            Link,
            Card,
            JetButton,
            VueLoader,
        },
        methods: {

        }
    })
</script>
