 <template>
<FrontEndLayout title="Login">
    <Head title="Forgotten Password" />
    <Teleport to="body"><FlashMessage /></Teleport>
    <template #fepage-content>
        <div class="loginBG">
            <div class="login-box">
                <h2 class="text-white font-bold text-center uppercase">Forgot Password</h2>
                <JetAuthenticationCard
                    color="gray-300"
                    class="w-full mx-auto md:w-1/2 flex flex-col">
                    <template #logo>
                        <jet-authentication-card-logo />
                    </template>

                    <div class="mb-4 font-medium text-navy-300">
                        Forgot your password? No problem. Just let us know your <strong>username</strong> and we will email you a password reset link that will allow you to choose a new one.
                    </div>

                    <div v-if="status" class="mb-4 font-medium text-green">
                        {{ status }}
                    </div>

                    <jet-validation-errors class="mb-4" />

                    <form @submit.prevent="submit">
                        <div>
                            <jet-label for="username" value="Username" />
                            <jet-input id="username" type="username" class="mt-1 block w-full border-1 border-black p-1" v-model="form.username" required autofocus />
                        </div>

                        <div class="flex items-center justify-end mt-4">
                            <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Email Password Reset Link
                            </jet-button>
                        </div>
                    </form>
                </JetAuthenticationCard>
            </div>
            <div class="login-img" style="background-image: url(/images/img-login-bg.png)" alt="twisting road"/>
        </div>
        </template>
    </FrontEndLayout>
</template>

<style lang="scss" scoped>
@import '../../../scss/_mixins.scss';

.loginBG {
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 85vh;
    top: var(--header-size);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -3;

        .login-img {
        position: absolute;
        height: 120vh;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: var(--mm-overlay);
            }
        }
}

.login-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0 1rem;

}
</style>

<script>
    import { defineComponent } from 'vue'
    import { Head } from '@inertiajs/inertia-vue3';
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FlashMessage from '../DashboardPartials/FlashMessages.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetLabel,
            JetValidationErrors,
            FrontEndLayout,
            FlashMessage,
        },

        props: {
            status: String
        },

        data() {
            return {
                form: this.$inertia.form({
                    username: ''
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('.forgot_password'))
            }
        }
    })
</script>
