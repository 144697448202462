<template>
    <jet-form-section>
        <template #description>
            <span v-if="isModify" class="font-medium">Update Coupon Code information. </span>
            <span v-else class="font-medium">Add Coupon Code information.</span>

        </template>
        <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="id" v-if="isModify" type="hidden" name="id" v-model="form.id" />

            <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                <div class="md:col-span-2">
                    <jet-label for="coupon_code" value="Coupon Code" />
                    <jet-input id="coupon_code" type="text" class="mt-1 block w-full" v-model="form.coupon_code" />
                    <jet-input-error :message="form.errors.coupon_code" class="mt-2" />
                </div>
                <div class="md:col-span-2">
                    <jet-label for="date_start" value="Start Date" />
                    <jet-input id="date_start" type="date" class="mt-1 block w-full" v-model="form.date_start" />
                    <jet-input-error :message="form.errors.date_start" class="mt-2" />
                </div>
                <div class="md:col-span-2">
                    <jet-label for="date_end" value="End Date" />
                    <jet-input id="date_end" type="date" class="mt-1 block w-full" v-model="form.date_end" />
                    <jet-input-error :message="form.errors.date_end" class="mt-2" />
                </div>
                <div class="md:col-span-1">
                    <jet-label for="is_active" value="Active" class="mr-2" />
                    <jet-checkbox name="is_active" v-model:checked="isActiveChecked" v-on:click="isActiveClicked($event)" />
                    <jet-input-error :message="form.errors.is_active" class="mt-2" />
                </div>
            
                <div  class="md:col-span-3">
                    <jet-label for="description" value="Description" />
                    <textarea id="description" type="text" class="mt-1 block w-full" v-model="form.description" />
                    <jet-input-error :message="form.errors.description" class="mt-2" />
                </div>
                <div class="md:col-span-1">
                    <jet-label class="mt-4" for="memberType" value="Member Type" />
                    <select id="memberType" class="mt-1 block w-full" v-model="form.plan_type_id" required >
                        <option :value="null"> -- </option>
                        <option v-for='data in member_types' :value='data.id' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.member_type" class="mt-2" />
                </div>
                <div class="md:col-span-2">
                    <jet-label for="prependCode" value="Member ID Prepend Code" />
                    <jet-input id="prependCode" type="text" class="mt-1 block w-full" v-model="form.member_prepend_code" maxlength="50" />
                    <jet-input-error :message="form.errors.member_prepend_code" class="mt-2" />
                </div>
            </div>
            <!-- Plan Pricing -->
            <!-- Leed to get the plan_pricing and iterate through the list while building this section -->
            <!-- We need the following:
                    plan_types->name - Call it plan_type_name
                    plans->code - call it plans_code -->
            <div  v-for="(item, index) in plan_pricing">
                <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                    <div class="col-span-7">
                        <h6 class="text-blue-500 font-bold mt-2">{{ index }}</h6>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-7 gap-4 mt-2" v-for="(item2, index2) in item.plans">
                    <div class="col-span-2">
                        {{ item2.name }}
                    </div>
                    <div class="col-span-1">
                        <jet-label><em>Original Price Per Year:</em></jet-label>
                        ${{ item2.price_1_year }}
                    </div>
                    <div class="col-span-1" v-if="form.coupon_code_to_plan_pricing[index2]">
                        <jet-label><em>Price With Coupon</em></jet-label>
                        <jet-input id="itemValue" type="number" class="mt-1 block w-full" v-model="form.coupon_code_to_plan_pricing[index2].value" @keypress="onlyForCurrency" />
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                <div class="col-span-7">
                    <h6 class="text-blue-500 font-bold mt-2">Plan Options</h6>
                </div>
            </div>
            <div  v-for="(item, index) in plan_options">
                
                <div class="grid grid-cols-1 md:grid-cols-7 gap-4 mt-2">
                    <div class="col-span-2">
                        {{ item.name }}
                    </div>
                    <div class="col-span-1">
                        <jet-label><em>Original Price Per Year:</em></jet-label>
                        ${{ item.price }}
                    </div>
                    <div class="col-span-1" v-if="form.coupon_code_to_plan_options[index]">
                        <jet-label><em>Price With Coupon</em></jet-label>
                        <jet-input id="itemValue" type="number" class="mt-1 block w-full" v-model="form.coupon_code_to_plan_options[index].value" @keypress="onlyForCurrency" />
                    </div>
                </div>
            </div>

        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Coupon Code Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Coupon Code Added.
            </jet-action-message>

            <jet-button @click="addModifyCouponCodeInfo()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetCheckbox,
            JetSecondaryButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    coupon_code: '',
                    description: '',
                    date_start: '',
                    date_end: '',
                    is_active: true,
                    coupon_code_to_plan_pricing: [],
                    coupon_code_to_plan_options: [],
                    member_prepend_code: '',
                    plan_type_id: '',
                }),
                plan_pricing: [],
                plan_options:[],
                isModify: false,
                raw_data: [],
                member_types: [],
            }
        },
        computed: {
            isActiveChecked : function() {
                return this.form.is_active ? true : false;
            },
        },
        methods: {
            fetchCouponCodeToPlanPricingData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var coupon_codeId = parseInt(last_segment);
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.coupon_codes.fetch_coupon_code'),
                    data: {
                        id: coupon_codeId
                    }
                })
                .then(response => {
                    this.raw_data = response.data;

                    Object.entries(response.data).forEach(([key, value]) => {
                        this.form.id = value.id;
                        this.form.coupon_code = value.coupon_code;
                        this.form.description = value.description;
                        this.form.date_start = value.date_start;
                        this.form.date_end = value.date_end;
                        this.form.is_active = value.is_active;
                        this.form.member_prepend_code = value.member_prepend_code;
                        this.form.plan_type_id = value.plan_type_id;

                        Object.entries(value.coupon_code_to_plan_pricing).forEach(([key2, value2]) => {
                            this.form.coupon_code_to_plan_pricing[value2.plan_pricing_id] = {id: value2.plan_pricing_id, value: value2.coupon_price};
                        });
                        
                        Object.entries(value.coupon_code_to_plan_options).forEach(([key2, value2]) => {
                            this.form.coupon_code_to_plan_options[key2] = {id: value2.plan_options_id, value: value2.coupon_price};
                        });
                    });
                })
            },
            addModifyCouponCodeInfo() {
                this.form.post(route('dashboard.motorists.coupon_codes.add_modify_coupon_code'), {
                    errorBag: 'addModifyCouponCode',
                    preserveScroll: true,
                    onSuccess: () => {
                        if (!this.isModify) {
                            window.location.href = '/dashboard/motorists/coupon_codes';
                        }
                    },
                });
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.typeId = typeId;
                    this.fetchCouponCodeToPlanPricingData();
                }
            },
            getPricePlanData()
            {
                // set plan_pricing = to results
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var dealerId = parseInt(last_segment);

                var vm = this;
                axios.get('/motorists-and-rentals/member-registration/getPlans')
                .then(function (response) {
                    vm.plan_pricing = response.data

                    if (!vm.isModify) {
                        //form.coupon_code_to_plan_pricing[index2].value
                        Object.entries(response.data).forEach(([key, value]) => {
                            Object.entries(value.plans).forEach(([key2, value2]) => {
                                vm.form.coupon_code_to_plan_pricing[key2] = {id: value2.id, value: ''};
                            })
                        });
                    }
                })
                .catch(function (response) {
                    console.error(response);
                })

                axios.get('/motorists-and-rentals/member-registration/getPlanOptions')
                .then(function (response) {
                    vm.plan_options = response.data;
                    Object.entries(vm.plan_options).forEach(([key, value]) => {
                        vm.form.coupon_code_to_plan_options[key] = {id: value.id, value: ''};
                    })
            vm.checkModify();
                })
                .catch(function (response) {
                    console.error(response);
                })


            },
            getMemberTypes() {
                var vm = this;
                axios.get(route('dashboard.motorists.coupon_codes.fetch_member_types'))
                .then(function (response) {
                    vm.member_types = response.data;
                });
            },
            onlyForCurrency ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || $event.key != '.')) { // 46 is dot
                    $event.preventDefault();
                }
                if (($event.path[0].value + ' ' + $event.key).split('.').length > 2)
                {
                    $event.preventDefault();
                }

                // restrict to 2 decimal places
                if(($event.path[0].value + '' + $event.key).indexOf(".")>-1 && (($event.path[0].value + '' + $event.key).split('.')[1].length > 2)){
                    $event.preventDefault();
                }
            },
            isActiveClicked : function(event) {
                this.form.is_active = event.target.checked ? 1 : 0;
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
        },
        mounted() {
            this.getPricePlanData();
            this.getMemberTypes();
        },
    }
</script>
