<template>
    <app-layout title="Dashboard - Add Tow Request Step 2">
        <template #header>
        </template>
    <Card
        title="Add Tow Step Two - Select Bike"
    >
        <div>
            <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                BACK
            </JetButton>
        </div>
        <div>
            <strong class="text-red-700" v-if="$page.props.user.current_team.name == 'Member'">
                If this is a roadside emergency, please call 1-800-234-1353 to be connected with our dispatch team and request a tow.
            </strong>
            <Teleport to="body"><FlashMessage /></Teleport>
        </div>
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-150 px-3 py-5 my-4">
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">First Name</h6> {{ members_data.first_name}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Last Name</h6>{{ members_data.last_name}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Terms</h6>{{ members_data.terms}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Account Type</h6> {{ members_data.member_type_name}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Member Number</h6> {{ members_data.member_number}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Expiration Date</h6>{{ expireDate}}</div>
                <div><h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Join Date</h6>{{ joinDate}}</div>
            </div>
            <jet-button @click="showAddMotorcycle()" >
                Add Bike
            </jet-button>
            <div class="memberships-table">
                <BikeTable
                    :data="bikes"
                    @bikes-updated="fetchBikeData()"
                    @bike-selected="bikeSelected"
                />

            </div>
            <div v-if="addMotorcycleVisible === true" class="col-12">
                <form @submit.prevent="form.post('/modify-member')" >
                    <jet-input id="member_id" type="hidden" name="member_id" v-model="form.member_id" />
                    <h3>New Motorcycle</h3>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <jet-label for="make" value="Make" />
                            <jet-input id="make" type="text" class="mt-1 block w-full" v-model="form.make"  required />
                            <jet-input-error :message="form.errors.make" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="model" value="Model" />
                            <jet-input id="model" type="text" class="mt-1 block w-full" v-model="form.model"  required />
                            <jet-input-error :message="form.errors.model" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <jet-label for="year" value="Year" />
                            <jet-input id="year" type="text" class="mt-1 block w-full" v-model="form.year" required />
                            <jet-input-error :message="form.errors.year" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="color" value="Color" />
                            <jet-input id="color" type="text" class="mt-1 block w-full" v-model="form.color" required />
                            <jet-input-error :message="form.errors.color" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="plate" value="Plate Number" />
                            <jet-input id="plate" type="text" class="mt-1 block w-full" v-model="form.plate" required />
                            <jet-input-error :message="form.errors.plate" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                        <jet-label for="state" value="State/Province" />
                            <select id="state" class="mt-1 block w-full" v-model="form.state" required >
                                <option value=""> -- </option>
                                <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                            </select>
                            <jet-input-error :message="form.errors.state" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="country" value="Country" />
                            <jet-input id="country" type="text" class="mt-1 block w-full" v-model="form.country" required />
                            <jet-input-error :message="form.errors.country" class="mt-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div class="md:col-span-4">
                            <jet-label for="vin" value="VIN" />
                            <jet-input id="vin" type="text" class="mt-1 block w-full" v-model="form.vin" required />
                            <jet-input-error :message="form.errors.vin" class="mt-2" />
                        </div>
                        <div class="md:col-span-1">
                            <jet-label value="Trike / Autocycle" />
                            <div class="form-group grid grid-cols-4 gap-4">
                                    <jet-input id="trike_autocycle_yes" type="radio" class="mt-1 block ml-4" v-model="form.trike_autocycle" name="trike_autocycle" @input="form.trike_autocycle = 1" :checked="form.trike_autocycle" required  />
                                    <jet-label for="trike_autocycle_yes" value="Yes" />

                                    <jet-input id="trike_autocycle_no" type="radio" class="mt-1 block ml-4" v-model="form.trike_autocycle" name="trike_autocycle" @input="form.trike_autocycle = 0" :checked="!form.trike_autocycle" required  />
                                    <jet-label for="trike_autocycle_no" value="No" />
                            </div>
                        </div>
                    </div>
                </form>
                <jet-button class="mt-3 flex-end" @click="addTowBike()">
                    Save and go to step 3
                </jet-button>
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import MembershipsTable from './Tables/MembershipsTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../DashboardPartials/FlashMessages'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import BikeTable from './Tables/BikeTable.vue'
    import axios from 'axios'
    import moment from 'moment'
    import Card from '@/Components/Card.vue'

    export default ({
        mixins: [formattingMixin],
        components: {
            JetApplicationLogo,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link,
            MemberHeader,
            MembershipsTable,
            FlashMessage,
            AppLayout,
            BikeTable,
            moment,
            Card,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'POST',
                    id: 0,
                    member_id: 0,
                    make: '',
                    model: '',
                    year: '',
                    color: '',
                    plate: '',
                    state: '',
                    country: '',
                    vin: '',
                    trike_autocycle: 0,
                }),
                members_data:[],
                bikes: [],
                addMotorcycleVisible: false,
                motorcycles: 1,
                states: [],
            }
        },
        methods: {
            fetchMemberData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var memberId = parseInt(last_segment);

                // If the member ID > 0, lets fetch the member data and bike data
                if (Number.isInteger(memberId) && memberId > 0)
                {
                    axios
                        .get('/dashboard/tow-requests/fetch_member_data/' + memberId)
                        .then(response => {
                            // Protect the page from a member trying to change the member id in the url.
                            if (response.data == 'goToDashboard'){
                                location.href = '/dashboard';
                            } else {
                                this.members_data = response.data.data;
                                this.bikes = this.members_data.bikes;
                            }
                        })
                }

                this.form.member_id = memberId;
            },
            fetchStates() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            showAddMotorcycle() {
                this.addMotorcycleVisible = true;
            },
            bikeSelected(id) {
                // Clear Map Cache
                sessionStorage.removeItem('TowRequestShowMap');

                this.$inertia.visit('/dashboard/tow-requests/add_tow_requests_step3/' + this.members_data.id + '/' + id);
            },
            addTowBike() {
                 this.form.post(route('dashboard.tow_requests.add_bike', this.form), {
                    errorBag: 'addBikeForm',
                    preserveScroll: true,
                });
            },
            back() {
              window.history.back();
            },
        },
        computed: {
            expireDate: function() {
                return moment(String(this.members_data.plan_expiration)).format('MM/DD/YYYY');
            },
            joinDate: function() {
                return moment(String(this.members_data.joinDate)).format('MM/DD/YYYY'); 
            }
        },

        mounted() {
            this.fetchMemberData();
            this.fetchStates();
        }
    })
</script>
