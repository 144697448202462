<template>
     <div class="flex-end absolute top-4 right-4">
        <JetButton v-if="!exporting" class="button button-secondary m-1 flex" @click="exportCSV()">Export CSV</JetButton>
        <span v-else="exporting"> Exporting...an email will be sent to you with the download link when finished.</span>
    </div>
</template>


<script>
    import JetButton from '@/Jetstream/Button.vue'

    export default {
        props: ["members", "searchModel", "start_date", "end_date", "member_type", "status"],
        components: {
            JetButton
        },
        data() {
            return {
                exporting: false,
            }
        },
        methods: {
            exportCSV() {
                this.exporting = true;

                axios({
                    method: 'post',
                    url: route('dashboard.back_office.memberships.export'),
                    data: {
                        options: this.members.options,
                        searchModel: this.searchModel,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        member_type: this.member_type,
                        status: this.status
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
        }
    }
</script>
