<template>
    <app-layout title="Dashboard - Add/Edit Coupon Code">
        <template #header>
        </template>

        <Card
            title="Coupon Code Information"
        >
            <div>
                <Teleport to="body"><FlashMessage /></Teleport>
                 <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                    BACK
                </JetButton>
            </div>
            <AddEditCouponCodeForm />
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../../../Pages/DashboardPartials/MemberHeader.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import AddEditCouponCodeForm from '../../Motorists/CouponCodes/Forms/AddEditCouponCodeForm.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'

    export default defineComponent({
        mixins: [formattingMixin],

        components: {
            AppLayout,
            JetApplicationLogo,
            MemberHeader,
            FlashMessage,
            AddEditCouponCodeForm,
            Card,
            JetButton,
        },
        methods: {
            back() {
              window.history.back();
            },
        }
    })
</script>
