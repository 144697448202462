<template>
    <jet-form-section @submitted="addModifyTestimonial">
        <template #description>
        </template>
        <template #form @submit.prevent="form.post('/modify-testimonial')">
            <jet-input v-if="isModify" id="testimonial_uuid" type="hidden" name="testimonial_uuid" v-model="form.testimonial_uuid" />
            <!-- Member Type Info -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="testimonial_type" value="Testimonial Type" />
                    <select id="testimonial_type" v-model="form.testimonial_type" class="border py-2 px-3 text-grey-800 w-full">
                        <option value=""> -- </option>
                        <option v-for='data in testimonial_types'
                            :value='data'
                            :key="data"
                        >{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.testimonial_type" class="mt-2" />
                </div>
                <div>
                    <jet-label for="active" value="Active/Show" />
                    <select id="active" v-model="form.active" class="border py-2 px-3 text-grey-800 w-full">
                        <option value=""> -- </option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <jet-input-error :message="form.errors.active" class="mt-2" />
                </div>
                <div>
                    <jet-label for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" name="city" required v-model="form.city" />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>
                <div>
                    <jet-label for="state" value="State/Province" />
                        <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                            <option value=""> -- </option>
                            <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                        </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="name" value="Name" />
                    <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.submitted_by" />
                    <jet-input-error :message="form.errors.submitted_by" class="mt-2" />
                </div>
                <div>
                    <jet-label for="email" value="Email" />
                    <jet-input id="email" type="text" class="mt-1 block w-full" v-model="form.email" />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
                <div>
                    <jet-label for="phone" value="Phone" />
                    <jet-input id="phone" type="text" class="mt-1 block w-full" v-model="form.phone" />
                    <jet-input-error :message="form.errors.phone" class="mt-2" />
                </div>
                <div>
                    <jet-label for="company" value="Company" />
                    <jet-input id="company" type="text" class="mt-1 block w-full" v-model="form.company" />
                    <jet-input-error :message="form.errors.company" class="mt-2" />
                </div>
                <div v-if="form.image_path != ''">
                    <jet-label for="image_path" value="Image" />
                    <img :src="form.image_path" alt="Current Image" />
                </div>
                <div>
                    <div class="mt-4">
                        <jet-label for="image" value="File: " />
                        <input type="file" name="testimonial_image" @input="form.testimonial_image = $event.target.files[0]" class="form-control" id="image" />
                        <jet-input-error :message="form.errors.testimonial_image" class="mt-2" />
                    </div>
                    <jet-input-error :message="form.errors.image_path" class="mt-2" />
                </div>
                <div>
                    <jet-label for="image_alt" value="Image Alt" />
                    <jet-input id="image_alt" type="text" class="mt-1 block w-full" v-model="form.image_alt" />
                    <jet-input-error :message="form.errors.image_alt" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label for="content" value="Content" />
                <textarea id="content" type="textarea" class="mt-1 block w-full" v-model="form.content" />
                <jet-input-error :message="form.errors.content" class="mt-2" />
            </div>
        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Testimonial Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Testimonial Added.
            </jet-action-message>

            <jet-button  v-if="isModify" @click="updateTestimonial()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Update
            </jet-button>

            <jet-button v-else @click="addModifyTestimonial()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetCheckbox,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    submitted_by: '',
                    city: '',
                    state: '',
                    company: '',
                    phone: '',
                    image_path: '',
                    image_alt: '',
                    email: '',
                    content: '',
                    active: 0,
                    testimonial_type: '',
                    testimonial_image: '',
                }),
                states: [],
                isModify: false,
                typeId: '',
                testimonial_types: ['Motorist', 'Vendor', 'Dealer'],
            }
        },
        methods: {
            addModifyTestimonial() {
                this.form.post(route('dashboard.testimonials.store'), {
                    errorBag: 'testimonialForm',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset('id', 'code', 'terms', 'description'),
                });
            },
            updateTestimonial(event)
            {
                this.form.post(route('dashboard.testimonials.update'),
                {
                    errorBag: 'testimonialForm',
                    preserveScroll: true,
                })
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var testimonialId = parseInt(last_segment);

                if(Number.isNaN(testimonialId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.testimonialId = testimonialId;
                    this.loadTestimonialData();
                }
            },
            loadTestimonialData()
            {
                axios({
                    method: 'post',
                    url: route('dashboard.testimonials.fetch_testimonial_data'),
                    data: {
                        id: this.testimonialId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        this.form[key] = value;
                    });
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
        },
        mounted() {
            this.checkModify();
            this.getStates();
        }
    }
</script>
