<template>
    <AdminDash v-if="$page.props.user.current_team.name == 'Admin'" />
    <OfficeDash v-if="$page.props.user.current_team.name == 'Office Staff'" />
    <DispatchDash v-if="$page.props.user.current_team.name == 'CSR'" />
    <MemberDash v-if="$page.props.user.current_team.name == 'Member'"
        :member_id = $page.props.member.member.member_id
    />
    <DealerDash v-if="$page.props.user.current_team.name == 'Dealer'"
        :dealer_id = $page.props.dealer.dealer.dealer_id
    />
</template>

<script>
    import { defineComponent } from 'vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import AdminDash from '@/Components/Dashboards/AdminDash.vue'
    import OfficeDash from '@/Components/Dashboards/OfficeDash.vue'
    import DispatchDash from '@/Components/Dashboards/DispatchDash.vue'
    import MemberDash from '@/Components/Dashboards/MemberDash.vue'
    import DealerDash from '@/Components/Dashboards/DealerDash.vue'

    export default defineComponent({
        components: {
            JetApplicationLogo,
            AdminDash,
            OfficeDash,
            DispatchDash,
            MemberDash,
            DealerDash,
        },
    })
</script>
