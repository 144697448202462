<template>
<FrontEndLayout title="Login">
    <Head title="Confirm Password" />
    <template #fepage-content>
        <div class="loginBG">
            <div class="login-box">
                <h2 class="text-white font-bold text-center uppercase">Confirm Password</h2>
                <JetAuthenticationCard
                    color="gray-300">
                    <template #logo>
                        <jet-authentication-card-logo />
                    </template>

                    <div class="mb-4 text-sm text-gray-600">
                        This is a secure area of the application. Please confirm your password before continuing.
                    </div>

                    <jet-validation-errors class="mb-4" />

                    <form @submit.prevent="submit">
                        <div>
                            <jet-label for="password" value="Password" />
                            <jet-input id="password" type="password" class="mt-1 block w-full" v-model="form.password" required autocomplete="current-password" autofocus />
                        </div>

                        <div class="flex justify-end mt-4">
                            <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Confirm
                            </jet-button>
                        </div>
                    </form>
                </JetAuthenticationCard>
            </div>
            <div class="login-img" style="background-image: url(/images/img-login-bg.png)" alt="twisting road"/>
        </div>
        </template>
    </FrontEndLayout>
</template>

<style lang="scss" scoped>
@import '../../../scss/_mixins.scss';

.loginBG {
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 85vh;
    top: var(--header-size);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -3;

        .login-img {
        position: absolute;
        height: 120vh;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: var(--mm-overlay);
            }
        }
}

.login-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0 1rem;

}
</style>

<script>
    import { defineComponent } from 'vue';
    import { Head } from '@inertiajs/inertia-vue3';
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetLabel,
            JetValidationErrors,
            FrontEndLayout,
        },

        data() {
            return {
                form: this.$inertia.form({
                    password: '',
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('password.confirm'), {
                    onFinish: () => this.form.reset(),
                })
            }
        }
    })
</script>
