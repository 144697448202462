<template>
    <FrontEndLayout title="Contact Us">
        <template #head>
            <meta name="description" content="Contact MTS Towing">
            <meta name="keywords" content="contact form">
        </template>
        <template #header>
            <h1>CONTACT US</h1>
                <Teleport to="body"><Teleport to="body"><FlashMessage /></Teleport></Teleport>
        </template>
        <template #fepage-content>
            <div class="container mt-5">
            <h2 class="text-center font-bold text-navy-500 mb-10">Connect With Our Team!</h2>
                <p class="text-center mb-5">Motorcycle Towing Service’s team is ready to provide assistance to you whenever you’re in need. Please give us a call whenever you need or fill out the contact form below. Our team will review your form submission and contact you as soon as possible.</p>
                <form @submit.prevent="submit" id="contactForm">
                    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                        <jet-label for="first_name" value="First Name" />
                        <jet-input id="first_name" type="text" class="mt-1 block w-full" name="first_name" required v-model="form.first_name" />
                        <jet-input-error :message="form.errors.first_name" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="last_name" value="Last Name" />
                        <jet-input id="last_name" type="text" class="mt-1 block w-full" name="last_name" required v-model="form.last_name" />
                        <jet-input-error :message="form.errors.last_name" class="mt-2" />
                    </div>
                    <div>
                        <vue-tel-input id="phone" label="Phone" v-model="form.phone" :error-message="form.errors.phone" />
                    </div>

                    <div>
                        <jet-label for="email" value="Email" />
                        <jet-input id="email" type="email" class="mt-1 block w-full" name="email" required v-model="form.email" />
                        <jet-input-error :message="form.errors.email" class="mt-2" />
                    </div>
                </div>
                    <div>
                        <jet-label for="message" value="Message" />
                        <textarea name="message" id="message" type="textarea" class="mt-1 block w-full" v-model="form.message" maxlength="500" rows="4" />
                        <p class="small">{{form.message.length}} /500 </p>
                        <jet-input-error :message="form.errors.message" class="mt-2" />
                    </div>
                    <div class="flex items-center justify-end mt-4">
                        <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Send
                        </jet-button>
                    </div>
                </form>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FlashMessage from '../DashboardPartials/FlashMessages.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import TextArea from '@/Components/FormElements/TextArea.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FooterGap from '@/Components/FooterGap.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            TextArea,
            JetLabel,
            FrontEndLayout,
            FlashMessage,
            FooterGap,
            VueTelInput,
        },
        data() {
            return {
                form: this.$inertia.form({
                    form_id: 1,
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    message: '',
                    terms: false,
                }),
            }
        },
        methods: {
            submit() {
                this.form.post(route('.contact_us.store'), {
                    errorBag: 'contactUs',
                    preserveScroll: true,
                    onSuccess: () => this.form.reset(),
                });
            },
            setRequired() {
                const membershipForm = document.getElementById("contactForm");
                const inputs         = membershipForm.querySelectorAll("[required]");
                inputs.forEach(input => {
                    let id = input.id;
                    let label = input.closest('label');
                    if (id) label = membershipForm.querySelector(`label[for="${input.id}"]`)
                    let span = document.createElement('span');
                    span.classList.add('text-red-700');
                    span.innerHTML = " *";
                    if (label) label.appendChild(span)
                })
            }
        },
        mounted() {
            this.setRequired()
        }
    });
</script>
