<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div class="towing-companies-table">
        <TowingCompaniesTable />
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import TowingCompaniesTable from './Tables/TowingCompaniesTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../DashboardPartials/FlashMessages'

    export default ({
        mixins: [formattingMixin],
        components: {
            JetApplicationLogo,
            MemberHeader,
            TowingCompaniesTable,
            FlashMessage,
        },
        data() {
            return {
                towing_companies_data:[],
            }
        },
    })
</script>
