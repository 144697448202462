<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('service_date')" @click="sortBy('service_date', 'number')">Service Date</p>
            <p :class="sortClass('problem')" @click="sortBy('problem')">Problem</p>
            <p :class="sortClass('towing_company')" @click="sortBy('towing_company')">Towing Company</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('service_date')" @click="sortBy('service_date','number')">Service Date</p>
            <p class="st-header" :class="sortClass('problem')" @click="sortBy('problem')" >Problem</p>
            <p class="st-header" :class="sortClass('towing_company')" @click="sortBy('towing_company')">Towing Co</p>
            <p class="st-header last-col"><span v-if="showActions">Actions</span></p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Service Date</span>{{ row.service_date }}</p>
                <p ><span class="st-hidden">Problem</span>{{ row.problem }}</p>
                <p><span class="st-hidden">Towing Company</span>{{ row.towing_company }}</p>
                <div class="last-col">
                    <span class="st-hidden" v-if="showActions">Actions</span>
                    <div class="st-buttons" v-if="showActions">
                        <button @click="setActiveDropdown(row.id)">Edit</button>
                        <jet-danger-button @click="confirmTowDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
                <TowTableDropdown
                    v-bind="dropdownBinder(row, row.id)"
                    :bikes="bikes"
                    @tows-updated="towsUpdated()" v-if="showActions"
                />
            </template>
        </div>
    </TableWrapper>
</template>

<script>
    import TowTableDropdown from './TowTableDropdown.vue';
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            TowTableDropdown,
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link
        },
        props: ['tows', 'bikes'],
        data() {
            return {
                towForm: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    service_date: '',
                    service_time: '',
                    problem: '',
                    problem_type: '',
                    cod: '',
                    break_down_location: '',
                    towing_company: '',
                    notes: '',
                    dealer_name: '',
                    dealer_location: '',
                    code: '',
                    po: '',
                    rental_agreement: '',
                    invoice_date: '',
                    amount_invoiced: '',
                    date_paid: '',
                    check_number: '',
                }),
                showActions: false,
            }
        },
        methods: {
            towsUpdated() {
                this.$emit('tows-updated')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>