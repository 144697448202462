<template>
    <div class="p-6 sm:px-20 bg-white border-b border-gray-200">
        <div class="mt-8 text-2xl" v-if="$page.props.user.current_team.name == 'Dealer'" >
            Hello, {{ $page.props.webmaster === null ? $page.props.user.name : $page.props.webmaster.fullName }} ( {{ $page.props.dealer.dealer.dealer_name != '' ? $page.props.dealer.dealer.dealer_name : '' }} )
        </div>
        <div v-else class="mt-8 text-2xl">
            Hello, {{ $page.props.webmaster === null ? $page.props.user.name : $page.props.webmaster.fullName }} ( {{ $page.props.user.current_team ? $page.props.user.current_team.name : '' }} )
        </div>
        <p>Last Visited: {{ $page.props.user.last_logged_in }}</p>
    </div>
</template>