<template>
    <FrontEndLayout title="Motorist Rentals">
        <template #head>
            <meta name="description" content="About MTS Towing">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>RENTAL SERVICES</h1>
        </template>
        <template #fepage-content>
            <div class="container mx-5">
                <h2 class="font-bold text-navy-500 text-center">The Benefits You Enjoy as Rental customer</h2>
                <div class="grid grid-cols-1 md:grid-cols-3 mx-5 mt-4 items-center justify-items-center">
                    <div class="col-span-2">
                <h5 class="text-blue-500">MTS knows that you love to ride anytime you get the chance. But, what about: </h5>
                    <ul class="red-check list-outside mx-auto">
                        <li>If you’re going on vacation and can’t take your bike along?</li>
                        <li>Your bike is broken down or in for service</li>
                        <li>Your friend is in town and you want to ride together </li>
                        <li>Or, you want to experience the newer models before you buy</li>
                    </ul>
                <p>There is only one solution to all of these questions…Rent a motorcycle!</p>
                <p>Motorcycle Towing Services partners with hundreds of reputable motorcycle rental companies. You can find a rental bike in all of the most popular areas of the United States including Hawaii and Alaska, as well as Canada.</p>
                <p>Make sure you ask for “The roadside assistance package serviced by MTS” when you rent your bike and MTS will be there for you if you need us.</p>
                    </div>
                    <div>
                        <img src="/images/ER_LOGO_2019_color.png" width="250" />
                    </div>
                    </div>
                        <a href="https://www.eaglerider.com" target="_blank" title="external link to eagle rider rentals" class="btn btn-arrow-tertiary">Learn More About Rentals</a>
            </div>
        </template>
        <template #footer-gap>
            <FooterGap />
            </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }

</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
        },
    })
</script>
