<template>
    <FrontEndLayout title="About Us">
        <template #head>
            <meta name="description" content="About MTS Towing">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>MEMBERSHIP OPTIONS</h1>
        </template>
        <template #fepage-content>
            <div class="container">
                <div class="text-center">
            <h2 class="font-bold text-navy-500">Choose The Membership That Works Best For You</h2>
            <p>We offer a wide range of plans to give you the ability to choose the one that works best for you. Below, you’ll find a full list of our membership options available with Motorcycle Towing Services. Once you’ve decided which MTS membership is the right choice for you, please complete our membership application.</p>
                <a href="/motorists-and-rentals/member-registration" title="internal link in same tab to Member Registration Application page" class="btn btn-arrow-tertiary">Submit Membership Application</a></div>
                 <MemberPlans />
            </div>
        </template>
                <template #footer-gap>
            <FooterGap />
            </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import MemberPlans from './Registration/Partials/MemberPlans.vue';

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
            MemberPlans,
        },
    })
</script>
