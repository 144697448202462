<template>
    <div class="disclaimer" v-if="!renewalAvailable">
        <p>If not within 30 days of renewal call our office number 800-999-7064 to renew or make updates to your account. ( Current expiration date: {{ this.form.plan_expiration }} )</p>
        <p>If you need to update your address, please <a href="/user/profile" title="Edit User Information">click here</a>.</p>
    </div>
    <jet-form-section v-else @submitted="updateMemberInformation">
        <template #description>
            <p v-if="isModify" class="font-medium">Update member account profile information. </p>
            <p v-else class="font-medium">Add member account profile information.</p>

        </template>

        <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="member_id" v-if="isModify" type="hidden" name="member_id" v-model="form.id" />
            <!-- Member Info -->
            <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                <div class="col-span-3">
                    <jet-label for="first_name" value="First Name" />
                    <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" />
                    <jet-input-error :message="form.errors.first_name" class="mt-2" />
                </div>
                <div class="col-span-3">
                    <jet-label for="last_name" value="Last Name" />
                    <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" />
                    <jet-input-error :message="form.errors.last_name" class="mt-2" />
                </div>
                <div class="col-span-1">
                    <jet-label for="middle_initial" value="Middle Initial" />
                    <jet-input id="middle_initial" type="text" class="mt-1 block w-full" v-model="form.middle_initial" />
                    <jet-input-error :message="form.errors.middle_initial" class="mt-2" />
                </div>
            </div>
            <div class="grid md:grid-cols-3 gap-4">
                <div>
                    <vue-tel-input id="phone" label="Phone" v-model="form.phone" :error-message="form.errors.phone" />
                </div>
                <div>
                    <vue-tel-input id="cell" label="Cell" v-model="form.cell" :error-message="form.errors.cell" />
                </div>
                <div>
                    <jet-label for="email" value="Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>
            </div>
            <!-- Address -->
            <div>
                <jet-label for="address" value="Address" />
                <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" />
                <jet-input-error :message="form.errors.address" class="mt-2" />
            </div>
            <div>
                <jet-label for="address2" value="Address 2" />
                <jet-input id="address2" type="text" class="mt-1 block w-full" v-model="form.address2" />
                <jet-input-error :message="form.errors.address2" class="mt-2" />
            </div>
            <div class="grid md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>
                <div>
                    <jet-label for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="postal_code" value="Zip/Postal Code" />
                    <jet-input id="postal_code" type="text" class="mt-1 block w-full" v-model="form.postal_code" />
                    <jet-input-error :message="form.errors.postal_code" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
            </div>
            <div class="grid md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="club" value="Club" />
                    <jet-input id="club" type="text" class="mt-1 block w-full" v-model="form.club" />
                    <jet-input-error :message="form.errors.club" class="mt-2" />
                </div>
                <div>
                    <jet-label for="member_number" value="Member #" />
                    <jet-input id="member_number" type="text" class="mt-1 block w-full" v-model="form.member_number" disabled />
                    <jet-input-error :message="form.errors.member_number" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label for="alert" value="Alert" />
                <textarea id="alert" type="text" class="mt-1 block w-full text-red-700" v-model="form.alert" />
                <jet-input-error :message="form.errors.alert" class="mt-2" />
            </div>
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <jet-label class="mt-4" for="promo_code" value="Promo Code" />
                    <jet-input id="promo_code" type="text" class="mt-1 block w-full" v-model="form.promo_code" name="promo_code" @change="promoCodeChanged" />
                    <jet-input-error :message="promo_code_error" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="referred_by" value="Referred By" />
                    <jet-input id="referred_by" type="text" class="mt-1 block w-full" v-model="form.referred_by" />
                    <jet-input-error :message="form.errors.referred_by" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 my-4">
                <div>
                    <p class="font-medium">Family Plan</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="family_plan_yes" value="Yes" />
                            <jet-input id="family_plan_yes" type="radio" class="mt-1 block" value="Yes" name="family_plan" required @input="form.family_plan = 1" :checked="form.family_plan" />
                        </div>
                        <div>
                            <jet-label for="family_plan_no" value="No" />
                            <jet-input id="family_plan_no" type="radio" class="mt-1 block" value="No" name="family_plan" required @input="form.family_plan = 0" :checked="!form.family_plan" />
                            <jet-input-error :message="form.errors.family_plan" class="mt-2" />
                        </div>
                    </div>
                </div>
                <div>
                    <p class="font-medium">Has Trailer</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="trailer_yes" value="Yes" />
                            <jet-input id="trailer_yes" type="radio" class="mt-1 block" value="Yes" name="has_trailer" required @input="form.has_trailer = 1" :checked="form.has_trailer" />
                        </div>
                        <div>
                            <jet-label for="trailer_no" value="No" />
                            <jet-input id="trailer_no" type="radio" class="mt-1 block" value="No" name="has_trailer" required @input="form.has_trailer = 0" :checked="!form.has_trailer" />
                            <jet-input-error :message="form.errors.has_trailer" class="mt-2" />
                        </div>
                    </div>
                </div>
                <div>
                    <p class="font-medium">Has Sidecar</p>
                    <div class="form-group grid grid-cols-2 gap-4">
                        <div>
                            <jet-label for="sidecar_yes" value="Yes" />
                            <jet-input id="sidecar_yes" type="radio" class="mt-1 block" value="Yes" @input="form.has_sidecar = 1" :checked="form.has_sidecar" name="has_sidecar" required  />
                        </div>
                        <div>
                            <jet-label for="sidecar_no" value="No" />
                            <jet-input id="sidecar_no" type="radio" class="mt-1 block" value="No" @input="form.has_sidecar = 0" :checked="!form.has_sidecar" name="has_sidecar" required  />
                            <jet-input-error :message="form.errors.has_sidecar" class="mt-2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-6 sm:col-span-4" v-if="isModify === false || showAddFam === true || form.family_plan == 1">
                <FamilyMembers
                    v-for="n in family_member_count"
                    :key="'family-member-' + n"
                    :index="n"
                    v-model:family-member-name="form.family_members[n-1].name"
                    v-model:family-member-phone="form.family_members[n-1].phone"
                />
                <jet-button type="button" @click="addAnotherFamilyMember();" class="ml-4 mt-4">
                    Add Another Family Member
                </jet-button>
                <jet-button v-if="family_member_count > 1" type="button" @click="removeFamilyMember();" class="ml-4 mt-4">
                    Remove Family Member
                </jet-button>
            </div>
            <div>
                <h2 class="mt-10">Available Plans *</h2>
                <MemberPlans
                    v-model:member-plan="form.plan_id"
                    v-model:member-plan-options="form.plan_options"
                    :plan-id="form.plan_id"
                    :member-plan-options="form.plan_options"
                    :errors="form.errors"
                    :promoCodeData="promo_code_data"
                    :familyPlanSelected="familyPlanSelected"
                />
            </div>
            <div class="container">
                <p v-if="additionalMotorcycleCharge > 0" class="font-medium">Additional Motorcycle(s) charge: ${{ parseFloat(additionalMotorcycleCharge).toFixed(2) }}</p>
                <h2>Total: ${{ totalCost }}</h2>
            </div>
            <div>
                <h2 class="mt-10">Credit Card Information</h2>
                <CreditCard
                    v-model:card-holder="form.card_holder"
                    v-model:card-number="form.card_number"
                    v-model:exp-month="form.exp_month"
                    v-model:exp-year="form.exp_year"
                    v-model:ccv="form.ccv"
                    :errors="form.errors"
                />
            </div>


            <div v-if="isModify === false || showAdd === true">
                <h3 class="text-navy-500">Motorcycle{{ motorcycles > 1 ? 's' : '' }}</h3>
                <Motorcycle
                    v-for="n in motorcycles"
                    :key="'member-motorcycle-' + n"
                    :index="n"
                    :states=states
                    v-model:motorcycle-year="form.member_motorcycles[n-1].motorcycle_year"
                    v-model:motorcycle-make="form.member_motorcycles[n-1].motorcycle_make"
                    v-model:motorcycle-model="form.member_motorcycles[n-1].motorcycle_model"
                    v-model:motorcycle-color="form.member_motorcycles[n-1].motorcycle_color"
                    v-model:motorcycle-plate="form.member_motorcycles[n-1].motorcycle_plate"
                    v-model:motorcycle-state="form.member_motorcycles[n-1].motorcycle_state"
                    v-model:motorcycle-country="form.member_motorcycles[n-1].motorcycle_country"
                    v-model:motorcycle-vin="form.member_motorcycles[n-1].motorcycle_vin"
                    v-model:trike-autocycle="form.member_motorcycles[n-1].trike_autocycle"
                />
            </div>
        </template>
        <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Member Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Member Added.
            </jet-action-message>

            <jet-button v-if="isModify" @click="updateMemberInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
            <jet-button v-else @click="addMemberInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
    <div>
        <h2 class="mt-3">My Family</h2>
        <FamilyTable
            ref="familyTable"
            :data="family"
            @family-updated="fetchMemberData()"
            @row-deleted="rowDeleted($event, 'family')"
        />
        <h2>My Bikes</h2>
        <BikeTable
            :data="bikes"
            :states="states"
            ref="bikeTable"
            @row-deleted="rowDeleted($event, 'bike')"
            @bikes-updated="fetchMemberData()"
        />
    </div>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import BikeTable from '../../Memberships/Tables/BikeTable.vue'
    import Motorcycle from '../../Memberships/Forms/Partials/Motorcycles.vue'
    import MemberPlans from './Partials/MemberPlans.vue'
    import CreditCard from './Partials/CreditCard.vue';
    import FamilyMembers from '../../../../DashboardPartials/FamilyMembers.vue'
    import FamilyTable from '../../../../DashboardPartials/Tables/FamilyTable.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'
    import dayjs from 'dayjs'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            BikeTable,
            Motorcycle,
            MemberPlans,
            CreditCard,
            FamilyMembers,
            FamilyTable,
            VueTelInput,
            dayjs,
        },
        computed: {
            totalCost() {
                return parseFloat(this.total_cost + this.additionalMotorcycleCharge).toFixed(2);
            },
            additionalMotorcycleCharge() {
                let bikeCount = this.bikes.length -2;
                if (bikeCount < 0){
                    bikeCount = 0;
                }
                return bikeCount * 5;
            },
            planType() {
                return this.form.plan_id;
            },
            planOptions() {
                return this.form.plan_options;
            },
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    first_name: '',
                    last_name: '',
                    middle_initial: '',
                    phone: '',
                    cell: '',
                    email: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    club: '',
                    alert: '',
                    member_number: '',
                    promo_code: '',
                    plan_id: 0,
                    plan_options: [],
                    family_plan: 0,
                    family_members: [{
                        name: '',
                        phone: '',
                    }],
                    has_trailer: 0,
                    has_sidecar: 0,
                    referred_by: '',
                    member_motorcycles: [{
                        motorcycle_year: '',
                        motorcycle_make: '',
                        motorcycle_model: '',
                        motorcycle_color: '',
                        motorcycle_plate: '',
                        motorcycle_state: '',
                        motorcycle_country: '',
                        motorcycle_vin: '',
                        trike_autocycle: 0,
                    }],
                    card_holder: '',
                    card_number: '',
                    exp_month: '',
                    exp_year: '',
                    ccv: '',
                }),
                bikes: [],
                states: [],
                family: [],
                isModify: true,
                showAdd: false,
                showAddFam: false,
                motorcycles: 1,
                family_member_count: 1,
                countries: ['USA', 'Canada'],
                renewalAvailable: false,
                promo_code_data: null,
                promo_code_error: '',
                total_cost: 0.00,
            }
        },
        methods: {
            fetchMemberData() {
                var userId = this.$page.props.user.id;
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.memberships.fetch_member_data_by_user'),
                    data: {
                        user_id: userId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        if(key != 'bikes' && key != 'family_members') {

                            if(key == 'plan_type_id'){
                                this.form['plan_id'] = value;
                            }
                            if(key == 'plan_expiration'){
                                const today = new Date();
                                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                const currentDate = dayjs(date);
                                const expirationDate = dayjs(value);
                                var difference = expirationDate.diff(currentDate, 'day');
                                this.form[key] = dayjs(value).format('MM-DD-YYYY');
                                if(difference < 31){
                                    this.renewalAvailable = true;
                                }
                            } else {
                                this.form[key] = value;
                            }
                        }
                    });
                    this.family = response.data.data.family_members;
                    this.bikes = response.data.data.bikes;
                })
            },
            getTotalCost() {
                if (this.form.plan_id){
                    axios({
                        method: 'post',
                        url: route('.calc_total'),
                        data: {
                            plan: this.form.plan_id,
                            options: this.form.plan_options,
                            motorcycles: this.motorcycles,
                            promoCode: this.form.promo_code,
                        }
                    })
                    .then(response => {
                        console.log(response);
                        this.total_cost = response.data;

                    })
                }
            },
            updateMemberInformation() {
                this.form.put(route('dashboard.motorists.memberships.update_renew_member'), {
                    errorBag: 'addModifyMember',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.resetForm();
                        this.fetchMemberData();
                    }
                });
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchPlanTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_plan_type_list'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                })
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.typeId = typeId;
                    this.fetchMemberData();

                }
            },
            addAnotherMotorcycle () {
                this.motorcycles++;
                this.form.member_motorcycles.push({
                    motorcycle_year: '',
                    motorcycle_make: '',
                    motorcycle_model: '',
                    motorcycle_color: '',
                    motorcycle_plate: '',
                    motorcycle_state: '',
                    motorcycle_country: '',
                    motorcycle_vin: '',
                    trike_autocycle: '',
                })
            },
            removeMotorcycle () {
                this.motorcycles--;
                this.form.member_motorcycles.pop();
            },
            addAnotherFamilyMember () {
                this.family_member_count++;
                this.form.family_members.push({
                    name: '',
                    phone: '',
                })
            },
            removeFamilyMember () {
                this.family_member_count--;
                this.form.family_members.pop();
            },
            promoCodeChanged () {
                // Search Promo Codes for the promo code that was entered
                if (this.form.promo_code.trim() == "")
                {
                    this.promo_code_data = null;
                    this.promo_code_error = '';
                }
                else
                {
                    axios.get('/motorists-and-rentals/member-registration/get_promo/' + this.form.promo_code,{
                    }).then(function(response){
                        if (response.data) {
                            this.promo_code_data = response.data;
                            this.promo_code_error = '';
                        } else {
                            this.promo_code_data = null;
                            this.promo_code_error = "Invalid Promo Code";
                        }
                    }.bind(this));
                }
            },
            getStates: function() {
                axios.get('/get_states',{

                    }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            showAddMotorcycle () {
                this.showAdd = true;
            },
            familyPlanSelected (e) {
                if(e){
                    this.form.family_plan = 1;
                } else {
                    this.form.family_plan = 0;
                }

            },
            rowDeleted(id, table) {
                if(table == 'family'){
                    this.$inertia.post('/dashboard/motorists/memberships/delete_family_member/' + id, id, {
                        onSuccess: (id) => {
                            this.fetchMemberData();
                            this.$refs.familyTable.closeModal();
                        }
                    })
                }
                if(table == 'bike'){
                    this.$inertia.post('/dashboard/motorists/memberships/delete_bike/' + id, id, {
                        onSuccess: (id) => {
                            this.fetchMemberData();
                            this.$refs.bikeTable.closeModal();
                        }
                    })
                }
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
        },
        watch: {
            planType(n) {
                if (n) {
                    this.getTotalCost();
                }
            },
            planOptions(n) {
                if (n) {
                    this.getTotalCost();
                }
            },
            motorcycles(n) {
                if (n) {
                    this.getTotalCost();
                }
            }
        },
        mounted() {
            this.fetchMemberData();
            this.fetchMemberTypes();
            this.fetchPlanTypes();
            this.getStates();
        },
    }
</script>
