<template>
    <JetButton class="button button-primary absolute right-4" @click="sendResetEmail" v-if="resetEmailButtonText">
        {{ resetEmailButtonText }}
    </JetButton>
    <jet-form-section @submitted="updateDealerInformation">
        <template #description>
            <span v-if="isModify" class="font-medium">Update member account profile information. </span>
            <span v-else class="font-medium">Add member account profile information.</span>

        </template>
                <template #form @submit.prevent="form.post('/modify-member')" >
            <jet-input id="dealer_id" v-if="isModify" type="hidden" name="dealer_id" v-model="form.id" />

            <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                <div class="col-span-3" v-if="$page.props.user.current_team_id != 5">
                    <jet-label for="member_type" value="Member Type" />
                    <select v-model="form.member_type" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in member_type_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.member_type" class="mt-2" />
                </div>
                <div class="col-span-2" v-if="$page.props.user.current_team_id != 5">
                    <jet-label for="mts_member_id" value="Dealer ID" />
                    <jet-input id="mts_member_id" type="text" class="mt-1 block w-full" v-model="form.mts_member_id" />
                    <jet-input-error :message="form.errors.mts_member_id" class="mt-2" />
                </div>
            </div>
                <!-- Dealer Info -->
                <div>
                    <jet-label for="company_name" value="Company Name" />
                    <jet-input id="company_name" type="text" class="mt-1 block w-full" v-model="form.company_name" />
                    <jet-input-error :message="form.errors.company_name" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="first_name" value="First Name" />
                        <jet-input id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" />
                        <jet-input-error :message="form.errors.first_name" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="last_name" value="Last Name" />
                        <jet-input id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" />
                        <jet-input-error :message="form.errors.last_name" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <vue-tel-input id="phone" label="Phone" v-model="form.phone" :error-message="form.errors.phone" />
                    </div>
                    <div>
                        <vue-tel-input id="fax" label="Fax" v-model="form.fax" :error-message="form.errors.fax" />
                    </div>
                    <div>
                        <jet-label for="email" value="Email" />
                        <jet-input id="email" type="email" class="mt-1 block w-full" v-model="form.email" />
                        <jet-input-error :message="form.errors.email" class="mt-2" />
                    </div>
                </div>
                <!-- Physical Address -->
                <div>
                    <jet-label for="address" value="Address" />
                    <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" />
                    <jet-input-error :message="form.errors.address" class="mt-2" />
                </div>
                <div>
                    <jet-label for="address2" value="Address 2" />
                    <jet-input id="address2" type="text" class="mt-1 block w-full" v-model="form.address2" />
                    <jet-input-error :message="form.errors.address2" class="mt-2" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <jet-label for="city" value="City" />
                        <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" />
                        <jet-input-error :message="form.errors.city" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="state" value="State/Province" />
                        <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                            <option value=""> -- </option>
                            <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                        </select>
                        <jet-input-error :message="form.errors.state" class="mt-2" />
                    </div>
                    <div>
                        <jet-label for="postal_code" value="Zip/Postal Code" />
                        <jet-input id="postal_code" type="text" class="mt-1 block w-full" v-model="form.postal_code" />
                        <jet-input-error :message="form.errors.postal_code" class="mt-2" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <jet-label for="country" value="Country" />
                        <jet-input id="country" type="text" class="mt-1 block w-full" v-model="form.country" />
                        <jet-input-error :message="form.errors.country" class="mt-2" />
                    </div>
                    <div v-if="$page.props.user.current_team_id != 5">
                        <jet-label for="status" value="Status" />
                        <select v-model="form.status" class="border py-2 px-3 text-grey-800 w-full">
                            <option
                                v-for="option in dealer_status_list"
                                :key="option"
                                :value="option"
                            >{{option}}</option>
                        </select>
                        <jet-input-error :message="form.errors.status" class="mt-2" />
                    </div>
                </div>
                <div v-if="isModify === false || addContactVisible === true">
                    <h3 class="font-medium text-navy-300 mt-4">Dealer Contact{{ contact_count > 1 ? 's' : '' }}</h3>
                    <DealerContact
                        v-for="n in contact_count"
                        :key="'dealer-contact-' + n"
                        :index="n"
                        v-model:contact-name="form.dealer_contacts[n-1].contact_name"
                        v-model:contact-phone="form.dealer_contacts[n-1].contact_phone"
                        v-model:contact-after-hours="form.dealer_contacts[n-1].contact_after_hours"
                    />
                    <jet-button type="button" @click="addAnotherContact();" class="ml-4 my-4">
                        Add Another Contact
                    </jet-button>
                    <jet-button v-if="contact_count > 1" type="button" @click="removeContact();" class="ml-4 ">
                        Remove Contact
                    </jet-button>
                </div>
        </template>
         <template #actions>
            <jet-action-message v-if="isModify" :on="form.recentlySuccessful" class="mr-3">
                Dealer Updated.
            </jet-action-message>
            <jet-action-message v-else :on="form.recentlySuccessful" class="mr-3">
                Dealer Added.
            </jet-action-message>

            <jet-button v-if="isModify" @click="updateDealerInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
            <jet-button v-else @click="addDealerInformation()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
    <div v-if="isModify">
        <h3 class="font-medium text-blue-500 mt-5">Contacts</h3>
        <DealerContactsTable
            :data="contacts"
            @contacts-updated="fetchDealerData()"
        />
        <jet-button @click="showAddContact()" >
            Add Contact
        </jet-button>
    </div>
    <div v-if="isModify">
        <h3 class="font-medium text-blue-500 mt-5">Tows</h3>
        <TowTable
            :data="tows"
            :bikes="bikes"
            @tows-updated="fetchDealerData()"
        />
    </div>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import TowTable from '../Tables/TowTable.vue'
    import DealerContactsTable from '../Tables/DealerContactsTable.vue'
    import DealerContact from '../Forms/Partials/DealerContact.vue'
    import Status from '@/Components/Status.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            TowTable,
            DealerContactsTable,
            DealerContact,
            Status,
            VueTelInput,
        },

        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    mts_member_id: '',
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                    fax: '',
                    email: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    member_number: '',
                    member_type: '',
                    status: '',
                    dealer_contacts: [{
                        contact_name: '',
                        contact_phone: '',
                        contact_after_hours: '',
                    }],
                }),
                tows: [],
                bikes: [],
                contacts: [],
                states: [],
                plan_type_list: [],
                member_type_list: [],
                dealer_status_list: [],
                isModify: false,
                addContactVisible: false,
                addMotorcycleVisible: false,
                contact_count: 1,
                resetEmailButtonText: null,
            }
        },
        methods: {
            fetchDealerData() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var dealerId = parseInt(last_segment);
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.dealerships.fetch_dealer_data'),
                    data: {
                        dealer: dealerId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        if(key != 'tows' && key != 'contacts'){
                            this.form[key] = value;
                        }
                    });
                    this.tows = response.data.data.tows;
                    this.contacts = response.data.data.contacts;
                    this.updateResetEmailButtonText(dealerId);
                })
            },
            updateResetEmailButtonText(id) {
                axios.get('/dashboard/dealerships/fetch_dealer_has_login/' + id,{
                }).then(function(response){
                    if (response.data){
                        this.resetEmailButtonText = 'Send Password Reset Email';
                    } else {
                        this.resetEmailButtonText = 'Send Invite Email';
                    }
                }.bind(this));
            },
            sendResetEmail() {
                var id = this.form.id;
                this.$inertia.post('/dashboard/dealerships/send_password_reset_email/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchMemberData();
                        this.$refs.familyTable.closeModal();
                    }
                })
            },
            updateDealerInformation() {
                this.form.post(route('dashboard.dealerships.update_dealer'), {
                    errorBag: 'addModifyDealer',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.fetchDealerData();
                        this.form.dealer_contacts = [{
                                contact_name: '',
                                contact_phone: '',
                                contact_after_hours: '',
                        }];
                        this.addContactVisible = false;
                    }
                });
                
            },
            addDealerInformation() {
                this.form.post(route('dashboard.dealerships.add'), {
                    errorBag: 'addModifyDealer',
                    preserveScroll: true,
                    onSuccess: () => this.resetForm(),
                });
            },
            fetchMemberStatusEnum() {
                axios({
                    method: 'get',
                    url: route('dashboard.dealerships.fetch_status_enum'),
                })
                .then(response => {
                    this.dealer_status_list = response.data;
                })
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchPlanTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_plan_type_list'),
                })
                .then(response => {
                    this.plan_type_list = response.data.data;
                })
            },
            checkModify()
            {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var last_segment = segment_array.pop();
                var typeId = parseInt(last_segment);

                if(Number.isNaN(typeId)){
                    this.isModify = false;
                } else {
                    this.isModify = true;
                    this.typeId = typeId;
                    this.fetchDealerData();
                }
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            addAnotherContact () {
                this.contact_count++;
                this.form.dealer_contacts.push({
                    contact_name: '',
                    contact_phone: '',
                    contact_after_hours: 0,
                })
            },
            removeContact () {
                this.contact_count--;
                this.form.dealer_contacts.pop();
            },
            showAddContact () {
                this.addContactVisible = true;
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
        },
        mounted() {
            this.checkModify();
            this.fetchMemberStatusEnum();
            this.getStates();
            this.fetchPlanTypes();
            this.fetchMemberTypes();
        },
    }
</script>
