<template>
    <div id="map"></div>
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader'
import JetButton from '@/Jetstream/Button.vue'

const loader = new Loader({
    // MTS API KEY: AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ
    apiKey: "AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ",
    version: "weekly",
    libraries: ["places"]
});

const mapOptions = {
    center: {
        lat: 42.4962397,
        lng: -92.4830837,
    },
    zoom: 5,
    mapTypeControl: false,
    mapTypeId: 'roadmap'
};


export default {
    components: {
        Loader,
        JetButton,
    },
    data() {
        return {
            map: {},
            markers: [],
            towing_companies: [],
        }
    },
    props: ['city', 'state'],
    methods: {
        setSearchLocation(lat, lng) {
            axios({
                method: 'post',
                url: route('dashboard.towing_companies.tows.towing_companies_in_range'),
                data: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                }
            })
            .then(response => {
                this.showTowingCompanies(response.data, this.map)
                this.towing_companies = response.data
            })
        },
        setSelectedTowingCompany(tc){
            this.$emit('selected-towing-company', tc);
        },
        showTowingCompanies(locationList) {
            locationList.forEach((location, id) => {
                const contentString =
                `<div class="gmap-info-window">
                    <div class="window-wrapper">
                        <div class="content">
                            <p class="title"><strong>${location.name}</strong></p>
                            <p>${location.address}</p><p>${location.address2}</p>
                            <p>${location.city} ${location.state}, ${location.postal_code}</p>
                            <p>Phone: ${location.phone}</p>
                            <p>After Hours: ${location.phone_24hrs}</p>
                            <p>Distance: ${parseFloat(location.distance).toFixed(2)} miles</p>
                            <button type="button" onclick="setTC('`+ location.id +`')" style="background-color: rgb(26, 115, 232); color: white; border: 0px; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; margin: 5px 0px 5px 0px; padding: 0px 0.25em; font: 400 12px Roboto, Arial, sans-serif; overflow: hidden; height: 40px; width: 100%; cursor: pointer;">Select Towing Company</button>
                        </div>
                    </div>
                </div>`;
                //create info window foreach location
                let infoWindow = new google.maps.InfoWindow({
                    content: contentString
                });

                let prevWindow = false;

                //set up markers for each location
                let marker = new google.maps.Marker({
                    clickable: true,
                    title: location.name,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    animation: google.maps.Animation.DROP,
                    position: {
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude)
                    },
                });

                marker.addListener('click', function(){
                    if (prevWindow) prevWindow.close();
                    prevWindow = infoWindow;
                    infoWindow.open(this.map, marker);
                });
                marker.set('id', id);
                marker.setMap(this.map);
                this.markers.push(marker);
            });
        },
        clearTCTable(){
            this.towing_companies = [];
        }
    },
    mounted() {
        const vm = this;
        // Promise
        loader.load()
        .then((google) => {
            vm.map = new google.maps.Map(document.getElementById("map"), mapOptions)

            let centerMarker;
            let geocoder;
            let response;

            geocoder = new google.maps.Geocoder();

            window.setTC = function(id) {
                vm.setSelectedTowingCompany(id);
                return false;
            }

            const inputText = document.createElement("input");

            inputText.type = "text";
            inputText.placeholder = "Enter a location";
            inputText.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;line-height: 40px;margin-right: 0;min-width: 25%;';
            const submitButton = document.createElement("input");

            submitButton.type = "button";
            submitButton.value = "Search";
            submitButton.classList.add("button", "button-primary");
            submitButton.style.cssText = 'background-color: #1a73e8;color: white;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;height: 40px;cursor: pointer;margin-left: 5px;';
            const clearButton = document.createElement("input");

            clearButton.type = "button";
            clearButton.value = "Clear";
            clearButton.classList.add("button", "button-secondary");
            clearButton.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;height: 40px;cursor: pointer;margin-left: 5px;background-color: white;color: #1a73e8;';
            response = document.createElement("pre");
            response.id = "response";
            response.innerText = "";


            const instructionsElement = document.createElement("p");

            instructionsElement.id = "instructions";
            instructionsElement.innerHTML =
                "<strong>Instructions</strong>: Enter an address in the textbox or click on the map to set breakdown location.";
            instructionsElement.style.cssText = 'background-color: #fff;border: 0;border-radius: 2px;box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);margin: 10px;padding: 0 0.5em;font: 400 18px Roboto, Arial, sans-serif;overflow: hidden;padding: 1rem;font-size: medium;';
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText);
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton);
            vm.map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton);
            vm.map.controls[google.maps.ControlPosition.LEFT_TOP].push(instructionsElement);

            centerMarker = new google.maps.Marker({
                map: vm.map,
                icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            });
            vm.map.addListener("click", (e) => {
                geocode({ location: e.latLng });
            });
            submitButton.addEventListener("click", () =>
                geocode({ address: inputText.value })
            );
            clearButton.addEventListener("click", () => {
                clear();
            });
            clear();

            if(vm.city && vm.state) {
                geocode({ address: vm.city + ', ' + vm.state })
                inputText.value = vm.city + ', ' + vm.state;
                vm.map.setZoom(6);
            }

            function clear() {
                centerMarker.setMap(null);
                for (var i = 0; i < vm.markers.length; i++) {
                    vm.markers[i].setMap(null);
                    vm.markers[i].setVisible(false);
                }
                vm.markers = [];
                vm.clearTCTable();
            }

            function geocode(request) {
                clear();
                geocoder
                    .geocode(request)
                    .then((result) => {
                        const { results } = result;

                        vm.map.setCenter(results[0].geometry.location);
                        centerMarker.setPosition(results[0].geometry.location);
                        centerMarker.setMap(vm.map);

                        vm.setSearchLocation(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                        return results;
                    })
                    .catch((e) => {
                        alert("Geocode was not successful for the following reason: " + e);
                    });
            }
        })
        .catch(e => {
            console.log(e);
        });

    },
}
</script>
<style lang="scss" scoped>

#map {
    height: 700px;
}
</style>
