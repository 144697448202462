<template>
 <div>
        <Teleport to="body"><FlashMessage /></Teleport>
</div>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('coupon_code')" @click="sortBy('coupon_code')">Coupon Code</p>
            <p :class="sortClass('description')" @click="sortBy('description')">Description</p>
            <p :class="sortClass('date_start')" @click="sortBy('date_start')">Start Date</p>
            <p :class="sortClass('date_end')" @click="sortBy('date_end')">End Date</p>
            <p :class="sortClass('is_active')" @click="sortBy('is_active')">Status</p>
        </div>
        <div class="simple-table" style="--cols:6">
            <p class="st-header first-col" :class="sortClass('coupon_code')" @click="sortBy('coupon_code')">Coupon Code</p>
            <p class="st-header" :class="sortClass('description')" @click="sortBy('description')" >Description</p>
            <p class="st-header" :class="sortClass('date_start')" @click="sortBy('date_start')">Start Date</p>
            <p class="st-header" :class="sortClass('date_end')" @click="sortBy('date_end')">End Date</p>
            <p class="st-header" :class="sortClass('is_active')" @click="sortBy('is_active')">Status</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Coupon Code</span>{{ row.coupon_code }}</p>
                <p><span class="st-hidden">Description</span>{{ row.description }}</p>
                <p><span class="st-hidden">Start Date</span>{{ row.date_start }}</p>
                <p><span class="st-hidden">End Date</span>{{ row.date_end }}</p>
                <p><span class="st-hidden">Status</span><span v-if="row.is_active == 1" class="text-green">Active</span><span v-else class="text-gray-400">Inactive</span></p>

                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/motorists/coupon_codes/edit_coupon_code/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>

                        <jet-danger-button @click="confirmCouponCodeDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete CouponCode Confirmation Modal -->
    <jet-dialog-modal :show="confirmingCouponCodeDeletion" @close="closeModal">
        <template #title>
            Delete Coupon Code
        </template>

        <template #content>
            Are you sure you want to delete this Coupon Code? (You will still be able to restore this Coupon Code if needed.)


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteCouponCode(this.planId)" >
                Delete Coupon Code
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            MemberHeader,
            FlashMessage,
            TableWrapper,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link
        },

        data() {
            return {
                confirmingCouponCodeDeletion: false,
                planId: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            confirmCouponCodeDeletion(index, id) {
                this.confirmingCouponCodeDeletion = true;
                this.planId = id;
                this.index = index;
            },

            deleteCouponCode(id) {
                this.$emit('row-deleted', id);
            },

            closeModal: function () {
                this.confirmingCouponCodeDeletion = false
            },
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>