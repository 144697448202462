<template>
    <div class="app">
        <Head :title="title">
            <slot name="head">
                <meta name="description" content="MTS Towing">
                <meta name="keywords" content="MTS Towing">
            </slot>
        </Head>
    </div>

    <jet-banner />

    <FrontEndHeaderNav />

    <header v-if="$slots.header">
        <div class="page-title">
            <div class="pt-bg" style="background-image: url(/images/bg-page-title.jpg)" alt="motorcycle driving down country road"/>
            <slot name="header"></slot>
        </div>
    </header>

    <main>
        <slot name="fepage-content"></slot>
    </main>

    <slot name="footer-gap"></slot>

    <FooterNav />
</template>

<style lang="scss">
    @use 'sass:math';
    @import '../../scss/_mixins.scss';
    @import '../../css/app.css';


$pt-overlay-color: rgba(18, 51, 54, .2);
$pt-h1-color: white;

.page-title {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 77vh;
    top: var(--header-size);
    pointer-events: none;

        &:after {
        content: '';
        background: url('/images/mm-bottom-texture.svg') no-repeat;
        background-size: 100% auto;
        height: 5.5vw;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        display: block;
        background-position: bottom center;
        z-index: 3;
        filter: drop-shadow(4px -11px 6px #0c26549a);
    }


    .pt-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: var(--mm-overlay);
        }

        &:after {
            content: '';
            background-image: url(/images/americanflag.png);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            opacity: .2;
            background-position: left;
        }
    }

    h1 {
        position: relative;
        margin: 35vh auto;
        line-height: 1.25;
        color: $pt-h1-color;
        text-align: center;
        font-weight: bold;
        text-shadow: 3px 3px 10px #000000CC;
        font-size: 40px;
        padding: 0 2rem;
        text-transform: uppercase;

        @include bp-up($bp-md) {
        font-size: 80px;
        }
    }
}

</style>

<script>
    import { defineComponent } from 'vue'
    import JetApplicationMark from '@/Jetstream/ApplicationMark.vue'
    import JetBanner from '@/Jetstream/Banner.vue'
    import JetDropdown from '@/Jetstream/Dropdown.vue'
    import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
    import JetNavLink from '@/Jetstream/NavLink.vue'
    import JetResponsiveNavLink from '@/Jetstream/ResponsiveNavLink.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FrontEndHeaderNav from '@/Layouts/FrontEndHeaderNav.vue';
    import FooterNav from '@/Layouts/FooterNav.vue';

    export default defineComponent({
        props: {
            title: String,
        },

        components: {
            Head,
            JetApplicationMark,
            JetBanner,
            JetDropdown,
            JetDropdownLink,
            JetNavLink,
            JetResponsiveNavLink,
            Link,
            FrontEndHeaderNav,
            FooterNav,
        },

        data() {
            return {
                showingNavigationDropdown: false,
            }
        },

        methods: {

        }
    })
</script>
