<template>
    <FrontEndLayout title="About Us">
        <template #head>
            <meta name="description" content="Text Opt-In/Opt-Out">
            <meta name="keywords" content="services">
        </template>
        <template #header>
            <h1>TEXT OPT-IN/OPT-OUT</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container">
                <div class="text-center">
                    <h2 class="font-bold text-navy-500">Please enter your phone number and set your preference</h2>
                </div>
                <form @submit.prevent="submit" id="membershipForm">
                    <div class="gray-section">
                        <div class="container">
                            <h3 class="text-navy-500">Text Opt-In/Opt-Out Preference</h3>
                            <div class="grid grid-cols-2 gap-4">
                                <div>
                                    <vue-tel-input id="phone_number" label="Phone Number" v-model="form.phone_number" :error-message="form.errors.phone_number" required />
                                    <jet-input-error :message="form.errors.phone_number" class="mt-2" />
                                </div>  
                                <div>
                                    <jet-label class="mt-4" for="allow_texts" value="Allow Texts" />
                                    <jet-checkbox :value="1" v-model:checked="form.allow_texts" class="mt-1 block"/>
                                </div>  
                            </div>
                        </div>
                        <div class="flex items-center justify-center mt-4">
                            <jet-button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Save Preference
                            </jet-button>
                        </div>
                    </div>
                </form>               
            </div>
        </template>
                <template #footer-gap>
            <FooterGap />
            </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'
    import FlashMessage from '../DashboardPartials/FlashMessages.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
            FooterGap,
            JetButton,
            JetInput,
            JetInputError,
            JetCheckbox,
            JetLabel,
            JetValidationErrors,
            VueTelInput,
            FlashMessage,
        },
        data() {
            return {
                form: this.$inertia.form({
                    phone_number: '',
                    allow_texts: true,
                    processing: false,
                }),
            }
        },
        methods: {
            savePreference(){
                alert('Clicked');
            },
            submit() {
                this.form.processing = true;
                this.form.post(route('.optin.store'), {
                    errorBag: 'optinStore',
                    onSuccess: (response) => {
                        if(!response.props.flash.error) {
                            this.form.reset();
                        }
                    }
                });
            },
        }
    })
</script>
