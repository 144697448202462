<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p :class="sortClass('phone')" @click="sortBy('phone', 'number')">Phone</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p class="st-header" :class="sortClass('phone')" @click="sortBy('phone', 'number')" >Phone</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.name }}</p>
                <p ><span class="st-hidden">Phone</span>{{ row.phone }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <jet-button @click="setActiveDropdown(row.id)" v-if="currentteam != 3">Edit</jet-button>
                        <jet-danger-button @click="confirmFamilyDeletion(index, row.id)" v-if="currentteam != 3">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
                <FamilyTableDropdown
                    v-bind="dropdownBinder(row, row.id)"
                    :states=states
                    @family-member-updated="familyUpdated()"
                />
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Confirmation Modal -->
    <jet-dialog-modal :show="confirmingFamilyDeletion" @close="closeModal">
        <template #title>
            Delete Family Member
        </template>

        <template #content>
            Are you sure you want to delete this Family Member?


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteFamilyMember(this.familyMemberId)"  >
                Delete Family Member
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import FamilyTableDropdown from '../Dropdowns/FamilyTableDropdown.vue';
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            FamilyTableDropdown,
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link
        },
        props: ['states'],
        emits: ['row-deleted', 'family-updated', 'currentteam'],
        data() {
            return {
                familyForm: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    name: '',
                    phone: '',
                }),
                confirmingFamilyDeletion: false,
                familyMemberId: null,
            }
        },
        methods: {
            familyUpdated() {
                this.$emit('family-updated')
            },
            deleteFamilyMember(id) {
                this.$emit('row-deleted', id);
            },
            confirmFamilyDeletion(index, id) {
                this.confirmingFamilyDeletion = true;
                this.familyMemberId = id;
                this.index = index;
            },
            closeModal: function () {
                this.confirmingFamilyDeletion = false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(2, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>
