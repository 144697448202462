<template>
 <div>
        <Teleport to="body"><FlashMessage /></Teleport>
</div>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!tows.isLoading">
        <form class="search-form" @submit.prevent="applyFilters()">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
                <div class="flex flex-col md:flex-row md:items-center">
                    <label for="date" class="m-1">Date Range </label>
                    <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                    <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                </div>
                <div>
                    <jet-button type="submit" class="button button-primary ml-2"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton>
                </div>
            </div>
            <div class="top-buttons">
                <Link
                    :href="`/dashboard/tow-requests/add_tow_requests_step1`"
                    as="button"
                    type="button"
                    class="w-full md:w-auto"
                >
                    <JetButton class="button button-primary w-full md:w-auto m-1">
                            ADD TOW
                    </JetButton>
                </Link>
                <JetSecondaryButton class="button button-secondary w-full md:w-auto m-1" @click="exportCSV()">{{ button_text }}</JetSecondaryButton>
            </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date',null, 'date')">Service Date</p>
            <p :class="tows.sortClass('membership_number')" @click="tows.sortBy('membership_number')">Member #</p>
            <p :class="tows.sortClass('rental_agreement')" @click="tows.sortBy('rental_agreement')">RA</p>
            <p :class="tows.sortClass('problem')" @click="tows.sortBy('problem')">Problem</p>
            <p :class="tows.sortClass('tow_company_name')" @click="tows.sortBy('tow_company_name')">Towing Company</p>
            <p :class="tows.sortClass('break_down_location')" @click="tows.sortBy('break_down_location')">Location</p>
            <p :class="tows.sortClass('status')" @click="tows.sortBy('status')">Status</p>
        </div>
        <div class="simple-table" style="--cols:8">
            <p class="st-header first-col" :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date',null, 'date')">Service Date</p>
            <p class="st-header" :class="tows.sortClass('membership_number')" @click="tows.sortBy('membership_number')" >Member #</p>
            <p class="st-header" :class="tows.sortClass('rental_agreement')" @click="tows.sortBy('rental_agreement')" >RA</p>
            <p class="st-header" :class="tows.sortClass('problem')" @click="tows.sortBy('problem')" >Problem</p>
            <p class="st-header" :class="tows.sortClass('tow_company_name')" @click="tows.sortBy('tow_company_name')" >Towing Company</p>
            <p class="st-header" :class="tows.sortClass('break_down_location')" @click="tows.sortBy('break_down_location')">Location</p>
            <p class="st-header" :class="tows.sortClass('status')" @click="tows.sortBy('status')">Status</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in tows.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Service Date</span>{{ localizeDate(row.service_date) }}</p>
                <p><span class="st-hidden">Member #</span>{{ row.membership_number }}</p>
                <p><span class="st-hidden">RA</span>{{ row.rental_agreement }}</p>
                <p><span class="st-hidden">Problem</span>{{ row.problem }}</p>
                <p><span class="st-hidden">Towing Company</span>{{ row.towing_company }}</p>
                <p><span class="st-hidden">Location</span>{{ row.break_down_location }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.status" /></p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <jet-button v-if="row.role_type != 'AD'" @click="editTow(row.id)">
                            Edit
                        </jet-button>

                        <jet-secondary-button @click="printPdf(row.id)">
                            Print PDF
                        </jet-secondary-button>

                        <jet-danger-button v-if="$page.props.user.current_team.name == 'Admin'" @click="confirmTowDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ tows.rangeText }}</p>
            <Pagination
                v-if="tows.itemCount || tows.itemCount <= tows.itemsPerPage"
                :active-page="tows.activePage"
                :item-count="tows.itemCount"
                :items-per-page="tows.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete Tow Confirmation Modal -->
    <jet-dialog-modal :show="confirmingTowDeletion" @close="closeModal">
        <template #title>
            Delete Tow
        </template>

        <template #content>
            Are you sure you want to delete this Tow? (You will still be able to restore if needed.)
        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteTow(this.towId)" >
                Delete Tow
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import { reactive, toRefs } from 'vue'
    import EloquentSSFP from '@/EloquentSSFP'
    import Pagination from '@/Components/Pagination.vue'
    import VueLoader from '@/Components/VueLoader'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Pagination,
            VueLoader,
            MemberHeader,
            FlashMessage,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link,
            Status,
            moment
        },
        data() {
            return {
                confirmingTowDeletion: false,
                towId: null,
                tows: reactive(new EloquentSSFP()),
                searchModel: '',
                start_date: null,
                end_date: null,
                searchList: [],
                button_text: 'EXPORT',
            }
        },
        methods: {
            confirmTowDeletion(index, id) {
                this.confirmingTowDeletion = true;
                this.towId = id;
                this.index = index;
            },
            deleteTow(id) {
                this.$inertia.post('/dashboard/tow-requests/delete_tow/' + id, id, {
                    onSuccess: (id) => {
                        this.tows.refreshData(this.tows.options);
                    }
                });
                this.closeModal();
            },
            closeModal: function () {
                this.confirmingTowDeletion = false
            },
            applyFilters() {
                let options = {...this.tows.options};
                options['filterProps']         = [];
                options['filterValues']        = [];
                options['filterOperators']     = [];
                options['filterExclusivities'] = [];
                options['searchModel']         = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('service_date');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                this.tows.refreshData(options);
            },
            updateActivePage(page) {
                this.tows.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            editTow(rowID) {
                // Clear Map Cache
                sessionStorage.removeItem('TowRequestShowMap');

                this.$inertia.visit('/dashboard/tow-requests/add_tow_requests_step3/edit/' + rowID);
            },
            resetFilters() {
                this.start_date = null;
                this.end_date   = null;
                this.tows.resetAll();
            },
            printPdf: function (id) {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.breakdowns.print_pdf'),
                    responseType: "blob",
                    data: {
                        tow: id
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-breakdown-${id}.pdf`;
                        link.click();

                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            exportCSV() {
                this.button_text = 'Processing...'
                axios({
                    method: 'post',
                    url: route('dashboard.tow_requests.export'),
                    responseType: "blob",
                    data: {
                        // all: all,
                        options: this.tows.options,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        tows: this.searchList
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link    = document.createElement("a");
                        link.href     = window.URL.createObjectURL(blob);
                        link.download = `tow-requests.xlsx`;
                        link.click();
                        link.remove();
                    }
                    this.button_text = 'EXPORT';
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
        },
        mounted() {
            this.tows.init('/dashboard/towing-companies/tows/fetch_tows_ssfp', {
                searchableData: ['service_date', 'membership_number', 'break_down_location', 'rental_agreement', 'tow_company_name', 'status'],
                activeSorts: ['service_date'],
                sortDirs: ['desc'],
                itemsPerPage: 50,
            });
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
