<template>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!towing_companies_data.isLoading">
         <form class="search-form" @submit.prevent="towing_companies_data.refreshData(applyFilters(true))">
            <jet-button type="button" class="button button-primary top-buttons" style="right: 12rem !important" 
                @click="exportCSV()" v-if="$page.props.user.current_team_id != 3"><span class="fas fa-search">{{ button_text }}</span></jet-button>
            
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
            <input
                class="st-search"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center">
                <label for="date" class="m-1">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                <div class="m-1 w-100 md:w-auto">
                    <label for="status" class="m-1">State </label>
                    <select v-model="state" class="border py-2 pl-3 pr-8 text-grey-800 m-1 w-100">
                        <option value=""> - </option>
                        <option
                            v-for="state in states"
                            :key="state"
                            :value="state"
                        >{{state}}</option>
                    </select>
                </div>
            </div>
            <div>
                <jet-button type="submit" class="button button-primary m-1"><span class="fas fa-search">APPLY FILTERS</span></jet-button>
                <JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton>
            </div>
            </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="towing_companies_data.sortClass('name')" @click="towing_companies_data.sortBy('name')">Company Name</p>
            <p :class="towing_companies_data.sortClass('city_state')" @click="towing_companies_data.sortBy('city_state')">City State</p>
            <p :class="towing_companies_data.sortClass('phone')" @click="towing_companies_data.sortBy('phone')">Phone</p>
            <p :class="towing_companies_data.sortClass('email')" @click="towing_companies_data.sortBy('email')">Email</p>
            <p :class="towing_companies_data.sortClass('alert_notes')" @click="towing_companies_data.sortBy('alert_notes')">Alert</p>
            <p :class="towing_companies_data.sortClass('notes')" @click="towing_companies_data.sortBy('notes')">Notes</p>
            <p :class="towing_companies_data.sortClass('insurance_expire')" @click="towing_companies_data.sortBy('insurance_expire',null,'date')">Ins. Expiration</p>
            <p :class="towing_companies_data.sortClass('join_status')" @click="towing_companies_data.sortBy('join_status')">Status</p>

        </div>
        <div class="simple-table" style="--cols:9">
            <p class="st-header first-col" :class="towing_companies_data.sortClass('name')" @click="towing_companies_data.sortBy('name')">Company Name</p>
            <p class="st-header" :class="towing_companies_data.sortClass('addresses')" @click="towing_companies_data.sortBy('city_state')">City / State</p>
            <p class="st-header" :class="towing_companies_data.sortClass('phone')" @click="towing_companies_data.sortBy('phone')">Phone</p>
            <p class="st-header" :class="towing_companies_data.sortClass('email')" @click="towing_companies_data.sortBy('email')">Email</p>
            <p class="st-header" :class="towing_companies_data.sortClass('alert_notes')" @click="towing_companies_data.sortBy('alert_notes')">Alert</p>
            <p class="st-header" :class="towing_companies_data.sortClass('notes')" @click="towing_companies_data.sortBy('notes')">Notes</p>
            <p class="st-header" :class="towing_companies_data.sortClass('insurance_expire')" @click="towing_companies_data.sortBy('insurance_expire',null,'date')">Ins. Expiration</p>
            <p class="st-header" :class="towing_companies_data.sortClass('join_status')" @click="towing_companies_data.sortBy('join_status')">Status</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in towing_companies_data.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Company Name</span>{{ row.name }}</p>
                <p><span class="st-hidden">City / State</span>{{ row.city_state }}</p>
                <p><span class="st-hidden">Phone</span>{{ row.phone }}</p>
                <p><span class="st-hidden">Email</span><a :href="'mailto:' + row.email + '?subject=Vendor%20Update'">{{ row.email }}</a> </p>
                <p class="text-red-300"><span class="st-hidden">Alert</span>{{ row.alert_notes }} </p>
                <p><span class="st-hidden">Notes</span>{{ row.notes }} </p>
                <p :class="GetExpColor(row.insurance_expire)"><span class="st-hidden">Ins. Expiration</span>{{ row.insurance_expire }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.join_status" /></p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/towing-companies/add_edit_towing_company/${row.id}`"
                            as="button"
                            type="button"
                            v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff'"
                        >
                            <jet-button>
                                EDIT
                            </jet-button>
                        </Link>
                        <Link
                            :href="`/dashboard/towing-companies/view_towing_company/${row.id}`"
                            as="button"
                            type="button"
                            v-if="$page.props.user.current_team.name == 'CSR'"
                        >
                            <jet-button>
                                VIEW
                            </jet-button>
                        </Link>

                        <jet-danger-button v-if="$page.props.user.current_team.name == 'Admin' || $page.props.user.current_team.name == 'Office Staff'" @click="confirmMemberDeletion(index, row.id)">
                            DELETE
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ towing_companies_data.rangeText }}</p>
            <Pagination
                v-if="towing_companies_data.itemCount || towing_companies_data.itemCount <= towing_companies_data.itemsPerPage"
                :active-page="towing_companies_data.activePage"
                :item-count="towing_companies_data.itemCount"
                :items-per-page="towing_companies_data.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingDeletion" @close="closeModal">
        <template #title>
            Delete Towing Company
        </template>

        <template #content>
            Are you sure you want to delete this Towing Company? (You will still be able to restore this Towing Company if needed.)

        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteTowingCompany(this.companyId)" >
                Delete Towing Company
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import Pagination from '@/Components/Pagination.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'
    import moment from 'moment';

    export default{
        mixins: [formattingMixin],
        components: {
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            Link,
            JetButton,
            VueLoader,
            Pagination,
            moment,
        },

        data() {
            return {
                towing_companies_data: reactive(new EloquentSSFP()),
                searchModel: '',
                confirmingDeletion: false,
                companyId: null,
                start_date: null,
                end_date: null,
                state: '',
                states: [],
                activePage: 1,
                activeSorts: [],
                sortsDirs: ['asc'],
                sortTypes: ['name'],
                button_text: 'EXPORT'
            }
        },
        methods: {
            applyFilters(resetPage = false, optionsParam = this.towing_companies_data.options) {
                let options = {...optionsParam};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";
                if (resetPage) {
                    options['activePage'] = 1;
                }

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('insurance_expire');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.state) {
                    options['filterProps'].push('state');
                    options['filterValues'].push([this.state]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }
                
                this.storeSearchCriteriaSession();
                return options;
            },
            updateActivePage(page) {
                this.towing_companies_data.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            storeSearchCriteriaSession() {
                sessionStorage.setItem('TowingCompaniesTableSearchModel', this.searchModel);
                sessionStorage.setItem('TowingCompaniesTableState', this.state);
                sessionStorage.setItem('TowingCompaniesTableStartDate', this.start_date);
                sessionStorage.setItem('TowingCompaniesTableEndDate', this.end_date);
                sessionStorage.setItem('TowingCompaniesTablePageNo', this.towing_companies_data.activePage);
                sessionStorage.setItem('TowingCompaniesTableActiveSorts', JSON.stringify(this.towing_companies_data.activeSorts));
                sessionStorage.setItem('TowingCompaniesTableSortDirs', JSON.stringify(this.towing_companies_data.sortDirs));
                sessionStorage.setItem('TowingCompaniesTableSortTypes', JSON.stringify(this.towing_companies_data.sortTypes));
                /*
                activeSorts = the properties in the data being sorted
                sortDirs = the directions those properties are sorting [asc, desc]
                sortTypes = the type of the data [String, Date, Number]
                */
            },
            getSearchCriteriaSession() {
                this.searchModel = sessionStorage.getItem('TowingCompaniesTableSearchModel') ? sessionStorage.getItem('TowingCompaniesTableSearchModel') : '';
                this.state = sessionStorage.getItem('TowingCompaniesTableState') ? sessionStorage.getItem('TowingCompaniesTableState') : '';
                this.start_date = sessionStorage.getItem('TowingCompaniesTableStartDate') !== "null" ? sessionStorage.getItem('TowingCompaniesTableStartDate') : null;
                this.end_date = sessionStorage.getItem('TowingCompaniesTableEndDate') !== "null" ? sessionStorage.getItem('TowingCompaniesTableEndDate') : null;
                this.activePage = sessionStorage.getItem('TowingCompaniesTablePageNo') !== "null" ? Number(sessionStorage.getItem('TowingCompaniesTablePageNo')) : 1;
                this.activeSorts = JSON.parse(sessionStorage.getItem('TowingCompaniesTableActiveSorts'))??['name'];
                this.sortDirs = JSON.parse(sessionStorage.getItem('TowingCompaniesTableSortDirs'))??['asc'];
                this.sortTypes = JSON.parse(sessionStorage.getItem('TowingCompaniesTableSortTypes'))??[];
            },
            deleteSearchCriteriaSession() {
                sessionStorage.removeItem('TowingCompaniesTableSearchModel');
                sessionStorage.removeItem('TowingCompaniesTableState');
                sessionStorage.removeItem('TowingCompaniesTableStartDate');
                sessionStorage.removeItem('TowingCompaniesTableEndDate');
                sessionStorage.removeItem('TowingCompaniesTablePageNo');
                sessionStorage.removeItem('TowingCompaniesTableActiveSorts');
                sessionStorage.removeItem('TowingCompaniesTableSortDirs');
                sessionStorage.removeItem('TowingCompaniesTableSortTypes');
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            GetExpColor(dateIn) {
                let currDate = new Date();
                let monthIn = dateIn.split('/')[0] - 1;
                let dayIn = dateIn.split('/')[1];
                let yearIn = dateIn.split('/')[2];
                let myDateIn = new Date(yearIn, monthIn, dayIn);

                if (currDate >= myDateIn)
                {
                    return "text-red-300";
                }
                return "";
            },
            resetFilters() {
                this.start_date = null;
                this.end_date = null;
                this.state = null;
                this.deleteSearchCriteriaSession();
                this.towing_companies_data.refreshData({
                    searchableData: ['name', 'phone','city_state','email','insurance_expire','join_status', 'alert_notes', 'notes', 'state'],
                    itemsPerPage: 50,
                });
            },
            confirmMemberDeletion(index, id) {
                this.confirmingDeletion = true;
                this.companyId = id;
                this.index = index;
            },
            deleteTowingCompany(id) {
                this.$inertia.post('/dashboard/towing-companies/delete_towing_company/' + id, id, {
                    onSuccess: (id) => {
                        this.closeModal();
                        this.towing_companies_data.refreshData(this.towing_companies_data.options);
                    }
                })
            },
            exportCSV() {
                this.button_text = 'Processing...'
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.export'),
                    responseType: "blob",
                    data: {
                        options: this.towing_companies_data.options,
                        //searchModel: this.searchModel,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        state: this.state
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link    = document.createElement("a");
                        link.href     = window.URL.createObjectURL(blob);
                        link.download = `towing-companies.xlsx`;
                        link.click();
                        link.remove();
                    }
                    this.button_text = 'EXPORT';
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
            fetchStates() {
                axios({
                    method: 'get',
                    url: route('dashboard.towing_companies.fetch_states'),
                })
                .then(response => {
                    this.states = response.data;
                })
            },
            closeModal: function () {
                this.confirmingDeletion = false
            },
        },
        computed: {
            currentPage() {
                return this.towing_companies_data.activePage;
            },
            currentActiveSorts() {
                return this.towing_companies_data.activeSorts;
            },
            currentSortDirs() {
                return this.towing_companies_data.sortDirs;
            },
            currentSortTypes() {
                return this.towing_companies_data.sortTypes;
            }
        },
        watch: {
            currentPage(n) {
                if (n) {
                    sessionStorage.setItem('TowingCompaniesTablePageNo', n);
                }
            },
            currentActiveSorts(n) {
                if (n) {
                    sessionStorage.setItem('TowingCompaniesTableActiveSorts', JSON.stringify(n));
                }
            },
            currentSortDirs(n) {
                if (n) {
                    sessionStorage.setItem('TowingCompaniesTableSortDirs', JSON.stringify(n));
                }
            },
            currentSortTypes(n) {
                if (n) {
                    sessionStorage.setItem('TowingCompaniesTableSortTypes', JSON.stringify(n));
                }
            }
        },
        mounted() {
            this.getSearchCriteriaSession();

            this.towing_companies_data.init('/dashboard/towing-companies/fetch_towing_companies_ssfp', this.applyFilters(false, {
                searchableData: ['name', 'phone','city_state','email','insurance_expire','join_status', 'alert_notes', 'notes', 'state'],
                itemsPerPage: 50,
                activePage: this.activePage,
                sortDirs: this.sortDirs,
                activeSorts: this.activeSorts,
                sortTypes: this.sortTypes
            }));
            this.fetchStates();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
