<template>
    <Head title="Motorcycle Towing Services" />
    <div class="header">
        <div class="logo">
            <a href="/" title="link to homepage"> <img src="/images/logo.png" title="MTS Logo" /></a>
        </div>

        <div class="all-nav">
            <div class="mobile-nav">

                <Link v-if="$page.props.user" href="/dashboard" title="Internal link in same tab to member dashboard">Your Home</Link>
                <Link v-else href="/login" title="Internal link in same tab to login page">LOGIN</Link>
                <a class="phone-num mr-2" href="tel:800-999-7064"><FontAwesomeIcon fixed-width :icon="phone"/></a>
            </div>

            <div class="nav" :class="{expanded: showingNavigationDropdown}">
                <div class="nav-top">
                    <Link v-if="$page.props.user"
                        :href="route('dashboard')"
                        title="Internal link in same tab to member dashboard"
                    >
                    YOUR HOME
                    </Link>
                    <Link v-else :href="route('login')">LOGIN</Link>
                    <Link href="/about-us" title="Internal link in same tab to about us page">ABOUT US</Link>
                    <Link href="/contact-us" title="Internal link in same tab to contact us page">CONTACT US</Link>
                    <a class="phone-num" href="tel:800-999-7064">800-999-7064</a>

                </div>

                <div class="nav-bottom">
                    <div class="hn-list" :class="{expanded: showingNavigationDropdown}">
                    <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown"  :class="{expanded: showingNavigationDropdown}"  data-depth="0">
                    <Link href="/about-us" title="Internal link in same tab to about us page" class="md:hidden h6 nav-title">About Us</Link></div>
                    <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown"  :class="{expanded: showingNavigationDropdown}"  data-depth="0">
                    <Link href="/contact-us" title="Internal link in same tab to contact us page" class="md:hidden h6 nav-title">Contact Us</Link></div>
                    <!-- <Link href="/login" title="Internal link in same tab to login page" class="btn btn-primary">Login</Link>
                <a class="phone-num mx-2 btn btn-primary" href="tel:800-999-7064"><FontAwesomeIcon fixed-width :icon="phone"/></a> </div> -->
                        <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown"  :class="{expanded: showingNavigationDropdown}" data-depth="0"><h6 class="nav-title">Motorist & Rentals</h6>
                            <div class="hn-children" data-depth="0">
                                <Link class="hn-item" href="/motorists-and-rentals/motorist-services" title="Internal link in same tab to Motorist Services page">Motorist Services</Link>
                                <Link class="hn-item" href="/motorists-and-rentals/motorist-rentals" title="Internal link in same tab to Motorist Rentals page">Motorist Rentals</Link>
                                <Link class="hn-item" href="/motorists-and-rentals/membership-options" title="Internal link in same tab to Membership Options page">Membership Options</Link>
                                <Link class="hn-item" href="/motorists-and-rentals/member-registration" title="Internal link in same tab to Membership Application page">Membership Application</Link>
                                <Link class="hn-item" href="/login" title="Internal link in same tab to Portal Login page">Member Login</Link>
                                <Link class="hn-item" href="/optin" title="Internal link in same tab to Portal Opt-In/Opt-Out page">Text Opt-In/Opt-Out</Link>
                            </div>
                        </div>
                        <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown" :class="{expanded: showingNavigationDropdown}"  data-depth="0"> <h6 class="nav-title">Towing Companies</h6>
                            <div class="hn-children" data-depth="0" title="Internal link in same tab to page">
                                <Link class="hn-item" href="/towing-companies/towing-company-services" title="Internal link in same tab to Towing Company Services page">Towing Company Services</Link>
                                <Link class="hn-item" href="/invoice" title="Internal link in same tab to Submit Invoices page">Submit Invoices</Link>
                                <Link class="hn-item" href="/towing-companies/vendor-application" title="Internal link in same tab to Vendor Application page">Vendor Application</Link>
                            </div>
                        </div>
                        <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown" :class="{expanded: showingNavigationDropdown}"  data-depth="0"> <h6 class="nav-title">Dealerships</h6>
                            <div class="hn-children" data-depth="0" title="Internal link in same tab to page">
                                <Link class="hn-item" href="/dealerships/dealership-services" title="Internal link in same tab to Dealership Services page">Dealership Services</Link>
                                <Link class="hn-item" href="/dealerships/dealership-application" title="Internal link in same tab to Dealership Application page">Dealership Application</Link>
                                <Link class="hn-item" href="/login" title="Internal link in same tab to Portal Login page">Dealer Login</Link>
                            </div>
                        </div>
                        <div class="hn-family" @click="showingNavigationDropdown = ! showingNavigationDropdown" :class="{expanded: showingNavigationDropdown}"  data-depth="0"> <Link href="/testimonials" title="internal link in same tab to testimonials page" class="h6 nav-title">Testimonials</Link>
                        </div>
                        <a class="hn-family" data-depth="0" @click="showingNavigationDropdown = ! showingNavigationDropdown" :class="{expanded: showingNavigationDropdown}" > <h6 class="nav-title">Apply Now</h6>
                            <div class="hn-children hn-last" data-depth="0" title="Internal link in same tab to page">
                                <Link class="hn-item" href="/motorists-and-rentals/member-registration" title="Internal link in same tab to Motorist Membership Application page">Motorist Membership Application</Link>
                                <Link class="hn-item" href="/towing-companies/vendor-application" title="Internal link in same tab to Towing Company Application page">Towing Company Application</Link>
                                <Link class="hn-item" href="/dealerships/dealership-application" title="Internal link in same tab to Dealership Application page">Dealership Application</Link>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="-mr-2 flex items-center md:hidden nav-control">
                <button @click="showingNavigationDropdown = ! showingNavigationDropdown" class="frontEndHamburger">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        <path :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';
    @import '../../scss/_nav-list.scss';

    .frontEndHamburger {
            font-weight: bold;
            position: relative;
            color: var(--blue-500);
            overflow: hidden;
            position: fixed;
            bottom: 1rem;
            left: calc(50% - 8.5px); // The width of the average scrollbar
            transform: translate(-50%, 0);
            transition: 200ms ease-out;
            background-color: var(--gray-200);
            border-radius: 50rem;
            box-shadow: 2px 1px 5px rgba(0,0,0,.15);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 12;
            height: 40px;
            width: 40px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: .75rem;
        position: relative;
        z-index: 1;

        .logo {
            display: flex;
            justify-items: flex-start;

            img {
                max-width: 255px;
                height: auto;
                margin: 0 2.5rem;

                @include bp-down($bp-lg) {
                    max-width: 200px;
                    height: auto;
                }
            }
        }

        .all-nav {
            display:flex;
            flex-direction: column;

        .nav-control {
            z-index: 20;
        }

        .mobile-nav {
            display: flex;
            color: white;
            font-weight: bold;
            margin: 0 3rem 0 0;
            align-items: top;
            text-shadow: 5px 5px 10px rgba(0,0,0,.35);

            > a {
                padding: 0 .5rem;
            }

            @include bp-up($bp-md + 1) {
                display: none;
            }

        }

        .nav {
            display: none;

            @include bp-up($bp-md + 1) {
                display: flex;
                flex-direction: column;
                justify-items: flex-end;
                align-content: space-between;
                text-transform: uppercase;
                color: white;
            }


            @include bp-down($bp-md) {
                &.expanded {
                    display: flex;
                    flex-direction: column;
                }
            }

            .nav-top {
                display: flex;
                justify-content: flex-end;
                margin: 0;
                margin: 0 3rem 0 0;
                align-items: center;
                text-shadow: 5px 5px 10px rgba(0,0,0,.35);

                    > a {
                    padding: .5rem .875rem;
                }

                .phone-num {
                    font-size: 1.75rem;
                    font-weight: bold;
                    text-shadow: 5px 5px 10px rgba(0,0,0,.35);
                }
            }
            .nav-bottom {
                display: flex;
                background-color: var(--navy-nav);
                padding: 0 1.5rem;

                > a {
                    padding: .5rem 1rem;
                    font-weight: bold;
                }

                .nav-title {
                    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));

                    @include bp-down($bp-md) {
                        font-size: 18px;
                        padding-top: .75rem;
                        border-bottom: 2px solid var(--red-800);

                    }
                }
            }
        }
    }
}
</style>


<script>
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import JetResponsiveNavLink from '@/Jetstream/ResponsiveNavLink.vue'
    import { faPhone } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        components: {
            Head,
            Link,
            JetResponsiveNavLink,
            faPhone,
            FontAwesomeIcon,
        },
        props: {
            canLogin: Boolean,
            canRegister: Boolean,
            laravelVersion: String,
            phpVersion: String,
        },
        data() {
            return {
                showingNavigationDropdown: false,
                showMobileDropdown: false,
                phone: faPhone,
            }
        },
        methods: {
            switchToTeam(team) {
                this.$inertia.put(
                    route('current-team.update'),
                    { 'team_id': team.id },
                    { preserveState: false }
                )
            },
            logout() {
                this.$inertia.post(route('logout'));
            },
        },
        mounted() {
            
        }
    }
</script>
