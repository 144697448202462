<template>
    <FrontEndLayout title="Dealership Application">
        <template #head>
            <meta name="description" content="MTS Dealership Application">
            <meta name="keywords" content="application">
        </template>
        <template #header>
            <h1>DEALERSHIP APPLICATION</h1>
            <Teleport to="body"><FlashMessage /></Teleport>
        </template>
        <template #fepage-content>
            <div class="container text-center mx-5">
            <h2 class="font-bold text-navy-500">Complete Our Simple Application Form</h2>
            <p>Please fill out this Dealership information Form and an MTS representative will be in contact with you regarding your interest in our dealership plans.</p>
            </div>
            <form @submit.prevent="submit" id="dealerApplicationForm">
                <div class="gray-section">
                    <div class="container">
                <h3>Company Information</h3>
                <div>
                    <jet-label for="company_name" value="Company Name" />
                    <jet-input id="company_name" type="text" class="mt-1 block w-full" name="company_name" required v-model="form.company_name" />
                    <jet-input-error :message="form.errors.company_name" class="mt-2" />
                </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="first_name" value="First Name" />
                    <jet-input id="first_name" type="text" class="mt-1 block w-full" name="first_name" required v-model="form.first_name" />
                    <jet-input-error :message="form.errors.first_name" class="mt-2" />
                </div>

                <div>
                    <jet-label for="last_name" value="Last Name" />
                    <jet-input id="last_name" type="text" class="mt-1 block w-full" name="last_name" required v-model="form.last_name"  />
                    <jet-input-error :message="form.errors.last_name" class="mt-2" />
                </div>
            </div>
                <div>
                    <jet-label for="address" value="Address" />
                    <jet-input id="address" type="text" class="mt-1 block w-full" name="address" required  v-model="form.address" />
                    <jet-input-error :message="form.errors.address" class="mt-2" />
                </div>

                <div>
                    <jet-label for="address2" value="Address 2" />
                    <jet-input id="address2" type="text" class="mt-1 block w-full" name="address2"  v-model="form.address2" />
                    <jet-input-error :message="form.errors.address2" class="mt-2" />
                </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" name="city" required  v-model="form.city" />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>

                <div>
                    <jet-label for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>

                <div>
                    <jet-label for="zip" value="Zip/Postal Code" />
                    <jet-input id="zip" type="text" class="mt-1 block w-full" name="zip" required  v-model="form.zip" />
                    <jet-input-error :message="form.errors.zip" class="mt-2" />
                </div>
                <div>
                    <jet-label for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" name="country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <vue-tel-input id="phone" label="Office Phone" v-model="form.phone" :error-message="form.errors.phone" />
                </div>

                <div>
                    <jet-label for="email" value="Email" />
                    <jet-input id="email" type="email" class="mt-1 block w-full" name="email" required  v-model="form.email" />
                    <jet-input-error :message="form.errors.email" class="mt-2" />
                    </div>
                </div>
                </div>
                </div>
                <div class="container">
                <h3>Mailing Address</h3>
                <div class="grid md:grid-cols-6 grid-cols-2 gap-1">
                    <jet-label class="col-span-1 h4 text-lg" for="same_as_company" value="Same as Company Address" />
                    <jet-input id="same_as_company" type="checkbox" class="mt-1 md:col-span-5" :checked="same_as_company" @change.prevent="same_as_company = !same_as_company" />
                </div>
                <div>
                    <jet-label for="mailing_address" value="Address" />
                    <jet-input id="mailing_address" type="text" class="mt-1 block w-full" name="mailing_address" required   v-model="form.mailing_address"/>
                </div>

                <div>
                    <jet-label for="mailing_address2" value="Address 2" />
                    <jet-input id="mailing_address2" type="text" class="mt-1 block w-full" name="mailing_address2"   v-model="form.mailing_address2"/>
                </div>

                <div>
                    <jet-label for="mailing_city" value="City" />
                    <jet-input id="mailing_city" type="text" class="mt-1 block w-full" name="mailing_city" required   v-model="form.mailing_city"/>
                </div>
                <div>
                    <jet-label for="mailing_state" value="State/Province" />
                    <select id="mailing_state" class="mt-1 block w-full" v-model="form.mailing_state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.mailing_state" class="mt-2" />
                </div>

                <div>
                    <jet-label for="mailing_zip" value="Zip/Postal Code" />
                    <jet-input id="mailing_zip" type="text" class="mt-1 block w-full" name="mailing_zip" required   v-model="form.mailing_zip"/>
                </div>

                <div>
                    <jet-label for="mailing_country" value="Country" />
                    <select id="mailing_country" class="mt-1 block w-full" v-model="form.mailing_country" name="mailing_country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.mailing_country" class="mt-2" />
                </div>
                </div>
                <div class="gray-section">
                    <div class="container">
                <em>
                    ** For more information
                    Call: 800-999-7064/319-984-6124
                    Email: info@mts-towing.com
                    Monday - Friday, 9am-3pm (CST)<br>
                    
                    Please allow 7-10 days to receive your membership kit.
                    MTS reserves the right to accept or deny membership.
                </em>
                <div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">
                    <jet-label for="terms">
                        <div class="flex items-center">
                            <jet-checkbox name="terms" id="terms" />

                            <div class="ml-2">
                                I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
                            </div>
                        </div>
                    </jet-label>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <jet-button class="ml-4" >
                        Submit
                    </jet-button>
                </div>
                    </div>
                    </div>
            </form>
        </template>
    </FrontEndLayout>
</template>

<style lang="scss">
    @import '../../../../scss/_mixins.scss';


main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;
        z-index: 2;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: -1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: -1;

        }
    }

</style>

<script>
    import { defineComponent } from 'vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'

    export default defineComponent({
        components: {
            Head,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetInputError,
            JetValidationErrors,
            Link,
            FrontEndLayout,
            FlashMessage,
            VueTelInput,
        },

        data() {
            return {
                form: this.$inertia.form({
                    dealer_app: true,
                    company_name: '',
                    first_name: '',
                    last_name: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    phone: '',
                    email: '',
                    mailing_address: '',
                    mailing_address2: '',
                    mailing_city: '',
                    mailing_state: '',
                    mailing_zip: '',
                    mailing_country: '',
                }),
                same_as_company: false,
                states: [],
                countries: ['USA', 'Canada'],
            }
        },

        methods: {
            submit() {
                this.form.post(route('.dealerships.store'), {
                    errorBag: 'dealerApplication',
                    onSuccess: () => this.resetForm(),
                });
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            resetForm() {
                this.form.reset();
                // Find all check boxes and reset them to their default state
                // It finds the checkboxes with the class attribute "checkbox"
                var allInputs = document.getElementsByTagName("input");
                for (var i = 0, max = allInputs.length; i < max; i++){
                    if (allInputs[i].type === 'checkbox')
                        allInputs[i].checked = allInputs[i].defaultChecked;
                }
            },
            setRequired() {
                const membershipForm = document.getElementById("dealerApplicationForm");
                const inputs         = membershipForm.querySelectorAll("[required]");
                inputs.forEach(input => {
                    let id = input.id;
                    let label = input.closest('label');
                    if (id) label = membershipForm.querySelector(`label[for="${input.id}"]`)
                    let span = document.createElement('span');
                    span.classList.add('text-red-700');
                    span.innerHTML = " *";
                    if (label) label.appendChild(span)
                })
            }
        },
        mounted() {
            this.setRequired()
        },
        watch:  {
            same_as_company (n) {
                if(n){
                    this.form.mailing_address = this.form.address
                    this.form.mailing_address2 = this.form.address2
                    this.form.mailing_city = this.form.city
                    this.form.mailing_zip = this.form.zip
                    this.form.mailing_state = this.form.state
                    this.form.mailing_country = this.form.country
                } else {
                    this.form.mailing_address = ""
                    this.form.mailing_address2 = ""
                    this.form.mailing_city = ""
                    this.form.mailing_state = ""
                    this.form.mailing_zip = ""
                    this.form.mailing_country = ""
                }
            }
        },
        created: function(){
            this.getStates()
        }
    })
</script>
