<template>
    <nav class="sidebar">
        <div class="logo">
            <Link :href="route('dashboard')">
                <img src="/images/mts-logo.png" alt="MTS Logo">
            </Link>
        </div>
        <div class="navigation">
            <div class="navigation-link" :class="{active: route().current('dashboard')}">
                <Link class="text-navy-500" :href="route('dashboard')">
                    <span>Dashboard</span>
                </Link>
            </div>

            <div class="navigation-link" :class="{active: route().current('dashboard.motorists.membership.membership')}">
                <Link class="text-navy-500" :href="route('dashboard.motorists.membership.membership')">
                    <span>Renew Now</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('dashboard.tow_requests.*')}">
                <Link class="text-navy-500" :href="'/dashboard/tow-requests/add_tow_requests_step2/' + member_id">
                    <span>Request a Tow</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('dashboard.motorists.membership.my_bikes.*')}">
                <Link class="text-navy-500" :href="route('dashboard.motorists.membership.my_bikes')">
                    <span>Manage Bikes</span>
                </Link>
            </div>
        </div>
    </nav>
</template>

<script>
import { faHouseUser, faPenAlt, faBook, faClipboard, faUsers } from '@fortawesome/free-solid-svg-icons';
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Link } from '@inertiajs/inertia-vue3';
import AppFooter from '@/Layouts/AppFooter.vue';

export default {
    name: "AppSidebar",
    components: {
        JetApplicationLogo,
        FontAwesomeIcon,
        Link,
        AppFooter,
    },
    props: ['member_id'],
    data() {
        return {
            houseUser: faHouseUser,
            penAlt: faPenAlt,
            clipboard: faClipboard,
            book: faBook,
            users: faUsers,
        }
    },
    methods:{
         routeAlert() {
            alert('This will go to correct page once route is made!')
         }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .sidebar {
        position: sticky;
        top: var(--footer-height);
        transform: translateY(calc(-1 * var(--footer-height)));
        height: 100%;
        grid-area: nav;
        z-index: 1;

        @include bp-down($bp-lg) {
            overflow: hidden;
            overflow-y: auto;
            position: fixed;
            height: 100vh;
            width: 100%;
            left: -100%;
            transition: 200ms ease-out;
            z-index: 3;

            .app.expanded & {
                left: 0;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;

            @include desktop {
                box-shadow: 2px 0 5px rgba(0,0,0,.1);
            }
        }

        .logo {
            grid-area: logo;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            transition: 200ms ease-out;
            position: relative;

            :deep(img) {
                @include fluid-size(100px, 125px, height);
                width: auto;
                transition: 200ms ease-out;
            }
        }

        .navigation {
            display: flex;
            flex-direction: column;

            .navigation-link {
                position: relative;

                span { transition: 200ms ease-out; }

                &:before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: white;
                    border-right: 0 solid transparent;
                    box-shadow: 0 0 0 rgba(0,0,0,0);
                    transition: 200ms ease-out;
                }

                &.active, &:hover {
                    &:before {
                        background-color: var(--navy-500);
                        box-shadow: 0 2px 5px rgba(0,0,0,.1);
                        border-right: 4px solid var(--blue-500);

                        @include desktop { width: calc(100% + 8px); }
                    }

                    a { color: white; }
                }

                a {
                    @include fluid-size(18px, 20px, font-size);
                    @include fluid-size(10px, 12px, padding-top);
                    @include fluid-size(10px, 12px, padding-bottom);
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    display: block;
                    font-weight: bold;
                    position: relative;
                    color: var(--navy-500);
                    transition: 200ms ease-out;

                    svg {
                        @include fluid-size(12px, 16px, margin-right);
                    }
                }

                &.child {
                    background-color: #F2F2F2;
                }
            }

            .navigation-child-group {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: #F2F2F2;
                    box-shadow: inset 0 0px 5px 2px rgb(0,0,0,.1);
                }
            }

            .navigation-child {
                position: relative;

                &.active, &:hover {
                    a {
                        color: var(--navy-500);
                        font-weight: bold;
                    }
                }

                a {
                    @include fluid-size(14px, 16px, font-size);
                    @include fluid-size(8px, 16px, padding-top);
                    @include fluid-size(8px, 16px, padding-bottom);
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    display: block;
                    font-size: 22px;
                    font-weight: regular;
                    position: relative;
                    color: var(--navy-500);
                    transition: 200ms ease-out;
                }
            }
        }
    }
</style>