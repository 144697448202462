<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <div v-if="$page.props.user.current_team_id != 4" class="mb-1">
        <jet-secondary-button @click="refresh()" class="m-1 w-full md:w-auto" >
            Last Data Fetch at {{ timestamp }}, Click to refresh
        </jet-secondary-button>
        <jet-secondary-button v-if="!breakdownLocationSet" @click="setKnownLocation" class="m-1 w-full md:w-auto" >
            Show Tow Locator Map
        </jet-secondary-button>
        <div v-if="!breakdownLocationSet && !form.location_sms_sent && (form.call_back_phone && form.person_calling)">
            <p>Update Tow and send request for location via SMS</p>
            <jet-button @click="submitTowRequest(true)" class="mt-5" >
                Update and send SMS
            </jet-button>
        </div>
        <jet-button style="float:right" class="button button-primary md:w-auto w-full m-1" @click="back">
            BACK
        </jet-button>
    </div>
    <jet-form-section @submitted="submitTowRequest">
        <template #form @submit.prevent="submitTowRequest">
            <jet-input id="member_id" type="hidden" name="member_id" v-model="form.member_id" />
            <jet-input id="bike_id" type="hidden" name="bike_id" v-model="form.bike_id" />
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-150 px-3 py-5 my-4">
               <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">First Name</h6>
                    {{ members_data.first_name }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Last Name</h6>
                    {{ members_data.last_name }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Account Type</h6>
                    {{ members_data.member_type_name }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Membership Number</h6>
                    {{ members_data.member_number }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Expiration Date</h6>
                    {{ expireDate }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Join Date</h6>
                    {{ joinDate }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Bike Year</h6>
                    {{ bike.year }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Bike Make</h6>
                    {{ bike.make }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Bike Model</h6>
                    {{ bike.model }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">VIN</h6>
                    {{ bike.vin }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Color</h6>
                    {{ bike.color }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">License</h6>
                    {{ bike.plate }}
                </div>
            </div>

        <div class="grid grid-cols-2 gap-4">
            <div v-if="!['4','5'].some(r => $page.props.user.current_team_id == r)">
                <jet-label for="person_calling" value="Person Calling" />
                <jet-input id="person_calling" type="text" class="mt-1 block w-full" v-model="form.person_calling" />
                <jet-input-error :message="form.errors.person_calling" class="mt-2" />
            </div>
            <div>
                <vue-tel-input id="call_back_phone" label="Call Back Number" v-model="form.call_back_phone" :error-message="form.errors.call_back_phone" />
            </div>
            <div v-if="!['4','5'].some(r => $page.props.user.current_team_id == r)">
                <jet-label for="date" value="Date" />
                <jet-input id="date" type="date" class="mt-1 block w-full" v-model="form.date" />
                <jet-input-error :message="form.errors.date" class="mt-2" />
            </div>
            <div v-if="!['4','5'].some(r => $page.props.user.current_team_id == r)">
                <div >
                    <jet-label for="time_call_received" value="Time Call Received" />
                    <jet-input id="time_call_received" type="time" class="mt-1 block w-full" v-model="form.time_call_received" />
                    <jet-input-error :message="form.errors.time_call_received" class="mt-2" />
                </div>
                <div>
                    <jet-label for="po_number" value="PO Number" />
                    <jet-input id="po_number" type="text" class="mt-1 block w-full" v-model="form.po_number" />
                    <jet-input-error :message="form.errors.po_number" class="mt-2" />
                </div>
                <div>
                    <jet-label for="ra_number" value="RA Number" />
                    <jet-input id="ra_number" type="text" class="mt-1 block w-full" v-model="form.ra_number" />
                    <jet-input-error :message="form.errors.ra_number" class="mt-2" />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 bg-gray-150 px-3 py-5 my-4" v-if="!['4','5'].some(r => $page.props.user.current_team_id == r)" >
            <div>
                <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Member Notes</h6>
               {{ members_data.notes }}
            </div>
            <div>
                <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Member Alerts</h6>
                <span class="text-red-500">{{ members_data.alert }}</span>
            </div>
            <div>
                 <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Terms</h6>
                {{ members_data.terms }}
            </div>
        </div>
        <div v-if="!['4','5'].some(r => $page.props.user.current_team_id == r)">
            <h3 class="text-navy-500 mt-5">Tow Sheet</h3>

            <div class="grid grid-cols-4 gap-4">
                <div class="col-span-1">
                    <jet-label for="problem_type" value="Problem Type" />
                    <select id="problem_type" v-model="form.problem_type" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in problem_type_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.problem_type" />
                </div>
                <div class="col-span-3">
                    <jet-label for="description" value="Description" />
                    <textarea id="description" class="block w-full" name="description" v-model="form.description"></textarea>
                    <jet-input-error :message="form.errors.description" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-4 gap-4 mt-1">
                <div class="col-span-2">
                    <jet-label for="break_down_location" value="Breakdown Location Address" />
                    <jet-input id="break_down_location" type="text" class="border block w-full" v-model="form.break_down_location" />
                    <jet-input-error :message="form.errors.break_down_location" class="mt-2" />
                </div>
                <div >
                    <jet-label for="breakdown_city" value="Breakdown Location City" />
                    <jet-input id="breakdown_city" type="text" class="border block w-full" v-model="form.breakdown_city" />
                    <jet-input-error :message="form.errors.breakdown_city" class="mt-2" />
                </div>
                <div>
                    <jet-label for="breakdown_state" value="Breakdown Location State" />
                    <select id="breakdown_state" v-model="form.breakdown_state" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in breakdown_state_list"
                            :key="option.value"
                            :value="option.abbreviation"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.breakdown_state" class="mt-2" />
                </div>
            </div>
            <div class="mt-5 w-100" v-if="breakdownLocationSet">
                <div v-if="form.latitude != 0 && form.longitude != 0" class="grids">
                    <Map
                        ref="googleMap"
                        :lat="form.latitude"
                        :lng="form.longitude"
                        :rangeList="towing_companies_in_range"
                        @selected-towing-company="updateTowingCompany($event)"
                    />
                </div>
            </div>
            <div class="mt-5 w-100" v-if="knownLocation">
                <div class="grids">
                    <TowLocatorMap
                        ref="towLocatorMap"
                        :lat="form.latitude"
                        :lng="form.longitude"
                        :city="form.breakdown_city"
                        :state="form.breakdown_state"
                        :rangeList="towing_companies_in_range"
                        @selected-towing-company="updateTowingCompany($event)"
                    />
                </div>
            </div>
            <div
                v-if="form.latitude && form.longitude"
                class="flex flex-start bg-gray-150 px-3 py-5 my-4"
            >
                <div><p>Latitude: <strong>{{form.latitude}}</strong> / Longitude: <strong>{{form.longitude}}</strong></p></div>
            </div>
            <div>
                <h3 class="text-navy-500 mt-5">Tow Notes</h3>
            </div>
            <TowNotesTable
                ref="towNotesTable"
                :data="tow_notes"
                v-if="tow_notes.length != 0"
                @family-updated="fetchMemberData()"
                @row-deleted="rowDeleted($event, 'family')"
                class="step3"
            />
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 justify-between bg-gray-150 p-4">
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1">Towing Company</h6>
                    {{ towing_company.company_name }}
                </div>
                <div>
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1 pr-2">Phone Number</h6>
                    {{ towing_company.company_phone }}
                </div>
                <div>
                    <jet-label for="note" value="Note" />
                    <textarea id="note" class="mt-1 block w-full" name="note" v-model="tow_note.note"></textarea>
                    <jet-input-error :message="tow_note.errors.note" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 justify-between bg-gray-150 p-4">
                <div class="col-span-1">
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1">Equipment</h6>
                    {{ equipmentList }}
                </div>
                <div class="col-span-1">
                    <h6 class="font-bold text-blue-900 h6 mt-3 mb-1">Alert Notes</h6>
                    <span class="text-red-900">{{ towing_company.alert_notes }}</span>
                </div>
                <jet-secondary-button v-show="towing_company.company_name" @click="useTowingCompany" class="row-start-3 md:row-start-2" type="button">Select Towing Company</jet-secondary-button>
                <jet-button @click="addTowNote" class=" md:col-start-3" type="button">Add Note</jet-button>
            </div>
            <div>
                <h3 class="text-navy-500 mt-5">Dispatch Information</h3>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <!-- Removed per discussion on 10/12/2022 MTS Meeting.  Both Dale and Jeff wanted it removed.
                <div class="col-span-1">
                    <jet-label for="time_called" value="Time Called" />
                    <jet-input id="time_called" type="time" class="mt-1 block w-full" v-model="form.time_called" />
                    <jet-input-error :message="form.errors.time_called" class="mt-2" />
                </div>
                -->
                <div class="col-span-4">
                    <jet-label for="notes" value="Notes" />
                    <textarea id="notes" class="mt-1 block w-full" name="notes" v-model="form.notes"></textarea>
                    <jet-input-error :message="form.errors.notes" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="towing_company_id" value="Towing Company" />
                    <select id="towing_company_id" v-model="form.towing_company_id" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in towing_company_list"
                            :key="option.id"
                            :value="option.id"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.towing_company" class="mt-2" />
                </div>
                <div>
                    <jet-label for="time_dispatched" value="Time Dispatched" />
                    <jet-input id="time_dispatched" type="time" class="block w-full" v-model="form.time_dispatched" />
                    <jet-input-error :message="form.errors.time_dispatched" class="mt-2" />
                </div>
                <div>
                    <jet-label for="tow_eta" value="ETA" />
                    <jet-input id="tow_eta" type="text" class="block w-full" v-model="form.tow_eta" />
                    <jet-input-error :message="form.errors.tow_eta" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <vue-tel-input id="tow_company_driver_cell" label="Driver's Cell" v-model="form.tow_company_driver_cell" :error-message="form.errors.tow_company_driver_cell" />
                </div>
                <div>
                    <jet-label for="dispatchers_name" value="Dispatcher's Name" />
                    <jet-input id="dispatchers_name" type="text" class="mt-1 block w-full" v-model="form.dispatchers_name" />
                    <jet-input-error :message="form.errors.dispatchers_name" class="mt-2" />
                </div>
                <div>
                    <jet-label for="stored" value="Stored" />
                    <select id="stored" v-model="form.stored" class="border mt-1 py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in stored_list"
                            :key="option.value"
                            :value="option.value"
                        >{{option.name}}</option>
                    </select>
                    <jet-input-error :message="form.errors.stored" class="mt-2" />
                </div>
            </div>
            <div>
                <div v-if="breakdownLocationSet && form.tow_company_driver_cell" class="message success mt-5 mb-5">
                    <p>Client has confirmed their location, Use this button to send a text to the towing company with exact position on a google map.</p>
                    <jet-button type="button" @click="submitTowRequest(false, true)" class="mt-5" >
                        {{ send_tow_driver_sms_text }}
                    </jet-button>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <jet-label for="dealer_name" value="Dealer Name" />
                    <jet-input id="dealer_name" type="text" class="mt-1 block w-full" v-model="form.dealer_name" />
                    <jet-input-error :message="form.errors.dealer_name" class="mt-2" />
                </div>
                 <div>
                    <jet-label for="status" value="Tow Status" />
                    <select id="status" v-model="form.status" class="border py-2 px-3 text-grey-800 w-full">
                        <option
                            v-for="option in tow_status_list"
                            :key="option"
                            :value="option"
                        >{{option}}</option>
                    </select>
                    <jet-input-error :message="form.errors.status" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="location" value="Location" />
                    <jet-input id="location" type="text" class="mt-1 block w-full" v-model="form.location" />
                    <jet-input-error :message="form.errors.location" class="mt-2" />
                </div>
                <div>
                    <jet-label for="mts_dispatchers_name" value="MTS Dispatcher's Name" />
                    <jet-input id="mts_dispatchers_name" type="text" class="mt-1 block w-full" v-model="form.mts_dispatchers_name" />
                    <jet-input-error :message="form.errors.mts_dispatchers_name" class="mt-2" />
                </div>
            </div>
             <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <jet-label for="on_scene" value="On Scene" />
                    <jet-input id="on_scene" type="time" class="mt-1 block w-full" v-model="form.on_scene" />
                    <jet-input-error :message="form.errors.on_scene" class="mt-2" />
                </div>
                <div>
                    <jet-label for="clear_time" value="Clear Time" />
                    <jet-input id="clear_time" type="time" class="mt-1 block w-full" v-model="form.clear_time" />
                    <jet-input-error :message="form.errors.clear_time" class="mt-2" />
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="date_invoiced" value="Date Invoiced" />
                    <jet-input id="date_invoiced" type="date" class="mt-1 block w-full" v-model="form.date_invoiced" />
                    <jet-input-error :message="form.errors.date_invoiced" class="mt-2" />
                </div>
                <div>
                    <jet-label for="amount_invoiced" value="Amount Invoiced" />
                    <jet-input id="amount_invoiced" type="number" class="mt-1 block w-full" v-model="form.amount_invoiced" />
                    <jet-input-error :message="form.errors.amount_invoiced" class="mt-2" />
                </div>
                <div>
                    <jet-label for="date_paid" value="Date Paid" />
                    <jet-input id="date_paid" type="date" class="mt-1 block w-full" v-model="form.date_paid" />
                    <jet-input-error :message="form.errors.date_paid" class="mt-2" />
                </div>
                <div>
                    <jet-label for="check_number" value="Payment Method" />
                    <jet-input id="check_number" type="text" class="mt-1 block w-full" v-model="form.check_number" />
                    <jet-input-error :message="form.errors.check_number" class="mt-2" />
                </div>
            </div>
        </div>
        </template>
        <template #actions>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                Tow Submitted.
            </jet-action-message>

            <jet-button @click="submitTowRequest()" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import VueTelInput from '@/Components/VueForms/VueTelInput.vue'
    import Map from '../Partials/Map.vue'
    import TowLocatorMap from '../Partials/Map/Map.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import TowNotesTable from '../Tables/TowNotesTable.vue'
    import moment from 'moment'
    import axios from 'axios'
    import dayjs from 'dayjs'

    export default {
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            Map,
            moment,
            VueTelInput,
            FlashMessage,
            dayjs,
            TowLocatorMap,
            TowNotesTable,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'POST',
                    id: 0,
                    member_id: 0,
                    bike_id: 0,
                    person_calling: '',
                    call_back_phone: '',
                    date: '',
                    time_call_received: '',
                    po_number: '',
                    ra_number: '',
                    problem_type: '',
                    description: '',
                    break_down_location: '',
                    breakdown_city: '',
                    breakdown_state: '',
                    additional_info: '',
                    time_called: '',
                    notes: '',
                    towing_company_id: 0,
                    time_dispatched: '',
                    tow_eta: '',
                    dispatchers_name: '',
                    stored: 0,
                    dealer_name: '',
                    location: '',
                    mts_dispatchers_name: '',
                    latitude: null,
                    longitude: null,
                    tow_company_driver_cell: '',
                    location_sms_sent: 0,
                    tow_company_sms_sent: 0,
                    date_invoiced: '',
                    amount_invoiced: '',
                    date_paid: '',
                    check_number: '',
                    status: 'Pending',
                    send_sms: false,
                    on_scene: null,
                    clear_time: null,
                }),
                tow_note: this.$inertia.form({
                    tow_id: 0,
                    company_name: '',
                    company_phone: '',
                    note: '',
                }),
                tow_notes: [],
                members_data: {
                    first_name: '',
                    last_name: '',
                    member_type: '',
                    member_number: '',
                    plan_expiration: '',
                    notes: '',
                    alert: '',
                    terms: '',
                },
                bike: {
                    color: '',
                    country: '',
                    id: '',
                    make: '',
                    model: '',
                    plate: '',
                    state: '',
                    vin: '',
                    year: ''
                },
                towing_company: {
                    id: 0,
                    company_name: '',
                    company_phone: ''
                },
                towing_companies_in_range: [],
                problem_type_list: [
                    {value: 'Accident', name: 'Accident'},
                    {value: 'Battery', name: 'Battery'},
                    {value: 'Electrical', name: 'Electrical'},
                    {value: 'GAS', name: 'GAS'},
                    {value: 'GOA', name: 'GOA'},
                    {value: 'JumpStart', name: 'Jump Start'},
                    {value: 'Mechanical', name: 'Mechanical'},
                    {value: 'Miscellaneous', name: 'Miscellaneous'},
                    {value: 'Tire', name: 'Tire'},
                    {value: 'Vandalism', name: 'Vandalism'},
                ],
                breakdown_state_list: [],
                cod_list: [
                    {value: 'PossReim', name: 'PossReim'},
                    {value: 'NoReim', name: 'NoReim'},
                ],
                towing_company_list: [
                    {value: '', name: ''},
                ],
                stored_list: [
                    {value: '1', name: 'Yes'},
                    {value: '0', name: 'No'},
                ],
                tow_status_list: ['Pending', 'On Hold', 'No Tow', 'Cancelled By Member', 'COD by Member', 'New Tow Company', 'Completed'],
                timestamp: '',
                knownLocation: false,
                temp_tow_company_id: 0,
                send_tow_driver_sms_text: 'Update and Send SMS For Location',
                equipment_list: [],
                return_to_page: '/dashboard/tow-requests',
            }
        },
        methods: {
            back() {
                window.location.replace(this.return_to_page);
            },
            refresh() {
                window.location.reload();
            },
            setKnownLocation() {
                this.knownLocation = true;
                sessionStorage.setItem('TowRequestShowMap', this.knownLocation);
            },
            getNow() {
                    const today    = new Date();
                    const date     = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                    const time     = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    const dateTime = date +' '+ time;
                    this.timestamp = dayjs(dateTime).format('MM/DD/YYY h:mm:s A');
            },
            checkForClientLocation() {
                if(this.form.id != 0){
                    axios({
                        method: 'post',
                        url: route('dashboard.towing_companies.tows.check_tow_location_data'),
                        data: {
                            tow: this.form.id
                        }
                    })
                    .then(response => {
                        if(response.data.lat && response.data.lng) {
                            this.form.latitude  = response.data.lat;
                            this.form.longitude = response.data.lng;
                        }
                    })
                }

            },
            fetchData(){
                var segment_str   = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var segment2      = parseInt(segment_array.pop());
                var segment1      = segment_array.pop();

                if (segment1 == "breakdowns")
                {
                    this.return_to_page = '/dashboard/back-office/breakdowns';
                }

                if (segment1 == "edit" || segment1 == "breakdowns")
                {
                    this.fetchTowData(segment2);
                }
                else
                {
                    segment1 = parseInt(segment1);
                    this.fetchMemberData(segment1, segment2);
                }
            },
            updateTowingCompany($tc) {
                this.temp_towing_company_id = $tc;
                this.fetchTowCompanyData($tc);
            },
            fetchMemberData(mem_id, bike_id) {
                this.form.member_id = mem_id;
                this.form.bike_id   = bike_id;

                // If the member ID > 0, lets fetch the member data and bike data
                if (mem_id > 0) // Number.isInteger(mem_id) breaks this...
                {
                    axios
                        .get('/dashboard/tow-requests/fetch_member_data/' + mem_id)
                        .then(response => {
                            this.members_data = response.data.data;

                            function isBike(thisBike) {
                                return thisBike.id == bike_id; // === breaks this...
                            }
                            this.bike = this.members_data.bikes.find(isBike);
                            if (this.form.po_number == ''){ this.form.po_number = this.members_data.member_number}
                        })
                }
            },
            fetchTowData(tow_id){
                // Pull tow data and update form
                axios
                    .get('/dashboard/tow-requests/fetch_tow_data/' + tow_id) // bike_id at this point has the tow id in it.
                    .then(response => {
                        Object.entries(response.data.data).forEach(([key, value]) => {
                            this.form[key] = value;
                        });
                        this.fetchMemberData(this.form.member_id, this.form.bike_id);
                });
                this.fetchTowNotes(tow_id);
            },
            fetchTowStatusEnum() {
                axios({
                    method: 'get',
                    url: route('dashboard.dealerships.fetch_status_enum'),
                })
                .then(response => {
                    this.tow_status_list = response.data;
                })
            },
            fetchTowCompanyData(tc_id){
                // Pull towing company data and update form
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.fetch_towing_company'),
                    data: {
                        towingCompany: tc_id,
                    }
                })
                .then(response => {
                      Object.entries(response.data.data).forEach(([key, value]) => {
                          if(key == 'office_phone'){
                              this.towing_company['company_phone'] = value;
                          } else {
                              this.towing_company[key] = value;
                          }
                        });
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                }).then(function(response){
                    this.breakdown_state_list = response.data;
                }.bind(this));
            },
            getTowingCompanies() {
                axios.get('/dashboard/tow-requests/fetch_towing_companies',{

                }).then(function(response){
                    this.towing_company_list = response.data.data;
                }.bind(this));
            },
            fetchEquipmentList() {
                axios.get('/dashboard/towing-companies/fetch_equipment_list',{

                }).then(function(response){
                    this.equipment_list = response.data;
                }.bind(this));
            },
            searchTowingCompany() {
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.tows.towing_companies_in_range'),
                    data: {
                        lat: parseFloat(this.form.latitude),
                        lng: parseFloat(this.form.longitude)
                    }
                })
                .then(response => {
                     this.towing_companies_in_range = response.data;
                })
            },
            submitTowRequest(sms = false, towDriverSms = false) {
                if(sms){
                    this.form.send_sms = true;
                }
                this.form.post('/dashboard/tow-requests/update_tow_request', {
                    errorBag: 'towRequestForm',
                    preserveScroll: true,
                    onSuccess: () => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                        this.fetchData();
                        if (towDriverSms) {
                            this.sendSMS(this.form.id);
                        }
                    }
                });
            },
            sendSMS(towId) {
                this.send_tow_driver_sms_text = 'Sending SMS...'
                axios({
                    method: 'post',
                    url: route('dashboard.towing_companies.tows.fetch_tow_data'),
                    data: {
                        tow_id: towId
                    }
                })
                .then(response => {
                    axios({
                        method: 'post',
                        url: route('dashboard.towing_companies.tows.send_location_link_to_tc'),
                        data: {
                            tow_data: response
                        }
                    })
                    .then(response => {
                        this.send_tow_driver_sms_text = 'Sent. Click to send again.'
                    })
                })
            },
            addTowNote() {
                this.tow_note.company_phone = this.towing_company.company_phone;
                this.tow_note.tow_id        = this.form.id;
                this.tow_note.company_name  = this.towing_company.company_name + ' | ' + this.towing_company.address + ' | ' + this.towing_company.city + ' | ' + this.towing_company.state + ' | ' + this.towing_company.zip;
                if(this.tow_note.company_name.length > 0 && this.tow_note.company_phone.length > 0 && this.tow_note.note.length > 0){
                    axios({
                        method: 'post',
                        url: route('dashboard.towing_companies.tows.add_tow_note'),
                        data: {
                            tow_note: this.tow_note,
                            tow_data: this.form,
                        }
                    })
                    .then(response => {

                        if(response.data.message == 'success'){
                            this.fetchTowNotes(this.form.id);
                            this.towing_company.company_name  = '';
                            this.towing_company.company_phone = '';
                            this.tow_note.note                = '';
                        } else {
                            window.location.href = response.request.responseURL;
                        }
                    })
                } else {
                    alert('You must select a towing company from the map and type in a note to submit a tow note.')
                }
            },
            useTowingCompany() {
                this.form.towing_company_id = this.temp_towing_company_id;
                this.fetchTowCompanyData(this.form.towing_company_id);
                return false;
            },
            fetchTowNotes(tow_id) {
                axios.get('/dashboard/tow-requests/fetch_tow_notes/' + tow_id,{

                }).then(function(response){
                    this.tow_notes = response.data.data;
                }.bind(this));
            }
        },
        computed: {
            expireDate: function() {
                return moment(String(this.members_data.plan_expiration)).format('MM/DD/YYYY');
            },
            joinDate: function() {
                return moment(String(this.members_data.joinDate)).format('MM/DD/YYYY');
            },
            breakdownLocationSet() {
                if(this.form.latitude && this.form.longitude){
                    return true;
                } else {
                    return false;
                }
            },
            equipmentList() {
                let equipmentList = '';
                if (this.towing_company && this.towing_company.equipment) {
                    this.towing_company.equipment.forEach(equipment => {
                        if (equipmentList != '') {
                            equipmentList += ', ';
                        }
                        equipmentList += this.equipment_list.find(el => el.id == equipment)?.name;
                    });
                }
                
                return equipmentList;
            }
        },
        watch: {
            breakdownLocationSet(n){
                if(n) {
                    this.searchTowingCompany();
                }
            },
        },
        mounted() {
            this.fetchData();
            this.getStates();
            this.getTowingCompanies();
            this.checkForClientLocation();
            this.getNow();
            this.fetchEquipmentList();
            this.knownLocation = sessionStorage.getItem('TowRequestShowMap') ?? false;
        },
        beforeDestroy () {
            this.cancelAutoUpdate();
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';
    .step3 {
        :deep(.search-fields) { display:none!important; }
    }
</style>
