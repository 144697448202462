<template>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!memberships.isLoading">
        <form class="search-form mb-5" @submit.prevent="memberships.refreshData(applyFilters())">
            <div class="search-fields md:align-center search flex justify-between flex-wrap flex-col md:flex-row md:items-end">
                <input
                    class="st-search w-100 md:w-auto"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <div class="flex flex-col md:flex-row md:items-center justify-between">
                    <label for="date" class="m-1">Date Range </label>
                    <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                    <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                    <div class="m-1 w-100 md:w-auto">
                        <label for="member_type" class="m-1">Member Type </label>
                        <select v-model="member_type" class="border py-2 pl-3 pr-8 text-grey-800 m-1">
                            <option value=""> - </option>
                            <option
                                v-for="option in member_type_list"
                                :key="option.value"
                                :value="option.name"
                            >{{option.name}}</option>
                        </select>
                    </div>
                    <div class="m-1 w-100 md:w-auto">
                        <label for="status" class="m-1">Status </label>
                        <select v-model="status" class="border py-2 pl-3 pr-8 text-grey-800 m-1 w-100">
                            <option value=""> - </option>
                            <option
                                v-for="option in member_status_list"
                                :key="option"
                                :value="option"
                            >{{option}}</option>
                        </select>
                    </div>
                </div>
                <div>
                    <jet-button type="submit" class="button button-primary ml-2"><span class="fas fa-search">APPLY FILTERS</span></jet-button>
                    <JetButton class="button button-primary m-1 flex-end" @click="resetFilters()">Reset Filters</JetButton>
                </div>
            </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="memberships.sortClass('membership_number')" @click="memberships.sortBy('membership_number')">Membership #</p>
            <p :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
            <p :class="memberships.sortClass('member_type')" @click="memberships.sortBy('member_type')">Member Type</p>
            <p :class="memberships.sortClass('plan_type')" @click="memberships.sortBy('plan_type')">Plan Type</p>
            <p :class="memberships.sortClass('city_state')" @click="memberships.sortBy('city_state')">City, State</p>
            <p :class="memberships.sortClass('alert')" @click="memberships.sortBy('alert')">Alert</p>
            <p :class="memberships.sortClass('notes')" @click="memberships.sortBy('notes')">Notes</p>
            <p :class="memberships.sortClass('plan_expiration')" @click="memberships.sortBy('plan_expiration',null,'date')">Expiration</p>
            <p :class="memberships.sortClass('status')" @click="memberships.sortBy('status')">Status</p>
        </div>
        <div class="simple-table" style="--cols:10">
            <p class="st-header first-col" :class="memberships.sortClass('membership_number')" @click="memberships.sortBy('membership_number')">Membership #</p>
            <p class="st-header" :class="memberships.sortClass('full_name')" @click="memberships.sortBy('full_name')" >Motorist</p>
            <p class="st-header" :class="memberships.sortClass('member_type')" @click="memberships.sortBy('member_type')">Member Type</p>
            <p class="st-header" :class="memberships.sortClass('plan_type')" @click="memberships.sortBy('plan_type')">Plan Type</p>
            <p class="st-header" :class="memberships.sortClass('city_state')" @click="memberships.sortBy('city_state')">City / State</p>
            <p class="st-header" :class="memberships.sortClass('alert')" @click="memberships.sortBy('alert')">Alert</p>
            <p class="st-header" :class="memberships.sortClass('notes')" @click="memberships.sortBy('notes')">Notes</p>
            <p class="st-header" :class="memberships.sortClass('plan_expiration')" @click="memberships.sortBy('plan_expiration',null,'date')">Expiration</p>
            <p class="st-header" :class="memberships.sortClass('status')" @click="memberships.sortBy('status')">Status</p>
            <p class="st-header last-col">Actions</p>
            <template v-for="(row, index) in memberships.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Membership #</span>{{ row.membership_number }}</p>
                <p><span class="st-hidden">Motorist</span>{{ row.full_name }}</p>
                <p><span class="st-hidden">Member Type</span>{{ row.member_type }}</p>
                <p><span class="st-hidden">Plan Type</span>{{ row.plan_type }} </p>
                <p><span class="st-hidden">City</span>{{ row.city_state }}</p>
                <p><span class="st-hidden">Alert</span><span class="text-red-700">{{ row.alert }}</span></p>
                <p><span class="st-hidden">Notes</span>{{ row.notes }}</p>
                <p><span class="st-hidden">Expiration</span>{{ row.plan_expiration }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.status" /></p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/motorists/memberships/edit_member/${row.id}`"
                            as="button"
                            type="button"
                            v-if="$page.props.user.current_team.name == 'Admin'"
                        >
                            <jet-button>
                                EDIT
                            </jet-button>
                        </Link>
                        <Link
                            :href="`/dashboard/tow-requests/add_tow_requests_step2/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.status == 'Current'"
                        >
                            <jet-button>
                                Add Tow
                            </jet-button>
                        </Link>
                        <Link
                            :href="`/dashboard/motorists/memberships/view_member/${row.id}`"
                            as="button"
                            type="button"
                            v-if="$page.props.user.current_team.name == 'CSR' || $page.props.user.current_team.name == 'Office Staff'"
                        >
                            <jet-button>
                                VIEW
                            </jet-button>
                        </Link>
                        <jet-danger-button
                            @click="confirmMemberDeletion(index, row.id)"
                            v-if="$page.props.user.current_team.name == 'Admin'"
                        >
                            DELETE
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ memberships.rangeText }}</p>
            <Pagination
                v-if="memberships.itemCount || memberships.itemCount <= memberships.itemsPerPage"
                :active-page="memberships.activePage"
                :item-count="memberships.itemCount"
                :items-per-page="memberships.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingMemberDeletion" @close="closeModal">
        <template #title>
            Delete Member
        </template>

        <template #content>
            Are you sure you want to delete this member? (You will still be able to restore this member if needed.)

        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteMember(this.memberId)" >
                Delete Member
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import EloquentSSFP from '@/EloquentSSFP'
    import { reactive, toRefs } from 'vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Pagination from '@/Components/Pagination.vue'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import VueLoader from '@/Components/VueLoader'
    import moment from 'moment';

    export default {
        mixins: [formattingMixin],
        components: {
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link,
            VueLoader,
            Pagination,
            moment,
        },
        data() {
            return {
                memberships: reactive(new EloquentSSFP()),
                confirmingMemberDeletion: false,
                memberId: null,
                searchModel: '',
                ember_type_list: [],
                member_status_list: [],
                member_type: '',
                start_date: null,
                end_date: null,
                status: '',
                activePage: 1,
                activeSorts: [],
                sortsDirs: ['asc'],
                sortTypes: ['membership_number'],
            }
        },
        methods: {
            resetFilters() {
                this.searchModel = '';
                this.status = '';
                this.member_type = '';
                this.start_date = null;
                this.end_date = null;
                this.deleteSearchCriteriaSession();
                this.memberships.refreshData({
                    searchableData: ['full_name', 'plan_type', 'member_type', 'membership_number', 'alert', 'city_state'],
                    itemsPerPage: 50,
                });
            },
            updateActivePage(page) {
                this.memberships.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            applyFilters(optionsParam = this.memberships.options) {
                let options = {...optionsParam};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('plan_expiration');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.member_type) {
                    options['filterProps'].push('member_type');
                    options['filterOperators'].push([null])
                    options['filterValues'].push([this.member_type])
                    options['filterExclusivities'].push(1);
                }

                if (this.status) {
                    options['filterProps'].push('status');
                    options['filterValues'].push([this.status]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }

                this.storeSearchCriteriaSession();
                return options;
            },
            storeSearchCriteriaSession() {
                sessionStorage.setItem('MembershipsTableSearchModel', this.searchModel);
                sessionStorage.setItem('MembershipsTableStatus', this.status);
                sessionStorage.setItem('MembershipsTableMemberType', this.member_type);
                sessionStorage.setItem('MembershipsTableStartDate', this.start_date);
                sessionStorage.setItem('MembershipsTableEndDate', this.end_date);
                sessionStorage.setItem('MembershipsTablePageNo', this.memberships.activePage);
                sessionStorage.setItem('MembershipsTableActiveSorts', JSON.stringify(this.memberships.activeSorts));
                sessionStorage.setItem('MembershipsTableSortDirs', JSON.stringify(this.memberships.sortDirs));
                sessionStorage.setItem('MembershipsTableSortTypes', JSON.stringify(this.memberships.sortTypes));
                /*
                activeSorts = the properties in the data being sorted
                sortDirs = the directions those properties are sorting [asc, desc]
                sortTypes = the type of the data [String, Date, Number]
                */
            },
            getSearchCriteriaSession() {
                this.searchModel = sessionStorage.getItem('MembershipsTableSearchModel') ? sessionStorage.getItem('MembershipsTableSearchModel') : '';
                this.status = sessionStorage.getItem('MembershipsTableStatus') ? sessionStorage.getItem('MembershipsTableStatus') : '';
                this.member_type = sessionStorage.getItem('MembershipsTableMemberType') ? sessionStorage.getItem('MembershipsTableMemberType') : '';
                this.start_date = sessionStorage.getItem('MembershipsTableStartDate') !== "null" ? sessionStorage.getItem('MembershipsTableStartDate') : null;
                this.end_date = sessionStorage.getItem('MembershipsTableEndDate') !== "null" ? sessionStorage.getItem('MembershipsTableEndDate') : null;
                this.activePage = sessionStorage.getItem('MembershipsTablePageNo') !== "null" ? Number(sessionStorage.getItem('MembershipsTablePageNo')) : 1;
                this.activeSorts = JSON.parse(sessionStorage.getItem('MembershipsTableActiveSorts'))??['membership_number'];
                this.sortDirs = JSON.parse(sessionStorage.getItem('MembershipsTableSortDirs'))??['asc'];
                this.sortTypes = JSON.parse(sessionStorage.getItem('MembershipsTableSortTypes'))??[];
            },
            deleteSearchCriteriaSession() {
                sessionStorage.removeItem('MembershipsTableSearchModel');
                sessionStorage.removeItem('MembershipsTableStatus');
                sessionStorage.removeItem('MembershipsTableMemberType');
                sessionStorage.removeItem('MembershipsTableStartDate');
                sessionStorage.removeItem('MembershipsTableEndDate');
                sessionStorage.removeItem('MembershipsTablePageNo');
                sessionStorage.removeItem('MembershipsTableActiveSorts');
                sessionStorage.removeItem('MembershipsTableSortDirs');
                sessionStorage.removeItem('MembershipsTableSortTypes');
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            fetchMemberStatusEnum() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_status_enum'),
                })
                .then(response => {
                    this.member_status_list = response.data;
                })
            },
            confirmMemberDeletion(index, id) {
                this.confirmingMemberDeletion = true;
                this.memberId = id;
                this.index = index;
            },
            deleteMember(id) {
                 this.$inertia.post('/dashboard/motorists/memberships/delete_member/' + id, id, {
                    onSuccess: (id) => {
                        this.memberships.refreshData(this.memberships.options);
                        this.closeModal();
                    }
                });
            },

            editMember: function (index, id) {
               this.$inertia.visit('/dashboard/motorists/memberships/edit_member/' + id);
            },
            closeModal: function () {
                this.confirmingMemberDeletion = false
            },
        },
        computed: {
            currentPage() {
                return this.memberships.activePage;
            },
            currentActiveSorts() {
                return this.memberships.activeSorts;
            },
            currentSortDirs() {
                return this.memberships.sortDirs;
            },
            currentSortTypes() {
                return this.memberships.sortTypes;
            }
        },
        watch: {
            currentPage(n) {
                if (n) {
                    sessionStorage.setItem('MembershipsTablePageNo', n);
                }
            },
            currentActiveSorts(n) {
                if (n) {
                    sessionStorage.setItem('MembershipsTableActiveSorts', JSON.stringify(n));
                }
            },
            currentSortDirs(n) {
                if (n) {
                    sessionStorage.setItem('MembershipsTableSortDirs', JSON.stringify(n));
                }
            },
            currentSortTypes(n) {
                if (n) {
                    sessionStorage.setItem('MembershipsTableSortTypes', JSON.stringify(n));
                }
            }
        },
        mounted() {
            this.getSearchCriteriaSession();

            this.memberships.init('/dashboard/motorists/memberships/fetch_members_ssfp', this.applyFilters({
                searchableData: ['full_name', 'plan_type', 'member_type', 'membership_number', 'alert', 'city_state'],
                itemsPerPage: 50,
                activePage: this.activePage,
                sortDirs: this.sortDirs,
                activeSorts: this.activeSorts,
                sortTypes: this.sortTypes
            }));
        },
        created() {
            this.fetchMemberTypes();
            this.fetchMemberStatusEnum();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../Components/VueTable/scss/_table-styles.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
