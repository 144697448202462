<template>
    <div class="motorcycle">
        <p class="mt-4 font-bold text-blue-500">Motorcycle {{ index }}</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <jet-label class="mt-4" :for="`motorcycle_make_${index}`" value="Make" />
                <jet-input :id="`motorcycle_make_${index}`" type="text" class="mt-1 block w-full" :value="motorcycleMake" @input="$emit('update:motorcycleMake', $event.target.value)"  required />
            </div>
            <div>
                <jet-label class="mt-4" :for="`motorcycle_model_${index}`" value="Model" />
                <jet-input :id="`motorcycle_model_${index}`" type="text" class="mt-1 block w-full" :value="motorcycleModel" @input="$emit('update:motorcycleModel', $event.target.value)"  required />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
                <jet-label class="mt-4" :for="`motorcycle_year_${index}`" value="Year" />
                <jet-input :id="`motorcycle_year_${index}`" type="number" class="mt-1 block w-full" :value="motorcycleYear" @input="$emit('update:motorcycleYear', $event.target.value)" required />
            </div>
            <div>
                <jet-label class="mt-4" :for="`motorcycle_color_${index}`" value="Color" />
                <jet-input :id="`motorcycle_color_${index}`" type="text" class="mt-1 block w-full" :value="motorcycleColor" @input="$emit('update:motorcycleColor', $event.target.value)" required />
            </div>
            <div>
                <jet-label class="mt-4" :for="`motorcycle_plate_${index}`" value="Plate Number" />
                <jet-input :id="`motorcycle_plate_${index}`" type="text" class="mt-1 block w-full" :value="motorcyclePlate" @input="$emit('update:motorcyclePlate', $event.target.value)" required />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <jet-label class="mt-4" :for="`motorcycle_state_${index}`" value="State/Province" />
                <select :id="`motorcycle_state_${index}`" class="mt-1 block w-full" :value="motorcycleState" @input="$emit('update:motorcycleState', $event.target.value)" required >
                    <option value=""> -- </option>
                    <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                </select>
            </div>
            <div>
                <jet-label class="mt-4" :for="`motorcycle_country_${index}`" value="Country" />
                <select :id="`motorcycle_country_${index}`" class="mt-1 block w-full" @change="$emit('update:motorcycleCountry', $event.target.value)" required >
                    <option value=""> -- </option>
                    <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div class="col-span-4">
                <jet-label class="mt-4" :for="`motorcycle_vin_${index}`" value="VIN" />
                <jet-input :id="`motorcycle_vin_${index}`" type="text" class="mt-1 block w-full" :value="motorcycleVin" @input="$emit('update:motorcycleVin', $event.target.value)" required />
            </div>
            <div class="md:col-span-1">
                <jet-label class="mt-4" value="Trike / Autocycle" />
                <div class="form-group grid grid-cols-4 gap-4">
                    <jet-input :id="`trike_autocycle_yes_${index}`" type="radio" class="mt-1 ml-4 block" :value="trikeAutocycle" @click="$emit('update:trikeAutocycle', 1)" :name="`trike_autocycle_no_${index}`" :checked="trikeAutocycle" required  />
                    <jet-label :for="`trike_autocycle_yes_${index}`" value="Yes" />
                    <jet-input :id="`trike_autocycle_no_${index}`" type="radio" class="mt-1 ml-4 block" :value="trikeAutocycle" @click="$emit('update:trikeAutocycle', 0)" :name="`trike_autocycle_no_${index}`" :checked="!trikeAutocycle" required  />
                    <jet-label :for="`trike_autocycle_no_${index}`" value="No" />
                </div>
            </div>
        </div>
    </div> <!-- end motorcycle1 -->
</template>

<script>
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'

export default {
    components: {
        JetInput,
        JetLabel
    },
    props: [
        'index',
        'motorcycleYear',
        'motorcycleMake',
        'motorcycleModel',
        'motorcycleColor',
        'motorcyclePlate',
        'motorcycleState',
        'motorcycleCountry',
        'motorcycleVin',
        'trikeAutocycle',
        'states'
    ],
    data() {
        return {
            countries: ['USA', 'Canada'],
        }
    }
}
</script>

<style>

</style>
