<template>
    <TableDropdownWrapper
        v-bind="wrapperBinder"
    >
        <form @submit.prevent="updateContact()">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6 sm:col-span-5">
                        <jet-label for="name" value="Name" />
                        <jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" />
                        <jet-input-error :message="form.errors.name" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-5">
                        <jet-label for="phone" value="Phone" />
                        <jet-input id="phone" type="text" class="mt-1 block w-full" v-model="form.phone" />
                        <jet-input-error :message="form.errors.phone" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-2 items-center">
                        <span>After Hours</span>
                        <div class="form-group grid grid-cols-2">
                            <div>
                                <jet-label for="after_hours_yes" value="Yes" />
                                <jet-input id="after_hours_yes" type="radio" class="mt-1 block mx-2" value="Yes" name="after_hours" required @input="form.after_hours = 1" :checked="form.after_hours" />
                            </div>
                            <div>
                                <jet-label for="after_hours_no" value="No" />
                                <jet-input id="after_hours_no" type="radio" class="mt-1 block mx-2" value="No" name="after_hours" required @input="form.after_hours = 0" :checked="!form.after_hours" />
                            </div>
                            <jet-input-error :message="form.errors.after_hours" class="mt-2" />
                        </div>
                    </div>
            </div>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3 text-grey-300">
                Tow Updated.
            </jet-action-message>
            <jet-button class="my-2" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
            </jet-button>
        </form>
    </TableDropdownWrapper>
</template>

<script>
    import TableDropdownWrapper from '@/Components/VueTable/TableDropdownWrapper.vue';
    import tableDropdownMixin from '@/Components/VueTable/mixins/tableDropdownMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableDropdownMixin],
        components: {
            TableDropdownWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        props: ["contacts"],
        emits: ["contact-updated"],
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: this.row.id,
                    name: this.row.name,
                    phone: this.row.phone,
                    after_hours: this.row.after_hours,
                }),
            }
        },
        methods: {
            updateContact() {
                let vm = this;
                axios({
                    method: 'put',
                    url: '/dashboard/dealerships/edit_dealer/update_contact',
                    data: vm.form,
                })
                .then(function (response){
                    vm.$emit('contact-updated');
                });
            },
        }
    }
</script>
