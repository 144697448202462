<template>
    <TableDropdownWrapper
        v-bind="wrapperBinder"
    >
        <form @submit.prevent="updateBike()">
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="year" value="Year" />
                <jet-input id="year" type="text" class="mt-1 block w-full" v-model="form.year" />
                <jet-input-error :message="form.errors.year" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="make" value="Make" />
                <jet-input id="make" type="text" class="mt-1 block w-full" v-model="form.make" />
                <jet-input-error :message="form.errors.make" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="model" value="Model" />
                <jet-input id="model" type="text" class="mt-1 block w-full" v-model="form.model" />
                <jet-input-error :message="form.errors.model" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="color" value="Color" />
                <jet-input id="color" type="text" class="mt-1 block w-full" v-model="form.color" />
                <jet-input-error :message="form.errors.color" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="plate" value="Plate" />
                <jet-input id="plate" type="text" class="mt-1 block w-full" v-model="form.plate" />
                <jet-input-error :message="form.errors.plate" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label class="mt-4" for="state" value="State/Province" />
                <select id="state" class="mt-1 block w-full" v-model="form.state">
                    <option value=""> -- </option>
                    <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                </select>
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="country" value="Country" />
                <jet-input id="country" type="text" class="mt-1 block w-full" v-model="form.country" />
                <jet-input-error :message="form.errors.country" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
                <jet-label for="vin" value="VIN" />
                <jet-input id="vin" type="text" class="mt-1 block w-full" v-model="form.vin" />
                <jet-input-error :message="form.errors.vin" class="mt-2" />
            </div>
            <div class="col-span-6 sm:col-span-4">
            <jet-label value="Trike / Autocycle" />
                <div class="form-group">
                    <jet-label for="trike_autocycle_yes" value="Yes" />
                    <jet-input id="trike_autocycle_yes" type="radio" class="mt-1 block" value="1" name="trike_autocycle_no" v-model="form.trike_autocycle" @input="form.trike_autocycle = 1" :checked="form.trike_autocycle" required  />
                    <jet-label for="trike_autocycle_no" value="No" />
                    <jet-input id="trike_autocycle_no" type="radio" class="mt-1 block" value="0" name="trike_autocycle_no" v-model="form.trike_autocycle" @input="form.trike_autocycle = 0" :checked="!form.trike_autocycle" required  />
                </div>
            </div>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                Bike Updated.
            </jet-action-message>
           <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
            </jet-button>
        </form>
    </TableDropdownWrapper>
</template>

<script>
    import TableDropdownWrapper from '@/Components/VueTable/TableDropdownWrapper.vue';
    import tableDropdownMixin from '@/Components/VueTable/mixins/tableDropdownMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import axios from 'axios';

    export default {
        mixins: [tableDropdownMixin],
        components: {
            TableDropdownWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: this.row.id,
                    year: this.row.year,
                    make: this.row.make,
                    model: this.row.model,
                    color: this.row.color,
                    plate: this.row.plate,
                    state: this.row.state,
                    country: this.row.country,
                    vin: this.row.vin,
                    trike_autocycle: this.row.trike_autocycle,
                }),
            }
        },
        props: ['states'],
        methods: {
            updateBike() {
                 this.form.post(route('dashboard.motorists.memberships.update_bike'), {
                    errorBag: 'updateBike',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit('bikes-updated');
                    }
                });
            },
        }
    }
</script>
