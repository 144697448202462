<template>
    <FrontEndLayout title="Testimonials">
        <template #head>
            <meta name="description" content="MTS Testimonials">
            <meta name="keywords" content="testimonials">
        </template>
        <template #header>
            <h1>TESTIMONIALS</h1>
        </template>
        <template #fepage-content>
            <div class="pb-20 z-10 py-5">
          <div class="container">
                <div class="text-center">
                    <h2 class="font-bold text-navy-500">Hear From Our Happy Members</h2>
                    <p>Earning our customers’ trust is the best way to ensure our success as a company. If you’re wondering why an MTS membership is the right choice for you or the rider in your family, look no further than our customer testimonials. We love hearing from our satisfied members, and we invite you to share your experiences with how your MTS membership has benefitted you over the years. Complete our testimonial <a href="#testimonialForm" title="anchor link on same page to testimonial form" style="text-decoration: underline;">submission form below</a> to tell us how we’ve helped you and share with others how an MTS membership will be valuable.</p>
                    </div>
                </div>
            </div>
             <div class="testimonial-wrapper mt-10 pointer-events-none">
                 <div class="testi-cards">
                    <div class="testimonials" v-for="(testimonial) in testimonials.slice(0,3)" :key="testimonial.id">
                            <TestimonialCards
                                :author="testimonial.submitted_by"
                                :testimonial="testimonial.content"
                                :type="testimonial.testimonial_type"
                                :company="testimonial.company"
                            />
                    </div>
                </div>
            </div>
            <div class="container extra-testi">
                <h2 class="font-bold text-navy-500 pb-5">Additional Testimonials</h2>
                    <div v-if="checkIfAnyOfType('Motorist')">
                        <h3 class="font-bold text-blue-500 pb-5" id="Motorist">Motorist Testimonials</h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <template v-for="testimonial in testimonials" :key="testimonial.id">
                                <div class="bg-gray-150" v-if="testimonial.testimonial_type == 'Motorist'">
                                    <div class="col-span-1">
                                        <ExtraTestimonials
                                            :author="testimonial.submitted_by"
                                            :testimonial="testimonial.content"
                                            :type="testimonial.testimonial_type"
                                            :image="testimonial.image_path"
                                            :imageAlt="testimonial.image_alt"
                                        />
                                    </div>
                                </div>
                            </template>
                            <div class="bg-gray-150 p-8 grid grid-cols-1 lg:grid-cols-2 gap-4">
                                <div>
                                    <h5 class="text-blue-500">"...You know what is great to have if you have a bike, Motorcycle Towing Services...They have been hauling bikes since 1988. It's what they specialize in and they are the best at it...Motorcycle Towing Services is the way to go. I wouldnt ride without them."</h5>
                                    <h6 class="text-navy-500">Alonzo Bodden</h6>
                                </div>
                                <div>
                                    <div class="video-bg-controls">
                                        <button v-show="paused" @click="play"><FontAwesomeIcon fixed-width :icon="playIcon"/></button>
                                        <button v-show="playing" @click="pause"><FontAwesomeIcon fixed-width :icon="pauseIcon"/></button>
                                    </div>
                                    <div class="video-container">
                                        <video preload controls id="alonzo" @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused">
                                        <source src="https://storage.googleapis.com/mts_towing/videos/Testimonial.mp4" type="video/mp4">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                <div v-if="checkIfAnyOfType('Vendor')">
                <h3 class="font-bold text-blue-500 py-5" id="Vendor">Vendor Testimonials</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <template v-for="testimonial in testimonials" :key="testimonial.id">
                        <div class="bg-gray-150" v-if="testimonial.testimonial_type == 'Vendor'">
                            <div class="col-span-1">
                                <ExtraTestimonials
                                    :author="testimonial.submitted_by"
                                    :testimonial="testimonial.content"
                                    :type="testimonial.testimonial_type"
                                    :image="testimonial.image_path"
                                    :imageAlt="testimonial.image_alt"
                                />
                            </div>
                        </div>
                    </template>
                </div>
                </div>
                <div v-if="checkIfAnyOfType('Dealer')">
                <h3 class="font-bold text-blue-500 py-5" id="Dealer">Dealer Testimonials</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <template v-for="testimonial in testimonials" :key="testimonial.id">
                        <div class="bg-gray-150" v-if="testimonial.testimonial_type == 'Dealer'">
                            <div class="col-span-1">
                                    <ExtraTestimonials
                                        :author="testimonial.submitted_by"
                                        :testimonial="testimonial.content"
                                        :type="testimonial.testimonial_type"
                                        :image="testimonial.image_path"
                                        :imageAlt="testimonial.image_alt"
                                    />
                            </div>
                        </div>
                    </template>
                </div>
                </div>
            </div>
                <SubmitTestimonial />
        </template>
        <template #footer-gap>
            <FooterGap />
        </template>
    </FrontEndLayout>
</template>
<style lang="scss">
@import '../../../scss/_mixins.scss';

main {
  position: relative;
  top: calc(var(--header-size) + 15px );
}


.testi-cards {
    display: flex;
    flex-direction: column;

    @include bp-up ($bp-lg){
        flex-direction: row;
    }
}

#alonzo{
    position: relative;
    width: auto;

}

.video-bg-controls {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

     @include bp-up($bp-lg + 1) { bottom:8vw; }

    .inactive svg {
        color: rgba(white, .4);

        &:hover { cursor: auto; }
    }

    svg {
        color: rgba(white, .85);
        margin: .25rem;

        &:hover { cursor: pointer; }
    }
}


.testi-heading {
    position: relative;
    height: 200px;
    width: auto;
    padding: 1rem 0;
}

.extra-testi {
    position: relative;
    top: var(--header-size);
}

.testimonial-wrapper {
        position: relative;
        margin: 0;
        padding: 0;
        top: var(--header-size);

        &:before {
            content: '';
            background-image: url(/images/testi-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 98px;
            width: 100%;
            position: relative;
            top: 45px;
            left: 0;
            display: block;
            background-position: bottom center;
            z-index: 1;
            filter: drop-shadow(4px 11px 5px #0c26549a);

            @include bp-up($bp-lg) {
                top: 95px;
            }
        }

        &:after {
            content: '';
            background-image: url(/images/footer-texture.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 95px;
            width: 100%;
            position: relative;
            bottom: 95px;
            left: 0;
            display: block;
            background-position: bottom center;
            z-index: 3;
            color: white;
            filter: drop-shadow(4px -11px 6px #0c26549a)
        }

    .testimonials {
        background-image: url(/images/img-testimonial.jpg);
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        padding: 120px 32px 64px 32px;
        background-attachment: fixed;
        background-position: center;

        @include bp-up ($bp-md){ }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(42, 82, 152, 0.6);
            z-index: -1;
        }

        &:after {
            content: '';
            background-image: url(/images/americanflag.png);
            position: absolute;
            top: 0;
            left: 0;
            width: 93%;
            height: 97%;
            z-index: -2;
            opacity: .43;
            background-attachment: fixed;
            background-position: left;
        }

        h2 {
            color: white;
            font-weight: bold;
            text-align: center;
            margin: 64px 0 32px;
        }
    }
}
</style>

<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
    import TestimonialCards from '@/Components/TestimonialCards.vue'
    import FooterGap from '@/Components/FooterGap.vue'
    import SubmitTestimonial from './Form/SubmitTestimonial.vue'
    import ExtraTestimonials from '@/Components/ExtraTestimonials.vue'


    export default defineComponent({
        components: {
            FrontEndLayout,
            TestimonialCards,
            FooterGap,
            FontAwesomeIcon,
            SubmitTestimonial,
            ExtraTestimonials,
        },
        data() {
            return {
                testimonials: [],
                pauseIcon: faPause,
                playIcon: faPlay,
                videoElement: null,
                paused: null
            }
        },
        computed: {
            playing() { return !this.paused; }
        },
        methods: {
            fetchTestimonials() {
                axios({
                    method: 'get',
                    url: route('.testimonials.fetch_testimonials'),
                })
                .then(response => {
                    this.testimonials = response.data.data;
                })
            },
            checkIfAnyOfType(type) {
                return this.testimonials.some(t => t.testimonial_type == type);
            }
        },
        mounted() {
            this.fetchTestimonials();
        }
    })
</script>
