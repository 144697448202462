<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('year')" @click="sortBy('year', 'number')">Year</p>
            <p :class="sortClass('make')" @click="sortBy('make')">Make</p>
            <p :class="sortClass('model')" @click="sortBy('model')">Model</p>
            <p :class="sortClass('plate')" @click="sortBy('plate')">Plate</p>
            <p :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p :class="sortClass('vin')" @click="sortBy('vin')">VIN</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('year')" @click="sortBy('year','number')">Year</p>
            <p class="st-header" :class="sortClass('make')" @click="sortBy('make')" >Make</p>
            <p class="st-header" :class="sortClass('model')" @click="sortBy('model')">Model</p>
            <p class="st-header" :class="sortClass('plate')" @click="sortBy('plate')">Plate</p>
            <p class="st-header" :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p class="st-header last-col" :class="sortClass('vin')" @click="sortBy('vin')">VIN</p>

            <template v-for="row in displayedList" :key="'row' + row.id">
                <p class="first-col" @click="selectBike(row.id)"><span class="st-hidden">Year</span>{{ row.year }}</p>
                <p @click="selectBike(row.id)" class="cursor-pointer"><span class="st-hidden">Make</span>{{ row.make }}</p>
                <p @click="selectBike(row.id)" class="cursor-pointer"><span class="st-hidden">Model</span>{{ row.model }}</p>
                <p @click="selectBike(row.id)" class="cursor-pointer"><span class="st-hidden">Plate</span>{{ row.plate }} </p>
                <p @click="selectBike(row.id)" class="cursor-pointer"><span class="st-hidden">State</span>{{ row.state }}</p>
                <p @click="selectBike(row.id)" class="cursor-pointer"><span class="st-hidden last-col">VIN</span>{{ row.vin }}</p>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
    import BikeTableDropdown from '@/Components/Dropdowns/BikeTableDropdown.vue';
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            BikeTableDropdown,
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link
        },
        props: ['bikes', 'bikeid'],
        data() {
            return {
                bikeForm: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    year: '',
                    make: '',
                    model: '',
                    color: '',
                    plate: '',
                    state: '',
                    country: '',
                    vin: '',
                })
            }
        },
        methods: {
            bikesUpdated() {
                this.$emit('bikes-updated')
            },
            selectBike(bikeid) {
                this.$emit('bike-selected', bikeid)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(5, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>