<template>
    <p>Your relative position is shown on the map below. Please drag the pin to your precise location then click the button below to confirm.</p>
    <jet-button class="mt-4 mb-10" @click="confirmLocation()">
        Confirm My Location
    </jet-button>
    <div id="map"></div>

</template>


<style lang="scss" scoped>
    #map{
        height:500px;
    }
</style>
<script>

import { Loader } from '@googlemaps/js-api-loader'
import JetButton from '@/Jetstream/Button.vue'
import axios from 'axios'

export default {
    components: {
        Loader,
        JetButton,
    },
    props: [
        "lat",
        "lng",
        "uuid",
    ],
    data() {
            return {
                newLat: this.lat,
                newLng: this.lng,
                map: {},
            }
        },
    methods: {
        confirmLocation() {
            window.location = window.location.pathname + '?uuid=' + this.uuid + '&success=true';
            this.setLocation(this.newLat, this.newLng);
        },
        setLocation(lat, lng) {
            let params = (new URL(document.location)).searchParams;
            let tow_uuid = params.get("uuid");
            axios({
                method: 'post',
                url: '/uuid_tow_update',
                data: {
                    uuid: tow_uuid,
                    lat: lat,
                    lng: lng,
                }
            });
        }
    },
    mounted() {
        let vm = this;

        const loader = new Loader({
            // MTS API KEY: AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ
            apiKey: "AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ",
            version: "weekly",
            libraries: ["places"]
        });

        const mapOptions = {
            center: {
                lat: vm.lat,
                lng: vm.lng,
            },
            zoom: 14
        };

        // Promise
        loader.load()
            .then((google) => {
                vm.map = new google.maps.Map(document.getElementById("map"), mapOptions);
                var userMarker = new google.maps.Marker({
                    position: { lat: vm.lat, lng: vm.lng },
                    map: vm.map,
                    title: "Your location",
                    draggable: true,
                    index: 1,
                });
                google.maps.event.addListener(userMarker, 'dragend', function(event) {
                    vm.newLat = event.latLng.lat();
                    vm.newLng = event.latLng.lng();
                });
            })
            .catch(e => {
                console.log(e);
            });
    },
}
</script>
