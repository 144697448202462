<template>
    <app-layout title="Dashboard - Add/Modify Testimonial">
        <template #header>
        </template>
        <Card
            title="Testimonial">
                <div>
                    <Teleport to="body"><FlashMessage /></Teleport>
                    <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                        BACK
                    </JetButton>
                </div>
                <AddModifyTestimonialForm />
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import AddModifyTestimonialForm from './Forms/AddModifyTestimonialForm.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages'
    import Card from '@/Components/Card.vue'

    export default defineComponent({
        mixins: [formattingMixin],

        components: {
            AppLayout,
            JetApplicationLogo,
            JetButton,
            MemberHeader,
            FlashMessage,
            AddModifyTestimonialForm,
            Card,
        },
        methods: {
            back() {
              window.history.back();
            },
        }
    })
</script>
