<template>
    <div>
        <slot></slot>

        <div class="st-bottom">
            <p>{{ rangeText }}{{ selectedText }}</p>
            <Pagination
                :active-page="activePage"
                :item-count="itemCount"
                :items-per-page="itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
</template>

<script>
    import Pagination from './../Pagination.vue';

    export default {
        name: "TableWrapper",
        components: {
            Pagination,
        },
        props: {
            itemCount: {
                type: Number,
                required: true
            },
            activePage: {
                type: Number,
                required: true
            },
            itemsPerPage: {
                type: Number,
                required: true
            },
            searchable: {
                type: Boolean,
                default: true
            },
            selectedCount: {
                type: Number
            },
            searchModel: {
                type: String
            }
        },
        methods: {
            updateActivePage(page) {
                this.$emit('update-page', page);
            },
            emitSearch() {
                this.$emit('on-search', event.target.value);
            }
        },
        watch: {
            itemCount() {
                if(this.itemCount > 0){
                    this.updateActivePage(1)
                }
            }
        },
        computed: {
            rangeText() {
                let [count, per, active] = [this.itemCount, this.itemsPerPage, this.activePage],
                    lastPage   = active>count/per,
                    rangeEnd   = lastPage?count:active*per,
                    rangeStart = lastPage?count-((count%per)-1):(active*per)-(per-1);

                if (count == 0) return '0 results';
                return `Showing ${rangeStart} to ${rangeEnd} of ${count} entries`;
            },
            selectedText() {
                if (this.selectedCount) {
                    return ` - (${this.selectedCount} row${this.selectedCount>1?'s':''} selected)`;
                }
            }
        }
    }
</script>

<style lang="scss">
    @import './scss/_table-styles.scss';
</style>