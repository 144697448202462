<template>
    <AppLayout title="Form Submissions">
        <template #header>
        </template>
        <Card
            title="Form Submissions"
        >
            <JetButton class="button button-primary absolute top-4 right-4" @click="back">
                BACK
            </JetButton>
            <JetButton class="button button-secondary absolute top-4 right-24" @click="showAllSubmissions">
                SHOW ALL SUBMISSIONS
            </JetButton>
            <div v-if="isLoading">
                <VueLoader />
            </div>
            <div v-else>
                <FormSubmissionsTable
                    :data="forms"
                    :searchable-data="['name', 'email', 'phone', 'created_at']"
                    @row-deleted="rowDeleted($event)"
                    ref="formSubmissions"
                />
            </div>
        </Card>
    </AppLayout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import FormSubmissionsTable from './Tables/FormSubmissionsTable.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            Card,
            FormSubmissionsTable,
            Link,
            JetButton,
            VueLoader,
        },
        data() {
            return {
                forms:[],
                isLoading: true,
            }
        },
        methods: {
            fetchForms() {
                axios
                    .get(route('dashboard.forms.fetch_form_submissions'))
                    .then(response => {
                        this.forms = response.data.data;
                        this.isLoading = false;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/forms/delete_form_submission/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchForms();
                        this.$refs.formSubmissions.closeModal();
                    }
                })
            },
            back() {
              window.history.back();
            },
            showAllSubmissions () {
                this.isLoading = true;
                axios
                    .get(route('dashboard.forms.fetch_form_submissions_with_trashed'))
                    .then(response => {
                        this.forms = response.data.data;
                        this.isLoading = false;
                    })
            }
        },
        mounted() {
            this.fetchForms();
        }
    })
</script>
