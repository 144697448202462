<template>
    <FrontEndLayout title="Terms of Service">
        <template #head>
            <meta name="description" content="Terms of Service">
            <meta name="keywords" content="terms">
        </template>
        <template #header>
            <h1>TERMS OF SERVICE</h1>
        </template>
        <template #fepage-content>
            <div class="container py-6">
                <h2 class="text-navy-500 font-bold">Member Benefits</h2>
                <p>Effective 5/1/2023<br>Coverage is for two motorcycles only. Each additional motorcycle is $5.00 per year.</p><br>
                <p>MTS has four individual plans to choose from:</p>
                <h3 class="text-navy-500 mt-6">Premier Plan</h3>
                    <p>MTS pays the first $300 of the tow from point of pickup to point of delivery. All motorcycles must be registered with MTS. Only 1985 and newer motorcycles are eligible for coverage.</p>
                <h3 class="text-navy-500 mt-6">Deluxe Plan</h3>
                    <p>MTS pays the first $150 of the tow from point of pickup to point of delivery. All motorcycles must be registered with MTS. Only 1985 and newer motorcycles are eligible for coverage.</p>
                <h3 class="text-navy-500 mt-6">Cold Country Plan</h3>
                    <p>MTS pays the first $150 of the tow from point of pickup to point of delivery. Single membership only. Covered April 1st - September 30th. Only 1985 and newer motorcycles are eligible for coverage. Available to residents of the following states: AK, CO, CT, DE, IA, ID, IL, IN, KS, KY, MA, MD, ME, MI, MN, MT, ND, NE, NH, NJ, NY, OH, OR, PA, RI, SD, UT, VA, VT, WA, WI, WY, WV, or CANADA</p>
                <h3 class="text-navy-500 mt-6">Economy Plan</h3>
                    <p>Member pays the service provider at the time of the tow. MTS dispatches only. No motorcycle year limit.</p>
                <h3 class="text-navy-500 mt-6">Emergency Service Plan</h3>
                    <p>MTS will reimburse 100% of replacement costs (excluding labor) on tires, battery or throttle, brake and clutch cables on your MTS registered motorcycle if they fail while over 100 miles from your home residence. Must be in conjunction with an MTS membership.</p>
                <h3 class="text-navy-500 mt-6">Emergency Roadside Service</h3>
                    <p>MTS will dispatch a professional service provider for any covered disablement. The motorcycle, including trailer or sidecar, will be delivered to the nearest motorcycle repair facility or your home (within comparable distance) if requested. If your motorcycle is towed to your home, and a subsequent tow is needed to the repair facility at a later date, this would be considered "one tow" and treated as the same occurrence. If the repair facility is closed, we will have your motorcycle stored and delivered to the facility the next day. Towing and emergency roadside coverage is available in all 50 states and Canada.</p>
                <h3 class="text-navy-500 mt-6">What Constitutes A Disablement</h3>
                    <p>A disablement is anything that prevents you from being able to safely ride your motorcycle or pull your trailer or sidecar. MTS will cover the same disablement only once in a consecutive 5-day period. MTS does not cover pre-existing conditions.</p>
                    <p>&nbsp;</p>
                    <p>Please note, disablements that are “off road” will not be covered. If possible, MTS will help with retrieving the bike, but the cost will be an out-of-pocket expense for the member. Also, as with other roadside assistance networks, Roadside Assistance is not permitted to serve customers on restricted-access roadways that are governed by regulatory mandates enacted and enforced by local municipalities and/or tollway authorities. In these cases, you must use the service provider approved by the governing body; however, Roadside Assistance can help you once your vehicle is towed outside the jurisdiction of the restricted-access highway authority.</p>
                <h3 class="text-navy-500 mt-6">Miscellaneous</h3>
                    <ul class="red-check list-outside mx-auto ml-5">
                        <li>MTS is not a repair service nor are we responsible for the repairs to your motorcycle or the quality of the work performed.</li>
                        <li>MTS is not an insurance company.</li>
                        <li>This is not a liability policy and does not comply with any financial responsibility by law.</li>
                        <li>This is not a motorcycle liability or physical damage insurance contract.</li>
                        <li>MTS is not responsible for any damage to your motorcycle, trailer, or sidecar by service provider. Each service provider is fully insured.</li>
                        <li>MTS reserves the right to add, delete, or modify any portion of the member benefits without prior notice.</li>
                        <li>MTS does not cover pre-existing conditions.</li>
                        <li>Effective 5/1/2023</li>
                    </ul>
                <h3 class="text-navy-500 mt-6">Lost Key Reimbursement</h3>
                    <p>MTS may reimburse up to $50 of the cost for emergency keys to be made if you are 100 miles or more from your home residence. Reimbursement will be made within 30 days of receiving the receipt.</p>
                <h3 class="text-navy-500 mt-6">Emergency Trip Interruption</h3>
                    <p>MTS may reimburse up to $250 for expenses incurred from the result of an accident subject to the following terms and conditions:</p><br>
                    <ul class="ml-5" style="list-style:disc;">
                        <li>The accident occurred a minimum of 100 miles from your home residence.</li>
                        <li>The accident has been reported to the local or state police.</li>
                        <li>MTS will reimburse you up to a maximum total of $250 for any of the following combination of expenses occurring within 72 hours of the time of the accident: commercial transportation (plane, trains or bus) or rental car to your residence or local commercial lodging and meals.</li>
                        <li>To obtain reimbursement, you must submit to us within 60 days of the accident all paid receipts from the business providing you with lodging, meals, transportation etc., and a copy of the accident report you filed with the police.</li>
                        <li>This benefit does not and is not intended to replace motorcycle liability and personal insurance. As such, all claims must be first submitted to your insurance company for reimbursement. Those items not covered by your insurance company may then be submitted with the letter of denial from the insurance company and the documentation as previously described for reimbursement.</li>
                        <li>Benefits are not applicable if the accident is due to war, insurrection, riot, etc. The laws of various states require the following disclosure:
                            <ul class="red-check list-outside mx-auto ml-5">
                                <li>This Is Not An Insurance Contract</li>
                                <li>This Is Not A Liability Policy And Does Not Comply With Any Financial Responsibility By Law</li>
                                <li>This Is Not An Automobile Liability Or Physical Damage Insurance Contract</li>
                            </ul>
                        </li>
                    </ul>
                <h3 class="text-navy-500 mt-6">Cancellation Policy</h3>
                    <p>If you choose to cancel your MTS membership prior to the expiration date, MTS must be notified by US mail, email, or by phone. A pro-rated refund (less $10 cancellation fee) will be sent within 30 days of the cancellation request.</p>
                <h3 class="text-navy-500 mt-6">Loaning Your MTS Membership To Another Rider</h3>
                    <p>Your MTS membership cannot be transferred or used by anyone other than the membership's registered owner.</p>
                <h3 class="text-navy-500 mt-6">Change Of Motorcycles</h3>
                    <p>You may change your MTS covered motorcycles at any time. MTS may refuse coverage at any time for unregistered motorcycles. If coverage is refused, the rider can pay COD and send us proof of ownership and receipt for reimbursement within 30 days of service.</p>
                <h3 class="text-navy-500 mt-6">Reimbursements</h3>
                    <p>If you breakdown and setup your own tow without first contacting MTS for authorization, you will NOT be reimbursed. If you receive authorization, MTS will reimburse you within 30 days from the time we receive a copy of the paid tow receipt from you.</p>
                <h3 class="text-navy-500 mt-6">Excessive Use</h3>
                    <p>Excessive use due to neglect or failure to repair or replace worn parts can result in cancellation of MTS coverage. MTS reserves the right to determine what constitutes excessive use.</p>
                <h3 class="text-navy-500 mt-6">Mishaps/ Accidents/ Impounds:</h3>
                    <p>
                        <ul>
                            <li>Mishaps- In the event of a single vehicle mishap (e.g. caused by loose gravel, oil slick, run off the road, etc.) MTS will handle that as any other call for roadside assistance.</li>
                            <li>Multiple vehicle accidents- MTS will provide towing for multi vehicle accidents if MTS is called first. If there are police officers on the scene their tow supersedes MTS towing services.</li>
                            <li>Impounds- MTS does not provide towing from any impound lot.</li>
                        </ul>
                    </p>
                <h3 class="text-navy-500 mt-6">Refusal Of Tow</h3>
                <p>If you refuse to be picked up by the MTS dispatched towing company, you must call the MTS dispatcher IMMEDIATELY so we can resolve the problem. If not, you will be held responsible for towing charges. If you refuse to pay them, your MTS coverage will be cancelled. </p>
                <h3 class="text-navy-500 mt-6">Leaving Before Help Arrives</h3>
                <p>If you call MTS for help, but you solve the problem before the towing company arrives and you DO NOT CALL OUR DISPATCHER TO CANCEL THE TOW- YOU WILL BE RESPONSIBLE FOR THE DISPATCH CHARGE. If you do not pay the dispatch charge, your MTS membership will be cancelled.</p>
            </div>
        </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';

    main {
        position: relative;
        top: calc(var(--header-size) + 30px );
    }

</style>
<script>
    import { defineComponent } from 'vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            FrontEndLayout,
        },
    })
</script>
