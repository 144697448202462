<template>
    <div>
        <Teleport to="body"><FlashMessage /></Teleport>
    </div>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('code')" @click="sortBy('code')">Plan Type</p>
            <p :class="sortClass('name')" @click="sortBy('name')">Member Type</p>
            <p :class="sortClass('title')" @click="sortBy('title')">Title</p>
            <p :class="sortClass('description')" @click="sortBy('description')" >Description</p>
        </div>
        <div class="simple-table" style="--cols:5">
            <p class="st-header first-col" :class="sortClass('code')" @click="sortBy('code')">Plan Type</p>
            <p class="st-header" :class="sortClass('name')" @click="sortBy('name')" >Member Type</p>
            <p class="st-header" :class="sortClass('title')" @click="sortBy('title')">Title</p>
            <p class="st-header" :class="sortClass('description')" @click="sortBy('description')" >Description</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Plan Type</span>{{ row.code }}</p>
                <p ><span class="st-hidden">Member Type</span>{{ row.name }}</p>
                <p ><span class="st-hidden">Title</span>{{ row.title }}</p>
                <p ><span class="st-hidden">Description</span>{{ row.description }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link
                            :href="`/dashboard/motorists/plan-types/edit_plan/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>

                        <jet-danger-button @click="confirmPlanDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingMemberDeletion" @close="closeModal">
        <template #title>
            Delete Member
        </template>

        <template #content>
            Are you sure you want to delete this Plan? (You will still be able to restore this plan if needed.)


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deletePlan(this.planId)" >
                Delete Plan
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            MemberHeader,
            FlashMessage,
            TableWrapper,
            Status,
            JetDangerButton,
            JetButton,
            JetDialogModal,
            JetSecondaryButton,
            Link
        },

        data() {
            return {
                confirmingMemberDeletion: false,
                planId: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            confirmPlanDeletion(index, id) {
                this.confirmingMemberDeletion = true;
                this.planId = id;
                this.index = index;
            },

            deletePlan(id) {
                this.$emit('row-deleted', id)
            },

            editMember: function (index, id) {
               this.$inertia.visit('/dashboard/motorists/memberships/edit_member/' + id);
            },
            closeModal: function () {
                this.confirmingMemberDeletion = false
            },
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>