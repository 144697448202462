import Glide from "@glidejs/glide";

export const mixin = {
    data() {
        return {
            windowWidth: window.innerWidth,
            initSuccess: false,
            slider: {},
            slides: [
                {
                    'mm_uuid': 1,
                    'title': 'Motorist Services',
                    'tagline': 'Our dispatch center will connect you with a tow company to get your bike taken care of.',
                    'button_url': '/motorists-and-rentals/motorist-services',
                    'button_target': '_self',
                    'button_text': 'Join MTS',
                    'desktop_path': '/images/marketingMessages/mm-d-motorist.jpg',
                    'tablet_path': '/images/marketingMessages/mm-t-motorist.jpg',
                    'mobile_path': '/images/marketingMessages/mm-m-motorist.jpg',
                    'image_alt': 'image of guy on a motorcycle smiling'
                },
                {
                    'mm_uuid': 2,
                    'title': 'Dealer Services',
                    'tagline': 'Offer an MTS membership as a perk for your customers.',
                    'button_url': '/dealerships/dealership-services',
                    'button_target': '_self',
                    'button_text': 'Add a Benefit',
                    'desktop_path': '/images/marketingMessages/mm-d-dealer.jpg',
                    'tablet_path': '/images/marketingMessages/mm-t-dealer.jpg',
                    'mobile_path': '/images/marketingMessages/mm-m-dealer.jpg',
                    'image_alt': 'image of people shaking hands'
                },
                {
                    'mm_uuid': 3,
                    'title': 'Tow Company Services',
                    'tagline': 'Join the MTS nationwide network of tow companies and we’ll connect you with stranded bikers when they need you most.',
                    'button_url': '/towing-companies/towing-company-services',
                    'button_target': '_self',
                    'button_text': 'Join Our Network',
                    'desktop_path': '/images/marketingMessages/mm-d-tow.jpg',
                    'tablet_path': '/images/marketingMessages/mm-t-tow.jpg',
                    'mobile_path': '/images/marketingMessages/mm-m-tow.jpg',
                    'image_alt': 'image of a trike tires and shocks'
                },
            ]
        }
    },
    computed: {
        slideCount() {
            return this.slides.length;
        },
        bgImageSize() {
            let result;
            if (this.windowWidth < 768) result = 'mobile_path';
            else if (this.windowWidth < 1024) result = 'tablet_path';
            else result = 'desktop_path';

            return result;
        }
	},
	methods: {
        onResize() {
            setTimeout(() => {
                this.windowWidth = window.innerWidth;
                this.slider.update();
                if (typeof this.playing !== undefined) {
                    this.playing = true;
                }
            }, 200);
        }
	},
    mounted() {
        if (!this.initSuccess) {
            this.slider = new Glide('.glide', this.options);
            this.slider.mount();
            this.initSuccess = true;

            //disable the slider if only one slide
            if (!(this.slides.length > 1)) {
                this.slider.disable();
            }
        }

        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    }
}