<template>

    <div class="auth-card mt-6 px-6 py-4 shadow-md overflow-hidden" :style="cardColor">
        <slot />
    </div>

</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';
    .auth-card {
        height: 100%;
        background-color: var(--card-color);

       @mixin not-mobile {
           width: 25vw;
       }
    }
</style>

<script>
import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "CTACard",
        props: {
            color: {
                type: String,
                required: true,
            }
        },
        components: {
            Link,
        },
        computed: {
            cardColor() {
                return `--card-color: var(--${this.color})`;
            }
        }
    }

</script>

