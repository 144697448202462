<template>
    <div class="simple-table-wrapper simple-table-wrapper-xl" v-if="!tows.isLoading">
        <jet-button class="button button-secondary absolute top-4 right-2" @click="exportCSV()">{{button_text}}</jet-button>
        <form class="search-form" @submit.prevent="tows.refreshData(applyFilters())">
            <div class="search-fields flex justify-between flex-wrap flex-col md:flex-row md:items-end">
                <input
                    class="st-search"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <div class="flex flex-col md:flex-row md:items-center">
                    <label for="date" class="m-1">Date Range </label>
                    <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                    <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                </div>
                <div>
                    <label for="member_type" class="m-1">Member Type </label>
                    <select v-model="member_type" class="border py-2 pl-3 pr-8 text-grey-800 m-1">
                        <option value=""> - </option>
                        <option
                            v-for="option in member_type_list"
                            :key="option.value"
                            :value="option.name"
                        >{{option.name}}</option>
                    </select>
                </div>
                <div>
                    <jet-button type="submit" class="button button-primary ml-2"><span class="fas fa-search">APPLY FILTERS</span></jet-button><JetSecondaryButton class="button button-primary m-1 flex-end" @click="resetFilters()">RESET FILTERS</JetSecondaryButton>
                </div>
            </div>
        </form>
        <div class="st-hidden-sorts">
            <p :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date',null, 'date')">Service Date</p>
            <p :class="tows.sortClass('member_type')" @click="tows.sortBy('member_type')" >Member Type</p>
            <p :class="tows.sortClass('membership_number')" @click="tows.sortBy('membership_number')" >Member #</p>
            <p :class="tows.sortClass('rental_agreement')" @click="tows.sortBy('rental_agreement')" >RA</p>
            <p :class="tows.sortClass('problem')" @click="tows.sortBy('problem')" >Problem</p>
            <p :class="tows.sortClass('towing_company')" @click="tows.sortBy('towing_company')" >Towing Company</p>
            <p :class="tows.sortClass('break_down_location')" @click="tows.sortBy('break_down_location')">City State</p>
            <p :class="tows.sortClass('dealer_name')" @click="tows.sortBy('dealer_name')" >Dealer</p>

        </div>
        <div class="simple-table" style="--cols:9">
            <p class="st-header first-col" :class="tows.sortClass('service_date')" @click="tows.sortBy('service_date',null,'date')">Service Date</p>
            <p class="st-header" :class="tows.sortClass('member_type')" @click="tows.sortBy('member_type')" >Member Type</p>
            <p class="st-header" :class="tows.sortClass('membership_number')" @click="tows.sortBy('membership_number')" >Member #</p>
            <p class="st-header" :class="tows.sortClass('rental_agreement')" @click="tows.sortBy('rental_agreement')" >RA</p>
            <p class="st-header" :class="tows.sortClass('problem')" @click="tows.sortBy('problem')" >Problem</p>
            <p class="st-header" :class="tows.sortClass('towing_company')" @click="tows.sortBy('towing_company')" >Towing Company</p>
            <p class="st-header" :class="tows.sortClass('break_down_location')" @click="tows.sortBy('break_down_location')" >Breakdown Location</p>
            <p class="st-header" :class="tows.sortClass('dealer_name')" @click="tows.sortBy('dealer_name')">Dealer</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in tows.data" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Service Date</span>{{ row.service_date }}</p>
                <p><span class="st-hidden">Member Type</span>{{ row.member_type }}</p>
                <p><span class="st-hidden">Member #</span>{{ row.member_number }}</p>
                <p><span class="st-hidden">RA</span>{{ row.rental_agreement }}</p>
                <p><span class="st-hidden">Problem</span>{{ row.problem }}</p>
                <p><span class="st-hidden">Towing Company</span>{{ row.towing_company }}</p>
                <p><span class="st-hidden">Breakdown Location</span>{{ row.break_down_location }}</p>
                <p><span class="st-hidden">Dealer</span>{{ row.dealer_name }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <jet-secondary-button @click="printPdf(row.id)">
                            Print PDF
                        </jet-secondary-button>
                        <Link
                            :href="`/dashboard/tow-requests/add_tow_requests_step3/breakdowns/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>
                        <jet-danger-button @click="confirmTowDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="st-bottom">
            <p>{{ tows.rangeText }}</p>
            <Pagination
                v-if="tows.itemCount || tows.itemCount <= tows.itemsPerPage"
                :active-page="tows.activePage"
                :item-count="tows.itemCount"
                :items-per-page="tows.itemsPerPage"
                @on-update="updateActivePage($event)"
            />
        </div>
    </div>
    <div class="position-relative py-5" v-else>
        <VueLoader />
    </div>
    <!-- Delete Tow Confirmation Modal -->
    <jet-dialog-modal :show="confirmingTowDeletion" @close="closeModal">
        <template #title>
            Delete Tow
        </template>

        <template #content>
            Are you sure you want to delete this Tow? (You will still be able to restore if needed.)
        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteTow(this.towId)" >
                Delete Tow
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import { reactive, toRefs } from 'vue'
    import EloquentSSFP from '@/EloquentSSFP'
    import Pagination from '@/Components/Pagination.vue'
    import VueLoader from '@/Components/VueLoader'
    import FlashMessage from '../../../../DashboardPartials/FlashMessages'
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import moment from 'moment';
import { resolveDynamicComponent } from 'vue'

    export default {
        mixins: [formattingMixin],
        components: {
            JetDangerButton,
            JetButton,
            JetDialogModal,
            Status,
            JetSecondaryButton,
            Link,
            Pagination,
            VueLoader,
            moment,
        },

        data() {
            return {
                tows: reactive(new EloquentSSFP()),
                searchModel: '',
                start_date: null,
                end_date: null,
                memberId: null,
                confirmingTowDeletion: false,
                towId: null,
                button_text: 'EXPORT',
                member_type_list: [],
                member_type: '',
                status: '',
                activePage: 1,
                activeSorts: [],
                sortsDirs: ['desc'],
                sortTypes: ['service_date'],
            }
        },
        methods: {
            applyFilters(optionsParam = this.tows.options) {
                let options = {...optionsParam};
                options['filterProps'] = [];
                options['filterValues'] = [];
                options['filterOperators'] = [];
                options['filterExclusivities'] = [];
                options['searchModel'] = "";

                if (this.searchModel) options['searchModel'] = this.searchModel;

                if (this.start_date || this.end_date) {
                    options['filterProps'].push('service_date');
                    options['filterOperators'].push([])
                    options['filterValues'].push([])
                    options['filterExclusivities'].push(1);

                    if (this.start_date) {
                        options['filterOperators'][0].push(">=");
                        options['filterValues'][0].push(this.start_date);
                    }
                    if (this.end_date) {
                        options['filterOperators'][0].push("<");
                        options['filterValues'][0].push((moment(this.end_date).add(1, "days")).format("YYYY-MM-DD"));
                    }
                }

                if (this.member_type) {
                    options['filterProps'].push('member_type');
                    options['filterOperators'].push([null])
                    options['filterValues'].push([this.member_type])
                    options['filterExclusivities'].push(1);
                }

                if (this.status) {
                    options['filterProps'].push('status');
                    options['filterValues'].push([this.status]);
                    options['filterOperators'].push([null]);
                    options['filterExclusivities'].push(1);
                }

                this.storeSearchCriteriaSession();
                return options;
            },
            storeSearchCriteriaSession() {
                sessionStorage.setItem('BreakdownsTableSearchModel', this.searchModel);
                sessionStorage.setItem('BreakdownsTableStatus', this.status);
                sessionStorage.setItem('BreakdownsTableMemberType', this.member_type);
                sessionStorage.setItem('BreakdownsTableStartDate', this.start_date);
                sessionStorage.setItem('BreakdownsTableEndDate', this.end_date);
                sessionStorage.setItem('BreakdownsTablePageNo', this.tows.activePage);
                sessionStorage.setItem('BreakdownsTableActiveSorts', JSON.stringify(this.tows.activeSorts));
                sessionStorage.setItem('BreakdownsTableSortDirs', JSON.stringify(this.tows.sortDirs));
                sessionStorage.setItem('BreakdownsTableSortTypes', JSON.stringify(this.tows.sortTypes));
                /*
                activeSorts = the properties in the data being sorted
                sortDirs = the directions those properties are sorting [asc, desc]
                sortTypes = the type of the data [String, Date, Number]
                */
            },
            getSearchCriteriaSession() {
                this.searchModel = sessionStorage.getItem('BreakdownsTableSearchModel') ? sessionStorage.getItem('BreakdownsTableSearchModel') : '';
                this.status = sessionStorage.getItem('BreakdownsTableStatus') ? sessionStorage.getItem('BreakdownsTableStatus') : '';
                this.member_type = sessionStorage.getItem('BreakdownsTableMemberType') ? sessionStorage.getItem('BreakdownsTableMemberType') : '';
                this.start_date = sessionStorage.getItem('BreakdownsTableStartDate') !== "null" ? sessionStorage.getItem('BreakdownsTableStartDate') : null;
                this.end_date = sessionStorage.getItem('BreakdownsTableEndDate') !== "null" ? sessionStorage.getItem('BreakdownsTableEndDate') : null;
                this.activePage = sessionStorage.getItem('BreakdownsTablePageNo') !== "null" ? Number(sessionStorage.getItem('BreakdownsTablePageNo')) : 1;
                this.activeSorts = JSON.parse(sessionStorage.getItem('BreakdownsTableActiveSorts'))??['service_date'];
                this.sortDirs = JSON.parse(sessionStorage.getItem('BreakdownsTableSortDirs'))??['desc'];
                this.sortTypes = JSON.parse(sessionStorage.getItem('BreakdownsTableSortTypes'))??[];
            },
            deleteSearchCriteriaSession() {
                sessionStorage.removeItem('BreakdownsTableSearchModel');
                sessionStorage.removeItem('BreakdownsTableStatus');
                sessionStorage.removeItem('BreakdownsTableMemberType');
                sessionStorage.removeItem('BreakdownsTableStartDate');
                sessionStorage.removeItem('BreakdownsTableEndDate');
                sessionStorage.removeItem('BreakdownsTablePageNo');
                sessionStorage.removeItem('BreakdownsTableActiveSorts');
                sessionStorage.removeItem('BreakdownsTableSortDirs');
                sessionStorage.removeItem('BreakdownsTableSortTypes');
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            updateActivePage(page) {
                this.tows.updateActivePage(page);
                window.scrollTo({top: 0, behavior: "smooth"});
            },
            fetchMemberTypes() {
                axios({
                    method: 'get',
                    url: route('dashboard.motorists.memberships.fetch_member_type_list'),
                })
                .then(response => {
                    this.member_type_list = response.data.data;
                })
            },
            resetFilters() {
                this.searchModel = '';
                this.start_date = null;
                this.end_date = null;
                this.tows.resetAll();
                this.member_type = '';
                this.deleteSearchCriteriaSession();
                this.tows.refreshData({
                    searchableData: ['service_date', 'member_type', 'membership_number', 'rental_agreement', 'tow_company_name', 'break_down_location', 'dealer_name', 'dealer_location', 'problem'],
                    sortDirs: ['desc'],
                    activeSorts: ['service_date'],
                    itemsPerPage: 50,
                });
            },
            printPdf: function (id) {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.breakdowns.print_pdf'),
                    responseType: "blob",
                    data: {
                        tow: id
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-breakdown-${id}.pdf`;
                        link.click();

                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });
            },
            closeModal: function () {
                this.confirmingTowDeletion = false;
            },
            resetOne: function () {
                this.$refs.customTable.updateActivePage(1);
            },
            confirmTowDeletion(index, id) {
                this.confirmingTowDeletion = true;
                this.towId = id;
                this.index = index;
            },
            deleteTow(id) {
                this.$inertia.post('/dashboard/tow-requests/delete_tow/' + id, id, {
                    onSuccess: (id) => {
                        this.tows.refreshData(this.tows.options);
                        this.closeModal();
                    }
                })
            },
            exportCSV() {
                this.button_text = 'Processing...';
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.breakdowns.export'),
                    responseType: "blob",
                    data: {
                        options: this.tows.options,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        tows: this.searchList
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `mts-breakdown-export.xlsx`;
                        link.click();
                        link.remove();
                    }
                    this.button_text = 'EXPORT';
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
        },
        computed: {
            currentPage() {
                return this.tows.activePage;
            },
            currentActiveSorts() {
                return this.tows.activeSorts;
            },
            currentSortDirs() {
                return this.tows.sortDirs;
            },
            currentSortTypes() {
                return this.tows.sortTypes;
            }
        },
        watch: {
            currentPage(n) {
                if (n) {
                    sessionStorage.setItem('BreakdownsTablePageNo', n);
                }
            },
            currentActiveSorts(n) {
                if (n) {
                    sessionStorage.setItem('BreakdownsTableActiveSorts', JSON.stringify(n));
                }
            },
            currentSortDirs(n) {
                if (n) {
                    sessionStorage.setItem('BreakdownsTableSortDirs', JSON.stringify(n));
                }
            },
            currentSortTypes(n) {
                if (n) {
                    sessionStorage.setItem('BreakdownsTableSortTypes', JSON.stringify(n));
                }
            }
        },
        mounted() {
            this.getSearchCriteriaSession();

            this.tows.init('/dashboard/back-office/breakdowns/fetch_all_tows_ssfp', this.applyFilters({
                searchableData: ['service_date', 'member_type', 'membership_number', 'rental_agreement', 'tow_company_name', 'break_down_location', 'dealer_name', 'dealer_location', 'problem'],
                sortDirs: ['desc'],
                activeSorts: ['service_date'],
                itemsPerPage: 50,
                activePage: this.activePage,
                sortDirs: this.sortDirs,
                activeSorts: this.activeSorts,
                sortTypes: this.sortTypes
            }));
            this.fetchMemberTypes();
        },
    }
</script>

<style lang="scss" scoped>
@import '../../../../../Components/VueTable/scss/_table-styles.scss';
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
