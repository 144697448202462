<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p :class="sortClass('phone')" @click="sortBy('phone')">Phone</p>
            <p :class="sortClass('after_hours')" @click="sortBy('after_hours')">After Hours</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('name')" @click="sortBy('name')">Name</p>
            <p class="st-header" :class="sortClass('phone')" @click="sortBy('phone')" >Phone</p>
            <p class="st-header" :class="sortClass('after_hours')" @click="sortBy('after_hours')">After Hours</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.name }}</p>
                <p ><span class="st-hidden">Name</span>{{ row.phone }}</p>
                <p><span class="st-hidden">After Hours</span>{{ row.after_hours }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button @click="setActiveDropdown(row.id)">Edit</button>
                        <jet-danger-button @click="confirmContactDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
                <ContactsTableDropdown
                    v-bind="dropdownBinder(row, row.id)"
                    @contact-updated="contactUpdated()"
                />
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Confirmation Modal -->
    <jet-dialog-modal :show="confirmingContactDeletion" @close="closeModal">
        <template #title>
            Delete Contact
        </template>

        <template #content>
            Are you sure you want to delete this Contact?


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteContact(this.contactId)" >
                Delete Contact
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import ContactsTableDropdown from './ContactsTableDropdown.vue';
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            ContactsTableDropdown,
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link,
        },
        props: ['tows'],
        data() {
            return {
                towForm: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    name: '',
                    phone: '',
                    after_hours: 0,
                }),
                confirmingContactDeletion: false,
                contactId: null,
            }
        },
        methods: {
            contactUpdated() {
                this.$emit('contacts-updated');
            },
            deleteContact(id) {
                this.$inertia.post('/dashboard/dealerships/delete_dealer_contact/' + id, id, {
                    onSuccess: (id) => {
                        this.contactUpdated();
                        this.closeModal();
                    }
                });
            },
            confirmContactDeletion(index, id) {
                this.confirmingContactDeletion = true;
                this.contactId = id;
                this.index = index;
            },
            closeModal: function () {
                this.confirmingContactDeletion = false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>
