<template>
    <Card
        title="Member Information">
         <jet-form-section @submitted="updateMemberInformation">
             <template #description>
                <p class="font-medium">Update your member information.</p>
            </template>

            <template #form>
            <!-- Address -->
            <div>
                <jet-label for="address" value="Address" />
                <jet-input id="address" type="text" class="mt-1 block w-full" v-model="form.address" />
                <jet-input-error :message="form.errors.address" class="mt-2" />
            </div>
            <div>
                <jet-label for="address2" value="Address 2" />
                <jet-input id="address2" type="text" class="mt-1 block w-full" v-model="form.address2" />
                <jet-input-error :message="form.errors.address2" class="mt-2" />
            </div>
            <div class="grid md:grid-cols-4 gap-4">
                <div>
                    <jet-label for="city" value="City" />
                    <jet-input id="city" type="text" class="mt-1 block w-full" v-model="form.city" />
                    <jet-input-error :message="form.errors.city" class="mt-2" />
                </div>
                <div>
                    <jet-label for="state" value="State/Province" />
                    <select id="state" class="mt-1 block w-full" v-model="form.state" name="state" required >
                        <option value=""> -- </option>
                        <option v-for='data in states' :value='data.abbreviation' :key="data.id">{{ data.name }}</option>
                    </select>
                    <jet-input-error :message="form.errors.state" class="mt-2" />
                </div>
                <div>
                    <jet-label for="postal_code" value="Zip/Postal Code" />
                    <jet-input id="postal_code" type="text" class="mt-1 block w-full" v-model="form.postal_code" />
                    <jet-input-error :message="form.errors.postal_code" class="mt-2" />
                </div>
                <div>
                    <jet-label class="mt-4" for="country" value="Country" />
                    <select id="country" class="mt-1 block w-full" v-model="form.country" required >
                        <option value=""> -- </option>
                        <option v-for='data in countries' :value='data' :key="data">{{ data }}</option>
                    </select>
                    <jet-input-error :message="form.errors.country" class="mt-2" />
                </div>
            </div>
            <div>
                <jet-label for="club" value="Club" />
                <jet-input id="club" type="text" class="mt-1 block w-full" v-model="form.club" />
                <jet-input-error :message="form.errors.club" class="mt-2" />
            </div>
            </template>

            <template #actions>
                <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                    Saved.
                </jet-action-message>

                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
                </jet-button>
            </template>
        </jet-form-section>
    </Card>
</template>

<script>
    import { defineComponent } from 'vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import Card from '@/Components/Card.vue'

    export default defineComponent({
        components: {
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            Card,
        },

        props: ['user'],

        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    club: '',
                }),
                states: [],
                countries: ['USA', 'Canada'],
            }
        },

        methods: {
            fetchMemberData() {
                var userId = this.$page.props.user.id;
                //need something here to check if it is a number before we do the call, or do check within membercontroller
                axios({
                    method: 'post',
                    url: route('dashboard.motorists.memberships.fetch_member_data_by_user'),
                    data: {
                        user_id: userId
                    }
                })
                .then(response => {
                    Object.entries(response.data.data).forEach(([key, value]) => {
                        if(key != 'bikes' && key != 'family_members') {
                            this.form[key] = value;
                        }
                    });
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                    }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            updateMemberInformation() {
                this.form.post(route('dashboard.motorists.memberships.update_member_info'), {
                    errorBag: 'updateMemberInfo',
                    preserveScroll: true,
                    // onSuccess: () => (this.clearPhotoFileInput()),
                });
            },
        },
        mounted() {
            this.fetchMemberData();
            this.getStates();
        },
    })
</script>
