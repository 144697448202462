<template>
 <div>
        <Teleport to="body"><FlashMessage /></Teleport>
</div>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('company_name')" @click="sortBy('company_name')">Name</p>
            <p :class="sortClass('location')" @click="sortBy('location')">City/State</p>
            <p :class="sortClass('added_date')" @click="sortBy('added_date')">Added Date</p>
            <p :class="sortClass('last_login')" @click="sortBy('last_login')">Last Login</p>
            <p :class="sortClass('status')" @click="sortBy('status')">Status</p>
        </div>
        <div class="simple-table" style="--cols:6">
            <p class="st-header first-col" :class="sortClass('company_name')" @click="sortBy('company_name')">Name</p>
            <p class="st-header" :class="sortClass('location')" @click="sortBy('location')" >City/State</p>
            <p class="st-header" :class="sortClass('added_date')" @click="sortBy('added_date')">Added Date</p>
            <p class="st-header" :class="sortClass('last_login')" @click="sortBy('last_login')">Last Login</p>
            <p class="st-header" :class="sortClass('status')" @click="sortBy('status')">Status</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.company_name }}</p>
                <p><span class="st-hidden">City/State</span>{{ row.location }}</p>
                <p><span class="st-hidden">Added Date</span>{{ row.added_date }}</p>
                <p><span class="st-hidden">Last Login</span>{{ row.last_login }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.status" /></p>

                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons" v-if="$page.props.user.current_team.name == 'Admin'" >
                        <Link
                                :href="`/dashboard/dealerships/edit_dealer/${row.id}`"
                                as="button"
                                type="button"
                                v-if="row.role_type != 'AD'"
                            >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>
                        <jet-danger-button  @click="confirmDealerDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Dealer Confirmation Modal -->
    <jet-dialog-modal :show="confirmingDealerDeletion" @close="closeModal">
        <template #title>
            Delete Dealer
        </template>

        <template #content>
            Are you sure you want to delete this Dealer? (You will still be able to restore this Dealer if needed.)


        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteDealer(this.planId)" >
                Delete Dealer
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import MemberHeader from '../../../DashboardPartials/MemberHeader.vue'
    import FlashMessage from '../../../DashboardPartials/FlashMessages'
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            MemberHeader,
            FlashMessage,
            TableWrapper,
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link
        },

        data() {
            return {
                confirmingDealerDeletion: false,
                planId: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted'],
        methods: {
            confirmDealerDeletion(index, id) {
                this.confirmingDealerDeletion = true;
                this.planId = id;
                this.index = index;
            },

            deleteDealer(id) {
                this.$emit('row-deleted', id);
            },

            editMember: function (index, id) {
               this.$inertia.visit('/dashboard/dealerships/edit_dealer/' + id);
            },
            closeModal: function () {
                this.confirmingDealerDeletion = false
            },
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>