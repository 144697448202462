<template>
    <div class="app" :class="{expanded: expandedNav}">
        <Head :title="title" />

        <jet-banner />

        <div class="app-wrapper">
            <AppSidebarAdmin v-if="$page.props.user.current_team.name == 'Admin'" />
            <AppSidebarOffice v-if="$page.props.user.current_team.name == 'Office Staff'" />
            <AppSidebarDispatcher v-if="$page.props.user.current_team.name == 'CSR'" />
            <AppSidebarMember
                v-if="$page.props.user.current_team.name == 'Member'"
                :member-id = "$page.props.member.member.member_id"
            />
            <AppSidebarDealer
                v-if="$page.props.user.current_team.name == 'Dealer'"
                :dealer_id ="$page.props.dealer.dealer.dealer_id"
            />

            <div class="hamburger" @click="expandToggle()">
                <FontAwesomeIcon v-if="!expandedNav" fixed-width :icon="bars"/>
                <FontAwesomeIcon v-else fixed-width :icon="times"/>
            </div>

            <div class="content-section">
                <div class="content-top">
                    <div class="account-actions">
                        <form @submit.prevent="logout">
                            <JetDropdownLink as="button" class="font-bold">
                                Log Out
                            </JetDropdownLink>
                        </form>
                        <div v-if="$page.props.user.current_team.name == 'Admin'">
                            <Link href="/dashboard/settings" class="text-navy-500 mr-2" title="Internal link in same tab site settings page" >
                                <FontAwesomeIcon :icon="gears" />
                            </Link>
                         </div>
                        <div class="relative">
                            <!-- Teams Dropdown -->
                            <JetDropdown align="right" width="max" v-if="$page.props.jetstream.hasTeamFeatures">
                                <template #trigger>
                                    <span class="flex rounded-md items-center">
                                        <p v-if="$page.props.user.current_team.name == 'Member'"><a class="phone-num h6" href="tel:800-999-7064">800-999-7064</a></p>
                                        <button class="text-navy-500">
                                            <FontAwesomeIcon :icon="user" />
                                            <span>{{ $page.props?.webmaster?.fullName || $page.props.user.name }}
                                           </span>
                                        </button>
                                    </span>
                                </template>

                                <template #content>
                                    <div class="w-max">
                                        <template v-if="$page.props.jetstream.hasTeamFeatures">
                                            <div class="block px-4 py-2 text-xs text-gray-400">
                                                Manage Account
                                            </div>

                                            <JetDropdownLink :href="route('profile.show')">
                                                Profile
                                            </JetDropdownLink>

                                        <JetDropdownLink :href="route('api-tokens.index')" v-if="$page.props.jetstream.hasApiFeatures">
                                            API Tokens
                                        </JetDropdownLink>

                                             <form @submit.prevent="logout">
                                                <JetDropdownLink as="button">
                                                    Log Out
                                                </JetDropdownLink>
                                            </form>
                                        </template>
                                    </div>
                                </template>
                            </JetDropdown>
                        </div>
                    </div>

                    <header v-if="isDash">
                        <h2 class="header-greeting" v-if="$page.props.user.current_team.name == 'Dealer'">Hello, {{ $page.props.webmaster === null ? $page.props.user.name : $page.props.webmaster.fullName }} ( {{ $page.props.dealer.dealer.dealer_name }} )</h2>
                        <h2 class="header-greeting" v-else-if="$page.props.user.current_team.name == 'Member'">Hello, {{ $page.props.webmaster === null ? $page.props.user.name : $page.props.webmaster.fullName }} ( {{ userStatus }} )</h2>
                        <h2 class="header-greeting" v-else >Hello, {{ $page.props.webmaster === null ? $page.props.user.name : $page.props.webmaster.fullName }} ( {{ $page.props.user.current_team ? $page.props.user.current_team.name : '' }} )</h2>
                        <p v-if="$page.props.user.current_team.name == 'Member'">Member ID: {{ membershipNumber }} </p>
                        <p>Last Visited: {{ dateTime($page.props.user.last_logged_in) }}</p>
                    </header>
                </div>

                <main>
                    <slot></slot>
                </main>
            </div>

            <AppFooter />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    main { top: 0; }

    .app-wrapper {
        overflow: auto;
        height: 100%;
        display: grid;
        min-height: 100vh;
        grid-template:
            "nav content" max-content
            "footer footer" max-content
            / max-content 1fr;

        .hamburger {
            @include fluid-size(18px, 20px, font-size);
            font-weight: bold;
            position: relative;
            color: var(--blue-800);
            overflow: hidden;
            position: fixed;
            bottom: 1rem;
            left: calc(50% - 8.5px); // The width of the average scrollbar
            transform: translate(-50%, 0);
            transition: 200ms ease-out;
            background-color: var(--gray-200);
            border-radius: 50rem;
            box-shadow: 2px 1px 5px rgba(0,0,0,.15);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;

             @include bp-down($bp-lg) {
                height: 40px;
                width: 40px;
            }

             @include bp-up($bp-lg + 1) {
                height: 0;
                width: 0;
            }
        }

        .content-section {
            grid-area: content;
            overflow: auto;
            background-color: var(--gray-300);
            min-height: 90vh;

            @include not-desktop {
                .app.expanded & { overflow: hidden; }
            }

            .content-top {
                display: flex;
                flex-direction: column;

                header {
                    align-self: flex-start;
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    @include fluid-size(16px, 32px, padding-bottom);

                    .header-greeting {
                        @include fluid-size(20px, 42px, font-size);
                        color: var(--navy-500);
                        font-weight: 700;
                        line-height: 1;
                    }
                }

                .account-actions {
                    align-self: flex-end;
                    display: flex;
                    align-items: center;
                    @include fluid-size(0px, 16px, padding);

                    button {
                        @include fluid-size(14px, 18px, font-size);
                        display: flex;
                        align-items: center;
                        font-weight: 800;
                        margin: .5rem;
                        text-align: left;
                        line-height: 1;

                        svg { @include fluid-size(18px, 24px, font-size) }

                        svg, span {
                            margin: .25rem;
                        }
                    }
                }
            }

            main {
                @include fluid-size(16px, 32px, padding-left);
                @include fluid-size(16px, 32px, padding-right);
                @include fluid-size(16px, 32px, padding-bottom);
                @include fluid-size(32px, 48px, padding-top);
            }
        }
    }

    .phone-num {
        color: var(--navy-500);
        font-weight: 700;
    }
</style>

<script>
    import { defineComponent } from 'vue'
    import JetApplicationMark from '@/Jetstream/ApplicationMark.vue'
    import JetBanner from '@/Jetstream/Banner.vue'
    import JetDropdown from '@/Jetstream/Dropdown.vue'
    import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
    import JetNavLink from '@/Jetstream/NavLink.vue'
    import JetResponsiveNavLink from '@/Jetstream/ResponsiveNavLink.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import { faBars, faTimes, faUser, faMask, faCogs } from '@fortawesome/free-solid-svg-icons';
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import AppSidebarAdmin from '@/Layouts/AppSidebarAdmin.vue'
    import AppSidebarOffice from '@/Layouts/AppSidebarOffice.vue'
    import AppSidebarMember from '@/Layouts/AppSidebarMember.vue'
    import AppSidebarDispatcher from '@/Layouts/AppSidebarDispatcher.vue'
    import AppSidebarDealer from '@/Layouts/AppSidebarDealer.vue'
    import AppSidebarTC from '@/Layouts/AppSidebarTC.vue'
    import AppFooter from '@/Layouts/AppFooter.vue'
    import moment from 'moment';
    import dayjs from 'dayjs'

    export default defineComponent({
        props: {
            title: String,
        },

        components: {
            Head,
            JetApplicationMark,
            JetBanner,
            JetDropdown,
            JetDropdownLink,
            JetNavLink,
            JetResponsiveNavLink,
            Link,
            FontAwesomeIcon,
            JetApplicationLogo,
            AppSidebarAdmin,
            AppSidebarOffice,
            AppSidebarMember,
            AppSidebarDispatcher,
            AppSidebarDealer,
            AppSidebarTC,
            AppFooter,
            moment,
            dayjs,
        },

        data() {
            return {
                showingNavigationDropdown: false,
                expandedNav: false,
                loading: false,
                times: faTimes,
                bars: faBars,
                user: faUser,
                mask: faMask,
                gears: faCogs,
                userStatus: null,
                membershipNumber: null,
            }
        },

        watch: {
            expandedNav(n,o) {
                if (!n) document.body.style.overflow = '';
            }
        },
        computed: {
            isDash() {
                return route().current() === 'dashboard'
            }
        },
        methods: {
            expandToggle() {
                this.expandedNav = !this.expandedNav;
            },
            switchToTeam(team) {
                this.$inertia.put(route('current-team.update'), {
                    'team_id': team.id
                }, {
                    preserveState: false
                })
            },
            dateTime(value) {
                return dayjs(value).format('MM/DD/YYYY');
            },
            logout() {
                this.$inertia.post(route('logout'));
            },
            getUserStatus() {
                if (this.$page.props.member.member && this.$page.props.member.member.status)
                {
                    this.userStatus = this.$page.props.member.member.status;
                    this.membershipNumber = this.$page.props.member.member.member_number;
                    if (this.userStatus != 'Current' && this.userStatus != '') {
                        axios.get('/fetch_user_status/' + this.$page.props.member.member.member_id,{}).then(function(response){
                            this.userStatus = response.data[0];
                            this.membershipNumber = response.data[1];
                        }.bind(this));
                    }
                }
            },
        },
        mounted() {
            this.getUserStatus()
        },
    })
</script>
