<template>
    <FrontEndLayout title="Privacy Policy">
        <template #head>
            <meta name="description" content="Privacy Policy" />
            <meta name="keywords" content="privacy policy" />
        </template>
        <template #header>
            <h1>PRIVACY POLICY</h1>
        </template>
        <template #fepage-content>
            <div class="container py-6">
            <h2 class="text-navy-500 font-bold text-center">Privacy Policy</h2>
            <p class="text-center">
                This privacy policy sets out how Motorcycle Towing Services,
                L.C. uses and protects any information that you give Motorcycle
                Towing Services, L.C. when you use this website. Motorcycle
                Towing Services, L.C. is committed to ensuring that your privacy
                is protected. Should we ask you to provide certain information
                by which you can be identified when using this website, then you
                can be assured that it will only be used in accordance with this
                privacy statement. Motorcycle Towing Services, L.C. may change
                this policy from time to time by updating this page. You should
                check this page from time to time to ensure that you are happy
                with any changes.
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
                <h3 class="text-navy-500 mt-6">What We Collect</h3>
                <p>We may collect the following information:</p>
                <ul class="red-check list-outside mx-auto">
                    <li>name</li>
                    <li>contact information including email address</li>
                    <li>
                        demographic information such as postcode, preferences and
                        interests
                    </li>
                    <li>
                        other information relevant to customer surveys and/or offers
                    </li>
                </ul>
            </div>
            <div>
                <h3 class="text-navy-500 mt-6">What We Do With the Information We Gather</h3>
                <p>
                    We require this information to understand your needs and provide
                    you with a better service, and in particular for the following
                    reasons:
                </p>
                <ul class="red-check list-outside mx-auto">
                    <li>Internal record keeping.</li>
                    <li>
                        We may use the information to improve our products and
                        services.
                    </li>
                    <li>
                        We may periodically send promotional emails about new
                        products, special offers or other information which we think
                        you may find interesting using the email address which you
                        have provided.
                    </li>
                    <li>
                        From time to time, we may also use your information to
                        contact you for market research purposes. We may contact you
                        by email, phone, fax or mail. We may use the information to
                        customize the website according to your interests.
                    </li>
                </ul>
                </div>
            </div>
            <h3 class="text-navy-500 mt-6">Security</h3>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
            <h3 class="text-navy-500 mt-6">Links to Other Websites</h3>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
            <h3 class="text-navy-500 mt-6">Controlling Your Personal Information</h3>
            <p>
                You may choose to restrict the collection or use of your
                personal information in the following ways:
            </p>
            <ul class="red-check list-outside mx-auto">
                <li>
                    whenever you are asked to fill in a form on the website,
                    look for the box that you can click to indicate that you do
                    not want the information to be used by anybody for direct
                    marketing purposes
                </li>
                <li>
                    if you have previously agreed to us using your personal
                    information for direct marketing purposes, you may change
                    your mind at any time by writing to or emailing us at
                    info@mts-towing.com.
                </li>
            </ul>

            <p>
                We will not sell, distribute or lease your personal information
                to third parties unless we have your permission or are required
                by law to do so. We may use your personal information to send
                you promotional information about third parties and partners
                which we think you may find interesting.
            </p>
            <br />

            <p>
                You may request details of personal information which we hold
                about you under the Data Protection Act 1998. A small fee will
                be payable. If you would like a copy of the information held on
                you please write to PO Box 559, Denver, IA 50622.
            </p>
            <br />

            <p>
                If you believe that any information we are holding on you is
                incorrect or incomplete, please write to or email us as soon as
                possible, at the above address. We will promptly correct any
                information found to be incorrect.
            </p>
            </div>
        </template>
    </FrontEndLayout>
</template>
<style lang="scss">
    @import '../../../scss/_mixins.scss';

main {
    position: relative;
    top: calc(var(--header-size) + 30px );
}

.gray-section {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--gray-300);
        padding: 3rem 0;
        width: 100%;
        justify-content: center;
        margin: 100px 0;


       &:before {
            content: '';
            background-image: url(/images/cta-gray-top.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            top: -23px;
            left: 0;
            z-index: 1;

            @mixin bp-between($md, $bp-2xl) {
                top: -32px;
            }

            @include bp-up($bp-2xl + 1) {
                top: -90px;
            }
       }

        &:after {
            content: '';
            background-image: url(/images/cta-gray-bottom.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 90px;
            width: 100%;
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 1;

        }
    }
</style>

<script>
import { defineComponent } from "vue";
import FrontEndLayout from "@/Layouts/FrontEndLayout.vue";

export default defineComponent({
    components: {
        FrontEndLayout,
    },
});
</script>
