<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('membership_number')" @click="sortBy('membership_number')">Membership #</p>
            <p :class="sortClass('full_name')" @click="sortBy('full_name')" >Motorist</p>
            <p :class="sortClass('member_type')" @click="sortBy('member_type')">Member Type</p>
            <p :class="sortClass('plan_type')" @click="sortBy('plan_type')">Plan Type</p>
            <p :class="sortClass('city_state')" @click="sortBy('city_state')">City State</p>
            <p :class="sortClass('plan_expiration')" @click="sortBy('plan_expiration','date')">Expiration</p>
            <p :class="sortClass('status')" @click="sortBy('status')">Status</p>

        </div>
        <div class="simple-table" style="--cols:8">
            <p class="st-header first-col" :class="sortClass('Membership_No')" @click="sortBy('membership_number')">Membership #</p>
            <p class="st-header" :class="sortClass('full_name')" @click="sortBy('full_name')" >Motorist</p>
            <p class="st-header" :class="sortClass('member_type')" @click="sortBy('member_type')">Member Type</p>
            <p class="st-header" :class="sortClass('plan_type')" @click="sortBy('plan_type')">Plan Type</p>
            <p class="st-header" :class="sortClass('addresses')" @click="sortBy('city_state')">City / State</p>
            <p class="st-header" :class="sortClass('plan_expiration')" @click="sortBy('plan_expiration','date')">Expiration</p>
            <p class="st-header" :class="sortClass('status')" @click="sortBy('status')">Status</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Membership #</span>{{ row.membership_number }}</p>
                <p><span class="st-hidden">Motorist</span>{{ row.full_name }}</p>
                <p><span class="st-hidden">Member Type</span>{{ row.member_type }}</p>
                <p><span class="st-hidden">Plan Type</span>{{ row.plan_type }} </p>
                <p><span class="st-hidden">City / State</span>{{ row.city_state }}</p>
                <p><span class="st-hidden">Expiration</span>{{ row.plan_expiration }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.status" /></p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons" v-if="$page.props.user.current_team.name == 'Dealer'">
                        <jet-secondary-button
                            @click="renew(row.membership_number, row.plan_expiration)"
                        >
                            RENEW
                        </jet-secondary-button>
                        <Link
                            :href="`/dashboard/motorists/memberships/edit_member/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>

                        <jet-danger-button @click="confirmMemberDeletion(index, row.id)">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
    <!-- Delete Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingMemberDeletion" @close="closeModal">
        <template #title>
            Delete Member
        </template>

        <template #content>
            Are you sure you want to delete this member? (You will still be able to restore this member if needed.)

        </template>

        <template #footer>
            <jet-secondary-button @click="closeModal">
                Cancel
            </jet-secondary-button>

            <jet-danger-button class="ml-2" @click="deleteMember(this.memberId)" >
                Delete Member
            </jet-danger-button>
        </template>
    </jet-dialog-modal>
    <!-- Renew Member Confirmation Modal -->
    <jet-dialog-modal :show="confirmingMemberRenewal" @close="closeRenewModal">
        <template #title>
            Renew Member
        </template>

        <template #content>
            Are you sure you want to renew until <strong>{{ futureExpiration }}</strong>?
        </template>

        <template #footer>
            <jet-secondary-button @click="closeRenewModal">
                Cancel
            </jet-secondary-button>

            <jet-button class="ml-2" @click="renewMember(this.memberId)" >
                Renew
            </jet-button>
        </template>
    </jet-dialog-modal>
</template>

<script>
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Status from '@/Components/Status.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import moment from 'moment'
    import { defineComponent } from '@vue/runtime-core'


    export default defineComponent({
        mixins: [tableMixin, formattingMixin],
        components: {
            TableWrapper,
            Status,
            JetDangerButton,
            JetDialogModal,
            JetSecondaryButton,
            JetButton,
            Link,
            moment,
        },

        data() {
            return {
                confirmingMemberDeletion: false,
                confirmingMemberRenewal: false,
                memberId: null,
                currentExpiration: null,
            }
        },
        props: ['data'],
        emits: ['row-deleted','refresh-table'],
        methods: {
            confirmMemberDeletion(index, id) {
                this.confirmingMemberDeletion = true;
                this.memberId = id;
                this.index = index;
            },

            deleteMember(id) {
                this.$emit('row-deleted', id);
            },

            editMember: function (index, id) {
               this.$inertia.visit('/dashboard/motorists/memberships/edit_member/' + id);
            },
            closeModal: function () {
                this.confirmingMemberDeletion = false;
            },
            closeRenewModal: function () {
                this.confirmingMemberRenewal = false;
            },
            renew(id, expiration) {
                this.confirmingMemberRenewal = true;
                this.memberId = id;
                this.currentExpiration = expiration;
            },
            renewMember(id) {
                this.$inertia.post('/dashboard/dealerships/renew_customer/' + id, id, {
                    onSuccess: (id) => {
                        this.closeRenewModal();
                        this.$emit('refresh-table');
                    }
                })
            },
        },
        computed: {
            futureExpiration: function() {
                return moment(this.currentExpiration).add(1, 'Y').format('MM/DD/YYYY');
            }
        }
    })
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
