<template>
    <app-layout title="Dashboard - Coupon Report">
        <template #header>
        </template>
        
        <Card
            title="Coupon Report">
            <p class="w-3/4 pb-3 text-sm">To get a list of coupons used, please select the date range.</p>
            <div class="search-fields flex flex-wrap flex-col md:flex-row">
            <input
                class="st-search pl-3 m-1"
                type="search"
                v-model="searchModel"
                placeholder="Search..."
            />
            <div class="flex flex-col md:flex-row md:items-center">
                <label for="date" class="m-1 pl-3">Date Range </label>
                <input class="m-1 w-100 md:w-auto" type="date" name="start_date" v-model="start_date" placeholder="Start Date"/>
                <input class="m-1 w-100 md:w-auto" type="date" name="end_date" v-model="end_date" placeholder="End Date"/>
                <JetButton class="button button-primary m-1 flex-end" @click="resetFilters()">Reset Filters</JetButton>
            </div>
                <div>
                    <JetButton class="button button-secondary top-buttons m-1" @click="exportCSV()">Export</JetButton>
                </div>
            </div>
            <div class="search-fields flex justify-between flex-row items-end">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <b>Total Used:</b> {{ totals_data.total_used }}
                    </div>
                    <div>
                        <b>Discount Total:</b> ${{ parseFloat(totals_data.discount_total).toFixed(2) }}
                    </div>
                    <div>
                        <b>Sales Total:</b> ${{ parseFloat(totals_data.sales_total).toFixed(2) }}
                    </div>
                </div>
            </div>
            <div v-if="filterCouponData == null || filterCouponData.length == 0">
                <VueLoader />
            </div>
            <div v-else>
                <CouponReportTable
                    :data="filterCouponData"
                    :searchable-data="['coupon_code', 'usage_date', 'coupon_price', 'original_plan_price', 'plan_option_coupon_price', 'original_plan_option_price', 'discount_total']"
                    :items-per-page="20"
                    @update-selection="updateSelection($event)"
                    ref="couponCodeTable"
                />
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import CouponReportTable from './Tables/CouponReportTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            CouponReportTable,
            Link,
            Card,
            JetButton,
            JetSecondaryButton,
            JetDialogModal,
            VueLoader,
        },
        data() {
            return {
                coupon_data: [],
                grid_data: [], // Send to xlsx generator
                start_date: null,
                end_date: null,
                searchModel: '',
            }
        },
        computed: {
            filterCouponData() {
                if(this.coupon_data){
                    let startDate = this.localizeDate(this.start_date);
                    let endDate = this.localizeDate(this.end_date);

                    let couponDataFiltered = this.coupon_data;

                    return couponDataFiltered.filter(couponUsage => {
                        const usageDate = new Date(couponUsage.usage_date)
                        const couponCode = couponUsage.coupon_code;
                        let search = true;
                        if (this.searchModel)
                        {
                            search = couponCode.toLowerCase().includes(this.searchModel.toLowerCase());
                        }

                        if (startDate && endDate) {
                            return startDate <= usageDate && usageDate <= endDate && search;
                        }
                        if (startDate && !endDate) {
                            return startDate <= usageDate && search;
                        }
                        if (!startDate && endDate) {
                            return usageDate <= endDate && search;
                        }
                        
                        return search;
                    })
                }
            },
            totals_data() {
                let $this_totals = {
                    total_used: 0,
                    discount_total: 0,
                    sales_total: 0
                };

                if (this.grid_data) {
                    this.grid_data.forEach(row => {
                        let $opTotal = row.coupon_price != null ? row.original_plan_price - row.coupon_price : 0;
                        let $poTotal = row.original_plan_option_price != null ? row.original_plan_option_price - row.plan_option_coupon_price : 0;
                        row.discount_total = $opTotal + $poTotal;
                        if (row.coupon_price == null)
                        {
                            row.coupon_price = row.original_plan_price;
                        }
                        if (row.plan_option_coupon_price == null)
                        {
                            row.plan_option_coupon_price = row.original_plan_option_price;
                        }
                        $this_totals.total_used ++;
                        $this_totals.discount_total += ($opTotal + $poTotal);
                        $this_totals.sales_total += parseFloat(row.coupon_price) + parseFloat(row.plan_option_coupon_price);
                    });
                }

                return $this_totals;
            }
        },
        methods: {
            fetchCouponUsageData() {
                axios
                    .get(route('dashboard.back_office.coupon_report.fetch_coupon_usage'))
                    .then(response => {
                        this.coupon_data = response.data;
                        this.updateSelection(this.coupon_data);
                        this.coupon_data.forEach(row => {
                            row.discount_total = row.original_plan_price - row.coupon_price;
                        });
                    })
            },
            resetFilters() {
                this.searchModel = '';
                this.start_date = null;
                this.end_date = null;
                this.$refs.couponCodeTable.resetOne();
                this.coupon_data.forEach(row => {
                    row.discount_total = row.original_plan_price - row.coupon_price;
                });
            },
            localizeDate(date) {
                if (!date || !date.includes('-')) return date
                const [yyyy, mm, dd] = date.split('-')
                return new Date(`${mm}/${dd}/${yyyy}`)
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(date))
            },
            updateSelection($data) {
                this.grid_data = $data;
            },
            exportCSV() {
                axios({
                    method: 'post',
                    url: route('dashboard.back_office.coupon_report.export_csv'),
                    responseType: "blob",
                    data: {
                        gridData: this.grid_data
                    }
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });

                    //For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        //Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `coupon-usage-export.xlsx`;
                        link.click();
                        link.remove();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.hasError = true;
                });

            },
        },
        created() {
            this.fetchCouponUsageData();
        }
    })
</script>
