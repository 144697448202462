<template>
    <app-layout title="Dashboard - Testimonials">
        <template #header>
        </template>
        <Teleport to="body"><FlashMessage /></Teleport>
        <Card
            title="Testimonials"
        >
            <Link
                href="/dashboard/testimonials/add_modify"
                as="button"
                type="button"
                >
                <JetButton class="button button-primary absolute top-4 right-4" >
                    Add Testimonial
                </JetButton>
            </Link>
            <div v-if="testimonials.length == 0">
                <VueLoader />
            </div>
            <div v-else>
                <TestimonialsTable
                    :data="testimonials"
                    @row-deleted="rowDeleted($event)"
                    ref="testimonialsTable"
                    :searchable-data="['type', 'submitted_by', 'created_at', 'status']"
                />
            </div>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import TestimonialsTable from './Tables/TestimonialsTable.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Card from '@/Components/Card.vue';
    import JetButton from '@/Jetstream/Button.vue'
    import FlashMessage from '../../DashboardPartials/FlashMessages.vue'
    import VueLoader from '@/Components/VueLoader'

    export default defineComponent({
        components: {
            AppLayout,
            TestimonialsTable,
            Link,
            Card,
            JetButton,
            FlashMessage,
            VueLoader
        },
        data() {
            return {
                testimonials:[],
            }
        },
        methods: {
            fetchTestimonials() {
                axios
                    .get(route('dashboard.testimonials.fetch_testimonials'))
                    .then(response => {
                        this.testimonials = response.data.data;
                    })
            },
            rowDeleted(id) {
                this.$inertia.post('/dashboard/testimonials/delete/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchTestimonials();
                        this.$refs.testimonialsTable.closeModal();
                    }
                })
            }
        },
        mounted() {
            this.fetchTestimonials();
        }
    })
</script>
