<template>
    <div id="map"></div>
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader'
import JetButton from '@/Jetstream/Button.vue'

export default {
    components: {
        Loader,
        JetButton,
    },
    props: {
        lat: String,
        lng: String,
        rangeList: Array,
        selectedTowingCompany: Number,
    },
    emits: ['selected-towing-company'],
    computed: {
        propsSet(){
            return this.lat && this.lng && (this.rangeList.length > 0);
        }

    },
    watch: {
        propsSet(n){
            if(n){
                this.mountMap();
            }
        }
    },
    methods: {
        setSelectedTowingCompany(tc){
            this.$emit('selected-towing-company', tc);
        },
        mountMap() {
            const vm = this;
            const loader = new Loader({
                // MTS API KEY: AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ
                apiKey: "AIzaSyCLPNXKObcIIy30YplgXIpZuViBRvISmOQ",
                version: "weekly",
                libraries: ["places"]
            });

            var mapLat = parseFloat(this.lat)
            var mapLng = parseFloat(this.lng)
            var locationList = this.rangeList

            const mapOptions = {
                center: {
                    lat: mapLat,
                    lng: mapLng,
                },
                zoom: 9,
                mapTypeId: 'roadmap'
            };

            // Promise
            loader.load()
            .then((google) => {
                const map = new google.maps.Map(document.getElementById("map"), mapOptions);

                let prevWindow = false;

                var userMarker = new google.maps.Marker({
                    position: { lat: mapLat, lng: mapLng },
                    map,
                    title: "Breakdown location",
                    draggable: false,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                    index: 1,
                });

                locationList.forEach((location, id) => {
                    const $vm = this;
                    const contentString ='<div class="gmap-info-window"><div class="window-wrapper"><div class="content"><p class="title">'+location.name+'</p><p>'+location.address+'</p><p>'+location.address2+'</p><p>'+location.city+' '+location.state+', '+location.postal_code+'</p>'+
                    +'<p>Phone: '+location.phone+'</p>'
                    +'<p>After Hours: '+location.phone_24hrs+'</p>'
                    +'<p>Distance: '+parseFloat(location.distance).toFixed(2)+' miles</p>'
                    +'<button type="button" onclick="setTC('+ location.id +')" style="background-color: rgb(26, 115, 232); color: white; border: 0px; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; margin: 5px 0px 5px 0px; padding: 0px 0.25em; font: 400 12px Roboto, Arial, sans-serif; overflow: hidden; height: 40px; width: 100%; cursor: pointer;">Select Towing Company</button>'
                    +'</div></div></div>';
                    //create info window foreach location
                    let infoWindow = new google.maps.InfoWindow({
                        content: contentString
                    });

                    window.setTC = function(id) {
                        vm.setSelectedTowingCompany(id);
                        return false;
                    }

                    //set up markers for each location
                    let marker = new google.maps.Marker({
                        clickable: true,
                        title: location.name,
                        icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                        map,
                        animation: google.maps.Animation.DROP,
                        position: {
                            lat: parseFloat(location.latitude),
                            lng: parseFloat(location.longitude)
                        },

                    });

                    marker.addListener('click', function(){
                        if (prevWindow) prevWindow.close();
                        prevWindow = infoWindow;
                        infoWindow.open(map, marker);
                    });
                    marker.set('id', id);
                });

            })
            .catch(e => {
                console.log(e);
            });
        }
    },
}
</script>
<style lang="scss" scoped>
    #map{
        height:500px;
        .gmap-info-window {
        .window-wrapper {
            display: flex;
            align-items: stretch;
        }

        .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: .25rem;
        }

        .hour-overview {
            font-size: 12px;
            margin-bottom: 0;
        }

        .image-wrapper {
            position: relative;
            height: 60px;
            width: 60px;
            flex: 0 0 auto;
            overflow: hidden;
            border-radius: 50rem;
            border: hsl(0deg,0%,95%);
            margin-right: 12px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                vertical-align: bottom;
            }
        }
    }
    }

</style>
