<template>
    <app-layout title="Dashboard - My Bikes">
        <template #header>
        </template>
        <Card
            title="Bikes -- Add / Remove or Edit your bikes here.">
            <jet-form-section @submitted="updateDealerBike">
                <template #form @submit.prevent="form.post('/modify-member-bikes')" >
                    <div v-if="showAdd === true" class="col-span-6 sm:col-span-4">
                        <h3 class="mt-4 font-medium text-navy-300">Motorcycle{{ motorcycles > 1 ? 's' : '' }}</h3>
                        <Motorcycle
                            v-for="n in motorcycles"
                            :key="'member-motorcycle-' + n"
                            :index="n"
                            :states=states
                            v-model:motorcycle-year="form.member_motorcycles[n-1].motorcycle_year"
                            v-model:motorcycle-make="form.member_motorcycles[n-1].motorcycle_make"
                            v-model:motorcycle-model="form.member_motorcycles[n-1].motorcycle_model"
                            v-model:motorcycle-color="form.member_motorcycles[n-1].motorcycle_color"
                            v-model:motorcycle-plate="form.member_motorcycles[n-1].motorcycle_plate"
                            v-model:motorcycle-state="form.member_motorcycles[n-1].motorcycle_state"
                            v-model:motorcycle-country="form.member_motorcycles[n-1].motorcycle_country"
                            v-model:motorcycle-vin="form.member_motorcycles[n-1].motorcycle_vin"
                            v-model:trike-autocycle="form.member_motorcycles[n-1].trike_autocycle"

                        />
                        <jet-button type="button" @click="addAnotherMotorcycle();" class="ml-4 mt-4">
                            Add Another Bike
                        </jet-button>
                        <jet-button v-if="motorcycles > 1" type="button" @click="removeMotorcycle();" class="ml-4 mt-4">
                            Remove Bike
                        </jet-button>
                        <jet-button v-if="showAdd" @click="updateDealerBike()" class="ml-5" :disabled="form.processing">
                            Save
                        </jet-button>
                    </div>
                    <BikesTable
                        :data="bikes"
                        :states=states
                        @bikes-updated="fetchDealerBikes()"
                        @row-deleted="rowDeleted($event, 'bike')"
                        ref="bikeTable"
                    />
                    <jet-button @click="showAddMotorcycle()" >
                        Add Another Bike
                    </jet-button>
                </template>
            </jet-form-section>
        </Card>
    </app-layout>
</template>

<script>
    import { defineComponent } from 'vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import MemberHeader from '../../DashboardPartials/MemberHeader.vue'
    import Motorcycle from '../Motorists/Memberships/Forms/Partials/Motorcycles.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import FlashMessage from '../../DashboardPartials/FlashMessages'
    import BikesTable from '../Motorists/Membership/Tables/BikesTable.vue'
    import Card from '@/Components/Card.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default defineComponent({
        mixins: [formattingMixin],

        components: {
            AppLayout,
            JetApplicationLogo,
            MemberHeader,
            FlashMessage,
            BikesTable,
            Card,
            Motorcycle,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
        },
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    id: '',
                    member_motorcycles: [{
                        motorcycle_year: '',
                        motorcycle_make: '',
                        motorcycle_model: '',
                        motorcycle_color: '',
                        motorcycle_plate: '',
                        motorcycle_state: '',
                        motorcycle_country: '',
                        motorcycle_vin: '',
                        trike_autocycle: 0,
                    }],
                }),
                bikes: [],
                states: [],
                motorcycles: 1,
                showAdd: false,
            }
        },
        methods: {
            fetchDealerBikes() {
                var dealerId = this.$page.props.dealer.dealer.dealer_id;
                axios({
                    method: 'post',
                    url: route('dashboard.dealerships.fetch_dealer_bikes'),
                    data: {
                        dealer_id: dealerId
                    }
                })
                .then(response => {
                    this.bikes = response.data.data;
                    this.form.id = dealerId;
                    this.showAdd = false;
                })
            },
            getStates: function() {
                axios.get('/get_states',{

                    }).then(function(response){
                    this.states = response.data;
                }.bind(this));
            },
            rowDeleted(id, table) {
                this.$inertia.post('/dashboard/motorists/memberships/delete_bike/' + id, id, {
                    onSuccess: (id) => {
                        this.fetchDealerBikes();
                        this.$refs.bikeTable.closeModal();
                    }
                })
            },
            showAddMotorcycle () {
                this.showAdd = true;
            },
            addAnotherMotorcycle () {
                this.motorcycles++;
                this.form.member_motorcycles.push({
                    motorcycle_year: '',
                    motorcycle_make: '',
                    motorcycle_model: '',
                    motorcycle_color: '',
                    motorcycle_plate: '',
                    motorcycle_state: '',
                    motorcycle_country: '',
                    motorcycle_vin: '',
                    trike_autocycle: 0,
                })
            },
            removeMotorcycle () {
                this.motorcycles--;
                this.form.member_motorcycles.pop();
            },
            updateDealerBike() {
                this.form.post(route('dashboard.dealerships.dealer_bikes_update'), {
                    errorBag: 'addModifyMemberBikes',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.form.reset();
                        this.fetchDealerBikes();
                    }
                });
            },
        },
        mounted() {
            this.fetchDealerBikes();
            this.getStates();
        }
    });
</script>
