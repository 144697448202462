<template>
    <TableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('date_sort')" @click="sortBy('date_sort')">Service Date</p>
            <p :class="sortClass('problem')" @click="sortBy('problem')">Problem</p>
            <p :class="sortClass('towing_company')" @click="sortBy('towing_company')">Towing Company</p>
        </div>

        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('date_sort')" @click="sortBy('date_sort')">Service Date</p>
            <p class="st-header" :class="sortClass('problem')" @click="sortBy('problem')" >Problem</p>
            <p class="st-header" :class="sortClass('towing_company')" @click="sortBy('towing_company')">Towing Co</p>
            <p class="st-header last-col"><span v-if="currentteam != 5">Actions</span></p>

            <template v-for="(row, index) in displayedList" :key="'row' + row.id">
                <p class="first-col"><span class="st-hidden">Service Date</span>{{ row.service_date }}</p>
                <p ><span class="st-hidden">Problem</span>{{ row.problem }}</p>
                <p><span class="st-hidden">Towing Company</span>{{ row.towing_company }}</p>
                <div class="last-col">
                    <span class="st-hidden" v-if="currentteam != 5">Actions</span>
                    <div class="st-buttons" v-if="currentteam != 5">
                        <Link
                            :href="`/dashboard/tow-requests/add_tow_requests_step3/edit/${row.id}`"
                            as="button"
                            type="button"
                            v-if="row.role_type != 'AD'"
                        >
                            <jet-button>
                                Edit
                            </jet-button>
                        </Link>
                        <jet-danger-button @click="confirmTowDeletion(index, row.id)" v-if="currentteam != 3">
                            Delete
                        </jet-danger-button>
                    </div>
                </div>
            </template>
        </div>
    </TableWrapper>
</template>

<script>
    import TableWrapper from '@/Components/VueTable/TableWrapper.vue'
    import tableMixin from '@/Mixins/tableMixin.js'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import JetButton from '@/Jetstream/Button.vue'
    import JetFormSection from '@/Jetstream/FormSection.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetDialogModal from '@/Jetstream/DialogModal.vue'
    import JetDangerButton from '@/Jetstream/DangerButton.vue'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            TableWrapper,
            JetActionMessage,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetDangerButton,
            JetDialogModal,
            Link
        },
        props: ['tows', 'bikes', 'currentteam'],
        methods: {
            towsUpdated() {
                this.$emit('tows-updated')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, minmax(min-content, auto)) minmax(min-content, max-content);
    }
</style>
