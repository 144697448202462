import moment from 'moment';

export default {
    methods: {
        formatMoney(val) {
            if (typeof val === 'string') val = Number(val.replace(/[^\d.]/g,''));
            if (!val&&val!==0) return '-'

            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(Number(val));
        },
        formatDate(val) {
            if (moment(val).isValid()) {
                return moment(val).format("MM/DD/YYYY");
            }
            return val;
        },
        formatPhone(val) {
            ac = substr(val, 0, 3); // 123
            prefix = substr(val, 3, 3); // 456
            suffix = substr(val, 6); // 7890
            formatted = "("+ac+") "+prefix+"-"+suffix;
            return $formatted;
        }
    }
}