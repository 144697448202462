<template>
<FrontEndLayout title="Reset Password" class="z100">
    <Head title="Log in" />
<template #fepage-content>
    <div class="loginBG">
        <div class="login-box">
            <h2 class="text-white font-bold text-center uppercase">Portal Login</h2>
            <JetAuthenticationCard
                color="gray-300">
                <jet-validation-errors class="mb-4" />
                <div>
                    <p>Passwords must be at least 8 characters long and contain: one uppercase letter, one number, and one special character.</p>
                </div>
                <div v-if="status" class="mb-4 font-medium text-sm text-green">
                    {{ status }}
                </div>

                <form @submit.prevent="submit">
                    <div>
                        <jet-label for="username" value="Username" />
                        <jet-input id="username" type="text" class="mt-1 block w-full" v-model="form.username" required autofocus readonly />
                    </div>

                    <div class="mt-4">
                        <jet-label for="password" value="Password" />
                        <jet-input id="password" type="password" class="mt-1 block w-full" v-model="form.password" required autocomplete="new-password" />
                    </div>

                    <div class="mt-4">
                        <jet-label for="password_confirmation" value="Confirm Password" />
                        <jet-input id="password_confirmation" type="password" class="mt-1 block w-full" v-model="form.password_confirmation" required autocomplete="new-password" />
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <Link :href="route('login')" class="mr-2">
                            <jet-button>
                                Back to Login
                            </jet-button>
                        </Link>
                        <jet-button class="ml-4 bg-red-500 hover:bg-red-200" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Reset Password
                        </jet-button>
                    </div>
                </form>
            </JetAuthenticationCard>
        </div>
        <div class="login-img" style="background-image: url(/images/img-login-bg.png)" alt="twisting road"/>
    </div>
    </template>
    </FrontEndLayout>
</template>
<style lang="scss" scoped>
@import '../../../scss/_mixins.scss';

.loginBG {
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    opacity: 1;
    transition: 200ms ease-out;
    height: 85vh;
    top: var(--header-size);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -3;

        .login-img {
        position: absolute;
        height: 120vh;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: var(--mm-overlay);
            }
        }
}

.login-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0 1rem;

    .apply-now-sec {
        background-color: var(--blue-500);
        padding: 1rem;
        justify-items: center;

        @mixin not-mobile {
           width: 25vw;
       }

        .app-links {
            position: relative;
            font-size: 16px;
            text-decoration: underline;
            font-weight: bold;
            padding: 0 0 0 .5rem;

            &:after {
                content: '|';
                position: absolute;
                color: white;
                right: -1.5rem;
            }

            &:last-child:after {
                content: '';
                position: absolute;
            }
        }
    }
}
</style>
<script>
    import { defineComponent } from 'vue';
    import { Head, Link } from '@inertiajs/inertia-vue3';
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import FrontEndLayout from '@/Layouts/FrontEndLayout.vue'

    export default defineComponent({
        components: {
            Head,
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetLabel,
            JetValidationErrors,
            Link,
            FrontEndLayout,
        },

        props: {
            email: String,
            token: String,
            username: String,
        },

        data() {
            return {
                form: this.$inertia.form({
                    token: this.token,
                    email: this.email,
                    username: this.username,
                    password: '',
                    password_confirmation: '',
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('password.update'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            }
        }
    })
</script>
